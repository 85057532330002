import { LitElement, html, css } from 'lit';
import { customElement, property,state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { DocumentationService } from '../../services/documentation.service';
import { container } from 'tsyringe';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

@customElement('se-documentation')
export class SeDocumentation extends LitElement {
    private _documentationService: DocumentationService;

    @property() customStyle: any = {};
    @property({ type: String }) documentUrl;

    @state() private documentContent = "";
    @state() private documentTitle = "";

    constructor() {
        super();

        this._documentationService = container.resolve(DocumentationService);
    }


    async firstUpdated() {
        const result = await this._documentationService.api.getDocumentationAsync(this.documentUrl);

        this.documentContent = result.value.body;
        this.documentTitle = result.value.title;
    }

    render() {
        return html`
        <div class="document">
            <h1><span style="font-size:110%; color: white;">${unsafeHTML(this.documentTitle)}</span></h1>
            ${unsafeHTML(this.documentContent)}
        </div>
        `;

    }

    static styles = css`
    :host([hidden]) {
        display: none;
    }
    :host {
        display: block;
        width: 100%;
    }
    .button {       
        padding: 10px;
        border-radius: 6px;
        justify-content: center;
        width:100%;
        transition: all 0.25s;
        font: var(--font-button);
        background-color: var(--primary-color);
        border: 1px solid gray;
        color: black;
    }
    .button:disabled {
        opacity: 1;
        background-color: var(--primary-color);
        border: 1px solid lightgray;
        color: gray;
        transition: none;
    }
    .button:hover {
        border: 1px solid black;
        background-color: white;
        box-shadow: 2px 2px 2px lightgray;
    }
    .button.red:hover {
        background-color: crimson;
        color: white;
        border: 1px solid crimson;
    }
    .button:hover:disabled {
        background-color: var(--primary-color);
        border: 1px solid lightgray;
        box-shadow: none;
    }
    .button:active {
        background-color: white;
        box-shadow: none;
    }
    .button.red:active {
        background-color: crimson;
        color: white;
        border: 1px solid crimson;
    }
     .iconImage {
        position: absolute;
        transform: translate(50%, -50%);
        top: 50%;
        width: 25px;
    }
    .document img,.document svg{
        width:100%;
    }
    .code{
        overflow: auto;
        background: var(--color-navy);
        padding-left: 10px;
        border-radius: 4px;
    }
    .document{
        background: var(--color-navy-1);
        color: var(--color-gray-2);
        padding: 20px;
        height: 100%;

    }
    .document a{
        color: var(--color-purple);
    }

  `;
}
import { ErrorSeverity } from "../marketplace/enums/error-severity";

export class SimpleError {
    constructor(public message: string, public severity = ErrorSeverity.Error) {

    }
}

export class NetworkError extends SimpleError {
    constructor(message: string, public severity = ErrorSeverity.Error, public status: number = 0) {
        super(message);
    }
}

export type NetworkResult<T> = Result<T, NetworkError>;
export type VoidNetworkResult = VoidResult<NetworkError>;

export type SimpleResult<T> = Result<T, SimpleError>;
export type VoidSimpleResult = VoidResult<SimpleError>;

export class Result<T, E> {
    
    private _isOk = true;

    get isOk() { return this._isOk }
    get isErr() { return !this._isOk }

    constructor(public value?: T, public err?: E) {
        if (err) {
            this._isOk = false;
        }
    }

    voidResult() {
        return new VoidResult(this.err);
    }
}

export class VoidResult<E> {
    private _isOk = true;

    get isOk() { return this._isOk }
    get isErr() { return !this._isOk }

    constructor(public err?: E) {
        if (err) {
            this._isOk = false;
        }
    }
}

export function ok<T, E>(value: T) {
    return new Result<T, E>(value);
}

export function okVoid<E>() {
    return new VoidResult<E>();
}

export function err<T, E>(err: E) {
    return new Result<T, E>(undefined, err);
}

export function errVoid<E>(err: E) {
    return new VoidResult<E>(err);
}

export class ModalEditorResult<T> extends Result<T, NetworkError> {

    constructor(public isSave?: boolean, value?: T, err?: NetworkError) {
        super(value, err);
        if (err) {
            this.isSave = false;
        }
    }
}
import { container, inject, singleton } from 'tsyringe';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';
import { SupportEmailViewModel } from '../models/support-email-view-model';


@singleton()
export class SupportApi extends BaseApi {
    private _authService: AuthService;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }
    async sendContactUs(email: SupportEmailViewModel) {
        return this.postObjectAsync(`/api/support/contact-us`, email, this._authService.token);
    }

}
import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import "element-internals-polyfill";
import { BaseEditor, SimpleEditor } from '../base-editor';
import { styleMap } from 'lit/directives/style-map.js';

export type inputType = "button" | "checkbox" | "color" | "date" | "datetime-local" |
    "email" | "file" | "hidden" | "image" | "month" | "number" | "password" |
    "radio" | "range" | "reset" | "search" | "submit" | "tel" | "text" | "time" |
    "url" | "week";

@customElement('se-input-inline-editor')
export class InputEditorInlineElement extends LitElement implements SimpleEditor {
    
    @property() value: string;
    get liveValue() { return this._inputElement?.value};

    @property({ type: Number }) size?: number;
    @property({ type: Number }) minlength?: number;
    @property({ type: Number }) maxlength?: number;
    @property() pattern?: string;
    @property() placeholder?: string;
    @property({ attribute: 'input-width' }) inputWidth?: string;
    @property({ type: Boolean }) readonly?: boolean;
    @property({ type: Boolean }) required = false;
    @property({ type: Boolean }) disabled = false;
    @property() name: string;    
    @property() type: inputType = "text";
    @property({ attribute: 'editor-size' }) editorSize: 'small' | 'nomal';

    @query('#input') private _inputElement: HTMLInputElement;

    constructor() {
        super();
    }    

    valueChanged() {
        this.dispatchEvent(new CustomEvent("valueChanged", { bubbles: true, composed: true, detail: { editor: this } }))
    }
    updateValue(event) {        
        if (this._inputElement.checkValidity())
            this._inputElement.classList.remove("invalid");
        else
            this._inputElement.classList.add("invalid");
        this.dispatchEvent(new CustomEvent("editorChanged", { bubbles: true, composed: true, detail: { editor: this } }))
    }
    reportValidity(): boolean {        
        if (this._inputElement.reportValidity()) {
            this._inputElement.classList.remove("invalid");
            return true;
        }
        else {
            this._inputElement.classList.add("invalid");
            return false;
        }            
    }

    setCustomValidity(error: string) {
        this._inputElement.setCustomValidity(error);
    }
    
    firstUpdated() {       
    }

    cancel() {
        this._inputElement.classList.remove("invalid");
        this.requestUpdate();
    }

    render() {
        const font = { font: this.editorSize === 'small' ? 'var(--font-input-small)' : 'var(--font-input)' };
        return html`            
                <input id="input" name="${this.name}" style="${styleMap({ ...font, width: this.inputWidth })}"
                    type="${this.type}" 
                    .value="${live(this.value)}"
                    @input="${this.updateValue}"
                    @change="${this.valueChanged}"
                    maxlength="${ifDefined(this.maxlength)}"
                    minlength="${ifDefined(this.minlength)}"
                    size="${ifDefined(this.size)}"
                    pattern="${ifDefined(this.pattern)}"
                    placeholder="${ifDefined(this.placeholder)}"
                    ?required="${this.required}"
                    ?disabled="${this.disabled}"                      
                />                
        `;
    }

    static styles = css`
    :host([hidden]) {
        display: none;        
    }
    :host {
        display: block;
    }        
    input.invalid {
        outline: 2px solid pink;
    }
  `;
}


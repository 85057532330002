import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { LitElement, html, css, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import './components/primary-button.element';
import './components/secondary-button.element';
import './editors/input-editor.element';
import './editors/checkbox-editor.element';
import logo from '../../../assets/logo-tagline.svg'
import twitter from '../../../assets/twitter.svg'
import linkedin from '../../../assets/linkedin.svg'
import instagram from '../../../assets/instagram.svg'
import msSignin from '../../../assets/ms-signin.png'
import googleSigninDark from '../../../assets/google_signin_dark.png'
import { BaseEditor } from './editors/base-editor';
import { AppConfigApi } from '../services/app-config.api';
import { container } from 'tsyringe';
import { AppConfigService } from '../services/app-config.service';
import { InputEditorElement } from './editors/input-editor.element';
import { CheckboxEditorElement } from './editors/checkbox-editor.element';
import { AuthService } from '../services/auth.service';
import { ToasterService } from '../services/toaster.service';
import Styles from '../../../assets/styles';
import { SePrimaryButton } from './components/primary-button.element';

@customElement('se-login')
export class SeLoginElement extends LitElement {    

    private _appConfigService: AppConfigService;
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _message: string;

    @query('#email') private _emailEditor: InputEditorElement;
    @query('#password') private _passwordEditor: InputEditorElement;
    @query('#stayLoggedIn') private _stayLoggedInEditor: CheckboxEditorElement;
    @query('.loginButton') private _loginButton: SePrimaryButton;
    @state() private _hasChanged = false;
    @state() private _isPasswordIncorrect = false;


    constructor() {
        super();
        this.submitLogin = this.submitLogin.bind(this);
        this.save = this.save.bind(this);

        this._appConfigService = container.resolve(AppConfigService);
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {        

        if (this._appConfigService.isNew) {            
            return commands.redirect('/create-first-user');
        }
        else {
            this._message = (new URLSearchParams(location.search)).get('message');
            return undefined;
        }
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener('keydown', this.submitLogin);
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev))
    }
    disconnectedCallback() {
        this.removeEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev))
        super.disconnectedCallback();
    }

    submitLogin(event) {
        if (event.key === 'Enter') {
            this.save(event);
        }
    }
    private onEditorChanged(ev) {
        this._hasChanged = true;
    }
    
    updateChangedValues() {        
    }

    firstUpdated() {
        if (this._message) {
            this._toasterService.showWarning(this._message);
        }
    }

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false)
                return false;
        }
        return true;
    }

    private async save(ev?) {
        ev.preventDefault();
        this._toasterService.clear();
        if (this.reportValidity()) {
            this._loginButton.loading = true; // Set loading property to true
            await this._authService.loginAsync(this._emailEditor.liveValue, this._passwordEditor.liveValue, this._stayLoggedInEditor.liveValue)
                .then(() => {
                    this._isPasswordIncorrect = false;
                    this.success();
                })
                .catch(err => {
                    this._isPasswordIncorrect = true;
                    this.error(err);
                })
                .finally(() => {
                    this._loginButton.loading = false; // Set loading property back to false
                });
        }
    }


    private async register(ev) {
        ev.preventDefault();
        Router.go("/register");
        
    }

    private async forgotPassword(ev) {
        ev.preventDefault();
        Router.go("/forgot-password");

    }
    
    private success() {
        Router.go("/");
    }

    private error(err: Error) {
        this._toasterService.showError(err.message);
    }

    render() {
        return html`            
            <form id="editorForm" class="editor">

                <div class="scroll-container">
                    <div class="content"> 
                        <div style="text-align: center;margin-bottom: 10px;">
                            <img src=${logo} class="header-logo" />
                        </div>
                        <se-input-editor enableAutocomplete .tabindex=${"1"} class="inputEditor" id="email" name="email" type="email" placeholder="example@company.com" label="Email" labelPosition="top" input-type="email" required ></se-input-editor>
                        <div class="password-container">
                            <se-input-editor enableAutocomplete .tabindex=${"2"} class="inputEditor" id="password" name="password" type="password" label="Password" labelPosition="top" input-type="password" required></se-input-editor>
                            <a href="" class="link forgot" @click="${this.forgotPassword}">Forgot?</a>
                            ${this._isPasswordIncorrect ? html`<div class="error-message">Incorrect email or password</div>` : ''}
                        </div>
                        <se-primary-button class="inputEditor loginButton" @click="${this.save}" text="Login"></se-primary-button>
                        <div class="loginLink">
                            <a href="" class="link" @click="${this.register}">Register</a>
                        </div>
                    </div>
                </div>
                <div class="savePanel" style="display: none">
                    <se-checkbox-editor .value=${true} id="stayLoggedIn" label="Stay signed in for 30 days" labelPosition="right" style="width: 100%"></se-checkbox-editor>
                </div>
                <div class="authenticationProviders" style="display: none">
                    <hr style="height:2px;width: 100%;margin-top: 15px; background-color:lightgray;border-width:0">
                    <div style="display: flex; align-items: center;justify-content: space-between;">
                        <input type="image" src=${msSignin} style="height: 30px"></input>
                        <input type="image" src=${googleSigninDark} style="height: 32px"></input>
                    </div>
                </div>
            </form>
            <div class="footer"><div>© 2024 Sequentum  •  <a href="https://www.sequentum.com/privacy-policy" target="_blank">Privacy Policy</a>  •  <a href="https://www.sequentum.com/terms-of-service" target="_blank">Terms of Service</a></div>
                <div style="text-align: right; flex:1">
                    <a href="https://twitter.com/sequentuminc" target="_blank"><img src=${twitter}></a>
                    <a href="https://www.linkedin.com/company/sequentum/" target="_blank"><img src=${linkedin}></a>
                    <a href="https://www.instagram.com/sequentuminc/" target="_blank"><img src=${instagram}></a>
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    .button {
        width: 30px;
        padding: 10px 10px 10px 10px;
    }
    .inputEditor{
        width: 100%;
    }

    .savePanel {
        margin-top: 4px;
    }
    .password-container{
        position: relative;
    }
    .forgot{
        position: absolute;
        top: 0;
        right: 0;
    }
    .incorrect-password {
        border-color: red;
    }
    .error-message {
        color: red;
        margin-top: 5px;
    }
    @media (max-width: 600px) {
        .editor {
            width: 100%;
        }
        .scroll-container {
            padding: 40px;
        }
        .header-logo {
            height: 50%;
        }
        .footer {
            display: none;
        }
    }
    
      `]
    };
}
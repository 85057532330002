import { container, inject, singleton } from 'tsyringe';
import { err, NetworkError, ok, Result } from '../../utils/result';
import { SeEvent } from '../../utils/se-events';
import { AppConfigApi } from './app-config.api';
import { SourceApi } from './source.api';
import { UserApi } from './user-api';

@singleton()
export class SourceService {

    private _sourceApi: SourceApi;

    get api() { return this._sourceApi };
    
    constructor() {        
        this._sourceApi = container.resolve(SourceApi);
    }    


}
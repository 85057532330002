import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { container } from 'tsyringe';
import { AuthService } from '../services/auth.service';
import { SelectEditorElement } from './editors/select-editor.element';
import './components/fa-icon.element';
import { UserApi } from '../services/user-api';
import { classMap } from 'lit/directives/class-map.js';
import { htmlTitle } from '../directives/html-title.directive';
import { ToasterService } from '../services/toaster.service';
import { LabelFilter } from '../enums/label-filter';
import { config } from '@fortawesome/fontawesome-svg-core';
import { ConfigScope } from '../enums/config-scope';
import logo from '../../../assets/logoDark.png'
import gear from '../../../assets/gear.svg'
import avatar from '../../../assets/avatar.svg'
import help from '../../../assets/help.svg'
import TriangleDown from '../../../assets/triangle-down.svg'
import Styles from '../../../assets/styles';


import { MenuService } from '../services/menu.service';
import { MenuItem } from './components/context-menu.element';

@customElement('se-top-menu')
export class SeTopMenuElement extends LitElement {


//New Pipe Setup Wizard --- > to steps names







    private _authService: AuthService;
    private _userApi: UserApi;
    private _toasterService: ToasterService;
    private _menuService: MenuService;

    @state() private _draftCount = 0;
    @state() private _selectedSpace: string;
    @state() private _selectedLabel: string;


    @query('#space') private _spaceEditor: SelectEditorElement;
    @query('#label') private _labelEditor: SelectEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userApi = container.resolve(UserApi);
        this._toasterService = container.resolve(ToasterService);
        this._menuService = container.resolve(MenuService);

    }


    async loadSpaces() {

    }
    async loadLabels() {

    }


    async connectedCallback() {
        super.connectedCallback();
        await this.loadSpaces();

    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    firstUpdated() {
    }

    private onChangePassword() {
        Router.go("/change-password");
    }

    private openMenu(event: MouseEvent, menu: MenuItem[]) {
        event.stopPropagation();
        this._menuService.openContextMenu({ isMouseEnter: event.type === 'mouseenter', button: event.target as HTMLElement, activeClass: 'menu-active', menu: menu, group: 'top' });
    }

    private openAccountMenu(event: MouseEvent) {
        this.openMenu(event, this.accountMenu());
    }
    

    private accountMenu() {
        return [
            { text: 'Logout', action: this.onLogout.bind(this) },
            { text: 'Change Password', action: this.onChangePassword.bind(this) },
            { text: '-' },
            { text: 'Edit' }
        ];
    }
    
    private helpMenu() {
        return [
            { text: 'Manual' },
            { text: 'Support' },
            { text: '-' },
            { text: 'About' }
        ];
    }

    private onLogout() {
        this._authService.logout();
        Router.go(`/login`)
    }
    private success() {
        Router.go("/");
    }

    private error(err: Error) {
        this._toasterService.showError(err.message);
    }

    private openHelpMenu(event: MouseEvent) {
        this.openMenu(event, this.helpMenu());
    }

    render() {
        return html`        
        <div class="body">
                

        </div>

        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;
        color: white;
        display: flex;
        flex-direction: column;
        font: var(--font);      
        height: 50px;
        background: #fff;
        border-bottom:0px solid #D0D3DC;
    }
    .separator {
      border-right: 1px solid black;
      height: 20px; /* Adjust the height as needed */
    }
    .image-top {
        width: 30px;
    }
    .image-container{
        width: 30px;
        height: 30px;
        background-position: center;
        background-size: cover;
    }
    .body {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        flex: 1;
        overflow-y: hidden;
        scrollbar-width: thin;
        scrollbar-color: var(--color-primary-tint) var(--color-primary);
        scrollbar-gutter: unset;
        gap: 20px;
        padding: 0px 41px;
    }
    .triangle-down{
        width: 15px;
    }
    .body:hover {
         overflow-y: auto;        
    }
    .editor {
        font: var(--font);
        margin-top: 10px;
        margin-right: 10px;
        flex:1;        
    }
    .operations {
        display: flex;
        gap: 5px;
        font: var(--font-small);
        color: white;
    }
    .select-panel {
        display: flex;        
        color: white;
        align-items: baseline;
        justify-content: space-between;
        padding-left: 15px;        
    }
    a {
        color: white;        
    }    
    .buckets {
        display: flex;
        flex-direction: column;        
    }
    .types {
        display: flex;
        flex-direction: column;        
    }
    .menu-item {
        cursor: default;
        padding: 2px 5px 2px 5px;
        color: #1A1F4B;
        display: flex;        
        justify-content: space-between;
        margin: 0px 0px 1px 0px;
        padding-left: 15px;
        gap: 5px
    }
    .menuItemSelected {
        font-weight: 900;
    }
    .menu-item:hover .menu {        
        visibility: visible;
    }
    .menu:hover {
        color:white;
    }
    .count {        
        padding: 4px 4px 4px 7px;
    }
    .menu {
        color: silver;        
        padding: 4px 5px 4px 0px;        
    }
    .menu-item .menu {
        visibility: hidden;
    }
    .menu-item a {
        text-decoration: none;
    }
    .menu-text {
        flex:1;        
        display: block;
        padding: 4px 10px 4px 0px;
    }
    .labels {        
        display: flex;
        flex-direction: column;
        border-top: solid 1px #404566;
        padding: 15px;
    }
    .add-menu-item {
        display: flex;        
        font: var(--font-small);
        margin-top: 3px;
        padding-left: 15px;
        gap: 3px;

    }
    .footer {
        padding: 5px 10px 0px 10px        
    }    

    ::-webkit-scrollbar {
         width: 10px;
    }
    ::-webkit-scrollbar-thumb {      
      border-radius: 20px;
      border: solid 1px gray;
      background-color: var(--color-primary-tint);
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--color-primary);
    }
    
      `];
    }
}
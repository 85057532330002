import { container, inject, singleton } from 'tsyringe';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';
import { DestinationsViewModel } from '../models/destinations-view-model';
import { DestinationViewModel } from '../models/destination-view-model';
import { RunViewModel } from '../models/run-view-model';
import { PipesViewModel } from '../models/pipes-view-model';


@singleton()
export class DestinationApi extends BaseApi {
    private _authService: AuthService;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }
    async callbackgoogle(code: string) {
        return this.postObjectAsync(`/api/destination/Callbackgoogle`, code, this._authService.token);
    } 
    /*async getAllDestinationsAsync(pageIndex = 1, recordsPerPage = 100, destinationName: string = '', sortOrder: number = -1) {
        return this.getObjectAsync<DestinationsViewModel>(`/api/destination?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&destinationName=${destinationName}&sortOrder=${sortOrder}`, this._authService.token);
    }*/
    async getAllDestinationsAsync(searchRequest: any) {
        return this.postObjectReturnObjectAsync<DestinationsViewModel>(`/api/destination`, searchRequest, this._authService.token);
    }
    async getGoogleOauthLink() {
        return this.getObjectAsync<string>(`/api/destination/googleOauthLink`, this._authService.token);
    }
    async getAllPipesByDestIdAsync(destinationId, pageIndex = 1, recordsPerPage = 100, sortColumn: string = 'Name', pipeName: string = '', sortOrder: number = -1) {
        return this.getObjectAsync<PipesViewModel>(`/api/destination/get-destination-pipes/${destinationId}?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&pipeName=${pipeName}&sortOrder=${sortOrder}`, this._authService.token);
    }
    async getDestinationRunHistory(destinationId: number, pageIndex = 1, recordsPerPage = 100, sortColumn: string = '', sortOrder: number = -1) {
        return this.getObjectAsync<{ configName: string, runsHistory: RunViewModel[], totalRecordCount: number }>(`/api/destination/run-history/${destinationId}?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`, this._authService.token);
    }
    async getDestinationsConfigsAsync() {
        return this.getObjectAsync<string>(`/api/destination/configs`, this._authService.token);
    }
    async getDestinationAsync(destinationId) {
        return this.getObjectAsync<DestinationViewModel>(`/api/destination/${destinationId}`, this._authService.token);
    }
    async validateDestination(destinationObj: DestinationViewModel) {
        return this.postObjectAsync(`/api/destination/validate`, destinationObj, this._authService.token);
    }
    async deleteDestinationAsync(destinationId: number) {
        return this.deleteObjectAsync(`/api/destination/${destinationId}`, this._authService.token);
    }
    async updateAsync(destinationId: number, destination: DestinationViewModel) {
        return this.putObjectAsync(`/api/destination/${destinationId}`, destination, this._authService.token);
    }

}
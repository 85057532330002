import { container, inject, singleton } from 'tsyringe';
import { AppAuthDetails } from '../models/app-auth-details';
import { AppConfigApi } from './app-config.api';
import { AuthService } from './auth.service';

@singleton()
export class AppConfigService {

    private _appConfigApi: AppConfigApi;
    private _authService: AuthService;

    private _appAuthDetails: AppAuthDetails;

    get isNew() { return this._appAuthDetails.isNew };

    constructor() {
        this._appConfigApi = container.resolve(AppConfigApi);
        this._authService = container.resolve(AuthService);
    }

    async initializeAsync() {
        const res = await this._appConfigApi.getAuthDetailsAsync();
        if (res.isOk) {
            this._appAuthDetails = res.value;
        }
        else {
            throw res.err;
        }
    }

    async registerUserAsync(name: string, organizationName: string, email: string, password: string) {
        const res = await this._appConfigApi.registerUserAsync(name, organizationName, email, password);
        if (res.isOk) {
            //logging in after registering is disabled

            //await this.initializeAsync();
            //this._authService.login(res.value);
        }
        else {
            throw (res.err);
            //alert(res.err.message);
        }
    }
}
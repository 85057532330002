import { container, inject, singleton } from 'tsyringe';
import { err, NetworkError, ok, Result } from '../../utils/result';
import { SeEvent } from '../../utils/se-events';
import { AppConfigApi } from './app-config.api';
import { PipeApi } from './pipe.api';
import { UserApi } from './user-api';

@singleton()
export class PipeService {

    private _pipeApi: PipeApi;

    get api() { return this._pipeApi };
    
    constructor() {        
        this._pipeApi = container.resolve(PipeApi);
    }    


}
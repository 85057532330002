import { PreventAndRedirectCommands, PreventResult, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
//import './home.element';
import './login.element';
import './forgot-password.element';
import './reset-password.element';
import './change-password.element';
import './user-registration.element';
import { container } from 'tsyringe';
import Styles from '../../../assets/styles';
import { AuthService } from '../services/auth.service';
import './components/primary-button.element';
import './components/secondary-button.element';
import { ToasterService } from '../services/toaster.service';
import { MenuService } from '../services/menu.service';
import { UserService } from '../services/user.service';
import { User } from '../models/user';
import { UserApi } from '../services/user-api';
import { CheckboxEditorElement } from './editors/checkbox-editor.element';
import { InputEditorElement } from './editors/input-editor.element';
import { UserAccess } from '../models/user-access';
import { BaseEditor } from './editors/base-editor';
import { ModalDialogService } from '../services/modal-editor.service';
import { SePrimaryButton } from './components/primary-button.element';

@customElement('se-profile')

export class ProfilePageElement extends LitElement {
    private _userService: UserService;
    private _toasterService: ToasterService;
    private _authService: AuthService;
    private _menuService: MenuService;
    private _modalService: ModalDialogService;

    private _userName: string = "null";
    //private _user: User;
    private _userAccess: UserAccess;
    private _userApi: UserApi;
    private _isNew = false;
    private _userId?: number;

    private listGroupItems: NodeListOf<HTMLElement>;
    private tabContents: NodeListOf<HTMLElement>;

    @query('#name') private _nameEditor: InputEditorElement;
    @query('#email') private _emailEditor: CheckboxEditorElement;

    @query('#currentPassword') private _currentPasswordEditor: InputEditorElement;
    @query('#password') private _passwordEditor: InputEditorElement;
    @query('#confirmPassword') private _comparePasswordEditor: InputEditorElement;

    @query("#saveButton") private _saveButton: SePrimaryButton;
    @query("#savePasswordButton") private _savePasswordButton: SePrimaryButton;

    @state() private _hasChanged = false;
    @state() private _user: User;
    @state() private _email: string;
    constructor() {
        super();

        this._authService = container.resolve(AuthService);
        this._userService = container.resolve(UserService);
        this._toasterService = container.resolve(ToasterService);
        this._menuService = container.resolve(MenuService);
        this._userApi = container.resolve(UserApi);
        this._modalService = container.resolve(ModalDialogService);
        //this.loadData();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        //this._userAccess = this._authService.user;
        if (!this._authService.isUser) {
            return commands.redirect('/login');
        }
        this._userId = this._authService.user.id;


    }

    async saveAsync(ev) {
        this._saveButton.loading = true;

        ev.preventDefault();
        // WAS this.reportValidity()
        if (true) {

            this._user["Id"] = this._userId;
            this._user["Email"] = this._emailEditor?.liveValue;
            this._user["Name"] = this._nameEditor?.liveValue;
            this._user["AccessLevel"] = this._user?.accessLevel;
            this._user["IsApproved"] = 1;

            const res = await this._userApi.updateAsync(this._userId, this._user);
            if (res.isOk) {
                //await this._userState.refreshUserSpacesAsync();
                //this._userState.userSpacesChanged.triggerVoid();
                history.back();
            }
            else {
                alert(res.err.message);
            }
        }
        this._saveButton.loading = false;
    }

    passwordChanged() {
        if (this._passwordEditor.liveValue !== this._comparePasswordEditor.liveValue) {
            this._comparePasswordEditor.setCustomValidity("Passwords don't match.");
        }
        else {
            this._comparePasswordEditor.setCustomValidity("");
            this._comparePasswordEditor.reportValidity();
        }
    }

    //load user data from api
    async LoadData() {
        const res = await this._userApi.getAsync(this._userId);
        if (res.isOk) {
            this._user = res.value;
            this._email = this._user.email;

        }
        else {

            this._toasterService.showNetworkError(res.err);
            //return commands.prevent();
        }

    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev))
        this.LoadData();
    }
    disconnectedCallback() {
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev))
        super.disconnectedCallback();
    }

    private onEditorChanged(ev) {
        this._hasChanged = true;
    }

    private reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false)
                return false;
        }
        return true;
    }

    private async success() {
        this._toasterService.showSuccess("Password successfully updated.");
        this._authService.logout();
        this._authService.loginAsync(this._email, this._passwordEditor.liveValue, true)
            .then(() => this.reLogin()).catch(err => this.error(err));
    }

    private reLogin() {
        history.back();
    }


    private async cancel(ev) {
        ev.preventDefault();
        history.back();
    }


    private error(err: Error) {
        alert(err.message);
        this._hasChanged = true;
    }

    private async savePassword(ev) {
        this._savePasswordButton.loading = true;
        ev.preventDefault();
        if (this.reportValidity()) {
            this._hasChanged = false;
            await this._authService.ChangePassword(this._authService.user.id, this._currentPasswordEditor.liveValue, this._passwordEditor.liveValue)
                .then(() => this.success()).catch(err => this.error(err));

        }
        this._savePasswordButton.loading = false;
    }

    private async logout() {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Logout",
            body: `Are you sure you want to log out?`,
            saveCaption: "Yes",
            cancelCaption: "No",
        });
        if (result.isSave) {
            this._authService.logout();
            Router.go(`/login`)
        }
    }

    switchTab(tabId: string) {
        // Remove active class from all list-group-items
        this.shadowRoot?.querySelectorAll('.list-group-item').forEach((item) => {
            item.classList.remove('active');
        });

        // Add active class to the clicked list-group-item
        this.shadowRoot?.querySelector(`[data-tab="${tabId}"]`)?.classList.add('active');

        // Hide all tab contents
        this.shadowRoot?.querySelectorAll('.tab-content').forEach((tab) => {
            tab.classList.remove('show');
        });

        // Show the clicked tab content
        this.shadowRoot?.querySelector(`#${tabId}`)?.classList.add('show');
    }

    render() {
        return html`
    <div class="right-body">
        <div class="container-header gradient">
            <div class="left-header top-header">
                <div class="h1">Account settings</div>
            </div>
        </div>
    <div class="container-content">
      <div class="card">
        <div style="width: 25%; float: left;">
          <ul class="list-group">
            <li class="list-group-item active" data-tab="account-profile" @click="${() => this.switchTab('account-profile')}">Profile</li>
            <li class="list-group-item" data-tab="account-change-password" @click="${() => this.switchTab('account-change-password')}">Change password</li>
            <li class="list-group-item" @click="${this.logout}">Logout</li>
          </ul>
        </div>
        <div class="menu">
          <div id="account-profile" class="tab-content show">
            <h2>Profile Information</h2>
            <form class="form">
                <div class="labeled-input">
                      <label for="name">Name:</label>
                      <se-input-editor class="inputEditor" .width=${'100'} .customStyle=${{ alignItems: 'unset' }} id="name" name="name" type="text" input-type="text" required .value=${this._user?.name}></se-input-editor>
                </div>
                <div class="labeled-input">
                    <label for="email">Email:</label>
                    <se-input-editor class="inputEditor" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} id="email" name="email" type="text" input-type="search" required .value=${this._user?.email}></se-input-editor>
                </div>
              <br>
              <div style=""><se-primary-button id="saveButton" .customStyle=${{ 'padding': '10px 40px' }} @click="${this.saveAsync}"  text="Save"></se-primary-button></div>
            </form>
          </div>
          <div id="account-change-password" class="tab-content">
            <h2>Change Password</h2>
            <form class="form">
                <div class="labeled-input">
                  <label for="currentPassword">Current Password:</label>
                  <se-input-editor class="inputEditor" id="currentPassword" name="currentPassword" type="text" placeholder="Current Password" labelPosition ="top" input-type="password" required size="30" tabindex="1" ></se-input-editor>
              </div>
              <div class="labeled-input">
                <label for="password">New Password:</label>
                <se-input-editor class="inputEditor" @editorChanged=${this.passwordChanged} id="password" name="new-password" type="text" placeholder="Password" labelPosition="top" input-type="password" required size="30"></se-input-editor>
              </div>
              <div class="labeled-input">
                <label for="confirmPassword">Confirm Password:</label>
                <se-input-editor class="inputEditor" @editorChanged=${this.passwordChanged} id="confirmPassword" name="confirmPassword" type="text"  placeholder="Confirm password" labelPosition="top" input-type="password" required size="30"></se-input-editor>
              </div>
              <br>
              <se-primary-button id="savePasswordButton" @click="${this.savePassword}" ?disabled="${!this._hasChanged}" text="Save"></se-primary-button>
            </form>
          </div>
        </div>
      </div>
      </div>
    </div>
  `;
    }


    static get styles() {
        return [
            Styles,
            css`
    
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        height: unset;    
        background:none;
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .card {
      border: none;
      border-radius: 5px;
      overflow: hidden;
      margin-top: 20px;
    }

    .list-group {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .list-group-item {
      padding: 1em;
      cursor: pointer;
      user-select: none; /* Prevents text from being selectable */
      border-radius: 8px;
    }

    .list-group-item:hover {
      background-color: rgba(206, 225, 244, 0.5); /* Lighter shade of active color */
    }

    .list-group-item.active {
      background-color: #CEE1F4; /* Active color */
    }

    .list-group-item::selection {
      background: none;
    }

    .tab-content {
        display: none;
        flex-direction: column;
        padding-left: 2em;
        padding-right: 2em;
      }
    .menu{
        width: 75%;
        max-width: 330px;
        float: left;
        padding-top: 0px;
    }
    .show {
      display: block;
    }
    .inputEditor{
          width: 400px;
    }
    h2{
        margin-top: 0;
    }
  `]
    };
}
import { LitElement, html, css, } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { BaseEditor, ObjectEditor } from './editors/base-editor';
import { container } from 'tsyringe';
import './components/primary-button.element';
import './components/secondary-button.element';
import './editors/input-editor.element';
import logo from '../../../assets/logo.png'
import twitter from '../../../assets/twitter.svg'
import linkedin from '../../../assets/linkedin.svg'
import instagram from '../../../assets/instagram.svg'
import { InputEditorElement } from './editors/input-editor.element';
import { ToasterService } from '../services/toaster.service';
import { AuthService } from '../services/auth.service';
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import Styles from '../../../assets/styles';

@customElement('se-change-password')
export class ChangePasswordElements extends LitElement {    

//    private _appConfigService: AppConfigService;
    private _toasterService: ToasterService;
    private _authService: AuthService;
    private _email: string;
//   private _user: User;
//   private _token: string;


    @query('#currentPassword') private _currentPasswordEditor: InputEditorElement;
    @query('#password') private _passwordEditor: InputEditorElement;
    @query('#confirmPassword') private _comparePasswordEditor: InputEditorElement;

    @state() private _hasChanged = false;    

    constructor() {
        super();

//        this._appConfigService = container.resolve(AppConfigService);
        this._toasterService = container.resolve(ToasterService);
        this._authService = container.resolve(AuthService);
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        //if (!this._authService.isLoggedIn) {
        //    return commands.redirect('/login');
        //}
        return undefined;
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev))        
    }
    disconnectedCallback() {
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev))        
        super.disconnectedCallback();
    }

    private onEditorChanged(ev) {
        this._hasChanged = true;
    }   

    updateChangedValues() {        
    }

    show() {
        this.hidden = false;
    }
    hide() {
        this.hidden = true;
    }

    firstUpdated() {

    }
    private fillDefaults() {
        
    }

    private reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false)
                return false;
        }
        return true;
    }


    private async save(ev) {
        ev.preventDefault();        
        if (this.reportValidity()) {
            this._hasChanged = false;
            this._email = this._authService.user.email;
            await this._authService.ChangePassword(this._authService.user.id, this._currentPasswordEditor.liveValue, this._passwordEditor.liveValue)
                .then(() => this.success()).catch(err => this.error(err));
         
        }
    }

    private async success() {
        this._toasterService.showSuccess("Password successfully updated.");
        this._authService.logout();
        this._authService.loginAsync(this._email, this._passwordEditor.liveValue, true)
            .then(() => this.reLogin()).catch(err => this.error(err));
    }

    private  reLogin() {
        history.back();
    }

    
    private async cancel(ev) {
        ev.preventDefault();
        history.back();
    }


    private error(err: Error) {
        alert(err.message);
        this._hasChanged = true;
    }

    passwordChanged() {
        if (this._passwordEditor.liveValue !== this._comparePasswordEditor.liveValue) {
            this._comparePasswordEditor.setCustomValidity("Passwords don't match.");
        }
        else {
            this._comparePasswordEditor.setCustomValidity("");
            this._comparePasswordEditor.reportValidity();
        }
    }

    render() {
        return html`
            <div class="header"></div>
            <form id="editorForm" class="editor">
                 <p class="h3"><img src=${logo} class="header-logo" background /></p>
                <div class="scroll-container scroll-container-small">                    


                    <se-input-editor class="inputEditor" id="currentPassword" name="currentPassword" type="text" placeholder="Current Password" labelPosition ="top" input-type="password" required size="30" tabindex="1" ></se-input-editor>
                    <se-input-editor class="inputEditor" @editorChanged=${this.passwordChanged} id="password" name="password" type="text" placeholder="Password" labelPosition="top" input-type="password" required size="30"></se-input-editor>
                    <se-input-editor class="inputEditor" @editorChanged=${this.passwordChanged} id="confirmPassword" name="confirmPassword" type="text"  placeholder="Confirm password" labelPosition="top" input-type="password" required size="30"></se-input-editor>
                    
                </div>
                <div class="savePanel">
                    <se-primary-button @click="${this.save}" ?disabled="${!this._hasChanged}" text="Save"></se-primary-button>
                    <se-secondary-button @click="${this.cancel}"  text="Cancel"></se-secondary-button>
                </div>
            </form>
            <div class="footer"><div>© 2024 Sequentum  •  <a href="https://www.sequentum.com/privacy-policy" target="_blank">Privacy Policy</a>  •  <a href="https://www.sequentum.com/terms-of-service" target="_blank">Terms of Service</a></div>
                <div style="text-align: right; flex:1">
                    <a href="https://twitter.com/sequentuminc" target="_blank"><img src=${twitter}></a>
                    <a href="https://www.linkedin.com/company/sequentum/" target="_blank"><img src=${linkedin}></a>
                    <a href="https://www.instagram.com/sequentuminc/" target="_blank"><img src=${instagram}></a>
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    .linkLabel {
        display: flex;
        flex: 2;
        padding-top: 15px;
        flex-direction: row;
    }
    .h3 {
        background-color: var(--color-primary);
        padding: 10px 10px 10px 10px;
    }

      `]
    };
}
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { LitElement, html, css, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import './components/primary-button.element';
import { BaseEditor } from './editors/base-editor';
import { AppConfigApi } from '../services/app-config.api';
import { container } from 'tsyringe';
import { AppConfigService } from '../services/app-config.service';
import { InputEditorElement } from './editors/input-editor.element';
import { CheckboxEditorElement } from './editors/checkbox-editor.element';
import { AuthService } from '../services/auth.service';
import { ToasterService } from '../services/toaster.service';
import Styles from '../../../assets/styles';

@customElement('se-welcome')
export class WelcomeElement extends LitElement {

    private _appConfigService: AppConfigService;
    private _authService: AuthService;
    private _toasterService: ToasterService;

    constructor() {
        super();

        this._appConfigService = container.resolve(AppConfigService);
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    render() {
        return html`
            <div class="body">
                <div class="wizard-header gradient">
                    <div class="top-header card">
                        <div class="h1">Welcome to Sequentum Marketplace</div>
                    </div>
                </div>
                <div class="body-container">
                    <div class="container center-container card">
                        <div class="container-content">
                            <div class="content-body">
                                <p>In the Marketplace, simplicity meets sophistication. At Sequentum, we're on a mission to eliminate the unnecessary, focusing solely on what's paramount: your data. Through Sequentum's intelligent agents, developed with our state-of-the-art data collection and automation technology, accessing quality, trustworthy data becomes effortless. We aim to provide all you need for frictionless, high-quality data collection, without requiring any coding skills or technical background.</p>
                                <ul>
                                    <li><strong>Agents Dashboard</strong>: Effortlessly manage and oversee your created agents, ensuring a smooth and productive experience.</li>
                                    <li><strong>Usage & Credits</strong>: Add credits with ease and explore comprehensive statistics about each agent's operational costs, keeping you informed and in control.</li>
                                    <li><strong>Organization</strong>: Add and manage users within your organization to facilitate larger operations seamlessly.</li>
                                </ul>

                                As a testament to our dedication to excellence, we're offering $4 in credits for you to discover the capabilities of our agents, with no obligation for renewal or credit card requirement. Immerse yourself in the Sequentum experience, where the integrity and quality of your data are our top priority.

                            </div>
                            <se-primary-button class="primary-button" @click=${() => Router.go('wizard?currentStep=1')} text="Get Started For Free"></se-primary-button>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
            .body {
        height: 100vh;
        background-color: var(--color-gray-1);
    }
    .top-header{
        border-bottom: var(--color-gray-2) solid 1px;
        padding: 50px;
    }
    .primary-button{
    margin: 20px 0px 10px 0px;
    justify-self: center;
    width: 250px;
    }
    .container-content{
        display: flex;
        align-items: center;
    }
    li{
        margin-top: 10px;
    }
    
      `]
    };


}

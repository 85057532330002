import { PreventAndRedirectCommands, Router, RouterLocation } from '@vaadin/router';
import { css, html, LitElement } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { container } from 'tsyringe';
import Styles from '../../../assets/styles';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { ToasterService } from '../services/toaster.service';
import { UserApi } from '../services/user-api';
import './change-password.element';
import './components/google-oauth-button.element';
import './components/primary-button.element';
import { SePrimaryButton } from './components/primary-button.element';
import './components/secondary-button.element';
import './components/source-destination.element';
import { CheckboxEditorElement } from './editors/checkbox-editor.element';
import './editors/date-editor.element';
import { InputEditorElement } from './editors/input-editor.element';
import { SelectEditorElement } from './editors/select-editor.element';
import './forgot-password.element';
//import './home.element';
import './login.element';
import './reset-password.element';
import './user-registration.element';





@customElement('se-user-invite')
export class PipeSetupWizardElement extends LitElement {

    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _user: User;
    private _userApi: UserApi;
    private _isNew = false;
    private _userId?: number;


    @query('#name') private _nameEditor: InputEditorElement;
    @query('#email') private _email: CheckboxEditorElement;
    @query('#password') private _password: SelectEditorElement;
    @query('#accessLevel') private _accessLevel: SelectEditorElement;
    @query('#saveButton') private _saveButton: SePrimaryButton;


    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userApi = container.resolve(UserApi);
        this._toasterService = container.resolve(ToasterService);
    }


    connectedCallback() {
        super.connectedCallback();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isAdmin) {
            return commands.redirect('/login');
        }        
        if (location.params.userId) {
            this._userId = parseInt(location.params.userId.valueOf() as string);
            this._isNew = false;
            const res = await this._userApi.getAsync(this._userId);
            if (res.isOk) {
                this._user = res.value;

            }
            else {
                this._toasterService.showNetworkError(res.err);
                return commands.prevent();
            }
        }
        else {
            this._isNew = true;
            this._user = new User();
        }
    }

    public parseSearchParams(search,wantedkey) {
        search = search.slice(1);
        var pairs = search.split('&');
        var params = {};

        pairs.forEach(function (pair) {
            var keyValue = pair.split('=');
            var key = decodeURIComponent(keyValue[0]);
            var value = decodeURIComponent(keyValue[1]);

            if (params.hasOwnProperty(key)) {
                if (!Array.isArray(params[key])) {
                    params[key] = [params[key]];
                }
                params[key].push(value);
            } else {
                params[key] = value;
            }
        });

        return params[wantedkey];
    }



    async saveAsync(ev) {
        this._saveButton.loading = true;
        ev.preventDefault();
        // WAS this.reportValidity()
        if (true) {

            this._user["Id"] = this._userId;
            this._user["Email"] = this._email?.liveValue;
            this._user["Name"] = this._nameEditor?.liveValue;
            this._user["Password"] = this._password?.liveValue;
            this._user["accessLevel"] = Number(this._accessLevel?.liveValue);
            this._user["IsApproved"] = 1;

            const res = this._isNew ?
                await this._userApi.createAsync(this._user):
                await this._userApi.updateAsync(this._userId, this._user);

            if (res.isOk) {

                history.back();
            }
            else {
                this._toasterService.showNetworkError(res.err);
            }
        }
        this._saveButton.loading = false;
    }

    private cancel() {
            history.back();
    };

    render() {

        return html`
            <div class="body">

                <div class="container-header gradient">
                    <div class="left-header top-header">
                        <div class="h1">${this._isNew ? html`Invite a User` : html`Update User`}</div>
                    </div>
                </div>
                        

                    
                    <div class="container-content">
                        <div class="wizard">
                            <div class="form">

                                <div>
                                    <label class="h3">User Name</label>
                                    <se-input-editor class="inputEditor" .width=${'100'} .customStyle=${{ alignItems: 'unset' }} id="name" name="name" type="text" input-type="text" required .value=${this._user?.name}></se-input-editor>
                                </div>
                                <div>
                                    <label class="h3" id="resetPassword">User Email</label>
                                    <se-input-editor class="inputEditor" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} id="email" name="email" type="email" input-type="text" required .value=${this._user?.email}></se-input-editor>
                                </div>
                                ${this._isNew
            ? html`<se-input-editor class="inputEditor" id="password" name="password" type="text" label="Temporary Password" labelPosition="top" input-type="text" required size="30" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }}></se-input-editor>`
            : html`<se-input-editor class="inputEditor" id="password" name="password" placeholder="(Unchanged)" type="text" label="New Password" labelPosition="top" input-type="text" size="30" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }}></se-input-editor>`}
                                <div>
                                    <label class="h3" id="resetPassword">User Role</label>
                                    <se-select-editor class="inputEditor" required id="accessLevel" name="accessLevel" type="text" .value=${String(this._user?.accessLevel || 300)} .options=${[{ "id": 300, name: "User" }, { "id": 200, name: "Administrator" }]} width="100%"></se-select-editor>
                                </div>
                            </div>
                            <div class="action-buttons">
                                <div style=""><se-secondary-button .customStyle=${{ 'padding': '10px 40px' }} @click="${this.cancel}"  text="Cancel"></se-secondary-button></div>
                                <div style=""><se-primary-button id="saveButton" .customStyle=${{ 'padding': '10px 40px' }} @click="${this.saveAsync}"  text="${this._isNew ? "Create" : "Save"}"></se-primary-button></div>
                            </div>

                        </div>
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        height: unset;    
        background:none;
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    :host *, :host *:before, :host *:after {
        outline:0px dashed red
    }
    
    .action-buttons{
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: flex-end;
    }
    .inputEditor{
        width: 400px;
    }
    .wizard{
        align-self: center;
        gap: 15px;
    }
    .container-content{
        padding: 40px 55px;
    }

      `]
    };
}

import { AnyObject } from 'chart.js/dist/types/basic';
import { container, inject, singleton } from 'tsyringe';
import { BillingHistory } from '../models/billing-history';
import { BillingUsers } from '../models/billing-users';
import { CreditHistoryViewModel } from '../models/credit-history-view-model';
import { OrgBilling } from '../models/org-billing';
import { PaymentCards } from '../models/payment-cards';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';

@singleton()
export class BillingApi extends BaseApi {
    private _authService: AuthService;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getOrgBillingUsersAsync(orgId: number) {
        return this.getObjectAsync<BillingUsers>(`/api/billing/${orgId}/users`, this._authService.token);
    }

    async getOrCreateCustomer() {
        return this.getObjectAsync<OrgBilling>(`/api/billing/customer`, this._authService.token);
    }

    async getPaymentCards(orgId: number) {
        return this.getObjectAsync<Array<PaymentCards>>(`/api/billing/${orgId}/payment-cards`, this._authService.token);
    }

    async updateOrgBillingAsync(orgBilling: any) {
        return this.postObjectReturnObjectAsync<OrgBilling>(`/api/billing/org/update`, orgBilling, this._authService.token);
    }

    async setDefaultPaymentMethod(defaultPayment: any) {
        return this.postObjectReturnObjectAsync(`/api/billing/payment/set-default`, defaultPayment, this._authService.token);
    }

    async getCheckoutSession(checkoutReq: any) {
        return this.postObjectReturnObjectAsync<AnyObject>(`/api/billing/checkout-session`, checkoutReq, this._authService.token);
    }

    async deletePaymentCard(orgId: number, pmId: string) {
        return this.deleteObjectAsync(`/api/billing/${orgId}/detach/${pmId}`, this._authService.token);
    }

    async getBillingHistoryAsync(orgId: number, startDate, endDate) {
        return this.getObjectAsync<Array<BillingHistory>>(`/api/billing/history/${orgId}?startDate=${startDate}&endDate=${endDate}`, this._authService.token);
    }

    async getTotalUsageCostAsync(orgId: number, startDate, endDate) {
        return this.getObjectAsync(`/api/billing/${orgId}/total-cost?startDate=${startDate}&endDate=${endDate}`, this._authService.token);
    }

    async getAvailableCreditsAsync(orgId: number) {
        return this.getObjectAsync(`/api/billing/${orgId}/available-credits`, this._authService.token);
    }

    async getCountries() {
        return this.getObjectAsync(`/api/countries`, this._authService.token);
    }

    async getStates(country) {
        return this.getObjectAsync(`/api/countries/${country}/states`, this._authService.token);
    }
    async getOrgCreditHistoryAsync(pageIndex: number, recordsPerPage: number) {
        return this.getObjectAsync <CreditHistoryViewModel>(`/api/billing/credit-history?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}`, this._authService.token);
    }
}
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { css, html, LitElement } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import { container } from 'tsyringe';
import search from '../../../assets/search.svg';
import Styles from '../../../assets/styles';
import { AccessLevel } from '../enums/access-level';
import { UserStatus } from '../enums/user-status';
import { ToasterService } from '../services/toaster.service';
import { UserService } from '../services/user.service';
import { BillingService } from '../services/billing.service';
import './change-password.element';
import { MenuItem } from './components/context-menu.element';
import { DataGridColumn } from './components/data-grid-template';
import './components/data-grid.element';
import { SeDataGrid } from './components/data-grid.element';
import './components/primary-button.element';
import './components/secondary-button.element';
import './forgot-password.element';
//import './home.element';
import './login.element';
import './reset-password.element';
import './user-registration.element';
import { InputEditorElement } from './editors/input-editor.element';
import { AuthService } from '../services/auth.service';
import { SePaginationElement } from './components/pagination.element';




@customElement('se-users')
export class UsersPageElement extends LitElement {
    private _authService: AuthService;
    private _userService: UserService;
    private _toasterService: ToasterService;
    private _billingService: BillingService;
    private _pageIndex: number = 1;
    private _savedPageIndex: number = 1;
    private _recordsPerPage: number = 10;
    private _totalRecordCount: number;
    private _sortOrder: number = 0;
    private _columns: DataGridColumn[] = [];    
    private _userName: string = "null";
    private _isProgrammaticallySet: boolean = true;
    private lastSearchedValue: string = "";
    private searchQueue = [];
    private processingSearch = false;
    @query('#search') private searchInput: InputEditorElement;

    @state() private _data: any[] = [];

    @query('se-data-grid') private _dataGrid: SeDataGrid;
    @query('se-pagination') private _pagination: SePaginationElement;
    private boundHandlePopState;

    private _sortColumn: string = 'name';
    @state() private _orgName: string = 'Organization';

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userService = container.resolve(UserService);
        this._toasterService = container.resolve(ToasterService);
        this._billingService = container.resolve(BillingService);
        //this.loadData();
        this.boundHandlePopState = this.handlePopState.bind(this);

    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('popstate', this.boundHandlePopState);
        //this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('popstate', this.boundHandlePopState);
    }

    private handlePopState(event) {
        const customEvent = new CustomEvent('customPageChange', {
            detail: { pageIndex: (event.state?.gridPage || 1), 
            searchTerm: encodeURIComponent(this.lastSearchedValue)}
        });
        this.onPageChanged(customEvent);
    }
    private setCookie(name, value, minutes) {
        const now = new Date();
        now.setTime(now.getTime() + (minutes * 60 * 1000));
        const expires = "expires=" + now.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }
    private getCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    firstUpdated() {
        this.lastSearchedValue = this.getCookie("lastSearchedValue") || "";
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);
        var pulledPage = Number(searchParams.get("gridPage") || this._pageIndex)
        if (this.lastSearchedValue != "") {
            this._userName = this.lastSearchedValue
            this._pagination.changePage(pulledPage || 1);
        }
        const customEvent = new CustomEvent('customPageChange', {
            detail: { pageIndex: pulledPage }
        });
        this.onPageChanged(customEvent);

    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isAdmin) {
            return commands.redirect('/login');
        }        
        this.pageChange(location)
        
    }

    pageChange(location: RouterLocation) {

        const message = {
            type: 'pageChange',
            payload: { location: location.route },
        };
        window.postMessage(message, '*');
    }


    public onAfterEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): void {

    }


    private cellActions = (row, col) => {
        if (row.status !== 3) {
            return this.editUser(row, col) ;
        }
        return;
    };
    private async loadData(nameValue: string = this.lastSearchedValue) {
        return new Promise<void>(async (resolve, reject) => {

            this._columns = [
                //{ field: 'id', title: 'User ID' },
                { field: 'name', title: 'Full Name', sortable: true, align: 'left', cellStyle: { textDecoration: 'underline' }, action: this.cellActions },
                { field: 'email', title: 'Email' },
                {
                    field: 'userAccessLevel', title: 'Group', sortable: true, template: (row, col) => html`${choose(row.userAccessLevel, [
                        [undefined || null, () => html`N/A`],
                        [AccessLevel.SuperAdministrator, () => html`<span>SE4 Administrator</span>`],
                        [AccessLevel.Administrator, () => html`<span>Org Administrator</span>`],
                        [AccessLevel.User, () => html`<span>User</span>`]],
                        () => html`${row.userAccessLevel}`)}`
                },
                {
                    field: 'status', title: 'Status', sortable: true, template: (row, col) => html`${choose(Number(row.status), [
                        [undefined || null, () => html`N/A`],
                        [UserStatus.Active, () => html`<span>Active</span>`],
                        [UserStatus.Disabled, () => html`<span>Disabled</span>`],
                        [UserStatus.Deleted, () => html`<span>Deleted</span>`]],
                        () => html`${row.status}`)}`
                },

            
                { name: 'menu', cellStyle: { textAlign: 'center', width: '20px' }, menu: (row, col) => this.menu(row, col) }

            ]
            try {

                this.lastSearchedValue = nameValue;
                this.setCookie("lastSearchedValue", this.lastSearchedValue, 15)

                let userSearchReq = {
                    pageIndex: this._pageIndex,
                    recordsPerPage: this._recordsPerPage,
                    name: this.lastSearchedValue,
                    sortColumn: this._sortColumn,
                    sortOrder: this._sortOrder
                }
                const result = await this._userService.api.getAllUsersAsync(userSearchReq);

                if (result.isOk) {
                    this._data = result.value.orgUsers != null ? result.value.orgUsers : [];
                    this._totalRecordCount = result.value.totalRecordCount;
                    this._orgName = result.value.organizationName;
                }
                else {
                    this._toasterService.showUnexpectedError(result.err.message);
                }
                resolve();
            } catch (e) {
                reject(e)
            }

        })
    }

    private menu(row: any, col: DataGridColumn): MenuItem[] {
        if (row.status == 3) {
            return [
                { text: 'Restore Access', action:this.restoreUserAccessAsync.bind(this, row, col) }
            ];
        } else {
            return [
                { text: 'Edit', action: this.editUser.bind(this, row, col) },
                { text: '-' },
                { text: 'Delete', action: this.deleteUserAsync.bind(this, row, col) }
            ];
        }
    }

    private editUser(row: any, col: DataGridColumn) {
        Router.go(`/invite/user/${row.id}`);
    }

    private async restoreUserAccessAsync(row: any, col: DataGridColumn) {

        if (true) {
            const result = await this._userService.api.restoreAccessAsync(row.id);
            if (result.isOk) {

                this._dataGrid.requestUpdate();
                this.loadData();
            }
            else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();

    }


    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortOrder = evt.detail.sortOrder;

        this._sortColumn = evt.detail.sortColumn;

        this._sortOrder = sortOrder;

        this.loadData();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;

        this.loadData();
    }


   

   

   

    private async filterByUserName() {
        const newValue = this.searchInput.liveValue;
        const trimmedNewValue = newValue.trim();
        if ((this.lastSearchedValue === '' || (this.lastSearchedValue === 'null' && this._isProgrammaticallySet)) && trimmedNewValue !== '') {
            this._savedPageIndex = this._pageIndex;
        }
        this._isProgrammaticallySet = false;

        if (this._userName.trim() === '' && trimmedNewValue === '') {
            this.searchQueue = [];
            return;
        }
        this._userName = newValue;
        if (trimmedNewValue === '') {
            this._pageIndex = this._savedPageIndex || 1;
            this._dataGrid.pageIndex = this._savedPageIndex || 1;
            this._pagination.changePage(this._savedPageIndex || 1);
        } else {
            this._pageIndex = 1;
            this._dataGrid.pageIndex = this._pageIndex;
        }

        if (trimmedNewValue === '') {
            this._userName = '';
            this._isProgrammaticallySet = true;
        }
        if (this.lastSearchedValue === this._userName) {
            return;
        }
        if (this.processingSearch == false) {
            this.searchQueue.push(this._userName)
            this.processingSearch = true
            await this.loadData(this.searchQueue[0]);
            this.processingSearch = false
            this.searchQueue.shift()
        } else {
            if (this.searchQueue.length !== 1) {
                this.searchQueue.pop()
            }
            var repeating = setInterval(() => {
                if (this.processingSearch == false) {
                    clearInterval(repeating)
                    this.filterByUserName()
                }
            }, 100)
        }

    }


    private createUser(row: any, col: DataGridColumn) {
        Router.go(`/invite/user`);
    }

    private async deleteUserAsync(row: any) {

        const result = await this._userService.api.deleteAsync(row.id);
       
        if (result.isOk) {
           
            this._dataGrid.requestUpdate();
            this.loadData();
        }
        else if (result.isErr) {
            this._toasterService.showNetworkError(result.err);
        }

    }

    render() {
        
        return html`
            <div class="right-body">

                <div class="container-header gradient">

                    <div class="left-header top-header">
                        <div class="h1">${this._orgName} Users</div>
                    </div>

                    <div class="right-header">
                        <se-primary-button @click=${this.createUser} class="inputEditor" text="Invite User"></se-primary-button>
                    </div>
                        
                </div>
                <div class="container-content">
                    <div class="table-header">
                        <se-input-editor class="inputEditor" .icon=${search} .value=${this._userName === "null" && this._isProgrammaticallySet ? "" : this._userName} @editorChanged=${this.filterByUserName} .customStyle=${{ alignItems: 'unset' }} id="search" name="search" type="text" placeholder="Search" labelPosition="top" input-type="search" required ></se-input-editor>
                    </div>
                    <div class="table-list">
                        <se-data-grid .selectAllCheckbox=${true} class="grid" .rows=${this._data} .recordsPerPage=${this._recordsPerPage} .pageIndex=${this._pageIndex} .columns=${this._columns} selectable @selectionchanged=${this.onGridSelectionChanged} @sortdata=${this.sortDataGrid}></se-data-grid>
                    </div>
                    <div class="table-pagination">
                        <se-pagination .dataType=${"Users"} .recordsPerPage=${this._recordsPerPage} .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
                    </div>
                        
                </div>
             

                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
        .table-header{
            flex-direction: row-reverse;
        }
    

      `]
    };
}

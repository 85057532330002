import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import cronstrue from 'cronstrue';
import { css, html, LitElement } from 'lit';
import { customElement, query, queryAll, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { container } from 'tsyringe';
import sheet from '../../../assets/google-sheets.svg';
import Styles from '../../../assets/styles';
import { MarketplaceItemViewModel } from '../models/marketplace-item-view-model';
import { AuthService } from '../services/auth.service';
import { DestinationService } from '../services/destination.service';
import { MarketplaceService } from '../services/marketplace.service';
import { PipeService } from '../services/pipe.service';
import { SourceService } from '../services/source.service';
import { ToasterService } from '../services/toaster.service';
import './change-password.element';
import { DetailsSchemaButton } from './components/details-streams-tab.element';
import './components/documentation.element';
import './components/google-oauth-button.element';
import './components/primary-button.element';
import { SePrimaryButton } from './components/primary-button.element';
import './components/secondary-button.element';
import './components/source-destination.element';
import { CheckboxEditorElement } from './editors/checkbox-editor.element';
import './editors/date-editor.element';
import { DateEditorElement } from './editors/date-editor.element';
import { InputEditorElement } from './editors/input-editor.element';
import { NumberEditorElement } from './editors/number-editor.element';
import { SelectEditorElement } from './editors/select-editor.element';
import './forgot-password.element';
//import './home.element';
import './login.element';
import './reset-password.element';
import './user-registration.element';





@customElement('se-pipe-setup-wizard')
export class PipeSetupWizardElement extends LitElement {
    private _destinationService: DestinationService;
    private _pipeService: PipeService;
    private _marketplaceService: MarketplaceService;
    private _sourceService: SourceService;
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _pageIndex: number = 1;
    private _recordsPerPage: number = 100;
    private _sortOrder: number = 0;
    private _setupStep: number = -1;
    private _sourceId: number = -1;
    private _selectedDestinationId: any;
    private _selectedDestinationName = "";
    private _selectedSource: MarketplaceItemViewModel;
    //private _selectedSourceId : any;
    private _sourceJson: any;
    private _pipeNameInput: string;
    private executedScripts = new Set();
    //private _isNewSource: boolean = true;
    @state() private _selectedSourceId: any;
    @state() private _selectedDestinationType: any;
    @state() private _isNewSource: boolean = null;
    @state() private _isNewDestination: boolean = null;


    @state() private _currentStep: number = 1;
    @state() private _data: any;
    @state() private _inputParams: any;
    @state() private _destinations: any[] = [];
    @state() private _sources: any[] = [];
    @state() private _marketplaceItems: any[] = [];
    @state() private _hasSelectedRows = false;
    @state() private _availableDestinations: any[] = [];

    @state() private _runOnceOption = false;
    @state() private _runEveryOption = true;
    private __today?= new Date();
    private __scheduleType = 2
    private __timePMAM?

    @query('#destinationSelector') private _destinationSelector: SelectEditorElement;
    @query('#sourceSelector') private _sourceSelector: SelectEditorElement;
    @query('#pipeName') private _pipeName: InputEditorElement;

    @query('#scheduleType') private _scheduleType: SelectEditorElement;

    @query('#StartDate') private _StartDate: DateEditorElement;
    @query('#StartHour') private _StartHour: NumberEditorElement;
    @query('#StartMinutes') private _StartMinutes: NumberEditorElement;
    @query('#StartPeriod') private _StartPeriod: SelectEditorElement;

    @query('#runEveryCount') private _runEveryCount: NumberEditorElement;
    @query('#runEveryPeriod') private _runEveryPeriod: SelectEditorElement;


    @queryAll('.inputParameter') private _parameters: NodeListOf<InputEditorElement>;;
    @queryAll('.destinationFields') private _destinationFields: NodeListOf<InputEditorElement>;;

    @query('se-details-streams-tab') private _streamsElement: DetailsSchemaButton;

    @queryAll('se-number-editor') private numberEditors: NumberEditorElement[];
    @queryAll('se-input-editor:not(.cronInput):not(.skipValidation)') private inputEditors: InputEditorElement[];

    @query('#createButton') private _createButton: SePrimaryButton;

    private deferredUpdates: ((pipeId: number) => Promise<any>)[] = [];


    private destinationsConfigs: any = [];
    private destinationsConfig: any = {}
    private fieldValues: any = {};

    private _emptyRequired: string = "";
    private boundHandlePopState;

    @state() private _useDestination = true;

    constructor() {
        super();
        this._sourceService = container.resolve(SourceService);
        this._destinationService = container.resolve(DestinationService);
        this._pipeService = container.resolve(PipeService);
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._marketplaceService = container.resolve(MarketplaceService);
        this.boundHandlePopState = this.handlePopState.bind(this);

    }



    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('popstate', this.boundHandlePopState);
        this.loadData();
    }
    disconnectedCallback() {
        window.removeEventListener('popstate', this.boundHandlePopState);
        super.disconnectedCallback();
    }
    private handlePopState(event) {
        if (event.state && event.state.step) {
            this._currentStep = event.state.step;
        }
    }




    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isLoggedIn) {
            return commands.redirect('/login');
        }        
        this.loadDestinationsConfigs();
        this.__timePMAM = this.gettimePMAM(this.__today)
        this._setupStep = Number(this.parseSearchParams(location.search, "step"))
        if (this._setupStep == 1 || this._setupStep == 4)
            this._currentStep = this._setupStep
        this._sourceId = Number(this.parseSearchParams(location.search, "source"))
        if (this._sourceId) {
            this.loadNewSource(this._sourceId)
            this._currentStep = 2
            this._isNewSource = false;
        }
        var comingStep = Number(this.parseSearchParams(location.search, "currentStep"))
        if (this._sourceId) {
            this._currentStep = comingStep
        }
        this.checkStep()
    }

    //*
    /* Get the source details from the database
    /*
    /*/
    private async loadNewSource(sourceId: number): Promise<void> {
        try {
            const result = await this._marketplaceService.api.getSourceAsync(sourceId);

            if (result.isOk) {
                this._data = result.value;

                this._selectedSource = this._data;
                if (this._data.configJson) {
                    this._sourceJson = JSON.parse(this._data.configJson);
                    this._inputParams = this._sourceJson?.Parameters;
                    this._useDestination = this._sourceJson?.UseDestination;

                    if (!this._inputParams) {
                        this.goNextStep();
                    }
                } else {
                    this._sourceJson = {};
                    this.goNextStep();
                }

                return Promise.resolve();
            } else {
                this._toasterService.showUnexpectedError(result.err.message);
                return Promise.reject(new Error(result.err.message));
            }
        } catch (error) {
            this._toasterService.showUnexpectedError(error.message);
            return Promise.reject(error);
        }
    }

    //*
    /* Get the source details for an existing source
    /*
    /*/
    private async loadExistingSource(sourceId: number): Promise<void> {
        try {
            const result = await this._sourceService.api.getSourceAsync(sourceId);

            if (result.isOk) {
                this._data = result.value;

                this._selectedSource = this._data;
                if (this._data.configJson) {
                    this._sourceJson = JSON.parse(atob(this._data.configJson));
                    this._inputParams = this._sourceJson[this._sourceId].Agent.Parameters || {}

                    if (!this._inputParams) {
                        this.goNextStep();
                    }
                } else {
                    this._sourceJson = {};
                    this.goNextStep();
                }

                return Promise.resolve();
            } else {
                this._toasterService.showUnexpectedError(result.err.message);
                return Promise.reject(new Error(result.err.message));
            }
        } catch (error) {
            this._toasterService.showUnexpectedError(error.message);
            return Promise.reject(error);
        }
    }


    public parseSearchParams(search, wantedkey) {
        search = search.slice(1);
        var pairs = search.split('&');
        var params = {};

        pairs.forEach(function (pair) {
            var keyValue = pair.split('=');
            var key = decodeURIComponent(keyValue[0]);
            var value = decodeURIComponent(keyValue[1]);

            if (params.hasOwnProperty(key)) {
                if (!Array.isArray(params[key])) {
                    params[key] = [params[key]];
                }
                params[key].push(value);
            } else {
                params[key] = value;
            }
        });

        return params[wantedkey];
    }
    public onAfterEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): void {

    }


    private async loadDestinations() {

        let destSearchReq = {
            pageIndex: this._pageIndex,
            recordsPerPage: this._recordsPerPage,
            Name: null,
            sortColumn: null,
            sortOrder: this._sortOrder
        }

        const result = await this._destinationService.api.getAllDestinationsAsync(destSearchReq);
        if (result.isOk) {
            this._destinations = result?.value?.destinations || [];
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }


    }
    private async loadSources() {
        let sourceSearchReq = {
            pageIndex: 1,
            recordsPerPage: this._recordsPerPage,
            Name: null,
            sortColumn: "name",
            sortOrder: 1
        }
        const result = await this._sourceService.api.getAllSourcesAsync(sourceSearchReq);
        if (result.isOk) {
            this._sources = result?.value?.configs || [];
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }


    }
    private async loadDestinationsConfigs() {

        const result = await this._destinationService.api.getDestinationsConfigsAsync();
        if (result.isOk) {
            this.destinationsConfigs = JSON.parse(result?.value || "[]");

            this._availableDestinations = this.destinationsConfigs.map(config => { return { "id": config.name, "icon": config.icon } })
                .sort((a, b) => a.id.localeCompare(b.id));

        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }


    }

    private async loadMarketplaceItems() {
        const result = await this._marketplaceService.api.getAllMarketplaceAsync();
        if (result.isOk) {
            this._marketplaceItems = result.value.configSummaries;

            //sort by name
            this._marketplaceItems.sort((a, b) => (a.name > b.name) ? 1 : -1)
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }


    }

    private async loadData(agentTemplateId: number = -1) {

    }

    firstUpdated() {
        history.replaceState({ step: this._currentStep }, null, `?currentStep=${this._currentStep}`);
    }

    updated(changedProperties) {
        super.updated(changedProperties);

        // Execute scripts after rendering
        if (changedProperties.has('destinationsConfig') && this.destinationsConfig) {
            this.destinationsConfig.parameters.forEach(param => {
                if (param.script) {
                    try {
                        const fn = eval(param.script);
                        fn(this);
                    } catch (error) {
                        console.error('Error executing script from JSON:', error);
                    }
                }
            });
        }
    }
 
    

    private openCopyMenu(event: MouseEvent) {
        event.stopPropagation();
        

    }

    private async newSourceClicked(item) {
        this._selectedSourceId = item.id;
        this._isNewSource = true;
        this.goNextStep();
    }

    private async newDestinationClicked(item) {
        this._selectedDestinationType = item.id;
        this._isNewDestination = true;
        this.goNextStep();
    }

    private async goNextStep() {
        if (this._currentStep == 1) {
            if (this._isNewSource == null || (this._isNewSource == false && this._sourceSelector.liveValue == undefined)) {
                this._toasterService.showUnexpectedError("Please pick a source.");
                return
            }

            if (!this._isNewSource) {
                this._selectedSourceId = Number(this._sourceSelector.liveValue);
            }

            this._sourceId = this._selectedSourceId;

            //get the source details from the database
            if (this._isNewSource) {
                await this.loadNewSource(this._sourceId)
            } else {
                await this.loadExistingSource(this._sourceId);
            }


            
            //check steps
            if (!this._isNewSource) 
                this._currentStep = 4
            else
                this._currentStep = 2

        } else if (this._currentStep == 2) {
                //check steps
                if (this._inputParams) {
                    if (this.checkRequiredSourcesFields()) {
                        this._toasterService.showUnexpectedError(`Please input the required fields : ${this._emptyRequired}.`);
                        return
                    }
                }

                if (!this._useDestination) {
                    this._currentStep = 7
                } else {
                    this._currentStep = 4
                }
        } else if (this._currentStep == 3) {
                //check steps

                this._currentStep = 4
        } else if (this._currentStep == 4) {
            //check steps
            if (this._isNewDestination == null || (this._isNewDestination == false && this._destinationSelector.liveValue == undefined)) {
                this._toasterService.showUnexpectedError("Please pick a destination.");
                return
            }

                        
            //this._selectedDestinationType = this._selectedDestination.
            //selected a previously created destination
            if (this._isNewDestination == false) {
                //get destination type
                //filter destinations configs based on destination type
                var dest = this._destinations.filter(e => e.id === Number(this._destinationSelector.liveValue))[0];
                this.destinationsConfig = this.destinationsConfigs.filter(e => e.name === dest.destinationType)[0];
                            
                this._selectedDestinationId = Number(this._destinationSelector.liveValue);
                this._selectedDestinationName = this._destinationSelector.liveName;
                this._currentStep = 7
            } else {
                //retreive the destination config from the database and affect it to this.destinationsConfig based on this._selectedDestinationType
                this.destinationsConfig = this.destinationsConfigs.filter(e => e.name === this._selectedDestinationType)[0]
                this._currentStep = 5
            }


        } else if (this._currentStep == 6) {
                        //check steps
                        this.deferredUpdates = this._streamsElement.getDeferredUpdates;

                        this._currentStep = 7
        } else if (this._currentStep == 5) {
            //check steps
            if (this.checkRequiredDestinationsFields()) {
                this._toasterService.showUnexpectedError(`Please input the required fields : ${this._emptyRequired}.`);
                return
            }

            if (this._selectedDestinationType == "S3 Bucket") {
                var destinationTempVariable = {}
                destinationTempVariable["destinationName"] = this._selectedDestinationName
                destinationTempVariable["configJson"] = JSON.stringify(this.fieldValues)
                destinationTempVariable["id"] = this.fieldValues.tempId
                destinationTempVariable["destinationType"] = this.destinationsConfig.name
                destinationTempVariable["description"] = ""
                const result = await this._destinationService.api.validateDestination(destinationTempVariable);
                if (!result.isOk) {
                    return this._toasterService.showUnexpectedError(result.err.message);
                }

            }
            this._currentStep = 7;
        }

        if (this._currentStep == 7) {
            //going to last screen. Set the time for the schedule to the next hour
            const rightNow = new Date();

            //round right now to the nearest one hour
            rightNow.setHours(rightNow.getHours() + 1);
            rightNow.setMinutes(0);
            this.__today = rightNow;

            //set am and pm
            this.__timePMAM = (String((this.__today).getHours() >= 12 ? this.__timePMAM = "12" : this.__timePMAM = "0"))

        }


        this.checkStep()

        history.pushState({ step: this._currentStep }, null, `?step=${this._currentStep}`);


    }
    //return if a parameter field is empty
    private checkRequiredSourcesFields(): boolean {
        let isEmpty = false;
        this._emptyRequired = "";
        let emptyRequired = []
        this._parameters.forEach((editor: InputEditorElement) => {
            if (editor.liveValue === '') {
                isEmpty = true;
                emptyRequired.push(editor.previousElementSibling.textContent)
            }
        });
        this._emptyRequired = emptyRequired.join(", ")
        return isEmpty;
    }

    //return if a destination field is empty
    private checkRequiredDestinationsFields(): boolean {
        let isEmpty = false;
        this._emptyRequired = "";
        let emptyRequired = []
        this._destinationFields.forEach((editor: InputEditorElement) => {
            if (editor.liveValue === '' || editor.liveValue == undefined) {
                isEmpty = true;
                emptyRequired.push(editor.previousElementSibling.textContent)
            }
        });
        this._emptyRequired = emptyRequired.join(", ")
        return isEmpty;
    }

    private goPrevStep() {
        if (this._currentStep == 2) {
            //check steps

            this._currentStep = 1
        } else
            if (this._currentStep == 3) {
                //check steps

                this._currentStep = 2
            } else
                if (this._currentStep == 4) {
                    //check steps

                    if (!this._isNewSource)
                        this._currentStep = 1
                    else
                        this._currentStep = 2
                } else
                    if (this._currentStep == 5) {
                        //check steps

                        this._currentStep = 4
                    } else
                        if (this._currentStep == 6) {
                            //check steps

                            if (!this._isNewDestination)
                                this._currentStep = 4
                            else
                                this._currentStep = 5
                        }
                    if (this._currentStep == 7) {
                        //check steps
                        if (!this._useDestination) {
                            return this._currentStep = 2
                        }

                        if (!this._isNewDestination)
                            this._currentStep = 4
                        else
                            this._currentStep = 5



                    }

        history.pushState({ step: this._currentStep }, null, `?step=${this._currentStep}`);

    };

    private checkStep() {
        if (this._currentStep == 4) {

            this.loadDestinations()

        }
        if (this._currentStep == 1) {

            this.loadSources()
            this.loadMarketplaceItems()

        }


    }


    updateRunEveryPeriod() {
        
    }


    toCron(mins, hrs, dayOfMonth, month, dayOfWeek) {
        return `${mins} ${hrs} ${dayOfMonth} ${month} ${dayOfWeek}`
    }

    scheduleChange() {

        if (this._scheduleType.liveValue == "0") {
            this._runOnceOption = false;
            this._runEveryOption = false;
        } else if (this._scheduleType.liveValue == "1") {
            this._runOnceOption = true;
            this._runEveryOption = false;
        }
        else if (this._scheduleType.liveValue == "2") {
            this._runOnceOption = false;
            this._runEveryOption = true;
        }
    }
    getScheduleDate(): any {
        var result
        var localschedule
        var runEveryData


        var dateTime = this._StartDate.liveValue


        for (let editor of this.inputEditors) {
            let value = editor.liveValue;
            if (!isNaN(Number(value))) {
                if (Number(value) !== Math.floor(Number(value))) {
                    //is decimal
                    return this._toasterService.showUnexpectedError(`Decimals are not allowed "${value}".`);;
                } else {
                    //is int
                    continue;
                }
            }
            //is string
            return this._toasterService.showUnexpectedError(`Invalid value detected "${value}".`);
        }
        for (let editor of this.numberEditors) {
            let value = String(editor.liveValue);
            if (!isNaN(Number(value))) {
                if (Number(value) !== Math.floor(Number(value))) {
                    //is decimal
                    return this._toasterService.showUnexpectedError(`Decimals are not allowed "${value}".`);;
                } else {
                    //is int
                    continue;
                }
            }
            //is string
            return this._toasterService.showUnexpectedError(`Invalid value detected "${value}".`);
        }




        if (dateTime == null) return this._toasterService.showUnexpectedError("Please pick a date and fill the necessary information.");
        if (Number(this._StartMinutes.liveValue) > 60) return this._toasterService.showUnexpectedError("Minutes cannot go above 60.");
        if (Number(this._StartHour.liveValue) > 12) return this._toasterService.showUnexpectedError("Hours cannot go above 12.");
        var hours = Number(this._StartHour.liveValue) == 12 ? Number(Number(this._StartHour.liveValue) + Number(this._StartPeriod.liveValue)) - 12 : Number(Number(this._StartHour.liveValue) + Number(this._StartPeriod.liveValue));
        hours = hours == 24 ? 0 : hours;
        dateTime.setHours(hours, this._StartMinutes.liveValue)
        let currentDate = new Date();
        if (currentDate.getTime() > dateTime.getTime()) {
            return this._toasterService.showUnexpectedError("Please pick a date in the future.");
        }

        var UTCDate = new Date(dateTime.getTime() + dateTime.getTimezoneOffset() * 60 * 1000)
        if (this._scheduleType.liveValue == "1") {
            result = this.toCron(
                (UTCDate.getMinutes()),
                (UTCDate.getHours()),
                (UTCDate.getDate()),
                (UTCDate.getMonth() + 1),
                (UTCDate.getDay()))
            localschedule = this.toCron(
                (dateTime.getMinutes()),
                (dateTime.getHours()),
                (dateTime.getDate()),
                (dateTime.getMonth() + 1),
                (dateTime.getDay()))
        }
        else if (this._scheduleType.liveValue == "2") {
            runEveryData = { runEveryCount: this._runEveryCount.liveValue, runEveryPeriod: this._runEveryPeriod.liveValue }

            var obj = {
                "1": [`*/${this._runEveryCount.liveValue}`, "*", "*", "*", "*"],
                "2": [`${UTCDate.getMinutes() }`, `*/${this._runEveryCount.liveValue}`, "*", "*", "*"],
                "3": [`${UTCDate.getMinutes()}`, `${UTCDate.getHours()}`, `*/${this._runEveryCount.liveValue}`, "*", "*"],
                "4": [`${UTCDate.getMinutes()}`, `${UTCDate.getHours()}`, `*/${7 * this._runEveryCount.liveValue}`, "*", "*"],
                "5": [`${UTCDate.getMinutes()}`, `${UTCDate.getHours()}`, "*", `*/${this._runEveryCount.liveValue}`, "*"],
            }
            const localObj = {
                "1": [`*/${this._runEveryCount.liveValue}`, "*", "*", "*", "*"],
                "2": [`${dateTime.getMinutes()}`, `*/${this._runEveryCount.liveValue}`, "*", "*", "*"],
                "3": [`${dateTime.getMinutes()}`, `${dateTime.getHours()}`, `*/${this._runEveryCount.liveValue}`, "*", "*"],
                "4": [`${dateTime.getMinutes()}`, `${dateTime.getHours()}`, `*/${7 * this._runEveryCount.liveValue}`, "*", "*"],
                "5": [`${dateTime.getMinutes()}`, `${dateTime.getHours()}`, "*", `*/${this._runEveryCount.liveValue}`, "*"],
            };
            result = this.toCron(
                (obj[this._runEveryPeriod.liveValue][0]),
                (obj[this._runEveryPeriod.liveValue][1]),
                (obj[this._runEveryPeriod.liveValue][2]),
                (obj[this._runEveryPeriod.liveValue][3]),
                (obj[this._runEveryPeriod.liveValue][4]))
            localschedule = this.toCron(
                localObj[this._runEveryPeriod.liveValue][0],
                localObj[this._runEveryPeriod.liveValue][1],
                localObj[this._runEveryPeriod.liveValue][2],
                localObj[this._runEveryPeriod.liveValue][3],
                localObj[this._runEveryPeriod.liveValue][4]
            );


        }

        return { "cron": result, "startDateTime": dateTime, "localSchedule": localschedule, runEveryData };
    }

    saveTaskAsync() {
        var dateRes = this.getScheduleDate();
        if (dateRes.cron == undefined) return;
        const groupId = undefined;
        const runEveryPeriod = dateRes.runEveryData != null ? dateRes.runEveryData.runEveryPeriod : null;
        const runEveryCount = dateRes.runEveryData != null ? dateRes.runEveryData.runEveryCount : null;
        const serverGroupId = groupId > 0 ? groupId : undefined;
        const serverOrganizationId = groupId < 0 ? -groupId : undefined

        var runName = this._data.name + " - " + new Date().toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric' }).replace(',', '')

        const task = {
            runEveryPeriod: runEveryPeriod,
            runEveryCount: runEveryCount,
            schedule: dateRes.cron,
            scheduleType: Number(this._scheduleType.liveValue),
            localSchedule: dateRes.localSchedule,
            startTime: dateRes.startDateTime,
            configId: 0,
            parallelism: 1,
            serverGroupId: serverGroupId,
            serverOrganizationId: serverOrganizationId,
            parameters: "{}",
            isSaveAsTask: true,
            taskName: runName,
            isExclusive: false, isWaitOnFailure: false
        }

        return task;


    }



    get12HoursFormat(today) {
        return (String((today).getHours() > 12 ? ((today).getHours() - 12) : (today).getHours())).padStart(2, "0")
    }
    getMinsFormat(today) {
        return (String((today).getMinutes())).padStart(2, "0")
    }
    gettimePMAM(today) { 
        return (String((today).getHours() >= 12 ? "12" : "0"))
    }

    /**
     * Called at the end of the wizard to create the pipe
     * @returns
     */
    async createPipe() {
        this._createButton.loading = true;
        //verify that pipe name is not empty
        this._data.name = this._pipeName.liveValue.trim()
        if (this._data.name == null || this._data.name == '') {
            this._toasterService.showUnexpectedError(`Please enter the agent name`);
            this._createButton.loading = false;
            return;
        }
  
        this._sourceJson.DestinationId = this._selectedDestinationId
        this._sourceJson.UseDestination = this._useDestination

        this._data.isNewDestination = this._isNewDestination?.toString() || "false";

        this._data.destIcon = this.destinationsConfig != null ? this.destinationsConfig.icon : null;
        if (this._useDestination && this._isNewDestination) {
            this._sourceJson.DestinationName = this._selectedDestinationName
            this._sourceJson.ConfigJson = JSON.stringify(this.fieldValues)
            this._sourceJson.Id = this.fieldValues.tempId
            this._sourceJson.DestinationType = this.destinationsConfig.name
            this._sourceJson.Description = ""
        }
        if (this._inputParams) {
            this._sourceJson.Parameters = this._inputParams
        }
        this._data.configJson = JSON.stringify({ Agent: this._sourceJson})
        
        this._data.source = "pipe"
        this._data.isNewSource = this._isNewSource.toString();
        let task = null;
        if (this._scheduleType.liveValue != "0") {
            task = this.saveTaskAsync()
            if (task == null) {
                this._createButton.loading = false;
                return;
            }
        }
    
        const result = await this._pipeService.api.createPipe(this._sourceId, this._data, task);
        if (result.isOk) {
            this._toasterService.showSuccess("Agent successfully created.");
            this.executeDeferredUpdates(result.value);

            this.openAgentDetails(result.value);
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }

        this._createButton.loading = false;

        return ""
    }

    private async executeDeferredUpdates(pipeId: number) {
        for (const updateFunc of this.deferredUpdates) {
            const updateResponse = await updateFunc(pipeId);
            if (!updateResponse.isOk) {
                this._toasterService.showUnexpectedError(updateResponse.err.message);
            }
        }
        this.deferredUpdates = [];
    }
    async createSource() {

        if (this._inputParams) {
            if (this.checkRequiredSourcesFields()) {
                this._toasterService.showUnexpectedError(`Please input the required fields : ${this._emptyRequired}.`);

                return
            }
            this._sourceJson.Parameters = this._inputParams
        }
        this._data.configJson = JSON.stringify(this._sourceJson)
        this._data.source = "source"
        this._data.isNewSource = "true";
        const result = await this._pipeService.api.createPipe(this._sourceId, this._data, null);
        if (result.isOk) {
            this._toasterService.showSuccess("Source successfully created.");
            this.openSources();
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }


        return ""
    }


    createDestination() {
        return ""
    }
    editInputParameters(evt, param) {
        this._inputParams[param] = evt.composedPath()[0].value
    }
    private openSources() {
        Router.go(`/sources/`);
    }
    private openPipes() {
        Router.go(`/agents/`);
    }

    /**
     * navigate to the pipe id
     * @param pipeId
     */
    private openAgentDetails(pipeId: number) {
        Router.go(`/agents/${pipeId}`);
    }

    private renderRequiredFields(config, fieldValues) {
        return html`
        ${config.parameters.map(param =>
            this.shouldDisplayField(param, fieldValues) ? html`
                <div class="labeled-input" style=${param.visible === false ? 'display: none;' : ''}>
                    <label class="h3" id="${param.id}">${param.name}</label>
                    ${this.renderField(param, fieldValues)}
                </div>
            ` : ''
        )}
    `;
    }
    private renderField(param, fieldValues) {

        if (param.script && !this.executedScripts.has(param.id)) {
            setTimeout(() => {
                try {
                    const fn = new Function('app', param.script);
                    fn(this);
                    this.executedScripts.add(param.id);
                } catch (error) {
                    console.error('Error executing script from JSON:', error);
                }
            });
        }
        if (param.type === 'input') {
            return html`<se-input-editor input-type="search" class="inputEditor ${param.required ? "destinationFields" : ""}" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} 
                          @input=${(e) => this.handleUpdate(param.id, e.currentTarget.liveValue)}
                          .value=${fieldValues[param.id] || ''}></se-input-editor>`;
        }
        if (param.type === 'password') {
            return html`<se-input-editor type="password" input-type="password" class="inputEditor ${param.required ? "destinationFields" : ""}" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} 
            .customEditor=${{ border: 'none', background: 'var(--color-gray-1)' }} 
            @input=${(e) => this.handleUpdate(param.id, e.currentTarget.liveValue)}
                          .value=${fieldValues[param.id] || ''}></se-input-editor>`;
        }

        if (param.type === 'select' && param.values) {
            const options = param.values.map(value => ({ id: value, name: value }));
            return html`<se-select-editor class="inputEditor ${param.required ? "destinationFields" : ""}" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} 
                          .options=${options} 
                          @input=${(e) => this.handleUpdate(param.id, e.currentTarget.liveValue)}
                          .value=${fieldValues[param.id] || ''}></se-select-editor>`;
        }
        if (param.component === 'se-google-oauth-button') {
            return html`<se-google-oauth-button .type=${'destination'}></se-google-oauth-button>`;
        }
        //if (param.type === 'custom') {
        //    const DynamicComponent = param.component;
        //    return html`<${DynamicComponent}></${DynamicComponent}>`;
        //}

        return '';
    }

    private shouldDisplayField(param, fieldValues) {
        if (param.displayIf) {
            const dependentFieldValue = fieldValues[param.displayIf.field];
            return Boolean(dependentFieldValue) === param.displayIf.hasValue;
        }
        return true;
    }
    private shouldDisplaySourceField(param, config, fieldValues) {
        if (param.displayIf) {
            let fieldName = null;
            const foundField = config.parameters.find(p => p.id === param.displayIf.field);
            if (foundField) {
                fieldName = foundField.name;
            }
            if (fieldName) {
                const dependentFieldValue = fieldValues[fieldName];
                return Boolean(dependentFieldValue) === param.displayIf.hasValue;
            }
        }
        return true;
    }
    private renderDynamicFields(paramName, configs, fieldValues) {
        let found = false;
        let renderedField = null;

        for (const config of configs) {
            const matchingParam = config.parameters.find(param => param.name === paramName);
            if (matchingParam) {
                found = true;
                const credentialsParam = config.parameters.find(param => param.id === "credentials");
                renderedField = this.shouldDisplaySourceField(matchingParam, config, this._inputParams) ? html`
                    <div class="labeled-input" style=${matchingParam.visible === false ? 'display: none;' : ''}>
                        <label class="h3" id="${matchingParam.id}">${matchingParam.name}</label>
                        ${this.renderSourceField(matchingParam, fieldValues)}
                    </div>
                    ${this.shouldDisplaySourceField(credentialsParam, config, this._inputParams)  && credentialsParam ? html`
                        <div class="labeled-input" style=${credentialsParam.visible === false ? 'display: none;' : ''}>
                            <label class="h3" id="${credentialsParam.id}">${credentialsParam.name}</label>
                             ${this.renderSourceField(credentialsParam, fieldValues)}
                        </div>` : 
                    ''}
                ` : ``;
                break;
            }
        }

        return { found, renderedField };
    }
    private renderSourceField(param, fieldValues) {

        if (param.script && !this.executedScripts.has(param.id)) {
            setTimeout(() => {
                try {
                    const fn = new Function('app', param.script);
                    fn(this);
                    this.executedScripts.add(param.id);
                } catch (error) {
                    console.error('Error executing script from JSON:', error);
                }
            });
        }
        if (param.type === 'input') {
            return html`<se-input-editor class="inputEditor ${param.required ? "destinationFields" : ""}" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} 
                          @input=${(e) => this.editInputParameters(e, param.name)}
                          .value=${fieldValues[param.id] || ''}></se-input-editor>`;
        }
        if (param.type === 'password') {
            return html`<se-input-editor type="password" input-type="password" class="inputEditor ${param.required ? "destinationFields" : ""}" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} 
            .customEditor=${{ border: 'none', background: 'var(--color-gray-1)' }} 
            @input=${(e) => this.editInputParameters(e, param.name)}
                          .value=${fieldValues[param.id] || ''}></se-input-editor>`;
        }

        if (param.type === 'select' && param.values) {
            const options = param.values.map(value => ({ id: value, name: value }));
            return html`<se-select-editor class="inputEditor ${param.required ? "destinationFields" : ""}" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} 
                          .options=${options} 
                          @input=${(e) => this.editInputParameters(e, param.name)}
                          .value=${fieldValues[param.id] || ''}></se-select-editor>`;
        }
        if (param.component === 'se-google-oauth-button') {
            return html`<se-google-oauth-button .type=${'source'}></se-google-oauth-button>`;
        }
        //if (param.type === 'custom') {
        //    const DynamicComponent = param.component;
        //    return html`<${DynamicComponent}></${DynamicComponent}>`;
        //}

        return '';
    }


    private handleUpdate(id, value) {
        this.fieldValues = { ...this.fieldValues, [id]: value };

    }
    render() {
        const runOnceOptionsStyle = { display: this._runOnceOption ? 'flex' : 'none', gap: '10px', 'flex-direction': 'column' };
        const runEveryOptionsStyle = { display: this._runEveryOption ? 'flex' : 'none', gap: '10px', 'flex-direction': 'column' };

        const scheduleOptionsStyle = { display: 'flex' };
        const noSchedule = (this._scheduleType == null && this.__scheduleType == 0) || (this._scheduleType != null && this._scheduleType.liveValue == "0");
        const dateTimeStyle = { display: noSchedule ? 'none' : 'flex', gap: '10px' };

        return html`
            <div class="body">
                <div class="wizard-header gradient" style="">

                    <div class="top-header ${(this._currentStep == 2 || this._currentStep == 5) ? `` : `card`}">
                        <div class="left-header">
                            <div class="h1">New Agent</div>
                        </div>
                            
                        <div class="right-header">

                            <div class="stepper-wrapper">
                                <div class="stepper-item ${(this._currentStep == 1 || this._currentStep == 2) ? `current` : `completed`}">
                                    <div class="step-counter">1</div>
                                    <div class="step-name">Source</div>
                                </div>
                                ${this._useDestination ? html`
                                <div class="stepper-item ${(this._currentStep < 3) ? `` : (this._currentStep == 4 || this._currentStep == 5) ? `current` : `completed`}">
                                    <div class="step-counter">2</div>
                                    <div class="step-name">Destination</div>
                                </div>
                                ` : html``}

                                <div class="stepper-item ${(this._currentStep == 7) ? `current` : ``}">
                                    <div class="step-counter">${this._useDestination ? `3` : `2`}</div>
                                    <div class="step-name">Agent Details</div>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                </div>

                <div class="body-container">
                    <div class="container ${(this._currentStep == 2 || this._currentStep == 5) ? `left-container` : `center-container card`}">
                        <div class="container-content">
                            <div class="wizard">
                                
                                ${(this._currentStep == 1) ?
                html`

                                                        <label class="h2" id="resetPassword">Select the source</label>
                                                            <div class="sources-list" @click=${() => { this._isNewSource = true }}>
                                                                ${(this._marketplaceItems.length == 0) ? "" : this._marketplaceItems.map(item => html`
                                                                    <se-source-destination class="marketplace-sources ${classMap({ "active": (this._isNewSource == true && this._selectedSourceId == item.id) })}" @click=${(e) => { this.newSourceClicked(item) }} text="${item.name}" .icon=${item.icon}></se-source-destination>
                                                                `)}

                                                            </div>
                                `: ``}

                                ${(this._currentStep == 2) ?
                html`
                                        <h1>${this._selectedSource?.name}</h1>
                                        ${(this._selectedSource?.description) ? html`<p>${this._selectedSource?.description}</p>` : ``}
                                        <div>
                                    ${(this._sourceId) ?
                        html`
                                            <div class="form">
                                            ${(!this._inputParams) ? html`<label class="h3" id="" style="color: grey;">No input Parameters in this Source</label>`
                                                : Object.entries(this._inputParams).map(param => {
                                                    const { found, renderedField } = this.renderDynamicFields(param[0], this.destinationsConfigs, this._inputParams);
                                                    return found ? renderedField : html`
                                                        <div class="labeled-input">
                                                            <label class="h3" id="${param[0]}">${param[0]}</label>
                                                            <se-input-editor class="inputEditor inputParameter" @input=${(e) => this.editInputParameters(e, param[0])} .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} id="search" name="search" type="text" .value=${param[1].toString()} labelPosition="top" input-type="search" required ></se-input-editor>
                                                        </div>
                                                    `;
                                                }) }
                                            </div>`
                        : html`
                                            <div>
                                                <div>
                                                    <label class="h3" id="resetPassword">Selected Source</label>
                                                    <se-source-destination class="inputEditor" .disabled=${true} text="Google Sheets" .icon=${sheet}></se-source-destination>
                                                </div>
                                                <div>
                                                    <label class="h3" id="resetPassword">Source Name</label>
                                                    <se-input-editor class="inputEditor" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} id="search" name="search" type="text" placeholder="Search" .disabled=${true} .value=${"Google Sheets"} labelPosition="top" input-type="search" required ></se-input-editor>
                                                </div>
                                                <div>
                                                    <label class="h3" id="resetPassword">Authentication</label>
                                                    <div class="inputEditor">
                                                        <se-google-oauth-button></se-google-oauth-button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label class="h3" id="resetPassword">Row Batch Size</label>
                                                    <se-input-editor class="inputEditor" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} id="search" name="search" type="text" labelPosition="top" input-type="search" required ></se-input-editor>
                                                </div>
                                                <div>
                                                    <label class="h3" id="resetPassword">Spreadsheet Link</label>
                                                    <se-input-editor class="inputEditor" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} id="search" name="search" type="text" labelPosition="top" input-type="search" required ></se-input-editor>
                                                </div>
                                            </div>`
                    }
                                        </div>
                                    `: ``
            }
                                ${(this._currentStep == 4) ?
                html`
                                    ${(this._destinations.length !== 0) ? html`
                                    <label class="h2" id="resetPassword">Select Previously Created Destination</label>
                                        <se-select-editor @click=${() => { this._isNewDestination = false }} class="inputEditor" id="destinationSelector" .placeholder="${"Select"}" .value="${"-1"}" width="100%" name="destinationType" label="" labelPosition="top" @valueChanged=${this.openCopyMenu}
                                                    .options=${this._destinations.map(e => ({ id: e.id, name: e.destinationName, type: e.destinationType }))}
                                                ></se-select-editor>
                                        </div>
                                    </div>
                                    ` : html ``}
                                    <div class="action-buttons">
                                        ${ !(this._setupStep == 4) ? html`<div><se-secondary-button class="secondary-button" text="Previous" @click=${this.goPrevStep}></se-secondary-button></div>` : `` }
                                        <div><se-primary-button class="primary-button" text="Next" @click=${this.goNextStep}></se-primary-button></div>
                                    </div>  
                                    ${(this._destinations.length !== 0) ? html`
                                    <div class="transparent-bg"><div class="wizard"><div class="separator-or"><span>or</span></div></div></div>
                                    ` : html``}
                                    <label class="h2" id="resetPassword">Select the destination</label>
                                        <div class="sources-list"  @click=${() => { this._isNewDestination = true }}>
                                            ${(this._availableDestinations.length == 0) ? "" : this._availableDestinations.map(item => html`
                                                <se-source-destination class="marketplace-sources ${classMap({ "active": (this._isNewDestination == true && this._selectedDestinationType == item.id) })}" @click=${(e) => { this.newDestinationClicked(item) }} text="${item.id}" .icon=${item.icon}></se-source-destination>
                                            `)}

                                        </div>
                                    `: ``
            }
                                ${(this._currentStep == 5) ?
                html`
                                        <label class="h2" id="resetPassword">Set up the destination</label>
                                        <div class="form">
                                            <div class="labeled-input">
                                                <label class="h3" id="resetPassword">Destination Name</label>
                                                <se-input-editor class="inputEditor destinationFields" @input=${(e) => this._selectedDestinationName = e.currentTarget.liveValue} .value=${this._selectedDestinationName} .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} id="destinationName" name="destinationName" type="text" labelPosition="top" input-type="search" required ></se-input-editor>
                                            </div>
                                            ${this.renderRequiredFields(this.destinationsConfig, this.fieldValues)}
                                        </div>
                                    `: ``
            }
                                ${(this._currentStep == 6) ?
                                html`
                                    <div class="h2" style="text-wrap:wrap;">Select the source streams you would like to move. <br> Each stream contains a table and will be delivered in separate entries to the destination.</div>
                                    </div>
                                    <se-details-streams-tab .isSetupNewPipe=${true} .isNewSource=${this._isNewSource} .pipeId=${String(this._selectedSourceId)}></se-details-streams-tab>` : ``
                                }
                                ${(this._currentStep == 7) ?
                html`
                                    <div class="confirmation-step">
                                        <div class="form">
                                            <div class="labeled-input">
                                                <label class="h3" id="resetPassword">Agent Name</label>
                                                <se-input-editor class="inputEditor skipValidation"  @input=${(e) => this._pipeNameInput = e.currentTarget.liveValue} .value=${this._pipeNameInput || ""} .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} id="pipeName" name="search" type="text" labelPosition="top" input-type="search" required ></se-input-editor>
                                            </div>
                                            <div class="labeled-input">
                                                <label class="h3" id="resetPassword">Source</label>
                                                <se-input-editor class="inputEditor skipValidation" .disabled=${true} .value=${this._selectedSource?.name} .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} id="search" name="search" type="text" labelPosition="top" input-type="search" required ></se-input-editor>
                                            </div>
                                            ${this._useDestination ? html`
                                            <div class="labeled-input">
                                                <label class="h3" id="resetPassword">Destination</label>
                                                <se-input-editor class="inputEditor skipValidation" .disabled=${true}  .value=${this._selectedDestinationName} .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} id="search" name="search" type="text" labelPosition="top" input-type="search" required ></se-input-editor>
                                            </div>
                                            ` : html``}

                                        </div>
                                        <div class="separator">

                                        </div>
                                        <div class="form">
                                            <h2>Schedule</h2>
                                            <div style=${styleMap(scheduleOptionsStyle)} class="form">
                                                <div style=${styleMap({ ...scheduleOptionsStyle, justifyContent: "space-between", width: "100%" })} >
                                                    <div class="labeled-input">
                                                        <se-select-editor class="inputEditor" .value="${String(this.__scheduleType)}" id="scheduleType" width="10em" name="Schedule" label="" labelPosition="top" @valueChanged=${this.scheduleChange}
                                                            .options=${[
                                                                        { id: 0, name: "No Schedule" },
                                                                        { id: 1, name: "Run Once" },
                                                                        { id: 2, name: "Repeat" }
                                                                    ]}>
                                                        </se-select-editor>
                                                    </div>
                                                </div>
                                                <div style=${styleMap(dateTimeStyle)} class="date-time">
                                                    <div class="labeled-input">
                                                        <label>Start Date</label>
                                                        <se-date-editor id="StartDate" style="flex: 1; display: flex;" .min=${(new Date()).toLocaleString("en-CA").split(",")[0]} .value=${this.__today} min="1" label="" labelPosition="top"></se-date-editor>
                                                    </div>
                                                    <div class="labeled-input">
                                                        <label>Start Time</label>
                                                        <div class="time-editor">
                                                            <se-input-editor class="time-input" style="width: 33%;" .customEditor=${{ textAlign: 'center', flex: '1', padding: '0px' }} .value = ${ this.get12HoursFormat(this.__today) } id = "StartHour" value = "01" min = "0" max = "12" label = "" labelPosition = "top" size = "2" width = "100%" > </se-input-editor>
                                                            &nbsp;:&nbsp;
                                                            <se-input-editor class="time-input" style="width: 33%;" .customEditor=${{ textAlign: 'center', flex: '1', padding: '0px' }} .value=${this.getMinsFormat(this.__today)} id="StartMinutes" value="00" min="0" max="60" label="" labelPosition="top"  size="2" width="100%"></se-input-editor>
                                                            <se-select-editor style="width: 33%;min-width: 70px; margin-left:5px;" .value=${this.__timePMAM} id="StartPeriod" width="100%" name="Proxy Pools" label="" labelPosition="top"
                                                                .options=${[
                                                                            { id: 12, name: "PM" },
                                                                            { id: 0, name: "AM" }
                                                                        ]}>
                                                            </se-select-editor>
                                                        </div>
                                                    </div>          
                                                </div>

                                                <div style=${styleMap(scheduleOptionsStyle)}>

                                                    <div id="taskOptions" style=${styleMap(runOnceOptionsStyle)}>
                                        
                                                    </div>
                        


                                                    <div id="taskOptions" style=${styleMap(runEveryOptionsStyle)}>


                                                        <div style="display: flex; gap: 10px;flex-direction: column;">
                                                            <span style="display: flex; gap: 10px;">
                                                                <span style="align-self: center;">every </span>
                                                                <se-number-editor id="runEveryCount" .value="${1}" min="1" label="" labelPosition="top" input-type="number" size="2" width="60px"></se-number-editor>
                                                                <se-select-editor id="runEveryPeriod" .value="${"3"}" width="120px" name="Proxy Pools" label="" labelPosition="top" @valueChanged=${this.updateRunEveryPeriod}
                                                                    .options=${[
                                                                                { id: 1, name: "minutes" },
                                                                                { id: 2, name: "hours" },
                                                                                { id: 3, name: "days" },
                                                                                { id: 4, name: "weeks" },
                                                                                { id: 5, name: "months" }
                                                                            ]}>
                                                                </se-select-editor>
                                                            </span>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    `: ``
            }
                                
                            </div>
                            <div style="flex-grow: 1;"></div>
                            ${(this._currentStep == 1 || this._currentStep == 4) ? html`` : html`
                            <div class="separator-or"></div>
                            <div class="wizard">
                                <div class="action-buttons">
                                    ${(!(this._currentStep == 4) && (this._currentStep > 1)) ?
                                    html`<div style=""><se-secondary-button @click="${this.goPrevStep}"  text="Previous"></se-secondary-button></div>`: ``}
                                    ${(this._currentStep == 7) ?
                                    html`<div style=""><se-primary-button @click="${this.createPipe}" id="createButton"  text="Create Agent"></se-primary-button></div>`
                                    :
                                    html`${(this._setupStep == 1 && this._currentStep == 2) ?
                                        html`<div style=""><se-primary-button @click="${this.createSource}"  text="Create Source"></se-primary-button></div>`
                                        :
                                        html`${(this._setupStep == 4 && this._currentStep == 5) ?
                                            html`<div style=""><se-primary-button @click="${this.createDestination}"  text="Create Destination"></se-primary-button></div>`
                                            :
                                            html`${(this._currentStep == 1 || this._currentStep == 4) ?
                                            html`` : html`<div> <se-primary-button @click="${this.goNextStep}"  text="Next"></se-primary-button></div>`}`
                                        }`
                                    }`
            }
                                </div>
                            </div>
`}
                        </div>
                   
                    ${(this._currentStep == 2) ? html`<div class="right-container">
                        
                    ${(this._selectedSource != null) ?
                    html`
                            <se-documentation .documentUrl=${this._selectedSource?.documentation}> </se-documentation>
                        `: ``
                }

                    </div>` : ``}    
                    ${(this._currentStep == 5) ? html`<div class="right-container">

                        <se-documentation .documentUrl=${this.destinationsConfig?.documentationUrl}> </se-documentation>
                    </div>` : ``}    
                </div>
                </div>

                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        height: unset;    
        background:none;
    
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    :host *, :host *:before, :host *:after {
        outline:0px dashed red
    }
    .body {
        height: 100vh;
        background-color: var(--color-gray-1);
    }
    .h1{
        color: black;
        font-weight: 900;
    }
    .inputEditor{
        width:100%;
    }
    .marketplace-sources{
        border:1px solid transparent
    }
    .marketplace-sources:hover{
        border: 1px solid black;
        background-color: white;
    }
    .active {
        border: 1px solid var(--color-secondary);
    }
    .wizard-steps{
        width: 100px;
    }
    .time-section{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .wizard{
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
    }
    
    
    .transparent-bg{
        background-color: #ffffff00;
    }

    .sources-list{
        display:flex;
        gap:10px;
        flex-direction: column;
        max-height: calc(100vh - 580px);
        overflow: auto;
    }
    .confirmation-step{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .separator{
        width: 100%;
        border-top: 1px solid #94979d4d;
    }
    .action-buttons{
        display: flex;
        flex-direction: row;
        gap: 12px;
        justify-content: flex-end;
        padding: 10px 0px;
    }
    .header {
        margin-left: 5px;
        display: flex;
        align-items: end;
        justify-content: space-between;
        overflow:hidden;
        padding-right: 5px;
        margin-right: -5px;
        padding-bottom: 5px;
        margin-bottom: -5px;
    }
    .top-header{
        border-bottom: var(--color-gray-2) solid 1px;
        padding: 50px;
    }

    .left-header {
        align-self: center;
    }
    .right-header {
        max-width: 500px;
        flex: 1;
        margin: 0px;
    }
    //.left-header, .right-header {
    //}
    .grid {
        flex:1;        
    }   
    .checkbox {
        width: 1rem;
        height: 1rem;
    }
    input[type="checkbox"]:checked {
        background-color: var(--color-secondary);
    }
    .label {
        background-color: dimgray;
        border-radius: 3px 3px;
        font: var(--font-smaller);
    }
    .left-container{
        margin: 0px 50px 50px;
        width: calc(100vw - 400px);
        display: table;
        table-layout: fixed;
        min-height: calc(100vh - 261px);
    }
    .left-container .left{
        align-self: flex-start;
        display: table-cell;
        vertical-align: top;
    }

    .right-container{
        background: var(--color-navy-1);
        overflow: hidden;
        display: table-cell;
        border-radius: 0px 0px 16px 0px;
    }
    se-documentation{
        height: 100%;
        overflow-y: scroll;
    }
    .separator-or{
        width: 100%; 
        text-align: center; 
        border-bottom: 1px solid #EDF1FB; 
        line-height: 0.1em;
        margin: 10px 0 20px; 
    }
    .separator-or span{
        background:#fff; 
        padding:0 10px; 
    }

    .stepper-wrapper {
      margin-left: 10px;
      display: flex;
      justify-content: space-between;
    }
    .stepper-item {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      flex-wrap: wrap;
      justify-content: space-evenly;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    .step-name {
        min-width: 100px;
        text-align: center;
    }

    .stepper-item .step-counter {
      position: relative;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: var(--color-gray-1);
      font-size: 24px;
    }

    .stepper-item.current {
      font-weight: bold;
    }

    .stepper-item.current .step-counter{
        background-color: var(--color-secondary);
        color: white;
    }

    .stepper-item.completed .step-counter {
      background-color: var(--color-status-green);
      color: transparent;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none"><path d="M11.4187 18.5269L22.1624 7.80036C22.4034 7.5622 22.6889 7.44312 23.0189 7.44312C23.3489 7.44312 23.633 7.56273 23.8712 7.80196C24.1093 8.04119 24.2284 8.32769 24.2284 8.66145C24.2284 8.99523 24.1093 9.28348 23.8712 9.52619L12.2751 21.1223C12.0328 21.3604 11.7463 21.4795 11.4156 21.4795C11.085 21.4795 10.8006 21.3604 10.5624 21.1223L5.09861 15.6585C4.86045 15.4144 4.74355 15.1253 4.74792 14.7911C4.75231 14.457 4.87412 14.1708 5.11335 13.9327C5.35258 13.6945 5.63908 13.5754 5.97284 13.5754C6.30662 13.5754 6.59487 13.6945 6.83758 13.9327L11.4187 18.5269Z" fill="white"/></svg>');
      background-size: cover;
    }

    
    
    StartDate{
        flex: 1;
    }
      `]
    };
}

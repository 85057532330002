import { container, inject, singleton } from 'tsyringe';
import { PipesViewModel } from '../models/pipes-view-model';
import { PipesConfigsViewModel } from '../models/pipes-configs-view-model';
import { PipeViewModel } from '../models/pipe-view-model';
import { RunViewModel } from '../models/run-view-model';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';
import { CreateRunModel } from "../models/create-run-model";
import { ScheduleRunModel } from "../models/schedule-run-model";
import { PipeStreamViewModel } from '../models/pipe-stream-view-model';
import { integer } from 'vscode-languageserver-protocol';


@singleton()
export class PipeApi extends BaseApi {
    private _authService: AuthService;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }
    async connectAsync(id: number) {
        return this.postObjectAsync(`/api/server/${id}/connect`, undefined, this._authService.token);
    }
    async getAllPipesAsync(pageIndex = 1, recordsPerPage = 100, pipeName: string = '', sortColumn: string = '', sortOrder: number = -1) {
        return this.getObjectAsync<PipesViewModel>(`/api/pipe?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&pipeName=${pipeName}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`, this._authService.token);
    }
    async getPipeAsync(pipeId: number) {
        return this.getObjectAsync<PipeViewModel>(`/api/pipe/${pipeId}`, this._authService.token);
    }
    async getPipeDataAsync(pipeId: number) {
        return this.getObjectAsync<PipeViewModel>(`/api/pipe/${pipeId}/data`, this._authService.token);
    }
    async getPipeScheduleAsync(pipeId: number) {
        return this.getObjectAsync<ScheduleRunModel>(`/api/pipe/${pipeId}/schedule`, this._authService.token);
    }
    async getPipeStreamsAsync(pipeId: number) {
        return this.getObjectAsync<Array<PipeStreamViewModel>>(`/api/pipe/${pipeId}/streams`, this._authService.token);
    }
    async getPipeRuns(configId: number, pageIndex = 1, recordsPerPage = 100, sortColumn: string = '', sortOrder: number = -1) {
        return this.getObjectAsync<{ configName: string, runsHistory: RunViewModel[], totalRecordCount: number }>(`/api/pipe/runs/${configId}?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`, this._authService.token);
    }
    async createPipe(pipeId: number, pipe: PipeViewModel, task?: ScheduleRunModel) {
        return this.postObjectReturnObjectAsync<number>(`/api/pipe/${pipeId}`, { pipe, task }, this._authService.token);
    }
    async createPipeRun(pipe: CreateRunModel) {
        return this.postObjectAsync(`/api/pipe/start-run`, pipe, this._authService.token);
    }

    /**
     * Send the updated pipe streams to the server
     * @param pipeId
     * @param pipe
     * @returns
     */
    async updatePipeStreamsAsync(pipeId: number, streams: Array<PipeStreamViewModel>) {
        return this.putObjectAsync(`/api/pipe/${pipeId}/streams`, streams, this._authService.token);
    }

    async updatePipeParametersAsync(pipeId: number, pipe: PipesConfigsViewModel) {
        return this.putObjectAsync(`/api/pipe/${pipeId}/parameters`, pipe, this._authService.token);
    }

    /**
     * 
     * Update or add a schedule for an agent
     * @param taskId
     * @param task
     * @returns
     */
    async updateTaskAsync(taskId: number, task: ScheduleRunModel) {
        return this.putObjectReturnObjectAsync<ScheduleRunModel>(`/api/pipe/${taskId}/schedule`, task, this._authService.token);
    }

    /**
     * 
     * Delete the existing schedule for an agent
     * @param pipId
     * @param taskId
     */
    async deleteTaskAsync(pipeId: number) {
        return this.deleteObjectAsync(`/api/pipe/${pipeId}/schedule`, this._authService.token);
    }

    async deletePipeAsync(pipeId: number) {
        return this.deleteObjectAsync(`/api/pipe/${pipeId}`, this._authService.token);
    }
    async updatePipeNameAsync(agentId: number, agentName: string) {
        return this.postObjectAsync(`/api/pipe/${agentId}/name`, agentName, this._authService.token);
    }

}
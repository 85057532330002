import { PreventAndRedirectCommands, PreventResult, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import './login.element';
import './forgot-password.element';
import './reset-password.element';
import './change-password.element';
import './user-registration.element';
import { container } from 'tsyringe';
import Styles from '../../../assets/styles';
import { AuthService } from '../services/auth.service';
import './components/primary-button.element';
import './components/secondary-button.element';
import { ToasterService } from '../services/toaster.service';
import { MenuService } from '../services/menu.service';
import { DestinationService } from '../services/destination.service';
import { SeDataGrid } from './components/data-grid.element';

import { DataGridColumn } from './components/data-grid-template';
import { MenuItem } from './components/context-menu.element';
import search from '../../../assets/search.svg'
import { choose } from 'lit/directives/choose.js';
import { InputEditorElement } from './editors/input-editor.element';
import { SePaginationElement } from './components/pagination.element';




@customElement('se-destinations')
export class DestinationsPageElement extends LitElement {
    private _destinationService: DestinationService;
    private _menuService: MenuService;
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _pageIndex: number = 1;
    private _savedPageIndex: number = 1;
    private _recordsPerPage: number = 10;
    private _totalRecordCount: number;
    private _sortColumn: string = "destinationName";
    private _sortOrder: number = 0;
    private _columns: DataGridColumn[] = [];
    private _destinationName: string = "";
    private _isProgrammaticallySet: boolean = true;
    private lastSearchedValue: string = "";
    private searchQueue = [];
    private processingSearch = false;

    @query('#search') private searchInput: InputEditorElement;

    @state() private _data: any[] = [];
    @state() private _hasSelectedRows = false;
    @state() private _hasSelectedAgentsWithUnknownValidation = false;

    @query('se-data-grid') private _dataGrid: SeDataGrid;
    @query('se-pagination') private _pagination: SePaginationElement;
    private boundHandlePopState;

    constructor() {
        super();

        this._authService = container.resolve(AuthService);
        this._destinationService = container.resolve(DestinationService);
        this._toasterService = container.resolve(ToasterService);
        this._menuService = container.resolve(MenuService);
        //this.loadData();
        this.boundHandlePopState = this.handlePopState.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('popstate', this.boundHandlePopState);
        //this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('popstate', this.boundHandlePopState);
    }
    private handlePopState(event) {
        const customEvent = new CustomEvent('customPageChange', {
            detail: { pageIndex: (event.state?.gridPage || 1) }
        });
        this.onPageChanged(customEvent);
    }
    firstUpdated() {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);
        var pulledPage = Number(searchParams.get("gridPage") || this._pageIndex)
        const customEvent = new CustomEvent('customPageChange', {
            detail: { pageIndex: pulledPage }
        });
        this.onPageChanged(customEvent);

    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isLoggedIn) {
            return commands.redirect('/login');
        }        
        this.pageChange(location)
    }

    pageChange(location: RouterLocation) {

        const message = {
            type: 'pageChange',
            payload: { location: location.route },
        };
        window.postMessage(message, '*');
    }


    public onAfterEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): void {
        
    }

    private async loadData(nameValue: string = this.lastSearchedValue) {
        return new Promise<void>(async (resolve, reject) => {

            this._columns = [
                { field: 'destinationName', title: 'Name', sortable: true, cellStyle: { textDecoration: 'underline' }, action: this.viewDestinationDetails.bind(this) },
                { field: 'destinationType', title: 'Type', sortable: true },
                /*{
                    field: 'status', title: 'Status', sortable: true, align: 'center', template: (row, col) => html`${choose(row.status, [
                        [undefined || null, () => html``],
                        ['active', () => this.getStatus('#6fcf97', 'Active', 'active')],
                        ['running', () => this.getStatus('#2f80ed', 'Running', 'running')],
                        ['failed', () => this.getStatus('#EB5757', 'Failed', 'failed')],
                        ['not-active', () => this.getStatus('#585A5F', 'Not Active', 'not-active')],
                    ],
                        () => html`${row.status}`)}`
                },*/
                { field: 'updated', title: 'Last Updated', template: (row, col) => html`${(new Date(row.updated)).toLocaleDateString()}` },
            ]
                try {
                    this.lastSearchedValue = nameValue;

                    let destSearchReq = {
                        Name: this.lastSearchedValue,
                        pageIndex: this._pageIndex,
                        recordsPerPage: this._recordsPerPage,
                        sortColumn: this._sortColumn,
                        sortOrder: this._sortOrder
                    }

                    const result = await this._destinationService.api.getAllDestinationsAsync(destSearchReq);
                    if (result.isOk) {
                        this._data = result.value.destinations;
                        this._totalRecordCount = result.value.totalRecordCount;
                    }
                    else {
                        this._toasterService.showUnexpectedError(result.err.message);
                    }

                    resolve();
                } catch (error) {
                    reject(error)
                }
        })

    }


    private async filterByDestinationName() {
        const newValue = this.searchInput.liveValue;
        const trimmedNewValue = newValue.trim();
        if ((this.lastSearchedValue === '' || (this.lastSearchedValue === 'null' && this._isProgrammaticallySet)) && trimmedNewValue !== '') {
            this._savedPageIndex = this._pageIndex;
        }
        this._isProgrammaticallySet = false;

        if (this._destinationName.trim() === '' && trimmedNewValue === '') {
            this.searchQueue = [];
            return;
        }
        this._destinationName = newValue;
        if (trimmedNewValue === '') {
            this._pageIndex = this._savedPageIndex || 1;
            this._dataGrid.pageIndex = this._savedPageIndex || 1;
            this._pagination.changePage(this._savedPageIndex || 1);
        } else {
            this._pageIndex = 1;
            this._dataGrid.pageIndex = this._pageIndex;
        }

        if (trimmedNewValue === '') {
            this._destinationName = '';
            this._isProgrammaticallySet = true;
        }
        if (this.lastSearchedValue === this._destinationName) {
            return;
        }
        if (this.processingSearch == false) {
            this.searchQueue.push(this._destinationName)
            this.processingSearch = true
            await this.loadData(this.searchQueue[0]);
            this.processingSearch = false
            this.searchQueue.shift()
        } else {
            if (this.searchQueue.length !== 1) {
                this.searchQueue.pop()
            }
            var repeating = setInterval(() => {
                if (this.processingSearch == false) {
                    clearInterval(repeating)
                    this.filterByDestinationName()
                }
            }, 100)
        }

    }

    private async viewDestinationDetails(row: any, col: DataGridColumn) {
        Router.go('/destinations/' + row.id);
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();

    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder;

        this._sortColumn = sortColumn;
        this._sortOrder = sortOrder;

        this.loadData();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadData();
    }

    private resetPagination() {
        //this._pageIndex = 1;
        //this._dataGrid.pageIndex = 1;
        //this._dataPagination.pageIndex = 1;
    }

    private filterByLabel(event: CustomEvent) {
        event.stopPropagation();
    }
    private filterByAgentTemplate(event: CustomEvent) {
        event.stopPropagation();
        this.loadData();
    }

    private getStatus(color: string, text: string, labelDlass: string): TemplateResult {
        return html`
        <div class="pipe-item-status" style="background-color: ${color};">

                <span>${text}</span>
        </div>`
    }

    render() {

        return html`
            <div class="right-body">

                <div class="container-header gradient">

                    <div class="left-header top-header">
                        <div class="h1">Destinations</div>
                    </div>

                        
                </div>
                <div class="container-content">
                    <div class="table-header">
                        <se-input-editor class="inputEditor" .icon=${search} .value=${this._destinationName === "null" && this._isProgrammaticallySet ? "" : this._destinationName} @editorChanged=${this.filterByDestinationName} .customStyle=${{ alignItems: 'unset' }} id="search" name="search" type="text" placeholder="Search" labelPosition="top" input-type="search" required ></se-input-editor>
                    </div>
                    <div class="table-list">
                        <se-data-grid .selectAllCheckbox=${true} class="grid" .rows=${this._data} .recordsPerPage=${this._recordsPerPage} .pageIndex=${this._pageIndex} .columns=${this._columns} selectable @selectionchanged=${this.onGridSelectionChanged} @sortdata=${this.sortDataGrid} @filterbylabel=${this.filterByLabel} @filterbyAgentTemplate=${this.filterByAgentTemplate}></se-data-grid>
                    </div>
                    <div class="table-pagination">
                        <se-pagination .dataType=${"Destinations"} .recordsPerPage=${this._recordsPerPage} .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
                    </div>
                </div>
             

                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
            .table-header{
                flex-direction: row-reverse;
            }

      `]
    };
}

import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { format } from 'date-fns';
import { css, html, LitElement } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import { classMap } from 'lit/directives/class-map.js';
import { container } from 'tsyringe';
import Styles from '../../../assets/styles';
import { TransactionType } from '../enums/transaction-type';
import { AuthService } from '../services/auth.service';
import { BillingService } from '../services/billing.service';
import { ToasterService } from '../services/toaster.service';
import { DataGridColumn } from './components/data-grid-template';
import { SeDataGrid } from './components/data-grid.element';

@customElement('se-credit-history')
export class CreditHistory extends LitElement {

	private _authService: AuthService;
	private _toasterService: ToasterService;
	private _billingService: BillingService;

	private _data: any[] = [];
	private _pageIndex: number = 1;
	private _recordsPerPage: number = 10;
	private _columns: DataGridColumn[] = [];
	private _totalRecordCount: number = 0;

	@query('se-data-grid') private _dataGrid: SeDataGrid;
	private boundHandlePopState;

	constructor() {
		super();
		this._authService = container.resolve(AuthService);
		this._toasterService = container.resolve(ToasterService);
		this._billingService = container.resolve(BillingService);
		this.boundHandlePopState = this.handlePopState.bind(this);
	}

	connectedCallback() {
		super.connectedCallback();
		window.addEventListener('popstate', this.boundHandlePopState);
		//this.loadData();
	}

	disconnectedCallback() {
		window.removeEventListener('popstate', this.boundHandlePopState);
		super.disconnectedCallback();
	}
	private handlePopState(event) {
		const customEvent = new CustomEvent('customPageChange', {
			detail: { pageIndex: (event.state?.gridPage || 1) }
		});
		this.onPageChanged(customEvent);
	}
	firstUpdated() {
		const currentUrl = window.location.href;
		const url = new URL(currentUrl);
		const searchParams = new URLSearchParams(url.search);
		var pulledPage = Number(searchParams.get("gridPage") || this._pageIndex)
		const customEvent = new CustomEvent('customPageChange', {
			detail: { pageIndex: pulledPage }
		});
		this.onPageChanged(customEvent);

	}

	private async loadData() {
		this._columns = [
			{ field: 'date', title: 'Date', sortable: true, align: 'left', template: (row, col) => html`${format(new Date(row.created), 'MMM d, yyyy, h:mm a')}` },
			{
				field: 'transactiontype', title: 'Transaction Type', align: 'left', template: (row, col) => html`${choose(row.transactionType, [

					[undefined || null, () => this.getTransaction('', 'Unknown')],
					[TransactionType.Initial, () => this.getTransaction('blue', 'Initial')],
					[TransactionType.Credit, () => this.getTransaction('green', 'Credit')],
					[TransactionType.Debit, () => this.getTransaction('red', 'Debit')],
					[TransactionType.Refund, () => this.getTransaction('green', 'Refund')]
				],
					() => html`${TransactionType[row.transactionType]}`)}`
			},
			{ field: 'amount', title: 'Amount', align: 'right', template: (row, col) => html`${'$' + (row.transactionType === 2 ? '-' : '') + row.amount.toFixed(2)}` },
			{ field: 'balance', title: 'Balance', align: 'right', template: (row, col) => html`${'$' + row.balance.toFixed(2)}`}
		]

		var orgId = this._authService.user.organizationId;

		const result = await this._billingService.api.getOrgCreditHistoryAsync(this._pageIndex, this._recordsPerPage);
		if (result.isOk) {
			this._data = result.value.creditHistory;
			this._totalRecordCount = result.value.totalRecordCount;
			this.requestUpdate();
		}
		else {
			this._toasterService.showUnexpectedError(result.err.message);
		}
	}

	private onPageChanged(evt: CustomEvent) {
		evt.stopPropagation();
		this._pageIndex = evt.detail.pageIndex;
		this._dataGrid.pageIndex = this._pageIndex;
		this.loadData();
	}

	private getTransaction(color: string, type: string) {
		return html`<div style="color: ${color}">${type}</div>`
	}

	render() {
		return html`
			<div class="body">
				<div class="container-header gradient" >
					<div class="left-header top-header">
						<div class="h1">Credit History</div>
					</div>
				</div>
				<div class="container-content">
					<div class="table-list">
						<se-data-grid .columns=${this._columns} .rows=${this._data} .pageIndex=${this._pageIndex} .recordsPerPage=${this._recordsPerPage} .totalRecordCount=${this._totalRecordCount}></se-data-grid>
					</div>
					<div class="table-pagination">
						<se-pagination .dataType=${"Transactions"} .recordsPerPage=${this._recordsPerPage} .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
					</div>
				</div>
			</div>

		`;
	}

	static get styles() {
		return [
			Styles,
			css`
			.body{
				background-color: white;
				display: flex;
				flex-direction: column;
				height: 100%;
			}
			.container-content {
				padding-top: 20px;
			}
			`]
	};
}
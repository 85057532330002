import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import "element-internals-polyfill";
import { BaseEditor, SimpleEditor } from '../base-editor';
import { styleMap } from 'lit/directives/style-map.js';

@customElement('se-textarea-inline-editor')
export class TextAreaEditorElement extends LitElement implements SimpleEditor {
    
    @property() value: string;
    get liveValue() { return this._inputElement?.value };
    
    @property({ type: Number }) size?: number;
    @property({ type: Number }) minlength?: number;
    @property({ type: Number }) maxlength?: number;
    @property({ type: Number }) rows?: number;
    @property({ type: Number }) cols?: number;
    @property() pattern?: string;
    @property() placeholder?: string;
    @property({ attribute: 'input-width' }) inputWidth?: string;
    @property({ type: Boolean }) readonly?: boolean;
    @property({ type: Boolean }) required = false;
    @property({ type: Boolean }) disabled = false;
    @property() resize : 'none' | 'both' | 'vertical' | 'horizontal' = 'both';
    @property() name: string;
    @property({ attribute: 'editor-size' }) editorSize: 'small' | 'nomal';

    @query('#input') private _inputElement: HTMLTextAreaElement;

    constructor() {
        super();
    }    
    valueChanged() {
        this.dispatchEvent(new CustomEvent("valueChanged", { bubbles: true, composed: true, detail: { editor: this } }))
    }
    updateValue(event) {        
        if (this._inputElement.checkValidity())
            this._inputElement.classList.remove("invalid");
        else
            this._inputElement.classList.add("invalid");
        this.dispatchEvent(new CustomEvent("editorChanged", { bubbles: true, composed: true, detail: { editor: this } }))
    }
    reportValidity(): boolean {
        if (this._inputElement.reportValidity()) {
            this._inputElement.classList.remove("invalid");
            return true;
        }
        else {
            this._inputElement.classList.add("invalid");
            return false;
        }
    }

    setCustomValidity(error: string) {
        this._inputElement.setCustomValidity(error);
    }
    
    firstUpdated() {        
    }

    cancel() {
        this._inputElement.classList.remove("invalid");
        this.requestUpdate();
    }

    render() {
        const font = { font: this.editorSize === 'small' ? 'var(--font-input-small)' : 'var(--font-input)' };

        return html`            
            <textarea style="${styleMap({ ...font, resize: this.resize, width: this.inputWidth })}" id="input" name="${this.name}"
                .value=${live(this.value??"")}
                @input="${this.updateValue}"
                @change="${this.valueChanged}"
                rows="${ifDefined(this.rows)}"
                cols="${ifDefined(this.cols)}"
                maxlength="${ifDefined(this.maxlength)}"
                minlength="${ifDefined(this.minlength)}"
                placeholder="${ifDefined(this.placeholder)}"
                ?required="${this.required}"
                ?disabkled="${this.disabled}"
                ?readonly="${this.readonly}"                    
            ></textarea>                
        `;
    }

    static styles = css`
    :host {
        display: block;
    }
    
    textarea:disabled+label {
        color:gray;
    }
    textarea.invalid {
        outline: 2px solid pink;
    }
  `;
}


import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import './components/primary-button.element';
import './components/secondary-button.element';
import './editors/input-editor.element';
import './editors/checkbox-editor.element';
import logo from '../../../assets/logoDark.png'
import twitter from '../../../assets/twitter.svg'
import linkedin from '../../../assets/linkedin.svg'
import instagram from '../../../assets/instagram.svg'
import msSignin from '../../../assets/ms-signin.png'
import googleSigninDark from '../../../assets/google_signin_dark.png'
import white_color_logo from '../../../assets/white_color_logo.png'
import { BaseEditor } from './editors/base-editor';
import { AppConfigApi } from '../services/app-config.api';
import { container } from 'tsyringe';
import { AppConfigService } from '../services/app-config.service';
import { InputEditorElement } from './editors/input-editor.element';
import { CheckboxEditorElement } from './editors/checkbox-editor.element';
import { AuthService } from '../services/auth.service';
import { ToasterService } from '../services/toaster.service';
import Styles from '../../../assets/styles';



@customElement('se-forgot-password')
export class ForgotPasswordElement extends LitElement {    

    private _appConfigService: AppConfigService;
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _message: string;

    @query('#email') private _emailEditor: InputEditorElement;
    @query('#password') private _passwordEditor: InputEditorElement;
    @query('#stayLoggedIn') private _stayLoggedInEditor: CheckboxEditorElement;
    @state() private _hasChanged = false;

    constructor() {
        super();

        this._appConfigService = container.resolve(AppConfigService);
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
    }
    
    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {        

        {
            this._message = (new URLSearchParams(location.search)).get('message');
            return undefined;
        }
    }
    
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev))
    }
    disconnectedCallback() {
        this.removeEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev))
        super.disconnectedCallback();
    }

    private onEditorChanged(ev) {
        this._hasChanged = true;
    }

    updateChangedValues() {        
    }

    firstUpdated() {
        if (this._message) {
            this._toasterService.showWarning(this._message);
        }
    }

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false)
                return false;
        }
        return true;
    }

    private async recoveryEmail(ev) {
        ev.preventDefault();
        if (this.reportValidity()) {
            //todo send email
            await this._authService.RecoverPassword(this._emailEditor.liveValue)
            //await this._authService.RecoverPassword(this._emailEditor.liveValue, this.recoveryHtml())
                .then(() => this.success()).catch(err => this.error(err));            
        }
    }
/*
    private async recoveryEmail(ev) {
        ev.preventDefault();
        Router.go("/register");
        
    }
*/

    private async login(ev) {
        ev.preventDefault();
        Router.go("/login");

    }
    

    private success() {
        this._toasterService.clear();
        this._toasterService.showSuccess("You will receive a message at " + this._emailEditor.liveValue + " if you’ve registered your account with that email address. Please check for an email from Sequentum and click on the included link to reset your password", 0);
        //Router.go("/");
    }

    private error(err: Error) {
        this._toasterService.clear();
        this._toasterService.showError(err.message);
        this._hasChanged = true;
    }
    /*
    private recoveryHtml() {
        var html: string;
       return  html = `
                <html>
                    <head>
                        <style>
                            header {padding: 30px;}
                            body {background-color: #1A1E4F;}
                            .logo {display: block; margin-left: auto; margin-right: auto; width: 50%;}
                            .container {display: block; margin-left: auto; margin-right: auto; width: 50%; background-color: white; padding: 3%; border-radius: 10px;}
                            a {color: #5248F9;}
                        </style>
                    </head>
                    <body>
                        <header>
                            <img src="${white_color_logo}" alt="Sequentum" class="logo">
                        </header>
                        <main>
                            <div class="container">
                                Please use the following link to reset your password: <a href="[link]">Reset password</a> <br>
                                If you did not request a password change, please feel free to ignore this message. <br><br>

                                If you have any comments or questions don't hesitate to reach us at <a href="#">[email to customer portal support team]</a>
                            </div>
                        </main>
                    </body>
                </html>
                `
        //return html;
    }
    */
    render() {
        return html`            
            <form id="editorForm" class="editor">
                <div class="scroll-container">
                    <div class="content"> 
                        <div style="text-align: center">
                            <img src=${logo} class="header-logo" />
                        </div>
                        <se-input-editor class="inputEditor" id="email" name="email" type="text" placeholder="example@company.com" label="Email" labelPosition="top" input-type="email" required size="30"></se-input-editor>
                        <se-primary-button class="inputEditor loginButton" @click="${this.recoveryEmail}" text="Send Recovery Email"></se-primary-button>
                        <div class="loginLink">
                            <a class="link" href="Forgot Password" @click="${this.login}">Return to Login</a>
                        </div>
                     </div>
                </div>
                
                <div class="authenticationProviders" style="display: none">
                    <hr style="height:2px;width: 100%;margin-top: 15px; background-color:lightgray;border-width:0">
                    <div style="display: flex; align-items: center;justify-content: space-between;">
                        <input type="image" src=${msSignin} style="height: 30px"></input>
                        <input type="image" src=${googleSigninDark} style="height: 32px"></input>
                    </div>
                </div>
            </form>
            <div class="footer"><div>© 2024 Sequentum  •  <a href="https://www.sequentum.com/privacy-policy" target="_blank">Privacy Policy</a>  •  <a href="https://www.sequentum.com/terms-of-service" target="_blank">Terms of Service</a></div>
                <div style="text-align: right; flex:1">
                    <a href="https://twitter.com/sequentuminc" target="_blank"><img src=${twitter}></a>
                    <a href="https://www.linkedin.com/company/sequentum/" target="_blank"><img src=${linkedin}></a>
                    <a href="https://www.instagram.com/sequentuminc/" target="_blank"><img src=${instagram}></a>
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    .button {
        width: 100%;
        padding: 10px 10px 10px 10px;
        align-items: center;
    }

    .savePanel {
        margin-top: 4px;
    }

    .inputEditor{
        width: 100%;
    }
    .linkLabel {
        padding-left: 15px;
    }

      `]
    };
}
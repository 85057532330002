import { Router } from '@vaadin/router';
import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import { container } from 'tsyringe';
import Styles from '../../../../assets/styles';
import { RunStatus } from "../../enums/run-status";
import { DestinationService } from '../../services/destination.service';
import { SourceService } from '../../services/source.service';
import { ToasterService } from '../../services/toaster.service';
import { DataGridColumn } from './data-grid-template';
import { SeDataGrid } from './data-grid.element';

@customElement('se-details-pipes-tab')
export class DetailsPipesButton extends LitElement {
    private _sourceService: SourceService;
    private _destinationService: DestinationService;
    private _pageIndex: number = 1;
    private _recordsPerPage: number = 10;
    private _totalRecordCount: number;
    private _sortOrder: number = -1;
    private _columns: DataGridColumn[] = [];
    private _destinationId?: number;
    private _sourceId?: number;
    private _toasterService: ToasterService;
    private _runRefresh: boolean = true;
    private _loadTimeout;
    private _sortColumn: string = "Name";


    @state() private _data: any[] = [];

    @property() destinationId: string;
    @property() sourceId: string;

    @query('.grid') private _schemaGrid: SeDataGrid;
    private boundHandlePopState;

    constructor() {
        super();
        this._destinationService = container.resolve(DestinationService);
        this._sourceService = container.resolve(SourceService);
        this._toasterService = container.resolve(ToasterService);
        this.boundHandlePopState = this.handlePopState.bind(this);

    }

    /**
     * Called when the page is loading
     */
    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('popstate', this.boundHandlePopState);
        this.updateDataAsync();
    }

    /**
     * Called when the page is unloading
     */
    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('popstate', this.boundHandlePopState);
        if (this._loadTimeout) {
            clearTimeout(this._loadTimeout);
        }
    }
    private handlePopState(event) {
        const customEvent = new CustomEvent('customPageChange', {
            detail: { pageIndex: (event.state?.gridPage || 1) }
        });
        this.onPageChanged(customEvent);
    }
    firstUpdated() {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);
        var pulledPage = Number(searchParams.get("gridPage") || this._pageIndex)
        const customEvent = new CustomEvent('customPageChange', {
            detail: { pageIndex: pulledPage }
        });
        this.onPageChanged(customEvent);

    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._schemaGrid.pageIndex = this._pageIndex;
        this.loadData();
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();

    }
    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortOrder = evt.detail.sortOrder;
        const sortColumn = evt.detail.sortColumn;

        this._sortOrder = sortOrder;
        this._sortColumn = sortColumn;

        this.loadData();
    }
    private filterByLabel(event: CustomEvent) {
        event.stopPropagation();
    }
    private filterByAgentTemplate(event: CustomEvent) {
        event.stopPropagation();
        this.loadData(event.detail.agentTemplateId);
    }

    private async updateDataAsync() {
        if (!this._runRefresh) {
            return;
        }
        this._loadTimeout = setTimeout(() => { this.updateDataAsync(); this.loadData(); }, 5000);
    }

    private loadData(nameValue: string = "null") {
        return new Promise<void>(async (resolve, reject) => {

            this._columns = [
                { field: 'name', title: 'Agent',sortable: true, cellStyle: { textDecoration: 'underline' }, action: this.viewPipeDetails.bind(this) },
                { field: 'source', title: 'Source' },
                //{ field: 'destination', title: 'Destination' },
                { field: 'schedule', title: 'Schedule', align: 'center', template: (row, col) => (row.nextRunTime) ? html`${(new Date(row.nextRunTime)).toLocaleString()}` : html`` },
                { field: 'lastActivity', title: 'Last Run', template: (row, col) => (row.lastActivity) ? html`${(new Date(row.lastActivity)).toLocaleDateString()}` : html`` },
                {
                    field: 'status', title: 'Status', sortable: true, template: (row, col) => html`${choose(row.status, [
                        [undefined || null, () => this.getStatus('#D0D3DC', 'Not Run')],
                        [RunStatus.success, () => this.getStatus('#219653', 'Completed')],
                        [RunStatus.completed, () => this.getStatus('#219653', 'Completed')],
                        [RunStatus.running, () => this.getStatus('#3F8CD9', 'Running')],
                        [RunStatus.exporting, () => this.getStatus('#3F8CD9', 'Running')],
                        [RunStatus.starting, () => this.getStatus('#3F8CD9', 'Running')],
                        [RunStatus.stopped, () => this.getStatus('#EB5757', 'Failed')],
                        [RunStatus.failed, () => this.getStatus('#EB5757', 'Failed')],
                        [RunStatus.stopping, () => this.getStatus('#EB5757', 'Failed')],
                        [RunStatus.failure, () => this.getStatus('#EB5757', 'Failed')],
                        [RunStatus.queuing, () => this.getStatus('#D0D3DC', 'Queued')]
                    ],
                        () => html`${row.status}`)}`
                },
            ]
            try {
                if (this.destinationId) {
                    this._destinationId = Number(this.destinationId)

                    const result = await this._destinationService.api.getAllPipesByDestIdAsync(this._destinationId, this._pageIndex, this._recordsPerPage, this._sortColumn, nameValue, this._sortOrder);
                    if (result.isOk) {
                        this._data = result.value.configs;
                        this._totalRecordCount = result.value.totalRecordCount;
                    }
                    else {
                        this._toasterService.showUnexpectedError(result.err.message);
                    }
                }
                if (this.sourceId) {
                    this._sourceId = Number(this.sourceId)

                    const result = await this._sourceService.api.getAllPipesBySourceIdAsync(this._sourceId, this._pageIndex, this._recordsPerPage, this._sortColumn, nameValue, this._sortOrder);
                    if (result.isOk) {
                        this._data = result.value.configs;
                        this._totalRecordCount = result.value.totalRecordCount;
                    }
                    else {
                        this._toasterService.showUnexpectedError(result.err.message);
                    }
                }
                resolve();
            } catch (error) {
                reject(error)
            }

        })
    }
    private getStatus(color: string, text: string): TemplateResult {
        return html`
        <div class="pipe-item-status" style="background-color: ${color};">

                <span>${text}</span>
        </div>`
    }
    private async viewPipeDetails(row: any, col: DataGridColumn) {
        Router.go('/agents/' + row.id);
    }

    render() {
        return html`
                    <div class="table-list">
                        <se-data-grid .selectAllCheckbox=${true} class="grid" .rows=${this._data} .recordsPerPage=${this._recordsPerPage} .pageIndex=${this._pageIndex} .columns=${this._columns} selectable @selectionchanged=${this.onGridSelectionChanged} @sortdata=${this.sortDataGrid} @filterbylabel=${this.filterByLabel} @filterbyAgentTemplate=${this.filterByAgentTemplate}></se-data-grid>
                    </div>
                    <div class="table-pagination" >
                            <se-pagination .dataType=${"Agents"} .recordsPerPage=${this._recordsPerPage} .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
                    </div>
 
                        `;
    }

    
 
    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        background:none;
        color:#1A1F4B
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .body {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
    }
    .time-section{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .action-button{
        margin-bottom: -75px;
        display: flex;
        justify-content: space-between;
    }
    .config-tab{
        max-width: 700px;
        width: 100%;
    }
    .action-button .inputEditor{
         width:100px;
    }
    .grid {
        flex:1;        
    }   
    .checkbox {
        width: 1rem;
        height: 1rem;
    }
    input[type="checkbox"]:checked {
        background-color: var(--color-secondary);
    }
    .left-header{
        flex: 1 1 0%;
        max-width: 600px;
        padding-right: 50px;
    }

      `]
    };

}


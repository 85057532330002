import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';

@customElement('se-title')
export class SeTitleElement extends LitElement {    
        
    @state() private _title: string | TemplateResult;
    private _description?: string;
    private _showOnClick?: boolean;

    myClick(e: Event) {        
        alert(e.type);
    }

    firstUpdated() {        
    }

    hide() {

    }

    showTitle(title: string | TemplateResult, description?: string) {
        this._title = title;
        this._description = description;
    }
    render() {
        if (typeof this._title === 'string') {
            return html`            
                <span style="font-weight: bold">${this._title}</span>
                ${when(this._description, () => html`<p>${this._description}</p>`)}
            `;
        }
        else {
            return this._title;
        }
    }

    static styles = css`
    :host[hidden] {
        display: hidden;
    }
    :host {
        display: block;        
        background-color: DimGray;
        border-radius: 3px 3px;
        color: white;
        font: var(--font-small);
        position: absolute;
        z-index: 2000;
        padding: 5px 10px 5px 10px;
        left: -1000px;
        top: -1000px;
    }
    
  `;
}
import { container, inject, singleton } from 'tsyringe';
import { SeTitleElement } from '../elements/components/title.element';
import '../elements/components/title.element';
import { TemplateResult } from 'lit';

@singleton()
export class TitleService {

    private _title: SeTitleElement;    

    constructor() {
        if (document.readyState !== 'loading') {
            this.createTitleElement();
        }
        else {
            window.addEventListener('DOMContentLoaded', (event) => {
                this.createTitleElement();
            })
        }
    }

    createTitleElement() {
        if (!this._title) {
            this._title = document.createElement("se-title") as SeTitleElement;
            this._title.hidden = true;
            document.body.appendChild(this._title);
        }        
    }

    hideTitle() {
        if (this._title) {
            this._title.style.left = '-1000px';
            this._title.style.top = '-1000px';
            this._title.hide();
        }        
    }

    async showTitle(target: HTMLElement, title: string | TemplateResult, description?: string) {
        if (this._title) {
            var rect = target.getBoundingClientRect();
            this._title.showTitle(title, description);
            this._title.style.maxWidth = (window.innerWidth - 20) + 'px';
            this._title.style.left = '-1000px';
            this._title.style.top = '-1000px';
            this._title.hidden = false;
            this._title.requestUpdate();
            await this._title.updateComplete;
            var titleRect = this._title.getBoundingClientRect();
            let left;
            if (titleRect.width > rect.width) {
                left = rect.left - (titleRect.width - rect.width) / 2;
            }
            else {
                left = rect.left + (rect.width - titleRect.width) / 2;
            }
            if (left < 10) {
                left = 10;
            }
            else if (left + titleRect.width > window.innerWidth - 10) {
                left = window.innerWidth - titleRect.width - 10;
            }
            let top = rect.bottom + 5;
            if (top + titleRect.height > window.innerHeight - 10) {
                top = rect.top - titleRect.height - 5;
            }
            this._title.style.left = left + 'px';
            this._title.style.top = top + 'px';
        }
    }
}
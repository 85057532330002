import { LitElement, html, css } from 'lit';
import { customElement, property, query, queryAll, state } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { SortOrder } from '../../enums/sort-order';
import { DataGridColumn, DataGridTemplate } from './data-grid-template';
import { MenuService } from '../../services/menu.service';
import { container } from 'tsyringe';
import { CheckboxEditorElement } from '../editors/checkbox-editor.element';
import { htmlTitle } from '../../directives/html-title.directive';
import { SimpleEditor } from '../editors/base-editor';
import Styles from '../../../../assets/styles';
import '../editors/inline/input-editor.element';
import '../editors/inline/select-editor.element';
import '../editors/inline/textarea-editor.element';
import '../editors/inline/number-editor.element';
import '../editors/inline/checkbox-editor.element';
import '../editors/inline/typeahead-editor.element';

@customElement('se-data-grid')
export class SeDataGrid extends LitElement {

    @property({ type: Array }) rows: any[];
    @property({ type: Array }) columns: DataGridColumn[];
    @property({ type: Number }) pageIndex?= 1;
    @property({ type: Number }) recordsPerPage?= 100;
    @property() styles: any = [];
    @property({ type: Boolean }) fullHeight = true;
    @property({ type: Number }) footerHeight = 10;
    @property({ type: Boolean }) selectable = false;
    @property({ type: Boolean }) selectAllCheckbox = false;
    @property({ type: Boolean }) hasPreSelected = false;
    @property({ type: Boolean }) isAllSelected = false;
    @property({ attribute: 'save-mode' }) saveMode: 'all' | 'cell' = 'cell';
    @property({ attribute: 'layout' }) layout: 'normal' | 'compact' = 'normal';
    @property({ attribute: 'new-button' }) newButton: 'none' | 'top' | 'bottom' = 'none';
    @property({ type: Object }) newRow?: (() => {}) | (() => Promise<{}>);
    @property({ attribute: 'accessor' }) accessor?: string;    
    @property({ attribute: 'placeholder' }) placeholder?: string;    
    //@property({ type: Object }) newRowAsync?: () => Promise<{}>;

    @state() private _editorCol: DataGridColumn;
    @state() private _editorRow: any;
    private _editorCellWidth: string;
    private _editorSaving = false;

    private _isBreakWords = false;
    private _wordsBrokenAtWidth = 0;
    @state() private _sortColumn;
    @state() private _sortOrder;
    private _menuService: MenuService;

    @query('#table') private _table: HTMLTableElement;
    @query('#selectAll') private _selectAllcheckb: CheckboxEditorElement;
    @query('#tableContainer') private _tableContainer: HTMLDivElement;
    @queryAll('.checkbox') private _selectedCheckboxes: CheckboxEditorElement[];

    constructor() {
        super();
        this._menuService = container.resolve(MenuService);
    }

    willUpdate(changedProperties) {
        if (changedProperties.has('rows')) {
            this._isBreakWords = false;
            this._wordsBrokenAtWidth = 0;
            if (!this.hasPreSelected) {
                this.clearSelection();
                if (this.selectAllCheckbox && this._selectAllcheckb)
                    this._selectAllcheckb.liveValue = false;
            } else {
                this.hasPreSelected = false;
            }
        }
    };

    connectedCallback() {
        super.connectedCallback();
        addEventListener('resize', () => this.refreshSize());
    }
    disconnectedCallback() {
        removeEventListener('resize', () => this.refreshSize());
        super.disconnectedCallback();
    }


    private onSelectRow(row: any) {
        row.selected = !row.selected;
        this.dispatchEvent(new CustomEvent("selectionchanged", { bubbles: true, composed: true, detail: { row: row } }));
    }
    selectAllRows() {
        this.rows.forEach(row => { row.selected = true; })
        this._selectedCheckboxes.forEach(checkbox => { checkbox.value = true; })
    }
    clearSelection() {
        this.rows.forEach(row => { row.selected = false; })
        this._selectedCheckboxes.forEach(checkbox => { checkbox.value = false; })
    }
    get selectedRows() {
        return this.rows.filter(p => p.selected);
    }

    private setWordBreak() {
        if (this._tableContainer) {
            var wasBreakWords = this._isBreakWords;
            if (this._tableContainer.scrollWidth > this._tableContainer.clientWidth + 5) {
                if (!this._isBreakWords) {
                    for (var i = 1; i < this._table.rows.length; i++) {
                        for (var j = 0; j < this._table.rows[i].cells.length; j++) {
                            this._table.rows[i].cells[j].style.wordBreak = 'break-all';
                            this._isBreakWords = true;
                            this._wordsBrokenAtWidth = this._tableContainer.clientWidth
                        }
                    }
                } else {
                    this._tableContainer.style.overflowX = 'auto';
                }
            } else if (this._isBreakWords && this._tableContainer.clientWidth > this._wordsBrokenAtWidth + 20) {
                for (var i = 1; i < this._table.rows.length; i++) {
                    for (var j = 0; j < this._table.rows[i].cells.length; j++) {
                        this._table.rows[i].cells[j].style.wordBreak = 'normal';
                        this._isBreakWords = false;
                        this._tableContainer.style.overflowX = 'hidden';
                    }
                }
            }
            if (wasBreakWords !== this._isBreakWords) {
                setTimeout(() => {
                    this.setWordBreak()
                }, 0);
            }
        }
    }

    private refreshSize() {
        if (this._tableContainer) {
            this._tableContainer.style.overflowX = 'hidden';
            setTimeout(() => this.setWordBreak(), 0);
        }
    }

    calculateHeight() {
        setTimeout(() => this.setWordBreak(), 0);
    }

    collapse() {
        this._tableContainer.style.height = '0px';
    }

    private getHeaderValue(col: DataGridColumn) {

        const words = col.title?.split('|') ?? [];
        return html`            
            ${(col.breakAtPipe && words.length > 1) ? (this._isBreakWords ? html`${words[0]}<img style="width:0px;height:0px"/>${words[1]}` : html`${words.join()}`) : html`${col.title ?? ''}`}
        `;
    }

    private getColumnHeader(col: DataGridColumn) {

        if (col.sortable) {
            const sortIcon = this._sortColumn === col.field ? (this._sortOrder === SortOrder.Ascending ? "fa-sort-down" : "fa-sort-up") : "fa-sort";
            return html`
            <span @click=${evt => this.sort(evt, col)} class="pointer" >
                <span style="display: flex; gap:5px"><span>${this.getHeaderValue(col)}</span><fa-icon secondary-color="DarkGray" fa-class='fad ${sortIcon}'></fa-icon></span>
            </span>
            `;
        }
        else {
            return this.getHeaderValue(col);
        }
    }

    private sort(evt: Event, col: DataGridColumn) {
        evt.stopPropagation();
        if (!col.sortable || !col.field) {
            return;
        }

        this._sortOrder = this._sortColumn !== col.field ? SortOrder.Descending : (this._sortOrder === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending);
        this._sortColumn = col.field;

        this.dispatchEvent(new CustomEvent("sortdata", { bubbles: true, composed: true, detail: { sortColumn: this._sortColumn, sortOrder: this._sortOrder } }));
    }

    firstUpdated() {
        this._tableContainer.style.overflowX = 'hidden';
        setTimeout(() => {
            this.setWordBreak()
        }, 0);
    }

    private getIcon(row: any, col: DataGridColumn) {
        return this.getIconByName((typeof col.icon === 'string') ? col.icon : col.icon.call(this, row, col), col.iconColor);
    }

    private getIconByName(icon?: string, iconColor?: string) {
        return html`<fa-icon single-color=${iconColor} fa-class='${icon}'></fa-icon>`;
    }

    private getLink(row: any, col: DataGridColumn) {
        if (!col.field) {
            return html`<a href=${col.link.call(row, col)}>${this.getIcon(row, col)}</a>`;
        }
        else {
            if (col.icon) {
                return html`<a href=${col.link.call(row, col)} style="display: flex; gap: 5px">${this.getIcon(row, col)}<span>${this.getValue(row, col)}</span></a>`;
            }
            else {
                return html`<a href=${col.link.call(row, col)}>${this.getValue(row, col)}</a>`;
            }
        }
    }
    private getAction(row: any, col: DataGridColumn) {
        if (!col.field) {
            return html`<span style="cursor: pointer" @click=${() => col.action(row, col)}>${this.getIcon(row, col)}</span>`;
        }
        else {
            if (col.icon) {
                return html`<span style="cursor: pointer" @click=${() => col.action(row, col)} style="display: flex; gap: 5px">${this.getIcon(row, col)}<span>${this.getValue(row, col)}</span></span>`;
            }
            else {
                return html`<span style="cursor: pointer" @click=${() => col.action(row, col)}>${this.getValue(row, col)}</span>`;
            }
        }
    }

    private getBaseValue(row: any, col: DataGridColumn) {
        if (col.getValue) {
            return col.getValue(row, col);
        }
        else {
            return this.accessor ? (row[col.field]?.[this.accessor] ?? undefined) : row[col.field]
        }        
    }

    private getValue(row: any, col: DataGridColumn) {
        if (col.preformatted) {
            return html`<pre style="margin:0; font: var(--font);">${this.getBaseValue(row,col)}</pre>`;
        }
        else if (col.options) {
            return html`${col.options.find(p => p.id === (this.getBaseValue(row,col) ?? ''))?.name??''}`;
        }
        else {
            return html`<span style="${(this.getBaseValue(row, col)?.length > 50) ? "text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 20vw;display: inline-block;text-decoration: underline;":"" }" title="${this.getBaseValue(row, col)}">${this.getBaseValue(row, col)}</span>`;
        }
    }
    private getText(row: any, col: DataGridColumn) {
        if (col.icon) {
            return html`<div style="display: flex; gap: 5px">${this.getIcon(row, col)}<span>${this.getValue(row, col)}</span></div>`;
        }
        else if (col.checkbox) {
            if (this.getBaseValue(row, col))
                return html`<fa-icon style="font: var(--font-input-large)" fa-class='far fa-check'></fa-icon>`;
            else
                return html``;
        }
        else {
            return this.getValue(row, col);
        }
    }
    private getButton(row: any, col: DataGridColumn) {
        return html`<button class="row-button" ${col.description ? htmlTitle(col.description) : ''} @mousedown="${() => col.button(row, col)}">${this.getButtonIcon(row, col)}</button>`;
    }
    private getButtonIcon(row: any, col: DataGridColumn) {
        if (col.icon) {
            return this.getIcon(row, col);
        }
        else {
            return this.getBaseValue(row,col);
        }
    }
    private getDeleteButton(row: any, col: DataGridColumn) {
        return html`<button ${htmlTitle('Delete Row')} class="row-button red" @mousedown="${() => this.deleteRow(row, col)}">${this.getDeleteIcon(row, col)}</button>`;
    }
    private getDeleteIcon(row: any, col: DataGridColumn) {
        if (col.icon) {
            return this.getIcon(row, col);
        }
        else {
            return this.getIconByName('far fa-trash-alt');
        }
    }
    private getMenu(row: any, col: DataGridColumn) {
        if (col.field)
            return html`<div class="menu">${this.getMenuText(row, col)}<button class="row-button" @mousedown="${evt => this.openMenu(evt, row, col)}">${this.getMenuIcon(row, col)}</button></div>`;
        else
            return html`<button class="row-button" @mousedown="${evt => this.openMenu(evt, row, col)}">${this.getMenuIcon(row, col)}</button>`;
    }
    private getMenuIcon(row: any, col: DataGridColumn) {
        if (col.icon) {
            return this.getIcon(row, col);
        }
        else {
            return this.getIconByName('far fa-ellipsis-v');
        }
    }
    private getMenuText(row: any, col: DataGridColumn) {
        if (col.field) {
            return html`<span>${this.getBaseValue(row,col)}</span>`;
        }
        else {
            return html``;
        }
    }

    private onEdit(event: Event, row: any, col: DataGridColumn) {
        event.stopPropagation();
        this._editorCol = col;
        this._editorRow = row;
        const button = event.target as HTMLElement;
        this._editorCellWidth = (button.parentElement.offsetWidth) + 'px';
    }

    private getCellContentWithEdit(row: any, col: DataGridColumn) {
        if (col.editor && col.editor !== 'none' && col.editor !== 'checkbox') {
            let content = this.getCellContent(row, col);
            return html`
                <div style="position:relative;">
                    <div class="edit-div">
                        ${content}
                        <button ?disabled=${this._editorSaving} class="button edit-icon" @mousedown=${event => this.onEdit(event, row, col)} ${htmlTitle("Edit " + col.title)}>${this.getIconByName("far fa-pencil")}</button>
                    </div>
                </div>
            `
        }
        else {
            return this.getCellContent(row, col);
        }
    }

    private getCellContent(row: any, col: DataGridColumn) {
        if (col.component) {
            return html`${this.getComponent(col.component, col, row)}`
        }
        else if (col.template) {
            return col.template.call(this, row, col);
        }
        else if (col.menu) {
            return this.getMenu(row, col);
        }
        else if (col.isDelete) {
            return this.getDeleteButton(row, col);
        }
        else if (col.button) {
            return this.getButton(row, col);
        }
        else {
            if (col.editor === 'checkbox')
                return this.getEditCheckBox(row, col);
            else if (col.link)
                return this.getLink(row, col);
            else if (col.action)
                return this.getAction(row, col);
            else if (!col.field)
                return this.getIcon(row, col);
            else
                return this.getText(row, col);
        }
    }

    private getEditCheckBox(row: any, col: DataGridColumn) {        
        return html`<se-checkbox-inline-editor .disabled=${this._editorSaving} @input=${evt => evt.target.setCustomValidity('')} @valueChanged=${evt => this.inputChanging(evt, row, col)} .value=${this.getBaseValue(row, col)}></se-checkbox-inline-editor>`
    }


    private getComponent(template: string, col: DataGridColumn, row: any) {
        let temp = document.createElement(template);
        if (temp instanceof DataGridTemplate) {
            temp.column = col;
            temp.row = row;
        }
        else {
            throw new Error("Unexpected template type.")
        }
        return temp;
    }

    private openMenu(event: MouseEvent, row: any, col: DataGridColumn) {
        event.stopPropagation();
        this._menuService.openContextMenu({ button: event.target as HTMLElement, activeClass: 'menu-active', menu: col.menu.call(this, row, col) });
    }

    updated() {
        setTimeout(() => {
            this.setWordBreak()
        }, 0);
    }

    private inputChanging(event: Event, row: any, col: DataGridColumn) {
        event.stopPropagation();
        const input = event.target as unknown as SimpleEditor;
        if (col.setValue) {
            this.saveEditor(input, row, col);
        }
        else {
            this.save(input, row, col);
        }
    }

    private getCellPadding() {
        return { padding: this.layout === 'compact' ? (this.saveMode === 'all' ? '2px 5px' : '5px 5px') : (this.saveMode === 'all' ? '5px 5px' : '10px 5px') } ?? {};
    }

    private getSaveAllEditorCell(row: any, col: DataGridColumn) {

        /*if (col.editor === 'text') {
            return html`
                <td style="${styleMap(col.cellStyle ?? {})}" class="${classMap(col.cellClass ?? {})}">
                    <input type="text" class="input" @change=${evt => this.inputChanging(evt, row, col)} @input=${evt => evt.target.setCustomValidity('')} value=${this.getBaseValue(row,col)}>
                </td>`
        }
        else {
            return html``;
        }*/

        if (col.editor !== 'none') {
            return html`
                <td style="${styleMap({ ...col.cellStyle, ...this.getCellPadding() })}" class="${classMap(col.cellClass ?? {})}">
                    ${this.getEditor(row, col)}
                </td>`
        }
        else {
            return html``;
        }
    }

    private onEditCancel(event: Event) {
        event.stopPropagation();
        this._editorCellWidth = undefined;
        this._editorCol = undefined;
        this._editorRow = undefined;
    }
    private onEditSave(event: Event, row: any, col: DataGridColumn) {
        event.stopPropagation();
        const button = event.target as HTMLElement;
        const input = button.parentElement.firstElementChild as unknown as SimpleEditor;
        if (col.setValue) {
            this.saveEditor(input, row, col);
        }        
        else {
            this.save(input, row, col);
        }
    }
    private async saveEditor(input: SimpleEditor, row: any, col: DataGridColumn) {
        const result = col.setValue(input.liveValue, row, col);
        if (result instanceof Promise) {
            this._editorSaving = true;
            const nextResult = await result;
            this._editorSaving = false;
            if (nextResult.success) {
                this._editorCellWidth = undefined;
                this._editorCol = undefined;
                this._editorRow = undefined;
            }
            else {
                input.setCustomValidity(nextResult.errorMessage ?? 'Error saving value.');
                input.reportValidity();
            }
        }
        else if (result.success) {
            this._editorCellWidth = undefined;
            this._editorCol = undefined;
            this._editorRow = undefined;
        }
        else {
            input.setCustomValidity(result.errorMessage ?? 'Error saving value.');
            input.reportValidity();
        }
    }

    private save(input: SimpleEditor, row: any, col: DataGridColumn) {
        input.setCustomValidity('');
        if(this.accessor)
            row[col.field][this.accessor] = input.liveValue;
        else
            row[col.field] = input.liveValue;
        this._editorCellWidth = undefined;
        this._editorCol = undefined;
        this._editorRow = undefined;
    }

    private async deleteRow(row: any, col: DataGridColumn) {
        if (col.deleteRow) {
            const result = col.deleteRow(row, col);
            if (result instanceof Promise) {
                this._editorSaving = true;
                if (await result) {
                    const index = this.rows.indexOf(row);
                    this.rows.splice(index, 1);
                    this.requestUpdate();
                }
                this._editorSaving = false;
            }
            else if (result) {
                const index = this.rows.indexOf(row);
                this.rows.splice(index, 1);
                this.requestUpdate();
            }
        }
        else {
            const index = this.rows.indexOf(row);
            this.rows.splice(index, 1);
            this.requestUpdate();
        }
    }

    private getEditor(row: any, col: DataGridColumn) {        
        if (col.editor === 'text') {
            const style = { flex: '1', width: '100%', minWidth: this.layout === 'compact' ? '100px' : '150px' };
            return html`<se-input-inline-editor .disabled=${this._editorSaving} style=${styleMap(style)} @input=${evt => evt.target.setCustomValidity('')} value=${this.getBaseValue(row, col) ?? ''} @valueChanged=${this.saveMode === 'all' ? evt => this.inputChanging(evt, row, col) : undefined}></se-input-inline-editor>`
        }
        else if (col.editor === 'text-area') {
            const style = { flex: '1', width: '100%', minWidth: this.layout === 'compact' ? '100px' : '150px' };
            const lines = (this.getBaseValue(row,col)?.split("\n").length) ?? 2
            return html`<se-textarea-editor .disabled=${this._editorSaving} style=${styleMap(style)} @input=${evt => evt.target.setCustomValidity('')} .value=${this.getBaseValue(row, col) ?? ''} rows=${lines < 2 ? 2 : lines} @valueChanged=${this.saveMode === 'all' ? evt => this.inputChanging(evt, row, col) : undefined}></se-textarea-editor>`
        }
        else if (col.editor === 'select') {
            const style = { flex: '1', width: '100%', minWidth: this.layout === 'compact' ? '100px' : '150px' };
            return html`<se-select-inline-editor input-width="100%" .disabled=${this._editorSaving} style=${styleMap(style)} @input=${evt => evt.target.setCustomValidity('')} @valueChanged=${this.saveMode === 'all' ? evt => this.inputChanging(evt, row, col) : undefined} .options=${col.options} .value=${this.getBaseValue(row, col) }></se-select-inline-editor>`
        }
        else if (col.editor === 'checkbox') {
            const style = { flex: '1', width: '100%' };
            return html`<se-checkbox-inline-editor .disabled=${this._editorSaving} style=${styleMap(style)} @input=${evt => evt.target.setCustomValidity('')} @valueChanged=${this.saveMode === 'all' ? evt => this.inputChanging(evt, row, col) : undefined} .value=${this.getBaseValue(row, col)}></se-checkbox-inline-editor>`
        }
        else if (col.editor === 'number') {
            const style = { flex: '1', width: '100%', minWidth: this.layout === 'compact' ? '100px' : '150px' };
            return html`<se-number-inline-editor .disabled=${this._editorSaving} style=${styleMap(style)} @input=${evt => evt.target.setCustomValidity('')} @valueChanged=${this.saveMode === 'all' ? evt => this.inputChanging(evt, row, col) : undefined} .value=${this.getBaseValue(row, col)}></se-number-inline-editor>`
        }
        else if (col.editor === 'typeahead') {
            const style = { flex: '1', width: '100%', minWidth: this.layout === 'compact' ? '100px' : '150px' };
            return html`<se-typeahead-inline-editor .disabled=${this._editorSaving} style=${styleMap(style)} @input=${evt => evt.target.setCustomValidity('')} @valueChanged=${this.saveMode === 'all' ? evt => this.inputChanging(evt, row, col) : undefined} .textOptions=${col.textOptions} show-all .value=${this.getBaseValue(row, col)}></se-typeahead-inline-editor>`
        }
    }

    private getSaveCellEditorCell(row: any, col: DataGridColumn) {
        if (col.editor !== 'none') {
            return html`
                <td style="${styleMap({ ...col.cellStyle, ...this.getCellPadding(), width: this._editorCellWidth } ?? {})}" class="${classMap(col.cellClass ?? {})}">
                    <div class="cell-editor-div">                    
                    ${this.getEditor(row, col)}
                    <button class="button" ?disabled=${this._editorSaving} @click=${event => this.onEditSave(event, row, col)}>${this.getIconByName("far fa-check")}</button>
                    <button class="button" ?disabled=${this._editorSaving} @click=${evt => this.onEditCancel(evt)}>${this.getIconByName("far fa-times")}</button>
                    </div>
                </td>`
        }
        else {
            return html``;
        }
    }

    private async addNewRow(evt: Event) {
        evt.stopPropagation();
        this._editorSaving = true;
        if (this.newRow) {
            const result = this.newRow();
            if (result instanceof Promise) {
                const row = await result;
                if (row)
                    this.rows.push(row);
            }
            else {
                this.rows.push(result);
            }
        }
        else {
            this.rows.push({});
        }
        this._editorSaving = false;
        this.requestUpdate();
    }
    private selectAllCheck(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this.selectAllRows();
            }
            else {
                this.clearSelection();
            }
        }
    }
    render() {
        let isOdd = true;
        const tableClass = { compact: this.layout === 'compact' };
        return html`
            <div id="tableContainer" class="tableContainer" style=${styleMap({ height: this.fullHeight ? '100%' : undefined })}>
              <table id="table" class="table ${classMap(tableClass)}" style="${styleMap(this.styles ?? {})}">
                <tr>
                  
                  ${this.columns.filter(p => !p.hidden).map(col => {
            if (col.align && col.align !== 'left') {
                const style = { display: 'flex', justifyContent: col.align === 'center' ? 'center' : 'right', textAlign: col.align === 'center' ? 'center' : 'right' };
                return html`<th style="${styleMap(col.headerStyle ?? {})}"><div style=${styleMap(style)}>
                            ${this.getColumnHeader(col)}</div></th>`
            }
            else {
                return html`<th style="${styleMap(col.headerStyle ?? {})}">${this.getColumnHeader(col)}</th>`
            }
        }
        )}
                </tr>
                ${when(this.newButton === 'top', () => html`<tr><td colspan=${this.columns.length + (this.selectable ? 1 : 0)}><button ?disabled=${this._editorSaving} class="button" @click=${this.addNewRow}>New</button></td></tr>`)}
                ${this.rows != null ?  this.rows.map(row => {                    
                            isOdd = !isOdd;                    
                            return html`<tr class="${classMap({ alt: isOdd })}">
                                ${this.columns.filter(p => !p.hidden).map(col => {
                        if (col.editor && col.editor !== 'none' && this.saveMode === 'all') {
                            return this.getSaveAllEditorCell(row, col);
                        }
                        else if (col.editor && col.editor !== 'none' && this._editorCol === col && this._editorRow === row) {
                            return this.getSaveCellEditorCell(row, col);
                        }
                        else if (col.align && col.align !== 'left') {
                            const style = { display: 'flex', justifyContent: col.align === 'center' ? 'center' : 'right', textAlign: col.align === 'center' ? 'center' : 'right' };
                            return html`
                                        <td style="${styleMap({ ...col.cellStyle, ...this.getCellPadding() })}" class="${classMap(col.cellClass ?? {})}">
                                            <div style=${styleMap(style)}>${this.getCellContentWithEdit(row, col)}</div>
                                        </td>`
                        }
                        else {
                            return html`
                                        <td style="${styleMap({ ...col.cellStyle, ...this.getCellPadding() })}" class="${classMap(col.cellClass ?? {})}">
                                            ${this.getCellContentWithEdit(row, col)}
                                        </td>`
                        }
                    })}                        
                    </tr>`
                }) : ''}
                ${(this.rows == null || this.rows.length) == 0 && this.placeholder ? html`<tr><td style="text-align: center; color: gray; font-size:0.75em;padding:10px" colspan=${this.columns.length + (this.selectable ? 1 : 0)}>${this.placeholder}</td></tr>`: html``}
                ${when(this.newButton === 'bottom', () => html`<tr><td colspan=${this.columns.length + (this.selectable ? 1 : 0)}><button ?disabled=${this._editorSaving} class="button" @click=${this.addNewRow}>New</button></td></tr>`)}
              </table>
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    tr th,tr td{
        border-bottom: 1px solid #d8dbe2;  
        vertical-align: middle !important;
    }
    :host {
        font: var(--font);
        min-height: 0px;
        min-width: 0px;        
    }
    .cell-editor-div {
        display: inline-flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 5px;
        width: 100%;
    }
    .input {
        font: var(--font);
        width: 100%;
        border: 1px solid lightgray;        
    }
    input:invalid {
        border: 1px solid salmon;
    }

    .edit-icon {        
        opacity: 0;
        position: absolute;
        right: 0px;        
    }
    div.edit-div {
        display: flex;
        flex-direction: row;        
        justify-content: space-between;
        min-height: 1.4em;
    }
    td:hover .edit-icon {
        opacity: 1;
    }    

    .table td {
      padding: 10px 5px;
      vertical-align: top;
      overflow-wrap: break-word;
      word-wrap: break-word;      
      color: #1A1F4B;      
      font-weight: normal;
    }

    .table.compact td {
      padding: 5px 5px;      
    }
    
    tr.alt {
      background: #fcfcfc;
    }    

    .table th {
      padding: 10px 5px;
      vertical-align: top;
      overflow-wrap: break-word;
      position: sticky;
      top: 0;
      z-index: 2;
      border-top: 0px;
      text-align: left;
      color:#94979D;
      background-color: white;
      font-weight: normal;
    }

    .table.compact th {
      padding: 5px 5px;
      font-weight: normal;
    }

    .table {
      width: 100%;      
      table-layout: auto;
      border-collapse: collapse;     
    }

    .tableContainer {
        width: 100%;      
        overflow-x: hidden;
        overflow-y: auto;
        background-color: white;        
    }
    .pointer {
        cursor: pointer;
    }
    .menu {
        display: flex; gap: 5px;        
    }
    .row-button {
        border: 1px solid lightgray;
        border-radius: 3px 3px;                
        background-color: whitesmoke;
        transition: all 0.25s;
        padding: 2px 4px;        
    }
    .row-button:hover {
        box-shadow: 2px 2px 2px gray;
        border: 1px solid gray;
        border-radius: 3px 3px;        
        background-color: white;
    }
    .row-button.red:hover {
        background-color: crimson;
        color: white;
        border: 1px solid crimson;
    }
    .row-button:active {
        background-color: white;
        box-shadow: none !important;
        border: solid 1px gray;
    }
    .row-button.red:active {
        background-color: crimson;
        color: white;
        border: 1px solid crimson;
        box-shadow: none !important;
    }
    .menu-active {
        background-color: white;
        box-shadow: none !important;
        border: solid 1px gray;        
    }    
    
    .button {
        border: 1px solid grey;
        border-radius: 3px 3px;
        background-color: white;
        transition: all 0.25s, opacity 0.5s;
        padding: 2px 4px;
        margin: 0;
    }
    .button:disabled {        
        background-color: white;
        border: 1px solid lightgray;
        color: gray;
        transition: none;
    }
    .button:hover {
        box-shadow: 2px 2px 2px lightgray;
        border: 1px solid black;                
    }
    .button.red:hover {
        background-color: crimson;
        color: white;
        border: 1px solid crimson;
    }
    .button:hover:disabled {        
        border: 1px solid lightgray;
        box-shadow: none;
    }
    .button:active {        
        box-shadow: none;
    }
    .button.red:active {
        background-color: crimson;
        color: white;
        border: 1px solid crimson;
    }    
  `]
    };
}


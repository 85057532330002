import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { container } from 'tsyringe';
import Styles from '../../../../assets/styles';
import { PipeService } from '../../services/pipe.service';
import { ToasterService } from '../../services/toaster.service';

@customElement('se-details-pipe-params-tab')
export class DetailsParamsButton extends LitElement {
    private _pipeService: PipeService;
    private _pipeId?: number;
    private _toasterService: ToasterService;

    @state() private _commandsArray: any[] = [];
    @state() private _currentPipeData: any[] = [];

    @property() pipeId: string;

    @state() private _inputParams: any;


    constructor() {
        super();
        this._pipeService = container.resolve(PipeService);
        this._toasterService = container.resolve(ToasterService);

    }
    connectedCallback() {
        super.connectedCallback();
        this.loadData();
    }



    private async loadData() {
        this._pipeId = Number(this.pipeId)

        // get pipe parameters
        const currentPipe = await this._pipeService.api.getPipeAsync(this._pipeId);
        if (currentPipe.isOk) {
            var tempArray = JSON.parse(atob(currentPipe.value.configJson));

            this._inputParams = tempArray[this._pipeId].Agent.Parameters || {}

            this._currentPipeData = tempArray


        }
        else {
            this._toasterService.showUnexpectedError(currentPipe.err.message);
        }


    }


    editInputParameters(evt, param) {
        this._inputParams[param] = evt.composedPath()[0].value
    }

    /**
     * Save the parameters to the server
     * @param row
     */
    private async saveParameters(row?: any) {

        this._commandsArray = [...this._commandsArray];
        var tempObj = this._currentPipeData
        if (this._inputParams) {
            tempObj[this._pipeId].Agent.Parameters = this._inputParams;
        }

        var configsJson = {
            configsJson: JSON.stringify(tempObj)
        }

        //send to server
        var result = await this._pipeService.api.updatePipeParametersAsync(this._pipeId, configsJson);
        if (!result.isOk) {
            this._toasterService.showNetworkError(result.err);
        }
        else {
            this._toasterService.showSuccess("Parameters have been updated.");
        }
    }
    render() {
        return html`
                            <div class="form parameters">
                            ${(!this._inputParams) ? "" : Object.entries(this._inputParams).map(param => html`
                                <div>
                                    <label class="h3">${param[0]}</label>
                                    <se-input-editor class="inputEditor" @input=${(e) => this.editInputParameters(e, param[0])} .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} id="search" name="search" type="text" .value=${param[1].toString()} labelPosition="top" input-type="search" required ></se-input-editor>
                                </div>
                            `)}
                                <div class="action-buttons" style="width: 200px;">
                                    <div style=""><se-primary-button .customStyle=${{ 'padding': '10px 40px' }} @click="${this.saveParameters}"  text="Save"></se-primary-button></div>
                                </div>
                            </div>
                            
                            
                        `;
    }

    firstUpdated() {

    }
    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        background:none;
        color:#1A1F4B
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .body {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
    }
    .time-section{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .action-button{
        margin-bottom: -75px;
        display: flex;
        justify-content: space-between;
    }
    .config-tab{
        max-width: 700px;
        width: 100%;
    }
    .action-button .inputEditor{
         width:100px;
    }
    .inputEditor{
        width: 400px;
    }
    .grid {
        flex:1;        
    }   
    .checkbox {
        width: 1rem;
        height: 1rem;
    }
    input[type="checkbox"]:checked {
        background-color: var(--color-secondary);
    }
    .left-header{
        flex: 1 1 0%;
        max-width: 600px;
        padding-right: 50px;
    }

      `]
    };


}


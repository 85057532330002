import { container, inject, singleton } from 'tsyringe';
import { BillingApi } from './billing.api';
import { UsageApi } from './usage.api';

@singleton()
export class BillingService {

    private _billingApi: BillingApi;

    get api() { return this._billingApi };
    
    constructor() {        
        this._billingApi = container.resolve(BillingApi);
    }    

}
import { ScheduleType } from "../enums/schedule-type";

export class ScheduleRunModel {
        
    id?: number;
    configId: number;
    parallelism: number;
    serverGroupId?: number;
    proxyPoolId?: number;
    serverOrganizationId?: number;
    parameters: string;
    isSaveAsTask: boolean;
    taskName: string;
    schedule: string;
    localSchedule: string;
    runEveryPeriod?: number;
    runEveryCount?: number;
    scheduleType?: ScheduleType;
    startTime: Date;
}
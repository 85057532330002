import { container, inject, singleton } from 'tsyringe';
import { AccessLevel } from '../enums/access-level';
import { LoginToken } from '../models/login';
import { UserAccess } from '../models/user-access';
import { AuthApi } from './auth.api';
import { UserApi } from './user-api';
import { err,  ok} from '../../utils/result';
import { integer } from 'vscode-languageserver-protocol';

@singleton()
export class AuthService {
  

    private _authApi: AuthApi;
    private _userApi: UserApi;
    private _user: UserAccess;
    private _token: string;
    private _expiry: Date;
    private _isLoggedIn: boolean;

    private _isAdmin: boolean;
    private _isSuperAdmin: boolean;
    private _isUser: boolean;
    private _isReadOnly: boolean;

    get token() { return this._token??"" };
    get user() { return this._user };
    get isSuperAdmin() { return this._isSuperAdmin };
    get isAdmin() { return this._isLoggedIn && this._isAdmin };
    get isUser() { return this._isLoggedIn && this._isUser };
    get isLoggedIn() { return this._isLoggedIn};

    constructor() {

        
        this._authApi = container.resolve(AuthApi);
        this.loadUser();
        
    }

    async loginAsync(email: string, password: string, isKeepMeLoggedIn?: boolean) {

        const res = await this._authApi.loginAsync(email, password, isKeepMeLoggedIn);
        if (res.isOk) {
            this.login(res.value, isKeepMeLoggedIn);
        }
        else {
            throw res.err;
        }        
    }

    async logoutAsync(token: string) {

        const res = await this._authApi.logoutAsync(token);
        if (res.isOk) {
            this.logout();
        }
        else {
            throw res.err;
        }
    }

    async RecoverPassword(email: string) {

        const res = await this._authApi.RecoverPassword(email);

        if (res.isOk) {
            return ok({ message: 'Recovery email sent' });
        }
        else {
            throw res.err;

        }
    }


    async ResetPassword(token: string, password: string) {
        this._userApi = container.resolve(UserApi);
        const res = await this._userApi.ResetPassword(token, password);
        if (res.isOk) {
            return ok({ message: 'Password changed succesfully.' });
        }
        else {
            throw res.err;

        }
    }


    async ChangePassword(userId: integer, currentPassword: string, newPassword: string) {

        this._userApi = container.resolve(UserApi);
        const res = await this._userApi.ChangePassword(userId, currentPassword, newPassword);
        if (res.isOk) {
            return ok({ message: 'Password changed succesfully.' });
        }
        else {
            throw res.err;

        }
    }

    async ValidateToken<User>(userId: string, token: string) {
        
        const res = await this._authApi.ValidateToken(userId, token);
        if (res.isOk) {
            return res;
            //return ok({ message: 'Valid Token.' });
        }
        else {
            throw res.err;
            //alert(res.err.message);
        }
    }

    login(login: LoginToken, isKeepMeLoggedIn?: boolean) {

        this._user = login.userAccess;
        this._expiry = login.expiry;
        this._token = login.token;
        this._isLoggedIn = true;
        this.setSystemAccess();

        if (isKeepMeLoggedIn) {
            localStorage.setItem("login", JSON.stringify(login));            
        }
        else {
            sessionStorage.setItem("login", JSON.stringify(login));
        }
    }

    logout() {
        this._user = null;
        this._expiry = null;
        this._token = "";
        this._isLoggedIn = false;
        localStorage.removeItem("login");
        sessionStorage.removeItem("login");
    }

    private loadUser() {
        let loginJson = localStorage.getItem("login");
        if (!loginJson) {
            loginJson = sessionStorage.getItem("login");
            if (!loginJson) {
                this._isLoggedIn = false;
                return;
            }
        }

        const login = JSON.parse(loginJson) as LoginToken;
        this._expiry = login.expiry;
        this._token = login.token;
        this._isLoggedIn = !this.hasTokenExpired();
        if (this._isLoggedIn) {
            this._user = login.userAccess;
            this.setSystemAccess();
        }                
    }

    private setSystemAccess() {
        this._isSuperAdmin = this._user && this._user.accessLevel <= AccessLevel.SuperAdministrator;
        this._isAdmin = this._user && this._user.accessLevel <= AccessLevel.Administrator;
        this._isUser = this._user && this._user.accessLevel <= AccessLevel.User;
        this._isReadOnly = this._user && this._user.accessLevel <= AccessLevel.ReadOnly;
    }

    private hasTokenExpired() {
        return !this._expiry || this._expiry.valueOf() < new Date().valueOf();
    }
}

import backgroundMaze from './backgroundMaze.png'
import pipeArrow from './pipe-arrow.svg'

import {
    css, unsafeCSS
}

    from 'lit-element';

export default css`


:host([hidden]) {
    display: none;
}

:host {
    display: flex;
    flex-direction: column;
    height: 100%;
}

:host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
}
.body *{
        outline: 0px red dashed;
}
.right-body{
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.content .header {
    box-sizing: border-box;
    color: white;
    display: flex;
    font: var(--font-h3);
    width: 350px;
}

.h5 {
    font: var(--font-h5);
    font-weight: 700;
}
.h3 {
    font: var(--font-h3);
    white-space: nowrap;
}
.h2 {
    font: var(--font-h2);
    font-weight: 700;
    white-space: nowrap;
}
.h1 {
    font: var(--font-h1);
    font-weight: 700;
    white-space: nowrap;
}

.header-logo {
    width:200px;
}

.h4 {
    font: var(--font-h3);
    background-color: white;
    padding: 10px 10px 10px 10px;
}

.editor {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: fit-content;
    min-height: 0;
    padding-top: 0px;
    border-radius: 5px 5px;
}
.top-header{
    display: flex;
    flex: 1 1 0%;
    justify-content: space-between;
    flex-direction: row;
    align-self: flex-end;
    background: white;
    padding: 30px;
    margin: 50px 50px 0px;
    border-radius: 8px 8px 0px 0px;
}
.table-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-self: flex-end;
    padding: 25px 0px;
}
.left-header {
        display: flex;
        align-items: center;
        gap: 20px;
        flex: 0 1 0%;
}
.source-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.header-image-container{
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 5px;
    border: 2px solid var(--Gray-1, #F2F2F5);
    border-radius: 50%;
    overflow: hidden;
}
.header-image{
    position: relative;
    min-height: 1px;
}

.market-item-title{
    position: relative;
    min-height: 1px;
    gap: 5px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.market-item-label-square {
    position: relative;
    display: inline-block;
    z-index: 0;
    text-align: center;
    font: var(--font-small);
    width: fit-content;
    background: var(--color-blue-50);
    padding: 2px 10px;
    border-radius: 17px;
    border-width: 1px !important;
}
.right-header {
    align-self: flex-end;
    margin: 30px 50px 30px 0px;
}
.tabs-header{
    display:flex;
    gap: 10px;
    background-color: white;
    margin: 20px 50px;
    border-bottom: solid 1px var(--color-gray-1);
}
.active-tab{
    color: var(--color-purple);
}
.active-tab::before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 3px;
    border-radius: 9px;
    background-color: var(--color-purple);
}
.tabs{
    cursor:pointer;
    padding: 10px;
    position: relative;
}
.tabs:hover{
    background-color: rgb(49 54 93 / 9%);
    border-radius: 8px 8px 0 0;
}
.center-header{
    align-self: flex-end;
}
.scroll-container {
    min-height: 0;
    overflow: auto;
    background-color: white;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 40px 90px;
}
.border-bottom {
    border-bottom: #D0D3DC 1px solid;
}
.savePanel {
    display: flex;
    flex-direction: rows;
    justify-content: right;
}
.loginButton{
    margin-top: 10px;
}
.linkLabel {
    font: var(--font-small);
    text-decoration: none;
    align-items: center;
}

.primary-button {
    display: flex;
    flex-direction: rows;
    justify-content: space-evenly;
}

.footer {
    color: rgba(83, 86, 122, 1);
    font: var(--font-small);
    padding: 0px 20px 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer a {
    color: rgba(83, 86, 122, 1);
    text-decoration: none;
}

.footer a:hover {
    color: var(--color-secondary);
    text-decoration: none;
}
:host{
    background-image: ${unsafeCSS(`url(${backgroundMaze})`)};
    background-size: cover;
}
.source-item-label::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -10px;
    height: 30px;
    width: calc(100% + 20px);
    border-radius: 15px;
    z-index: -1;
}
.active-label::before {
    background-color: #32cd32;
    border: 1px solid #32cd3280;
}
.running-label::before {
    background-color: #0000ff;
    border: 1px solid #0000ff80;
}
.failed-label::before {
    background-color: #ff0000;
    border: 1px solid #ff000080;
}
.not-active-label::before {
    background-color: #808080;
    border: 1px solid #80808080;
}
.scroll-container-small{
    width:700px;
    height:400px;
    border-radius: 24px;
    display: flex;
}
.content{
    width:300px;
    gap: 10px;
    margin:auto;
    display: flex;
    flex-direction: column;
}

.link {
    color: var(--color-purple-dark);
    text-decoration: none;
}
.loginLink {
    display: flex;
    justify-content: center;
    padding: 10px;
}
.header {
    display: flex;
    justify-content: space-between;
    overflow:hidden;
}

.grid {
    flex:1;        
}   
.checkbox {
    vertical-align: middle;
}
input[type="checkbox"]:checked {
    background-color: var(--color-secondary);
}
.label {
    background-color: dimgray;
    border-radius: 3px 3px;
    font: var(--font-smaller);
}
.table-list{
    flex: 1;
}
.run-status{
    position: absolute;
    top: -3px;
    left: 50%;
    transform: translate(-50%, 0%);
    height: 30px;
    width: 109px;
    z-index: -1;
}

.header-logo {
    width: 165px;
    height: fit-content;
    margin: 27px;
}
.gradient{
    background: linear-gradient(90deg, rgba(82, 72, 249, 0.25) 9.91%, rgba(92, 83, 249, 0.10) 48.48%, rgba(47, 128, 237, 0.17) 75.23%);
}
.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.labeled-input{
    display: flex;
    gap: 5px;
    flex-direction: column;
}
se-pipe-setup-wizard{
    overflow-y: scroll;
}
.pipe-item-status{
    border-radius: 4px;
    flex: 1;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 120px;
    color: white;
    text-wrap: nowrap;
    padding: 0px 8px;
}
.container-header{
    border: none;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    background-color: white;
}
.container-content{
    padding: 0px 50px;
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
}
.table-pagination{
    width: 100%;
    margin: 20px 0px;
    overflow:hidden;
    align-self: flex-end;
}
.pipe-arrow{
    position: relative;
    min-width: 20px;
    height: 20px;
    background-color: var(--color-gray-1);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='19' viewBox='0 0 11 19' fill='none'%3E%3Cpath d='M2 17L9 9.5L2 2' stroke='%23262E71' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30%;
    border-radius: 50%;
}
.schedule-switch{
        display: flex;
        flex-direction: row;
        gap: 10px;
}
.time-editor{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.time-input{
    display: flex;
    height: 100%;
    box-sizing: border-box;
}
.distribution{
    display: flex;
    min-width: 100px;
    height: 16px;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--color-blue-50, #CEE1F4);
    overflow: hidden;
    flex-shrink: 1;
}
.distribution span{
    border-radius: 8px;
    background-color: var(--color-status-blue);
}
#deleteConfirmPopup {
    position: absolute;
    border: 1px solid #ddd;
    margin-top: 10px;
    padding: 10px;
    background-color: white;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    border-radius: 8px;
}
.confirm-popup {
    position: absolute;
    border: 1px solid #ddd;
    margin-top: 10px;
    padding: 10px;
    background-color: white;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
    z-index: 100;
    display: flex;
    flex-direction: col umn;
    align-items: center;
    justify-content: center;
    width: auto;
    border-radius: 8px;
}
.button-container {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
}
.inputEditor{
    width: 200px;
}
td:has(> se-toggle) {
    width: 100px;
}
td {
    text-wrap: nowrap;
}
.modal {
    display: flex; /* Hidden by default */
    position: absolute; /* Stay in place */
    z-index: 10; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100vh; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black with opacity */
}
.modal-content {
    display: inline-block;
    background-color: #fefefe;
    margin: auto;
    border: 1px solid #888;
    width: auto;
    height: min-content;
    border-radius: 8px;
    padding: 10px;
}
.card{
        max-width: 800px;
        min-width: 560px;
    }
    .container{
        border: none;
        height: 100%;
        background: transparent;
        display: flex;
        flex-direction: column;
        margin: 0px 50px 50px;
        border-radius: 0px 0px 16px 16px;
        overflow: hidden;
        flex: 1;
    }
    .wizard-header{
        border: none;
        height: fit-content;
        display: flex;
        justify-content: center;
    }
    .container-content{
        background-color: white;
        padding: 20px 50px;
        height: 100%;
        display: table-cell;
        vertical-align: top;
    }
    .center-container{
        align-self: flex-end;
    }
    .body-container{
        display: flex;
        overflow: hidden;
        justify-content: center;
    }
    se-loading {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            flex-grow: 1;
            background-color: var(--color-gray-1);
        }

`;

import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { DestinationService } from '../../services/destination.service';
import { container } from 'tsyringe';
import { PipeService } from '../../services/pipe.service';
import { ToasterService } from '../../services/toaster.service';
import Styles from '../../../../assets/styles';

@customElement('se-details-destination-config-tab')
export class DetailsDestinationConfigButton extends LitElement {
    private _pipeService: PipeService;
    private _destinationService: DestinationService;
    private _destinationId?: number;
    private _toasterService: ToasterService;
    private _selectedDestination?: string;
    private _selectedDestinationId?: number;
    private rendered: boolean = false;

    @property() destinationId: string;

    private executedScripts = new Set();
    private _selectedDestinationName: any;
    private destinationsConfigs: any = [];
    private destinationsConfig: any = {}
    private fieldValues: any = {};

    @state() private _availableDestinations: any[] = [];
    @state() private _currentDestination: any;

    constructor() {
        super();
        this._destinationService = container.resolve(DestinationService);
        this._pipeService = container.resolve(PipeService);
        this._toasterService = container.resolve(ToasterService);

    }
    connectedCallback() {
        super.connectedCallback();
        this.loadData();
    }

    private async loadData(agentTemplateId: number = -1) {

        this._destinationId = Number(this.destinationId)

        // get summary of destinations for the dropdown
        this.loadDestination()

    }

    private async loadDestination() {

        const result = await this._destinationService.api.getDestinationAsync(this._destinationId);
        if (result.isOk) {
            this._selectedDestination = this._destinationId.toString();
            this._currentDestination = result?.value
            this.fieldValues = JSON.parse(this._currentDestination.configJson)
            this.loadDestinationsConfigs();
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }

        //    if (result.isOk) {
        //        this._data = result.value.destinations;
        //        this._totalRecordCount = result.value.totalRecordCount;
        //    }
        //    else {
        //        this._toasterService.showUnexpectedError(result.err.message);
        //    }
    }

    private renderRequiredFields(config, fieldValues) {
        if (this.rendered) {
            return;
        }
        if (config.parameters == undefined) {
            setTimeout(() => {
                return this.renderRequiredFields(config, fieldValues)
            }, 500);
        } else {
            this.rendered = true
            return html`
            ${config.parameters.map(param =>
                    param.required && this.shouldDisplayField(param, fieldValues) ? html`
                    <div class="labeled-input" style=${param.visible === false ? 'display: none;' : ''}>
                        <label class="h3" id="${param.id}">${param.name}</label>
                        ${this.renderField(param, fieldValues)}
                    </div>
                ` : ''
                )}
                `;
        }
    }

    private renderField(param, fieldValues) {

        if (param.script && !this.executedScripts.has(param.id)) {
            setTimeout(() => {
                try {
                    const fn = new Function('app', param.script);
                    fn(this);
                    this.executedScripts.add(param.id);
                } catch (error) {
                    console.error('Error executing script from JSON:', error);
                }
            });
        }
        if (param.type === 'input') {
            return html`<se-input-editor class="inputEditor destinationFields" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} 
                          @input=${(e) => this.handleUpdate(param.id, e.currentTarget.liveValue)}
                          .value=${fieldValues[param.id] || ''}></se-input-editor>`;
        }
        if (param.type === 'password') {
            return html`<se-input-editor type="password" input-type="password" class="inputEditor destinationFields" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} 
                             .customEditor=${{ border: 'none', background: 'var(--color-gray-1)' }} 
                          @input=${(e) => this.handleUpdate(param.id, e.currentTarget.liveValue)}
                          .value=${fieldValues[param.id] || ''}></se-input-editor>`;
        }

        if (param.type === 'select' && param.values) {
            const options = param.values.map(value => ({ id: value, name: value }));
            return html`<se-select-editor class="inputEditor destinationFields" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} 
                          .options=${options} 
                          @input=${(e) => this.handleUpdate(param.id, e.currentTarget.liveValue)}
                          .value=${fieldValues[param.id] || ''}></se-select-editor>`;
        }
        if (param.component === 'se-google-oauth-button') {
            return html`<se-google-oauth-button></se-google-oauth-button>`;
        }
        //if (param.type === 'custom') {
        //    const DynamicComponent = param.component;
        //    return html`<${DynamicComponent}></${DynamicComponent}>`;
        //}

        return '';
    }

    private shouldDisplayField(param, fieldValues) {
        if (param.displayIf) {
            const dependentFieldValue = fieldValues[param.displayIf.field];
            return Boolean(dependentFieldValue) === param.displayIf.hasValue;
        }
        return true;
    }

    private handleUpdate(id, value) {
        this.fieldValues = { ...this.fieldValues, [id]: value };
    }

    private async loadDestinationsConfigs() {

        const result = await this._destinationService.api.getDestinationsConfigsAsync();
        if (result.isOk) {
            this.destinationsConfigs = JSON.parse(result?.value || "[]");

            this._availableDestinations = this.destinationsConfigs.map(config => { return { "id": config.name, "icon": config.icon } })

            var dest = this._currentDestination;

            this.destinationsConfig = this.destinationsConfigs.filter(e => e.name === dest.destinationType)[0];

            this._selectedDestinationId = this._destinationId;
            this._selectedDestinationName = dest.destinationName;

        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    private isEmpty = (value) => {
        return (value == null || (typeof value === "string" && value.trim().length === 0));
    }

    async saveDestination() {

        //validate the destination name field.
        if (this.isEmpty(this._selectedDestinationName)) {
            this._toasterService.showError("Destination name is required.");
            return;
        }

        this._currentDestination.destinationName = this._selectedDestinationName
        this._currentDestination.configJson = JSON.stringify(this.fieldValues);
        const result = await this._destinationService.api.updateAsync(this._destinationId, this._currentDestination);
        if (result.isOk) {
            this._toasterService.showSuccess("Saved!");
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }

        return ""
    }

    render() {
        
        return html`
                    <label class="h2" id="resetPassword">Set up the destination</label>
                    <div class="form">
                        <div class="labeled-input" style="padding-top: 20px">
                            <label class="h3" id="resetPassword">Destination Name</label>
                            <se-input-editor class="inputEditor destinationFields" @input=${(e) => this._selectedDestinationName = e.currentTarget.liveValue} value=${this._selectedDestinationName} .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} id="destinationName" name="destinationName" type="text" labelPosition="top" input-type="search" required ></se-input-editor>
                        </div>
                        ${this.renderRequiredFields(this.destinationsConfig, this.fieldValues)}
                    </div>
                    <div class="action-buttons" style="width: 200px; padding-top: 20px">
                        <div style=""><se-primary-button .customStyle=${{ 'padding': '10px 40px' }} @click="${this.saveDestination}"  text="Save"></se-primary-button></div>
                    </div>

                        `;
    }
    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        background:none;
        color:#1A1F4B
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .body {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
    }
    .time-section{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .action-button{
        margin-bottom: -75px;
        display: flex;
        justify-content: space-between;
    }
    .config-tab{
        max-width: 700px;
        width: 100%;
    }
    .action-button .inputEditor{
         width:100px;
    }
    .inputEditor{
        width: 400px;
    }
    .grid {
        flex:1;        
    }   
    .checkbox {
        width: 1rem;
        height: 1rem;
    }
    input[type="checkbox"]:checked {
        background-color: var(--color-secondary);
    }
    .left-header{
        flex: 1 1 0%;
        max-width: 600px;
        padding-right: 50px;
    }

      `]
    };

}


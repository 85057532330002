import { PreventAndRedirectCommands, PreventResult, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

import './login.element';
import './forgot-password.element';
import './reset-password.element';
import './change-password.element';
import './user-registration.element';
import { container } from 'tsyringe';
import Styles from '../../../assets/styles';
import { AuthService } from '../services/auth.service';
import './components/primary-button.element';
import './components/secondary-button.element';
import { ToasterService } from '../services/toaster.service';
import { UsageService } from '../services/usage.service';
import { SeDataGrid } from './components/data-grid.element';
import './components/data-grid.element';

import { DataGridColumn } from './components/data-grid-template';
import { MenuItem } from './components/context-menu.element';
import search from '../../../assets/search.svg'
import { SelectEditorElement } from './editors/select-editor.element';
import { format, sub, startOfDay, endOfDay, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns';
import { BillingService } from '../services/billing.service';
import { InputEditorElement } from './editors/input-editor.element';
import { SePaginationElement } from './components/pagination.element';

@customElement('se-usage')
export class UsagesPageElement extends LitElement {
    private _usageService: UsageService;
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _billingService: BillingService;
    private _pageIndex: number = 1;
    private _recordsPerPage: number = 10;
    private _totalRecordCount: number;
    private _sortColumn: string = 'ConfigName';
    private _sortOrder: number = -1;
    private _columns: DataGridColumn[] = [];
    private _usageName: string = "null";
    private __today?= new Date();

    // UI constants
    private RUN_USAGE = 'Server Time';
    private DATA_USAGE = 'Data Transfer';
    private INPUT_COUNT = 'Agent Inputs';

    @state() private _data: any[] = [];
    @state() private _hasSelectedRows = false;
    @state() private _usageDateRangeValue = "1";
    @state() private _usageTimeUnit = "day";
    @state() private _showModal = false;
    @state() private _isCustomerExist = false;
    @state() private _orgBilling: any;

    @query('se-data-grid') private _dataGrid: SeDataGrid;
    @query('#chartCanvas') private _chartCanvas: HTMLCanvasElement;
    @query('#usageDateRange') private _usageDateRange: SelectEditorElement;

    @property({ type: Date }) startDate = startOfMonth(new Date());
    @property({ type: Date }) endDate = endOfDay(new Date());
    @property({ type: Array }) datasets: any = [];
    @property({ type: Array }) labels: string[] = [];
    @property({ type: Number }) totalCost: number = 0.0;
    @property({ type: Number }) availableCredits: number = 0.0;

    private chart?: Chart;
    private boundHandlePopState;

    @query('se-pagination') private _pagination: SePaginationElement;
    @query('#search') private searchInput: InputEditorElement;
    private _savedPageIndex: number = 1;
    private _agentName: string = "";
    private _isProgrammaticallySet: boolean = true;
    private lastSearchedValue: string = "";
    private searchQueue = [];
    private processingSearch = false;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._usageService = container.resolve(UsageService);
        this._toasterService = container.resolve(ToasterService);
        this._billingService = container.resolve(BillingService);
        this.boundHandlePopState = this.handlePopState.bind(this);
        this.getOrCreateCustomer();
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('popstate', this.boundHandlePopState);
        this.getOrCreateCustomer();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('popstate', this.boundHandlePopState);
    }
    private handlePopState(event) {
        const customEvent = new CustomEvent('customPageChange', {
            detail: {
                pageIndex: (event.state?.gridPage || 1),
                searchTerm: encodeURIComponent(this.lastSearchedValue)
            }
        });
        this.onPageChanged(customEvent);
    }
    private setCookie(name, value, minutes) {
        const now = new Date();
        now.setTime(now.getTime() + (minutes * 60 * 1000));
        const expires = "expires=" + now.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }
    private getCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    async firstUpdated() {
        this._usageDateRangeValue = this.getCookie("usageDateRangeValue") || "1d";
        this.lastSearchedValue = this.getCookie("lastSearchedValue") || "";

        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);
        var pulledPage = Number(searchParams.get("gridPage") || this._pageIndex)
        if (this.lastSearchedValue != "") {
            this._agentName = this.lastSearchedValue
            this._pagination.changePage(pulledPage || 1);
        }

        const customEvent = new CustomEvent('customPageChange', {
            detail: { pageIndex: pulledPage }
        });
        //false to just adjust the chart
        await this.updateDateRange(false);
        this.onPageChanged(customEvent);
        this.loadChart();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        this.pageChange(location)
        if (!this._authService.isAdmin) {
            return commands.redirect('/login');
        }

        if (location.params.checkoutStatus) {
            const status = location.params.checkoutStatus.valueOf() as string
            if (status === 'success') {
                console.log("Checkout Payment successful..");
                this._toasterService.showSuccess("Payment successfull.")
            }

            if (status === 'cancel') {
                console.log("Checkout Payment cancelled..");
                this._toasterService.showWarning("Payment cancelled.")
            }
            history.replaceState(null, null, '/usage');
        }
    }

    pageChange(location: RouterLocation) {
        const message = {
            type: 'pageChange',
            payload: { location: location.route },
        };
        window.postMessage(message, '*');
    }

    private async loadData(nameValue: string = this.lastSearchedValue) {
        var creditRequest = await this._billingService.api.getAvailableCreditsAsync(this._authService.user.organizationId);
        if (creditRequest.isOk) {
            this.availableCredits = Number(creditRequest.value);
        }
        else {
            this._toasterService.showUnexpectedError(creditRequest.err.message);
        }

        var orgId = this._authService.user.organizationId;
        this.lastSearchedValue = nameValue;
        this.setCookie("lastSearchedValue", this.lastSearchedValue, 15)

        var usageRequest = {
            name: this.lastSearchedValue,
            orgId,
            timeUnit: this._usageTimeUnit,
            startDate: format(this.startDate, 'yyyy-MM-dd'),
            endDate: format(this.endDate, 'yyyy-MM-dd'),
            pageIndex: this._pageIndex,
            recordsPerPage: this._recordsPerPage,
            sortColumn: this._sortColumn,
            sortOrder: this._sortOrder
        };

        const result = await this._usageService.api.getOrgPipesUsageAsync(usageRequest);
        if (result.isOk) {
            this._data = result.value.pipesUsage;
            this._totalRecordCount = result.value.totalRecordCount;

            this.calculateDistribution();
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    /**
     * Loop over all of the data records and calculate the distribution of each individual Pipe as a percentage of the overall total price
     */
    private calculateDistribution() {

        if (this._data == null || this.totalCost == null) {
            return;
        }

        this._data.forEach((item) => {
            // To calculate the distribution of each individual Pipe as a percentage of the overall total price
            item.distribution = (item.cost / this.totalCost) * 100

            //item.cost = '$' + item.cost.toFixed(6); // Assuming you want to format the cost with two decimal places
        });

        this.requestUpdate();
    }

    async loadRunUsageData() {
        try {
            var orgId = this._authService.user.organizationId;
            const response = await this._usageService.api.getOrgRunsDataUsageAsync(orgId, this._usageTimeUnit, format(this.startDate, 'yyyy-MM-dd'), format(this.endDate, 'yyyy-MM-dd'));

            if (response.isOk) {
                var data = await response.value;
                if (data.labels !== null && data.labels.length > 0) {
                    this.labels = data.labels;
                    this.datasets = data.dataSets;
                    this.totalCost = data.totalCost;
                } else {
                    this.labels = [];
                    this.datasets = data.dataSets;
                    this.totalCost = 0;
                }

                //force the grid to render again
                this._columns = null;
                this.calculateDistribution();

            } else {
                this._toasterService.showUnexpectedError(`Usage API request failed with status: ${response.value}`);
            }

        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    private async loadChart() {
        if (this._chartCanvas.getAttribute("prepared") == "true") {
            return;
        }
        this._chartCanvas.setAttribute("prepared", "true")
        var ctx = this._chartCanvas.getContext('2d');

        // Chart data
        var data = {
            labels: this.labels,
            datasets: this.getDataSets(),
        };

        // Chart options
        var options = {
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                x: {
                    stacked: true
                },
                y: {
                    stacked: true,
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Cost',
                        color: '#1a1f4b', //#4e4ef0
                        font: {
                            family: 'Helvetica',
                            size: 16,
                            weight: 'bold',
                        },
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const datasetLabel = context.dataset.label;// Get the label name
                            const value = context.parsed.y.toFixed(2); // Format the data to 6 decimal places
                            return `${datasetLabel}: $${value}`; // Combine label name and "$" cost value in the tooltip label
                        },
                    },
                },
            },
        };

        // Create Chart.js instance
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: options,
        });
    }
    private async filterByAgentName() {
        const newValue = this.searchInput.liveValue;
        const trimmedNewValue = newValue.trim();
        if ((this.lastSearchedValue === '' || (this.lastSearchedValue === 'null' && this._isProgrammaticallySet)) && trimmedNewValue !== '') {
            this._savedPageIndex = this._pageIndex;
        }
        this._isProgrammaticallySet = false;

        if (this._agentName.trim() === '' && trimmedNewValue === '') {
            this.searchQueue = [];
            return;
        }
        this._agentName = newValue;
        if (trimmedNewValue === '') {
            this._pageIndex = this._savedPageIndex || 1;
            this._dataGrid.pageIndex = this._savedPageIndex || 1;
            this._pagination.changePage(this._savedPageIndex || 1);
        } else {
            this._pageIndex = 1;
            this._dataGrid.pageIndex = this._pageIndex;
        }

        if (trimmedNewValue === '') {
            this._agentName = '';
            this._isProgrammaticallySet = true;
        }
        if (this.lastSearchedValue === this._agentName) {
            return;
        }
        if (this.processingSearch == false) {
            this.searchQueue.push(this._agentName)
            this.processingSearch = true
            await this.loadData(this.searchQueue[0]);
            this.processingSearch = false
            this.searchQueue.shift()
        } else {
            if (this.searchQueue.length !== 1) {
                this.searchQueue.pop()
            }
            var repeating = setInterval(() => {
                if (this.processingSearch == false) {
                    clearInterval(repeating)
                    this.filterByAgentName()
                }
            }, 100)
        }

    }


    updated(changedProperties: Map<string | number | symbol, unknown>): void {
        super.updated(changedProperties);
        if (changedProperties.has('labels')) {
            this.updateChart();
        }
    }

    private updateChart(): void {
        if (this.chart) {
            this.chart.data.labels = this.labels;
            this.chart.data.datasets = this.getDataSets();
            this.chart.update();
        }
    }

    private getDataSets() {
        var datasets = [];

        this.datasets.forEach((item) => {
            if (item.label == "RunUsage") {
                var dataset = {
                    label: this.RUN_USAGE,
                    data: item.data,
                    backgroundColor: ['#3F8CD9'],
                    borderColor: ['#3F8CD9'],
                    borderWidth: 1,
                };
                datasets.push(dataset);
            }

            if (item.label == "DataUsage") {
                var dataset = {
                    label: this.DATA_USAGE,
                    data: item.data,
                    backgroundColor: ['#93bfea'],
                    borderColor: ['#93bfea'],
                    borderWidth: 1,
                };
                datasets.push(dataset);
            }

            if (item.label == "InputCount") {
                var dataset = {
                    label: this.INPUT_COUNT,
                    data: item.data,
                    backgroundColor: ['#4E4EF0'],
                    borderColor: ['#4E4EF0'],
                    borderWidth: 1,
                };
                datasets.push(dataset);
            }

        });

        return datasets;
    }

    private async updateDateRange(loadData = true) {

        //we may have to change the end date logic for Months filter once we have proper data.
        const today = new Date();
        switch (this._usageDateRangeValue) {
            case '1d':
                this.startDate = startOfMonth(today);
                this.endDate = endOfDay(today);
                this._usageTimeUnit = 'day';
                break;
            case '1m':
                this.startDate = startOfMonth(sub(today, { months: 1 }));
                this.endDate = endOfMonth(sub(today, { months: 1 }));
                this._usageTimeUnit = 'day';
                break;
            case '3m':
                this.startDate = startOfMonth(sub(today, { months: 3 }));
                this.endDate = endOfDay(today); //endOfMonth(sub(today, { months: 1 }));
                this._usageTimeUnit = 'day';
                break;
            case '6m':
                this.startDate = startOfMonth(sub(today, { months: 6 }));
                this.endDate = endOfDay(today);  //endOfMonth(sub(today, { months: 1 }));
                this._usageTimeUnit = 'day';
                break;
            case '1y':
                this.startDate = startOfYear(sub(today, { years: 1 }));
                this.endDate = endOfDay(today);
                this._usageTimeUnit = 'year';
                break;
            default:
                // Default to the current date range
                this.startDate = startOfDay(today);
                this.endDate = endOfDay(today);
                break;
        }

        this._totalRecordCount = 0;
        this._data = [];
        this.totalCost = 0.0;
        this.loadRunUsageData();
        if (loadData) {
            await this.loadData();
        }
    }

    private handleDateChange() {
        this._usageDateRangeValue = this._usageDateRange.liveValue;
        this.setCookie("usageDateRangeValue", this._usageDateRange.liveValue, 15)
        this.updateDateRange();
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
    }

    private async viewUsageDetails(row: any, col: DataGridColumn) {
        Router.go('/pipe-usage/' + row.configId);
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder;

        this._sortColumn = sortColumn;
        this._sortOrder = sortOrder;

        this.loadData();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;

        this.loadData();
    }

    private filterByLabel(event: CustomEvent) {
        event.stopPropagation();
    }
    private filterByAgentTemplate(event: CustomEvent) {
        event.stopPropagation();
        this.loadData();
    }

    private getDistribution(value: number): TemplateResult {
        return html`
        <span style="display: flex; justify-content: flex-start;">
            <div class="distribution">
                <span style="height: 100%; width: ${value}%"></span>
            </div>
        </span>`
    }

    private async filterList() {
    }

    async getOrCreateCustomer() {

        const orgId = this._authService.user.organizationId;
        const result = await this._billingService.api.getOrCreateCustomer();
        if (result.isOk) {
            this._orgBilling = result.value;
            this._isCustomerExist = true;
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    private async handleCheckout() {
        try {
            const response = await this._billingService.api.getCheckoutSession({ orgId: this._orgBilling.orgId, customerId: this._orgBilling.customerId });
            if (response.isOk) {
                window.location.href = response.value.checkoutUrl; // Redirect to the session URL
            } else {
                this._toasterService.showUnexpectedError(response.err.message);
            }
        } catch (error) {
            this._toasterService.showUnexpectedError(error.message);
        }
        this.requestUpdate();
    }

    private async handleCreditHistory() {
        Router.go(`/credit-history`);
    }

    private createUsage(row: any, col: DataGridColumn) {
        Router.go(`/wizard?step=1`);
    }

    /**
     * Get the columns for the data grid
     * @returns
     */
    private getColumns() {
        if (this._columns == null || this._columns.length == 0) {

            //expand decimals places if ALL of the numbers are very small
            const decimalPlaces = this.totalCost >= 0.01 ? 2 : 3;

            this._columns = [
                { field: 'name', title: 'Agent Name', sortable: true, cellStyle: { textDecoration: 'underline' }, action: this.viewUsageDetails.bind(this) },
                { field: 'source', title: 'Source' },
                //{ field: 'destination', title: 'Destination' },
                { field: 'cost', title: 'Cost', sortable: true, align: 'right', template: (row, col) => html`<span class="numbers" style="font-feature-settings: var(--font-feature-numbers);">${'$' + row.cost.toFixed(decimalPlaces)}</span>` },
                {
                    field: 'distribution', title: 'Distribution', align: 'center', template: (row, col) => html`${this.getDistribution(row.distribution)}`
                },
            ]
        }

        return this._columns;
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">
                <div class="container-header gradient" >
                    <div class="left-header top-header">
                        <div class="h1">Usage</div>
                    </div>
                    <div class="right-header">
                        <div  style="display: flex; justify-content: center;">
                            <se-secondary-button @click=${this.handleCreditHistory} class="inputEditor" style="margin-right: 20px;" text="Credit History"></se-secondary-button>
                            <se-primary-button @click=${this.handleCheckout} class="inputEditor" text="Buy Credits"></se-primary-button>

                            
                        </div>
                    </div>
                </div>
                <div class="container-content">
                    <div class="table-header">
                        <div class="left-header">
                            <se-select-editor class="inputEditor" id="usageDateRange" .value="${this._usageDateRangeValue}" width="100%" name="usageDateRange" label="" labelPosition="top"
                                    @valueChanged=${this.handleDateChange}
                                    .options=${[
                { id: '1d', name: "This Month" },
                { id: '1m', name: "Last Month" },
                { id: '3m', name: "3 Months" },
                { id: '6m', name: "6 Months" },
                { id: '1y', name: "1 Year" }
            ]}>
                            </se-select-editor>
                            <!-- <se-select-editor class="inputEditor" id="destinationType" .placeholder="${"All Sources"}" .value="${"-1"}" width="100%" name="destinationType" label="" labelPosition="top" @valueChanged=${this.filterList}></se-select-editor>
                            <se-select-editor class="inputEditor" id="destinationType" .placeholder="${"All Destinations"}" .value="${"-1"}" width="100%" name="destinationType" label="" labelPosition="top" @valueChanged=${this.filterList}></se-select-editor> -->
                        </div>
                        <div class="right-header">
                            <div>Credits Available: <span class="numbers">$${this.availableCredits.toFixed(2)}</span></div>
                            <div>Credits Used: <span class="numbers">$${this.totalCost.toFixed(2)}</span></div>
                        </div>
                    </div>
                    <div class="usage-list">
                        <div style="position: relative; height:30vh; flex:1;">
                            <canvas id="chartCanvas"></canvas>
                        </div>
                        <div class="labelsFilters">
                            <!-- <div class="market-item-label marketing-label"><span>Proxy Usage</span></div>-->
                            <div class="market-item-div"><div class="usage-color" style="background-color: var(--color-purple)"></div><span>Agent Inputs</span></div> 
                            <div class="market-item-div"><div class="usage-color" style="background-color: var(--color-blue-50)"></div><span>Data Delivery</span></div>
                            <div class="market-item-div"><div class="usage-color" style="background-color: var(--color-status-blue)"></div><span>Server Time</span></div> 
                        </div>
                    </div>
                </div>
                <div class="container-content">
                        <div class="table-header">
                            <div class="left-header">
                                <span class="h1">Usage by Agents</span>
                           </div>
                           <se-input-editor class="inputEditor" .icon=${search} .customStyle=${{ alignItems: 'unset' }} .value=${this._agentName === "null" && this._isProgrammaticallySet ? "" : this._agentName} @editorChanged=${this.filterByAgentName} id="search" name="search" type="text" placeholder="Search" labelPosition="top" input-type="search" required ></se-input-editor>
                        </div>
                        <div class="table-list">
                            <se-data-grid .selectAllCheckbox=${true} class="grid" .rows=${this._data} .recordsPerPage=${this._recordsPerPage} .pageIndex=${this._pageIndex} .columns=${this.getColumns()} selectable @selectionchanged=${this.onGridSelectionChanged} @sortdata=${this.sortDataGrid} @filterbylabel=${this.filterByLabel} @filterbyAgentTemplate=${this.filterByAgentTemplate}></se-data-grid>
                        </div>
                        <div class="table-pagination" >
                            <se-pagination .dataType=${"Agents"} .recordsPerPage=${this._recordsPerPage} .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    .body{
        background-color: white;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .container-header{
        justify-content: space-between;
    }
    .usage-item-label::before {
        content: "";
        position: absolute;
        top: -5px;
        left: -10px;
        height: 30px;
        width: calc(100% + 20px);
        border-radius: 15px;
        z-index: -1;
    }
    .transparent-bg{
        background-color: #ffffff00;
    }
    .labelsFilters{
        display: flex;
        gap: 8px;
        flex-direction: column;
        place-content: center flex-start;
        align-items: center;
        height: 100%;
    }
    .market-item-div {
        padding: 15px;
        width: 200px;
        text-align: center;
        border-radius: 9px;
        border: 1px solid var(--color-gray-2, #D0D3DC);
        display: flex;
        gap: 15px;
    }
    .usage-color{
        border-radius: 50%;
        height: 15px;
        width: 15px;
        align-self: center;
    }
    .usage-list{
        display:flex;
        gap: 20px;
    }
    .right-header{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .right-header .numbers
    {
        font-feature-settings: var(--font-feature-numbers);
        font-weight: bold;
    }
  `]
    };
}
import { Router } from '@vaadin/router';
import { css, html, LitElement } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { container } from 'tsyringe';
import avatar from '../../../assets/avatar.svg';
import logo from '../../../assets/logo.png';
import organization from '../../../assets/organization.svg';
import sources from '../../../assets/sources.svg';
import Styles from '../../../assets/styles';
import agents from '../../../assets/agents.svg';
import usage from '../../../assets/usage.svg';
import contact from '../../../assets/contact.svg';
import { AuthService } from '../services/auth.service';
import { ToasterService } from '../services/toaster.service';
import { UserApi } from '../services/user-api';
import './components/fa-icon.element';
import { SelectEditorElement } from './editors/select-editor.element';

@customElement('se-left-menu')
export class SeLeftMenuElement extends LitElement {

    private _authService: AuthService;
    private _userApi: UserApi;
    private _toasterService: ToasterService;
    private _router: Router;
    private _isAdmin: boolean;

    @state() private _activeMenu: string = '';


    @state() private _draftCount = 0;
    @state() private _selectedLabel: string;
    @state() private _userName: string;

    @query('#space') private _spaceEditor: SelectEditorElement;
    @query('#label') private _labelEditor: SelectEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userApi = container.resolve(UserApi);
        this._userApi = container.resolve(UserApi);

        if (this._authService.isLoggedIn) {
            this._userName = this._authService.user.name;
            this._isAdmin = this._authService.isAdmin;
        }
    }


    loadSpaces(event) {
        if (event.data && event.data.type === 'pageChange') {
            const payload = event.data.payload;
            var link = payload.location.path.substring(1)
            this._activeMenu = link.split("/")[0];
            this.requestUpdate(); // Request a re-render to update the highlighted menu item
        }
    }

    loadLabels() {

    }


    async connectedCallback() {
        super.connectedCallback();
        window.addEventListener('message', this.loadSpaces.bind(this));
    }

    disconnectedCallback() {

        super.disconnectedCallback();
    }



    firstUpdated() {

    }
    updated() {

    }

    private goAgents() {
        this._activeMenu = "agents";
        Router.go("/agents");
    }
    private goUsage() {
        this._activeMenu = "usage";
        Router.go("/usage");
    }
    private goContactUs() {
        this._activeMenu = "contact-us";
        Router.go("/contact-us");
    }
    private goUsers() {
        this._activeMenu = "users";
        Router.go("/users");
    }
    private goWizard() {
        Router.go("/wizard");
    }
    private goDestinations() {
        this._activeMenu = "destinations";
        Router.go("/destinations");
    }
    private goSources() {
        this._activeMenu = "sources";
        Router.go("/sources");
    }
    private goProfile() {
        this._activeMenu = "profile";
        Router.go("/profile");
    }
    private goToBilling() {
        Router.go("/billing/overview");
    }

    render() {

        return html`        
        <div class="body">
            <div class="header">
                <img src=${logo} class="header-logo" />                
            </div>
            <div class="labels">

                <div class="buckets">
                    <div class="menu-item h5 ${classMap({ menuItemSelected: (this._activeMenu == "agents") })}">
                        <img class="iconImage" src="${agents}"/>
                        <a class="menu-text" @click="${this.goAgents}" href="/agents" >Agents</a>
                    </div>
                    <div class="menu-item h5 ${classMap({ menuItemSelected: (this._activeMenu == "sources") })}">
                        <img class="iconImage" src="${sources}"/>
                        <a class="menu-text" @click="${this.goSources}" href="/sources" >Sources</a>
                    </div>
                </div>

                <div class="bottom-section">
                <div class="buckets">
                    ${this._isAdmin ? html`
                        <div class="menu-item h5 ${classMap({ menuItemSelected: (this._activeMenu == "users") })}">
                            <img class="iconImage" src="${organization}"/>
                            <a class="menu-text" @click="${this.goUsers}" href="/users" >Organization</a>
                        </div>
                        <div class="menu-item h5 ${classMap({ menuItemSelected: (this._activeMenu == "usage") })}">
                            <img class="iconImage" src="${usage}"/>
                            <a class="menu-text" @click="${this.goUsage}" href="/usage" >Usage</a>
                        </div>
                    ` : html``}
                    <div class="menu-item h5 ${classMap({ menuItemSelected: (this._activeMenu == "contact-us") })}">
                        <img class="iconImage" src="${contact}"/>
                        <a class="menu-text" @click="${this.goContactUs}" href="/contact-us" >Contact Us</a>
                    </div>
                </div>
                <div class="profile-section">
                    <div class="menu-item h5 ${classMap({ menuItemSelected: (this._activeMenu == "profile") })}">
                        <img class="iconImage" src="${avatar}"/>
                        <a class="menu-text" @click="${this.goProfile}" href="/profile" >${this._userName}</a>
                    </div>
                </div>
                </div>

            </div>
        </div>

        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;
        color: white;
        display: flex;
        flex-direction: column;
        font: var(--font);     
        width: 300px;
        background:none;

    }
    .image-container{
        width: 30px;
        height: 30px;
        background-position: fit;
        background-size: cover;
    }
    .iconImage{
        width: 24px;
    }


    .body {
        display: flex;
        flex-direction: column;
        flex: 1;
        scrollbar-width: thin;
        scrollbar-color: var(--color-primary-tint) var(--color-primary);
        scrollbar-gutter: unset;
    }
    .editor {
        font: var(--font);
        margin-top: 10px;
        margin-right: 10px;
        flex:1;        
    }
    .operations {
        display: flex;
        gap: 5px;
        font: var(--font-small);
        color: white;
    }
    .select-panel {
        display: flex;        
        color: white;
        align-items: baseline;
        justify-content: space-between;
        padding-left: 15px;        
    }
    a {
        color: white;        
    }    
    .buckets {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 5px;
    }
    .types {
        display: flex;
        flex-direction: column;        
    }
    .menu-item {
        display: flex;        
        justify-content: space-between;
        margin: 0px 0px 1px 0px;
        padding: 5px 12px;
        margin: 0px 15px;
    }
    .menu-item:hover {
        background-color: #31365D;
        border-radius: 8px;
    }
    .menuItemSelected {
        font-weight: 900;
        background-color: #31365D;
        border-radius: 8px;
    }
    .menu-item:hover .menu {        
        visibility: visible;
    }
    .menu:hover {
        color:white;
    }
    .count {        
        padding: 4px 4px 4px 7px;
    }
    .menu {
        color: silver;        
        padding: 4px 5px 4px 0px;        
    }
    .menu-item .menu {
        visibility: hidden;
    }
    .menu-item a {
        text-decoration: none;
    }
    .menu-text {
        flex:1;        
        display: block;
        padding: 10px;
        font-weight: 700;
        font-style: bold;
        line-height: 120%;
    }
    .labels {        
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }
    .profile-section{
        flex-direction: row;    
        align-items: center;
        gap: 5px;
        justify-content: space-between;
        border-top: 1px solid rgba(148, 151, 157, 0.3);
        padding: 10px 0px;
    }
    .add-menu-item {
        display: flex;        
        font: var(--font-small);
        margin-top: 3px;
        padding-left: 15px;
        gap: 3px;

    }
    .footer {
        padding: 5px 10px 0px 10px        
    }    

    ::-webkit-scrollbar {
         width: 10px;
    }
    ::-webkit-scrollbar-thumb {      
      border-radius: 20px;
      border: solid 1px gray;
      background-color: var(--color-primary-tint);
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--color-primary);
    }
    
      `];
    }
}
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { LitElement, html, css } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { container } from 'tsyringe';
import { AuthService } from '../services/auth.service';
import logo from '../../../assets/logo.png'
import './left-menu.element';
import './top-menu.element';
import { SeLeftMenuElement } from './left-menu.element';
import { SeTopMenuElement } from './top-menu.element';
import './pipes.element';
import './destinations.element';
import './sources.element';
import './pipe-details.element';
import './destination-details.element';
import './source-details.element';
import './pipe-setup-wizard.element';
import './usage.element';
import './billing/billing.element';
import './billing/billing-overview.element';
import './billing/billing-payment.element';
import './billing/billing-history.element';
import './billing/billing-preferences.element';
import './users.element';
import './user-invite.element';
import { MenuService } from '../services/menu.service';
import { SePaginationElement } from './components/pagination.element';
import './components/pagination.element';
import './profile.element';
import './oAuthResponse.element';
import './components/toggle.element';
import './components/details-history-tab.element';
import './components/details-streams-tab.element';
import './components/details-pipe-schedule-tab.element';
import './components/details-destination-config-tab.element';
import './components/details-pipe-params-tab.element';
import './components/details-pipes-tab.element';
import './pipe-usage.element.ts';
import './credit-history.element.ts';
import './welcome.element.ts';
import './contact-us.element';

@customElement('se-home')
export class SeHomeElement extends LitElement {    

    private _authService: AuthService;    
    //private _location: RouterLocation;    
    
    @query('#leftMenu') private _leftMenu: SeLeftMenuElement;
    @query('#topMenu') private _topMenu: SeTopMenuElement;
    //@query('#contextmenu') private _contextmenu: contextmenuelement;
    //@query('#accountmenu') private _accountmenu: htmldivelement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isLoggedIn) {
            return commands.redirect('/login');
        }        
        //this._location = location;
        
    }

    onPopState() {


    }

    connectedCallback() {
        super.connectedCallback();

        window.addEventListener("popstate", evt => this.onPopState());
    }
    disconnectedCallback() {        
        super.disconnectedCallback();
    }

    firstUpdated() {
        /*const location = this._userState.router.location;
        if (location.route.component === 'se-space' && location.params.id) {
            const spaceId = parseInt(location.params.id.toString());
            const labelId = location.params.labelId ? parseInt(location.params.labelId.toString()) : 0;
            this._leftMenu.setSelectedSpace(spaceId, labelId);
        }*/
                
        /*if ((this._location.pathname.startsWith('/space/') && this._location.params.id)) {
            const spaceId = parseInt(this._location.params.id.toString());
            const labelId = this._location.params.labelId ? parseInt(this._location.params.labelId.toString()) : 0;            
            this._leftMenu.setSelectedSpace(spaceId, labelId);
        }*/       
    }

    

    render() {
        return html`            
            <div class="body">
                <se-left-menu id="leftMenu"></se-left-menu>
                <div class="right">
                    <slot></slot>
                </div>    
            </div>            
        `;
    }

    static styles = css`
    :host {        
        height: 100%;
        box-sizing: border-box;
        display: flex;        
        flex-direction: column;
    }    
    .header {       
        background-color: var(--color-primary);
        color:white;        
        padding: 10px 20px 0px 15px;        
        display:flex;
        align-items: center;
        gap:15px;
        font: var(--font-h3);
        justify-content: space-between;
        flex-direction: row-reverse;
    }
    
    .account {
        color:Gainsboro;
        margin-left:12px;
    }
    .account:hover {
        color:white;
    }
    .header-logo {
        height: 25px;        
    }    
    .body {
        display: flex;
        direction: row;        
        flex: 1;        
        min-height: 0px;
        gap: 0px;
    }
    .right {
        box-sizing: border-box;
        flex: 1;
        background-color: white;
        height: 100%;
        min-height: 0px;
        min-width: 0px;
        overflow-y: scroll;
    }
    @media screen and (max-width: 700px) {
      .header-logo {
        display: none;
      }
}
  `;
}
import { LitElement, html, css, } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { BaseEditor, ObjectEditor } from './editors/base-editor';
import { container } from 'tsyringe';
import './components/primary-button.element';
import './components/secondary-button.element';
import './editors/input-editor.element';
import seq32 from '../../../../assets/seq-32.svg'
import { readFileSync } from 'fs';
import './components/fa-icon.element';

import logo from '../../../assets/logo-tagline.svg'
import twitter from '../../../assets/twitter.svg'
import linkedin from '../../../assets/linkedin.svg'
import instagram from '../../../assets/instagram.svg'
import { InputEditorElement } from './editors/input-editor.element';
import { AppConfigService } from '../services/app-config.service';
import { ToasterService } from '../services/toaster.service';
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { CheckboxEditorElement } from './editors/checkbox-editor.element';
import Styles from '../../../assets/styles';
import { SePrimaryButton } from './components/primary-button.element';
declare function fbq(arg1: string, arg2: string): void;

@customElement('se-user-registration')
export class UserRegistrationElements extends LitElement {    

    private _appConfigService: AppConfigService;
    private _toasterService: ToasterService;

    @query('#saveButton') private _saveButton: SePrimaryButton;

    @query('#name') private _nameEditor: InputEditorElement;
    @query('#companyName') private _companyNameEditor: InputEditorElement;
    @query('#email') private _emailEditor: InputEditorElement;
    @query('#password') private _passwordEditor: InputEditorElement;
    @query('#confirmPassword') private _comparePasswordEditor: InputEditorElement;
    private _eulaAccepted: boolean = false;

    @state() private _hasChanged = false;    

    constructor() {
        super();
        this.submitLogin = this.submitLogin.bind(this);
        this.onSave = this.onSave.bind(this);

        this._appConfigService = container.resolve(AppConfigService);
        this._toasterService = container.resolve(ToasterService);
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {                
        //if (!this._appConfigService.isNew) {
        //    return commands.redirect('/login');
        //}
        //else {
        //    return undefined;
        //}
        return undefined;
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener('keydown', this.submitLogin);
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev))        
    }
    disconnectedCallback() {
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev))        
        super.disconnectedCallback();
    }
    submitLogin(event) {
        if (event.key === 'Enter') {
            this.onSave(event);
        }
    }

    private onEditorChanged(ev) {
        this._hasChanged = true;
    }   

    updateChangedValues() {        
    }

    show() {
        this.hidden = false;
    }
    hide() {
        this.hidden = true;
    }

    firstUpdated() {        
    }

    private reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false)
                return false;
        }
        return true;
    }

    private async onSave(ev) {
        this._saveButton.loading = true;
        ev.preventDefault();
        this._toasterService.clear();

        //make sure that EULA has been accepted
        if (this._eulaAccepted !== true) {
            this._toasterService.showError("Please accept the EULA");
            return;
        }

        if (this.reportValidity()) {
            this._hasChanged = false;
            await this._appConfigService.registerUserAsync(this._nameEditor.liveValue, this._companyNameEditor.liveValue, this._emailEditor.liveValue, this._passwordEditor.liveValue)
                .then(() => this.success()).catch(err=>this.error(err));            
        }
        this._saveButton.loading = false;
        // facebook pixel registration
        fbq('track', 'CompleteRegistration');
    }

    /**
     * The eula checkbox was clicked
     * @param evt 
     */
    private onEulaChanged(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._eulaAccepted = true;
            }
            else {
                this._eulaAccepted = false;
            }
        }
    }

    private success() {

        this._toasterService.showSuccess("Your account has been successfully created. Please log in.", 0);
        Router.go("/login");

        //this._toasterService.showSuccess("Thank you for registering for an account. A sales representitive will contact you shortly.", 0);
        //alert("User successfully registered. Please use your email and password to login.");
        //Router.go("/login");
        //Router.go("/");
    }

    private async login(ev) {
        ev.preventDefault();
        Router.go("/login");

    }

    
    private async showEULA(ev) {
        ev.preventDefault();
        this.openEULADialog();
        //Router.go("/login");

    }

    private openEULADialog() {

        fetch('./eula.txt')
            .then(response => response.text())
            .then(eulaContent => {
                // Open a new window
                let eulaWin = window.open('', 'EULA');

                // Write the content of the EULA into the new window
                eulaWin.document.write(`
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Sequentum EULA</title>
                        <style>
                            body {
                                font-family: Arial, sans-serif;
                                margin: 20px;
                                overflow-y: scroll;
                                max-height: 90vh;
                            }
                        </style>
                    </head>
                    <body>
                        <pre>${eulaContent}</pre>
                    </body>
                    </html>
                `);

                // Close the document after writing
                eulaWin.document.close();
            })
            .catch(error => console.error('Error fetching EULA:', error));
    }


    private error(err: Error) {
        this._toasterService.showError(err.message);
        this._hasChanged = true;
    }

    passwordChanged() {
        if (this._passwordEditor.liveValue !== this._comparePasswordEditor.liveValue) {
            this._comparePasswordEditor.setCustomValidity("Passwords don't match.");
        }
        else {
            this._comparePasswordEditor.setCustomValidity("");
            this._comparePasswordEditor.reportValidity();
        }
    }

    render() {
        return html`
            <div class="header"></div>
            <form id="editorForm" class="editor" @keydown="${this.submitLogin}">
                <div class="scroll-container">
                    <div class="content"> 
                        <div style="text-align: center;margin-bottom: 20px;margin-top: 20px;">
                            <img src=${logo} class="header-logo" />
                        </div>

                        <se-input-editor enableAutocomplete class="inputEditor" id="name" name="name" type="text" label="Name" placeholder="John Doe" labelPosition="top" required size="30"></se-input-editor>
                        <se-input-editor class="inputEditor" id="email" name="email" type="text" label="Email" placeholder="example@company.com" labelPosition="top" input-type="email" required size="30"></se-input-editor>
                        <se-input-editor class="inputEditor" id="companyName" name="companyName" type="text" label="Organization" placeholder="Company Name" labelPosition="top" required size="30"></se-input-editor>
                    
                        <se-input-editor enableAutocomplete class="inputEditor" @editorChanged=${this.passwordChanged} id="password" name="password" type="text" label="Password" labelPosition="top" input-type="password" required size="30"></se-input-editor>
                        <se-input-editor enableAutocomplete class="inputEditor" @editorChanged=${this.passwordChanged} id="confirmPassword" name="confirmPassword" type="text"  label="Confirm password" labelPosition="top" input-type="password" required size="30"></se-input-editor>

                        <div class="inputEditor" style="flex:1">
                            <div class="linkLabel">
                                <se-checkbox-editor id="eula" name="eula" .value=${this._eulaAccepted} @valueChanged=${this.onEulaChanged} tristate="auto"></se-checkbox-editor>

                                <a href="EULA" class="link" @click="${this.showEULA}" style="margin-left: 10px">I agree to EULA </a>
                            </div>
                        </div>

                        <div class="loginButton inputEditor">
                            <se-primary-button id="saveButton" @click="${this.onSave}" ?disabled="${!this._hasChanged}" text="Register"></se-primary-button>
                    
                        </div>
                        <div class="loginLink">
                            <a href="Forgot Password" class="link" @click="${this.login}">Return to Login</a>

                        </div>

                    </div>
                </div>
            </form>
            <div class="footer"><div>© 2024 Sequentum  •  <a href="https://www.sequentum.com/privacy-policy" target="_blank">Privacy Policy</a>  •  <a href="https://www.sequentum.com/terms-of-service" target="_blank">Terms of Service</a></div>
                <div style="text-align: right; flex:1">
                    <a href="https://twitter.com/sequentuminc" target="_blank"><img src=${twitter}></a>
                    <a href="https://www.linkedin.com/company/sequentum/" target="_blank"><img src=${linkedin}></a>
                    <a href="https://www.instagram.com/sequentuminc/" target="_blank"><img src=${instagram}></a>
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    .linkLabel {
        display: flex;
        flex: 2;
        flex-direction: row;
    }
    .h3 {
        padding: 10px 10px 10px 10px;
    }
    .inputEditor{
        width: 100%;
    }
    @media (max-width: 600px) {
        .editor {
            width: 100%;
        }
        .scroll-container {
            padding: 40px;
        }
        .header-logo {
            height: 50%;
        }
        .footer {
            display: none;
        }
    }

      `]
    };
}
import { container, inject, singleton } from 'tsyringe';
import { err, NetworkError, ok, Result } from '../../utils/result';
import { SeEvent } from '../../utils/se-events';
import { AppConfigApi } from './app-config.api';
import { DocumentationApi } from './documentation.api';
import { UserApi } from './user-api';

@singleton()
export class DocumentationService {

    private _documentationApi: DocumentationApi;

    get api() { return this._documentationApi };
    
    constructor() {        
        this._documentationApi = container.resolve(DocumentationApi);
    }    


}
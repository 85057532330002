import { Router } from '@vaadin/router';
import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import { container } from 'tsyringe';
import Styles from '../../../../assets/styles';
import { RunStatus } from "../../enums/run-status";
import { RunViewModel } from '../../models/run-view-model';
import { DestinationService } from '../../services/destination.service';
import { PipeService } from '../../services/pipe.service';
import { SourceService } from '../../services/source.service';
import { DataGridColumn } from './data-grid-template';
import { SeDataGrid } from './data-grid.element';

@customElement('se-details-history-tab')
export class DetailsHistoryButton extends LitElement {
    private _pipeService: PipeService;
    private _sourceService: SourceService;
    private _destinationService: DestinationService;
    private _pageIndex: number = 1;
    private _recordsPerPage: number = 10;
    private _totalRecordCount: number;
    private _sortColumn: string;
    private _sortOrder: number = 1;
    private _columns: DataGridColumn[] = [];
    private _pipeId?: number;
    private _sourceId?: number;
    private _destinationId?: number;
    private _runRefresh: boolean = true;
    private _loadTimeout;

    @state() private _data: any[] = [];


    @query('.runs-grid') private _runsGrid: SeDataGrid;

    @property() pipeId: string;
    @property() sourceId: string;
    @property() destinationId: string;
    @property() showPipeName: boolean = false;

    //helps remove added event when we unload the page
    private boundHandlePopState;

    constructor() {
        super();
        this._destinationService = container.resolve(DestinationService);
        this._pipeService = container.resolve(PipeService);
        this._sourceService = container.resolve(SourceService);
        this.boundHandlePopState = this.handlePopState.bind(this);

    }
    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('popstate', this.boundHandlePopState);
        this._runRefresh = true;
        this.updateDataAsync();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('popstate', this.boundHandlePopState);
        if (this._loadTimeout) {
            clearTimeout(this._loadTimeout);
        }
    }
    private handlePopState(event) {
        const customEvent = new CustomEvent('customPageChange', {
            detail: { pageIndex: (event.state?.gridPage || 1) }
        });
        this.onPageChanged(customEvent);
    }
    firstUpdated() {
        //get page number from link
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);
        var pulledPage = Number(searchParams.get("gridPage") || this._pageIndex)
        const customEvent = new CustomEvent('customPageChange', {
            detail: { pageIndex: pulledPage }
        });
        this.onPageChanged(customEvent);

    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._runsGrid.pageIndex = this._pageIndex;
        this.loadRuns();
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();

    }
    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder;

        this._sortColumn = sortColumn;
        this._sortOrder = sortOrder;

        this.loadRuns();
    }
    private filterByLabel(event: CustomEvent) {
        event.stopPropagation();
    }
    private filterByAgentTemplate(event: CustomEvent) {
        event.stopPropagation();
        this.loadRuns();
    }
    private getStatus(color: string, text: string, labelDlass: string): TemplateResult {
        return html`
        <div class="pipe-item-status" style="background-color: ${color};">

                <span>${text}</span>
        </div>`
    }

    /**
     * Jump to the pipe details page
     * @param row
     * @param col
     */
    private async viewPipeDetails(row: any, col: DataGridColumn) {
        Router.go('/agents/' + row.configId);
    }


    //formatting time difference
    private timeDifference(startDate: Date | string, endDate: Date | string): string {
        if (startDate == null || endDate == null) {
            return " - "
        }
        const start = new Date(startDate);
        const end = new Date(endDate);

        const diffMs = end.getTime() - start.getTime();

        const seconds = Math.floor((diffMs / 1000) % 60);
        const minutes = Math.floor((diffMs / (1000 * 60)) % 60);
        const hours = Math.floor(diffMs / (1000 * 60 * 60));

        let formattedDiff = '';

        if (hours) {
            formattedDiff += `${hours} hour${hours !== 1 ? 's' : ''} `;
        }

        if (minutes) {
            formattedDiff += `${minutes} minute${minutes !== 1 ? 's' : ''} `;
        }

        if (seconds) {
            formattedDiff += `${seconds} second${seconds !== 1 ? 's' : ''}`;
        }

        if (!formattedDiff) {
            return "0 seconds";
        }

        return formattedDiff.trim();
    }


    private async updateDataAsync() {
        if (!this._runRefresh) {
            return;
        }
        this._loadTimeout = setTimeout(() => { this.updateDataAsync(); this.loadRuns(); }, 5000);
    }

    private async loadRuns() {
        try {

            const options: Intl.DateTimeFormatOptions = {
                dateStyle: 'medium',
                timeStyle: 'short'
            };
            this._columns = [
                { field: 'startTime', title: 'Start Time', sortable: true, template: (row, col) => html`${(new Date(row.startTime)).toLocaleString(undefined, options)}` },
                {
                    field: 'configName', title: 'Agent', sortable: false, hidden: !this.showPipeName, cellStyle: { textDecoration: 'underline' }, action: this.viewPipeDetails.bind(this)
                },
                { title: 'Run Time', template: (row, col) => html`${this.timeDifference((row.startTime), (row.endTime))}` },
                {
                    field: 'status', title: 'Status', align: 'center', template: (row, col) => html`${choose(row.status, [
                        [undefined || null, () => this.getStatus('#D0D3DC', 'Not Run', 'No Activity')],
                        [RunStatus.success, () => this.getStatus('#219653', 'Completed', 'Success')],
                        [RunStatus.completed, () => this.getStatus('#219653', 'Completed', 'Completed')],
                        [RunStatus.running, () => this.getStatus('#3F8CD9', 'Running', 'Running')],
                        [RunStatus.exporting, () => this.getStatus('#3F8CD9', 'Running', 'Exporting')],
                        [RunStatus.starting, () => this.getStatus('#3F8CD9', 'Running', 'Starting')],
                        [RunStatus.stopped, () => this.getStatus('#EB5757', 'Failed', 'Stopped')],
                        [RunStatus.failed, () => this.getStatus('#EB5757', 'Failed', 'Failed')],
                        [RunStatus.stopping, () => this.getStatus('#EB5757', 'Failed', 'Stopping')],
                        [RunStatus.failure, () => this.getStatus('#EB5757', 'Failed', 'Failed')],
                        [RunStatus.queuing, () => this.getStatus('#D0D3DC', 'Queued', 'Queuing')],
                        [RunStatus.scheduled, () => this.getStatus('#D0D3DC', 'Scheduled', 'Scheduled')]



                    ],
                        () => html`${row.status}`)}`
                },
            ]

            var result;
            var sched;
            if (this.pipeId) {
                this._pipeId = Number(this.pipeId)
                result = await this._pipeService.api.getPipeRuns(this._pipeId, this._pageIndex, this._recordsPerPage, this._sortColumn, this._sortOrder);
                sched = await this._pipeService.api.getPipeScheduleAsync(this._pipeId);
                if (sched.isOk && sched.value.id != null) {
                    var nextRun = new RunViewModel();
                    nextRun.startTime = sched.value.startTime;
                    nextRun.status = RunStatus.scheduled;
                    this._data.unshift(nextRun);
                }
                if (sched.isOk && sched.value.id != null) {
                    var nextRun = new RunViewModel();
                    nextRun.startTime = sched.value.startTime;
                    nextRun.status = RunStatus.scheduled;
                    this._data.unshift(nextRun);
                }
            }
            if (this.sourceId) {
                this._sourceId = Number(this.sourceId)
                result = await this._sourceService.api.getSourceRunHistory(this._sourceId, this._pageIndex, this._recordsPerPage, this._sortColumn, this._sortOrder);
            }
            if (this.destinationId) {
                this._destinationId = Number(this.destinationId)
                result = await this._destinationService.api.getDestinationRunHistory(this._destinationId, this._pageIndex, this._recordsPerPage, this._sortColumn, this._sortOrder);
            }

            //new runviewmodel object which contains the pipe sched date as the date


            this._data = result.value.runsHistory != null ? result.value.runsHistory: [];


            this._totalRecordCount = result.value.totalRecordCount;

        } catch (e) {
            
        }
    }

    render() {
        return html`

                        <div class="table-list">
                            <se-data-grid .selectAllCheckbox=${true} class="grid runs-grid" .rows=${this._data} .recordsPerPage=${this._recordsPerPage} .pageIndex=${this._pageIndex} .columns=${this._columns} selectable @selectionchanged=${this.onGridSelectionChanged} @sortdata=${this.sortDataGrid} @filterbylabel=${this.filterByLabel} @filterbyAgentTemplate=${this.filterByAgentTemplate}></se-data-grid>
                        </div>
                        <div class="table-pagination" >
                            <se-pagination .dataType=${"Runs"} .recordsPerPage=${10} .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
                        </div>

                        
                        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        background:none;
        color:#1A1F4B
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .body {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
    }
    .time-section{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .action-button{
        margin-bottom: -75px;
        display: flex;
        justify-content: space-between;
    }
    .config-tab{
        max-width: 700px;
        width: 100%;
    }
    .action-button .inputEditor{
         width:100px;
    }
    .grid {
        flex:1;        
    }   
    .checkbox {
        width: 1rem;
        height: 1rem;
    }
    input[type="checkbox"]:checked {
        background-color: var(--color-secondary);
    }
    .left-header{
        flex: 1 1 0%;
        max-width: 600px;
        padding-right: 50px;
    }

      `]
    };


}


import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';

@customElement('se-source-destination')
export class SeSourceDestination extends LitElement {    

    @property() customStyle: any = {};
    @property() icon: string;
    @property() iconColor: string;    
    @property() text: string;
    @property() title: string;    
    @property({ type: Boolean }) redHover = false;
    @property({ type: Boolean }) disabled = false;
    @property({ type: Boolean }) active = false;

    firstUpdated() {        
    }
    
    render() {
        const style = this.active ? (this.redHover ? {
            ...this.customStyle, ...{
                backgroundColor: 'crimson',
                color: 'white',
                border: '1px solid crimson'
            }
        } : {
            ...this.customStyle, ...{backgroundColor: 'white',
                boxShadow: 'none',
                border: '1px solid black'
            }
        }) : this.customStyle;

        return html`
            <div style="position:relative;">
                <img class="iconImage" src="${this.icon}"/>
                <button ?disabled=${this.disabled}  title="${this.title}" class="button ${classMap({ red: this.redHover })}" type="button" style="${styleMap({ ...style, paddingLeft: '50px', height: '60px',textAlign:'left' })}">${this.text}</button>
            </div>`;        
        
    }

    static styles = css`
    :host([hidden]) {
        display: none;
    }
    :host {
        display: block;
        width: 100%;
        border-radius: 6px;
    }
    .button {       
        padding: 10px;
        border-radius: 6px;
        justify-content: center;
        width:100%;
        transition: all 0.25s;
        font: var(--font-button);
        background-color: var(--primary-color);
        border: none;
        color: black;
    }
    .button:disabled {
        opacity: 1;
        background-color: var(--primary-color);
        border: 1px solid lightgray;
        color: gray;
        transition: none;
    }
    .button:hover {
        border: 1px solid black;
        background-color: white;
    }
    .button.red:hover {
        background-color: crimson;
        color: white;
        border: 1px solid crimson;
    }
    .button:hover:disabled {
        background-color: var(--primary-color);
        border: 1px solid lightgray;
        box-shadow: none;
    }
    .button:active {
        background-color: white;
        box-shadow: none;
        border: 2px solid var(--primary-color);
    }
    .button.red:active {
        background-color: crimson;
        color: white;
        border: 1px solid crimson;
    }
     .iconImage {
        position: absolute;
        transform: translate(50%, -50%);
        top: 50%;
        width: 25px;
    }

  `;
}
import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import Styles from '../../../../assets/styles';
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { container } from 'tsyringe';
import { AuthService } from '../../services/auth.service';
import { ToasterService } from '../../services/toaster.service';
import { InputEditorElement } from '../editors/input-editor.element';
import { SelectEditorElement } from '../editors/inline/select-editor.element';
import { BillingService } from '../../services/billing.service';
import { OrgBilling } from '../../models/org-billing';
import { BillingUser } from '../../models/billing-user';

@customElement("se-billing-preferences")
export class BillingPreferencesElement extends LitElement {

    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _billingService: BillingService;

    @query('#companyName') private _companyNameEditor: InputEditorElement;
    @query('#billingEmail') private _billingEmailEditor: InputEditorElement;
    @query('#billingUser') private _billingUserSelector: SelectEditorElement;
    @query('#addressLine1') private _addressLine1Editor: InputEditorElement;
    @query('#addressLine2') private _addressLine2Editor: InputEditorElement;
    @query('#addressCity') private _addressCityEditor: InputEditorElement;
    @query('#addressPostal') private _addressPostalEditor: InputEditorElement;
    @query('#addressState') private _stateSelector: SelectEditorElement;
    @query('#addressCountry') private _countrySelector: SelectEditorElement;

    @state() private _orgBilling: any;
    @state() private _orgCustomer: any;
    @state() private _apiBillingUsers: any[] = [];
    @state() private _billingUsers: any[] = [];
    @state() private _selectedUserId: any;
    @state() private _countries: any[] = [];
    @state() private _selectedCountry: string;
    @state() private _states: any[] = [];
    @state() private _selectedState: string;

    @property({ type: String }) tabName = 'preferences';

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._billingService = container.resolve(BillingService);
    }

    connectedCallback() {
        super.connectedCallback();
        this.getOrCreateCustomer();
        this.loadBillingUsers();
        this.loadCountries();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isAdmin) {
            return commands.redirect('/login');
        }        

       if (location.params.billingId) {
           let val = parseInt(location.params.pipeId.valueOf() as string);//location.params.billingTab as string;
        }
    }

    async loadBillingUsers() {

        var orgId = this._authService.user.organizationId;

        const result = await this._billingService.api.getOrgBillingUsersAsync(orgId);
        if (result.isOk) {
            this._apiBillingUsers = result.value.users;

            this._apiBillingUsers.forEach((item) => {
                this._billingUsers.push({ id: item.id, name: item.name });
            });
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    async getOrCreateCustomer() {
        const result = await this._billingService.api.getOrCreateCustomer();
        if (result.isOk) {
            this._orgCustomer = result.value;
            this._orgBilling = result.value;
            this._selectedUserId = this._orgCustomer.billingUserId;
            this._selectedCountry = this._orgCustomer.country;
            this._selectedState = this._orgCustomer.state;
            if (this._selectedCountry !== null) {
                this.loadStates();
            }
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    async loadCountries() {

        const response = await this._billingService.api.getCountries();
        if (response.isOk) {
            let countries: any = response.value;
            countries.forEach((item) => {
                this._countries.push({ id: item.id, name: item.name });
            });
        }
    }

    async loadStates() {
        const response = await this._billingService.api.getStates(this._selectedCountry);
        if (response.isOk) {
            let states: any = response.value;
            states.forEach((item) => {
                this._states.push({ id: item.id, name: item.name });
            });
        }
        this.requestUpdate();
    }

    async saveAsync(ev) {
        ev.preventDefault();

        const updatedOrgBilling: OrgBilling = {
            ...this._orgBilling, 
            companyName: this._companyNameEditor.liveValue,
            line1: this._addressLine1Editor.liveValue,
            line2: this._addressLine2Editor.liveValue,
            city: this._addressCityEditor.liveValue,
            state: this._stateSelector.liveValue,
            country: this._countrySelector.liveValue,
            postalCode: this._addressPostalEditor.liveValue

            //Enable it once Invoice functionlity is implemented.
            //billingEmail: this._billingEmailEditor.liveValue
        };

        const result = await this._billingService.api.updateOrgBillingAsync(updatedOrgBilling);
        if (result.isOk) {
            this._orgBilling = result.value;
            this._orgCustomer = result.value;
            this._selectedUserId = this._orgBilling.billingUserId;
            this._toasterService.showSuccess("Succesfully updated the Billing customer details.");
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
        
    }

    private pickUser(event: MouseEvent) {
        event.stopPropagation();
        this._selectedUserId = Number(this._billingUserSelector.liveValue);
        var items = document.querySelectorAll(".billing-users")
        for (var i = 0; i < items.length; i++) {
            items[i].classList.remove("active")
        }
    }

    private onUserChange(event: MouseEvent) {
        event.stopPropagation();
        this._selectedUserId = Number(this._billingUserSelector.liveValue);

        if (this._selectedUserId != null) {
            this._apiBillingUsers.forEach((item: BillingUser) => {
                if (item.id === this._selectedUserId) {
                    const updatedOrgBilling = {
                        ...this._orgBilling,
                        companyName: this._selectedUserId == this._orgCustomer.billingUserId ? this._orgCustomer.companyName : item.organizationName,
                        orgId: item.organizationId,
                        billingUserId: this._selectedUserId == this._orgCustomer.billingUserId ? this._orgCustomer.billingUserId : item.id,
                        email: item.email,
                    };

                    this._orgBilling = updatedOrgBilling;
                }
            });
        }
        this.requestUpdate();
    }

    private pickCountry(event: MouseEvent) {
        event.stopPropagation();
        var items = document.querySelectorAll(".billing-users")
        for (var i = 0; i < items.length; i++) {
            items[i].classList.remove("active")
        }
    }

    private async onCountryChange(event: MouseEvent) {
        event.stopPropagation();
        this._selectedCountry = this._countrySelector.liveValue ?? this._selectedCountry;
        this._states = [];
        if (this._selectedCountry != null) {
            this.loadStates();
        }
    }

    private pickState(event: MouseEvent) {
        event.stopPropagation();
        var items = document.querySelectorAll(".billing-users")
        for (var i = 0; i < items.length; i++) {
            items[i].classList.remove("active")
        }
    }

    private async onStateChange(event: MouseEvent) {
        event.stopPropagation();
        this._selectedState = this._stateSelector.liveValue ?? this._selectedState;
        this.requestUpdate();
    }
    

    render() {
        return html`
            <div class="body">
                <se-billing .tabName=${this.tabName}></se-billing>
                <div class="container-content">
                        <div style="margin: 10px;">
                            Changes to these preferences will apply to future invoices only.
                            If you need past invoice reissued, please contact <strong>support@sequentum.com</strong>
                        </div><br>
                        <div class="labeled-input">
                        <label for="billingUser" style="font-weight: 800">Billing User</label>
                        <se-select-editor class="inputEditor" id="billingUser" .value="${String(this._selectedUserId) ?? "-1"}" width="100% " name="billingUser" label="" labelPosition="top"
                            @mousedown=${this.pickUser} @click=${this.onUserChange} .options=${this._billingUsers}
                        ></se-select-editor><br>
                        </div>
                        <div class="labeled-input">
                            <label for="companyName" style="font-weight: 800">Company Name</label>
                            <small>If specified, this name will appear on invoices instead of your organization name.</small>
                            <se-input-editor class="inputEditor" id="companyName" name="companyName" type="text" .value=${this._orgBilling?.companyName} input-type="text" required placeholder="Company Name" labelPosition="top" size="50"></se-input-editor>
                        </div><br>
                        <!-- //Enable it when Invoice functionlity is implemented.
                        <div class="labeled-input">
                            <label for="billingEmail" style="font-weight: 800">Billing Email</label>
                            Invoices and other billing notifications will be sent here.
                            <se-input-editor class="inputEditor" id="billingEmail" name="billingEmail" type="text" .value=${this._orgBilling?.billingEmail} input-type="text" placeholder="support@sequentum.com" labelPosition="top" required size="50"></se-input-editor>
                       </div> -->
                       <br>
                       <div class="labeled-input">
                            <label for="billAddress" style="font-weight: 800">Primary business address</label>
                            <small>This is the physical address of the company purchasing Sequentum services and is used to calculate any applicable sales tax.</small>
                       </div><br>
                       <div class="labeled-input">
                            <se-select-editor class="inputEditor" id="addressCountry" .placeholder="${"Country"}" .value="${this._selectedCountry ?? "-1"}" width="100% " name="addressCountry" labelPosition="top"
                                @mousedown=${this.pickCountry} @click=${this.onCountryChange} .options=${this._countries.map(e => ({ id: e.id, name: e.name }))}>
                            </se-select-editor>
                       </div>
                       <div class="labeled-input" style="padding-top: 10px">
                            <se-input-editor class="inputEditor" id="addressLine1" name="addressLine1" type="text" .value=${this._orgBilling?.line1} input-type="text" required placeholder="Adress line1" labelPosition="top" size="50"></se-input-editor>
                       </div>
                       <div class="labeled-input" style="padding-top: 10px">
                            <se-input-editor class="inputEditor" id="addressLine2" name="addressLine2" type="text" .value=${this._orgBilling?.line2} input-type="text" required placeholder="Adress line2" labelPosition="top" size="50"></se-input-editor>
                       </div>
                       <div class="labeled-input" style="padding-top: 10px">
                            <se-input-editor class="inputEditor" id="addressCity" name="addressCity" type="text" .value=${this._orgBilling?.city} input-type="text" required placeholder="City" labelPosition="top" size="50"></se-input-editor>
                       </div>
                       <div class="address-input" style="padding-top: 10px">
                            <se-select-editor class="inputEditor" id="addressState" .placeholder="${"State"}" .value="${this._selectedState ?? "-1"}" width="100% " name="billingUser" labelPosition="top"
                                @mousedown=${this.pickState} @click=${this.onStateChange} .options=${this._states.map(e => ({ id: e.id, name: e.name }))}>
                            </se-select-editor>
                            <se-input-editor class="inputEditor" id="addressPostal" name="addressPostal" type="text" .value=${this._orgBilling?.postalCode} input-type="text" required placeholder="Postal code" labelPosition="top" size="50"></se-input-editor>
                       </div>
                       <br>
                       <div class="action-buttons"><se-primary-button @click="${this.saveAsync}"  text="Save"></se-primary-button></div>
                    </div>
                </div>
            </div>    
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        background:none;
        color:#1A1F4B
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .body {
        display: flex;
        flex-direction: column;
        background-color: white;
    }
    .time-section{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .config-tab{
        max-width: 700px;
        width: 100%;
    }
    .inputEditor{
         width:20%;
    }
    .grid {
        flex:1;        
    }   
    .checkbox {
        width: 1rem;
        height: 1rem;
    }
    input[type="checkbox"]:checked {
        background-color: var(--color-secondary);
    }
    .left-header{
        flex: 1 1 0%;
        max-width: 250px;
        padding-right: 50px;
    }
    .billing-users{
        border:1px solid transparent
    }
    .billing-users:hover{
        border: 1px solid black;
        background-color: white;
    }
    .action-buttons{
        display: flex;
        flex-direction: row;
        gap: 12px;
        justify-content: flex-end;
        padding: 2% 70% 0 20%;
    }
    .labeled-input{
        display: flex;
        gap: 10px;
        flex-direction: column;
    }
    .address-input{
        display: flex;
        gap: 30px;
        flex-direction: row;
    }
      `]
    };
}

export class PipeViewModel {
        
    constructor() {        
    }
    
    id?: number;
    name?: string;
    source?: string;
    srcIcon?: string;
    isNewSource?: string;
    isNewDestination?: string;
    organizationId?: number;
    destination?: string;
    destinationId?: number;
    nextRunTime?: Date;
    destIcon?: string;
    schedule?: string;
    status?: string;
    configJson?: string;
    lastRun?: Date;
}
import { LitElement, html, css, TemplateResult, noChange } from 'lit';
import { AsyncDirective, directive } from 'lit/async-directive.js';
import { customElement, property } from 'lit/decorators.js';
import { ChildPart, DirectiveParameters } from 'lit/directive.js';
import { ElementPart } from 'lit/directive.js';
import { PartInfo, PartType } from 'lit/directive.js';
import { container } from 'tsyringe';
import { TitleService } from '../services/title.service';

class HtmlTitleDirective extends AsyncDirective  {

    private _titleService: TitleService;
    private _timeout: number;
    private _element: HTMLElement;
    private _title: string | TemplateResult;
    private _description: string;
    private _showOnClick = false;
    private _isHidden = true;

    constructor(partInfo: PartInfo) {
        super(partInfo);
        if (partInfo.type !== PartType.ELEMENT) {
            throw new Error('The `setitle` directive must be used in an element');
        }
        this._titleService = container.resolve(TitleService);
    }

    update(part: ElementPart, [title, showOnClick, description]: DirectiveParameters<this>) {
        this._title = title;
        this._description = description;
        this._showOnClick = showOnClick;
        this._isHidden = true;
        if (this._element !== part.element) {
            if (this._element) {
                this._element.removeEventListener("mouseenter", (evt) => this.onMouseEnter(evt));
                this._element.removeEventListener("mouseleave", (evt) => this.onMouseLeave(evt));
                this._element.removeEventListener("mousedown", (evt) => this.onMouseDown(evt));
            }
            this._element = part.element as HTMLElement;
            this._element.addEventListener("mouseenter", (evt) => this.onMouseEnter(evt));
            this._element.addEventListener("mouseleave", (evt) => this.onMouseLeave(evt));
            this._element.addEventListener("mousedown", (evt) => this.onMouseDown(evt));
        }        
        return this.render(title, showOnClick, description);
    }

    onMouseLeave(evt) {   
        evt.stopPropagation();
        if (this._timeout) {
            clearTimeout(this._timeout);
            this._timeout = undefined;
        }
        else {
            this._titleService.hideTitle();
            this._isHidden = true;
        }
    }

    onMouseDown(evt: MouseEvent) {
        evt.stopPropagation();
        if (this._showOnClick && this._isHidden)
            this.showTitle();
        else
            this.onMouseLeave(evt);
    }

    onMouseEnter(evt) {
        evt.stopPropagation();
        if (!this._timeout)
            this._timeout = window.setTimeout(() => this.showTitle(), 500);        
    }

    showTitle() {
        this._timeout = undefined;
        if (!this._element["active"]) {
            this._titleService.showTitle(this._element, this._title, this._description);
            this._isHidden = false;
        }            
    }

    disconnected() {        
        this._element.removeEventListener("mouseenter", (evt) => this.onMouseEnter(evt));
        this._element.removeEventListener("mouseleave", (evt) => this.onMouseLeave(evt));
        this._element.removeEventListener("mousedown", (evt) => this.onMouseDown(evt));
    }
    reconnected() {
        if (this._element) {
            this._element.removeEventListener("mouseenter", (evt) => this.onMouseEnter(evt));
            this._element.removeEventListener("mouseleave", (evt) => this.onMouseLeave(evt));
            this._element.removeEventListener("mousedown", (evt) => this.onMouseDown(evt));
        }
        this._element.addEventListener("mouseenter", (evt) => this.onMouseEnter(evt))
        this._element.addEventListener("mouseleave", (evt) => this.onMouseLeave(evt))
        this._element.addEventListener("mousedown", (evt) => this.onMouseDown(evt))
        this._isHidden = true;
    }

    render(title: string | TemplateResult, showOnClick?: boolean, description?: string) {
        return noChange;
    }
}

export const htmlTitle = directive(HtmlTitleDirective);
import { Router } from '@vaadin/router';
import { ok, err, errVoid, NetworkError, NetworkResult, VoidNetworkResult, okVoid } from '../../utils/result';


export class BaseApi {

    deleteObjectAsync(input: RequestInfo, authToken?: string): Promise<VoidNetworkResult> {
        return this.sendRequestVoid(input, { method: 'DELETE', authToken: authToken});
    }
    deleteAllObjectsAsync(input: RequestInfo, body: any, authToken?: string): Promise<VoidNetworkResult> {
        return this.sendRequestVoid(input, { method: 'DELETE', body: body, authToken: authToken });
    }
    getObjectAsync<T>(input: RequestInfo, authToken?: string): Promise<NetworkResult<T>> {
        return this.sendRequest(input, { method: 'GET', authToken: authToken});
    }
    postObjectReturnObjectAsync<T>(input: RequestInfo, body: any, authToken?: string): Promise<NetworkResult<T>> {
        return this.sendRequest(input, { method: 'POST', body: body, authToken: authToken});
    }
    putObjectReturnObjectAsync<T>(input: RequestInfo, body: any, authToken?: string): Promise<NetworkResult<T>> {
        return this.sendRequest(input, { method: 'PUT', body: body, authToken: authToken});        
    }
    postObjectAsync(input: RequestInfo, body: any, authToken?: string): Promise<VoidNetworkResult> {
        return this.sendRequestVoid(input, { method: 'POST', body: body, authToken: authToken});
    }
    putObjectAsync(input: RequestInfo, body: any, authToken?: string): Promise<VoidNetworkResult> {
        return this.sendRequestVoid(input, { method: 'PUT', body: body, authToken: authToken});
    }
    postAsync(input: RequestInfo, authToken?: string): Promise<VoidNetworkResult> {
        return this.sendRequestVoid(input, { method: 'POST', authToken: authToken });
    }
    putAsync(input: RequestInfo, authToken?: string): Promise<VoidNetworkResult> { 
        return this.sendRequestVoid(input, { method: 'PUT', authToken: authToken });
    }
    

    sendRequest<T>(input: RequestInfo, opt: { method: string, body?: any, authToken?: string}): Promise<NetworkResult<T>> {
        return fetch(input, {
            method: opt.method,
            headers: {                
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                , ...(opt.authToken && { 'Authorization': 'bearer ' + opt.authToken }),
            }, ...(opt.body && { body: JSON.stringify(opt.body) })
        }).then(response => {
            if (response.ok)
                return response.json().then(result => ok(result));
            else if (response.status === 401) {
                Router.go('/login?message=Access+denied');
                throw new Error("Access denied");
                //return err(new NetworkError("Access denied", response.status));
            }                
            else
                return response.text().then(text => 
                {
                    try {
                        const e = JSON.parse(text);
                        return err(new NetworkError(e.message, e.severity, e.statusCode));
                    }
                    catch {
                        return err(new NetworkError(text, response.status));
                    }                    
                });
        });
    }
    sendRequestVoid(input: RequestInfo, opt: { method: string, body?: any, authToken?: string}): Promise<VoidNetworkResult> {
        return fetch(input, {
            method: opt.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                , ...(opt.authToken && { 'Authorization': 'bearer ' + opt.authToken }),
            }, ...(opt.body && { body: JSON.stringify(opt.body) })
        }).then(response => {
            if (response.ok)
                return okVoid();                
            else if (response.status === 401)
                return errVoid(new NetworkError("Access denied", response.status));
            else
                return response.text().then(text =>
                {
                    try {
                        const err = JSON.parse(text);
                        return errVoid(new NetworkError(err.message, err.severity, err.statusCode));
                    }
                    catch {
                        return errVoid(new NetworkError(text, response.status));
                    }                    
                });
        });
    }
    
}
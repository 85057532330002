import { Route, Router } from '@vaadin/router';
import { container, inject, singleton } from 'tsyringe';

@singleton()
export class RouterService {
        
    private _router: Router

    constructor() {        
    }

    initialize(outlet: HTMLDivElement) {
        this._router = new Router(outlet);
        this._router.setRoutes(
            [
                {
                    path: '',
                    component: 'se-home',
                    children:
                        [
                            {
                                path: '/',
                                redirect: '/agents'
                            },                         
                            {
                                path: '/agents',
                                component: 'se-pipes'
                            },                            
                            {
                                path: '/destinations',
                                component: 'se-destinations'
                            },                            
                            {
                                path: '/sources',
                                component: 'se-sources'
                            },                            
                            {
                                path: '/agents/:pipeId?',
                                component: 'se-pipe-details'
                            },
                            {
                                path: '/agents/:pipeId?/:tab?',
                                component: 'se-pipe-details'
                            },
                            {
                                path: '/sources/:sourceId?',
                                component: 'se-source-details'
                            },
                            {
                                path: '/sources/:sourceId?/:tab?',
                                component: 'se-source-details'
                            },
                            {
                                path: '/destinations/:destinationId?',
                                component: 'se-destination-details'
                            },
                            {
                                path: '/destinations/:destinationId?/:tab?',
                                component: 'se-destination-details'
                            },
                            {
                                path: '/usage',
                                component: 'se-usage'
                            },
                            {
                                path: '/usage/:checkoutStatus?',
                                component: 'se-usage'
                            },
                            {
                                path: '/pipe-usage/:pipeId?',
                                component: 'se-pipe-usage'
                            },
                            {
                                path: '/billing/overview',
                                component: 'se-billing-overview'
                            },
                            {
                                path: '/billing/payment-method',
                                component: 'se-billing-payment'
                            },
                            {
                                path: '/billing/history',
                                component: 'se-billing-history'
                            },
                            {
                                path: '/billing/preferences',
                                component: 'se-billing-preferences'
                            },
                            {
                                path: '/users',
                                component: 'se-users'
                            },
                            {
                                path: '/wizard',
                                component: 'se-pipe-setup-wizard'
                            },                            
                            {
                                path: '/invite/user/:userId?',
                                component: 'se-user-invite'
                            },        
                            {
                                path: '/profile',
                                component: 'se-profile'
                            },
                            {
                                path: "/oauth-response",
                                component: "se-oauth-response",
                            },
                            {
                                path: "/credit-history",
                                component: "se-credit-history"
                            },
                            {
                                path: "/welcome",
                                component: "se-welcome"
                            },
                            {
                                path: '/contact-us',
                                component: 'se-contact-us'
                            },
                        ]
                },
                {
                    path: '/login',
                    component: 'se-login'
                },
                {
                    path: '/forgot-password',
                    component: 'se-forgot-password'
                },
                {
                    path: '/reset-password',
                    component: 'se-reset-password'
                },
                {
                    path: '/change-password',
                    component: 'se-change-password'
                },
                {
                    path: '/register',
                    component: 'se-user-registration'
                },

            ]
        );
    }
}
import { container, inject, singleton } from 'tsyringe';
import { err, NetworkError, ok, Result } from '../../utils/result';
import { SeEvent } from '../../utils/se-events';
import { AppConfigApi } from './app-config.api';
import { DestinationApi } from './destination.api';
import { UserApi } from './user-api';

@singleton()
export class DestinationService {

    private _destinationApi: DestinationApi;

    get api() { return this._destinationApi };
    
    constructor() {        
        this._destinationApi = container.resolve(DestinationApi);
    }    


}
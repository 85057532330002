import "reflect-metadata";
import { LitElement, html, css } from 'lit';
import { customElement, query } from 'lit/decorators.js';
import './marketplace/elements/marketplace.element';
import { MarketplaceElement } from './marketplace/elements/marketplace.element';
import { container } from 'tsyringe'
import { until } from 'lit/directives/until.js';
import { AppConfigService } from "./marketplace/services/app-config.service";
import { MenuService } from "./marketplace/services/menu.service";

@customElement('se-app')
export class SeApp extends LitElement {

    private _appConfigService: AppConfigService;
    private _menuService: MenuService;

    constructor() {
        super();

        this._appConfigService = container.resolve(AppConfigService);
        this._menuService = container.resolve(MenuService);
    }

    //static theMode: ShadowRootMode = 'closed';
    //static shadowRootOptions = { mode: SeApp.theMode};

    sessionId: string;

    @query('#marketplace') marketplace: MarketplaceElement;

    static styles = css`
    :host {
        display: none;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
    }
  `;

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener("blur", this.closeMenu);
        this.addEventListener("mousedown", this.closeMenu);
        this.addEventListener("menuopening", this.closeMenu);
   }

    closeMenu(e: Event) {
        if (this._menuService) this._menuService.closeMenu();
    }

    render() {
        return html`
            ${until(Promise.all([this._appConfigService.initializeAsync()]).then(() => {
            this.dispatchEvent(new CustomEvent("stoploading", { bubbles: true, composed: true, detail: {} }));
            return html`<se-marketplace id="marketplace"></se-marketplace>`
        }).catch(err => html`<h1>${err.message}</h1>`), html``)}
        `;
    }
}
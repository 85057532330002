import { PreventAndRedirectCommands, PreventResult, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

//import './home.element';
import './login.element';
import './forgot-password.element';
import './reset-password.element';
import './change-password.element';
import './user-registration.element';
import { container } from 'tsyringe';
import Styles from '../../../assets/styles';
import { AuthService } from '../services/auth.service';
import './components/primary-button.element';
import { choose } from 'lit/directives/choose.js';
import './components/secondary-button.element';
import { BillingType } from '../enums/billing-type';
import { ToasterService } from '../services/toaster.service';
import { UsageService } from '../services/usage.service';
import { SeDataGrid } from './components/data-grid.element';
import './components/data-grid.element';

import { DataGridColumn } from './components/data-grid-template';
import { SelectEditorElement } from './editors/select-editor.element';
import { format, sub, startOfDay, endOfDay, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns';

@customElement('se-pipe-usage')
export class PipeUsage extends LitElement {
    private _usageService: UsageService;
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _pageIndex: number = 1;
    private _recordsPerPage: number = 10;
    private _totalRecordCount: number;
    private _sortColumn: string = 'date';
    private _sortOrder: number = 1;
    private _columns: DataGridColumn[] = [];
    private _pipeId?: number;
    private _pipeName?: string;

    // UI constants
    private RUN_USAGE = 'Server Time';
    private DATA_USAGE = 'Data Transfer';
    private INPUT_COUNT = 'Agent Inputs';

    @state() private _data: any[] = [];
    @state() private _usageDateRangeValue = "1";
    @state() private _usageTimeUnit = "day";

    @query('se-data-grid') private _dataGrid: SeDataGrid;
    @query('#chartCanvas') private _chartCanvas: HTMLCanvasElement;
    @query('#usageDateRange') private _usageDateRange: SelectEditorElement;

    @property({ type: Date }) startDate = startOfMonth(new Date());
    @property({ type: Date }) endDate = endOfDay(new Date());
    @property({ type: Array }) datasets: any = [];
    @property({ type: Array }) labels: string[] = [];
    @property({ type: Number }) totalCost: number = 0.0;

    private chart?: Chart;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._usageService = container.resolve(UsageService);
        this._toasterService = container.resolve(ToasterService);
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isLoggedIn) {
            return commands.redirect('/login');
        }        
        this.pageChange(location)
        if (location.params.pipeId) {
            this._pipeId = parseInt(location.params.pipeId.valueOf() as string);
        }
    }

    pageChange(location: RouterLocation) {
        const message = {
            type: 'pageChange',
            payload: { location: location.route },
        };
        window.postMessage(message, '*');
    }

    private async loadData() {
        // get pipe metadata
        //const pipeData = await this._pipeService.api.getPipeDataAsync(this._pipeId);
        //if (pipeData.isOk) {
        //    this._pipeName = pipeData.value.name;
        //}
        //else {
        //    this._toasterService.showUnexpectedError(pipeData.err.message);
        //}

        var orgId = this._authService.user.organizationId;

        var usageRequest = {
            orgId,
            pipeId: this._pipeId,
            timeUnit: this._usageTimeUnit,
            startDate: this.formatUTC(this.startDate),
            endDate: this.formatUTC(this.endDate),
            pageIndex: this._pageIndex,
            recordsPerPage: this._recordsPerPage,
            sortColumn: this._sortColumn,
            sortOrder: this._sortOrder
        };
        const result = await this._usageService.api.getOrgPipeUsageAsync(usageRequest);
        if (result.isOk) {
            this._data = result.value.pipesUsage;
            this._totalRecordCount = result.value.totalRecordCount;
            this.calculateDistribution();
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    /**
     * Update the distribution of the data based on the total cost
     */
    private calculateDistribution() {

        if (this._data == null || this.totalCost == null) {
            return;
        }

        this._data.forEach((item) => {
            // To calculate the distribution of each individual Pipe as a percentage of the overall total price
            item.distribution = (item.cost / this.totalCost) * 100
        });

        this.requestUpdate();
    }

    /**
     * Format the date to UTC in the format yyyy-mm-dd
     * 
     * @param date
     * @returns
     */
    private formatUTC(date: Date): string {

        const month = date.getUTCMonth() + 1; // Gets the month, zero-indexed, so add 1
        const day = date.getUTCDate(); // Gets the day of the month according to universal time

        // Pad the month and day with a zero if they are less than 10
        const formattedMonth = month < 10 ? '0' + month : month;
        const formattedDay = day < 10 ? '0' + day : day;

        return date.getUTCFullYear() + '-' + formattedMonth + '-' + formattedDay;
    }

    async loadRunUsageData() {
        try {
            const response = await this._usageService.api.getOrgPipeRunsUsageAsync(this._pipeId, this._usageTimeUnit, format(this.startDate, 'yyyy-MM-dd'), format(this.endDate, 'yyyy-MM-dd'));

            if (response.isOk) {
                var data = await response.value;
                if (data.labels !== null && data.labels.length > 0) {
                    this.labels = data.labels;
                    this.datasets = data.dataSets;
                    this.totalCost = data.totalCost;
                    this._pipeName = data.pipeName;
                } else {
                    this.labels = [];
                    this.datasets = data.dataSets;
                    this.totalCost = 0;
                }

                //force the grid to render again
                this._columns = null;
                this.calculateDistribution();

            } else {
                this._toasterService.showUnexpectedError(`Usage API request failed with status: ${response.value}`);
            }

        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    private async loadChart() {
        if (this._chartCanvas.getAttribute("prepared") == "true") {
            return;
        }
        this._chartCanvas.setAttribute("prepared", "true")
        var ctx = this._chartCanvas.getContext('2d');

        // Chart data
        var data = {
            labels: this.labels,
            datasets: this.getDataSets(),
        };

        // Chart options
        var options = {
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                x: {
                    stacked: true
                },
                y: {
                    stacked: true,
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Cost',
                        color: '#1a1f4b', //#4e4ef0
                        font: {
                            family: 'Helvetica',
                            size: 16,
                            weight: 'bold',
                        },
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const datasetLabel = context.dataset.label;// Get the label name
                            const value = context.parsed.y.toFixed(2); // Format the data to 6 decimal places
                            return `${datasetLabel}: $${value}`; // Combine label name and "$" cost value in the tooltip label
                        },
                    },
                },
            },
        };

        // Create Chart.js instance
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: options,
        });
    }
    private setCookie(name, value, minutes) {
        const now = new Date();
        now.setTime(now.getTime() + (minutes * 60 * 1000));
        const expires = "expires=" + now.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }
    private getCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    firstUpdated() {
        this._usageDateRangeValue = this.getCookie("usageDateRangeValue") || "1d";
        this.updateDateRange();
        this.loadChart();
    }

    updated(changedProperties: Map<string | number | symbol, unknown>): void {
        super.updated(changedProperties);
        if (changedProperties.has('labels')) {
            this.updateChart();
        }
    }

    private updateChart(): void {
        if (this.chart) {
            this.chart.data.labels = this.labels;
            this.chart.data.datasets = this.getDataSets();
            this.chart.update();
        }
    }

    private getDataSets() {
        var datasets = [];

        this.datasets.forEach((item) => {
            if (item.label == "RunUsage") {
                var dataset = {
                    label: this.RUN_USAGE,
                    data: item.data,
                    backgroundColor: ['#3F8CD9'],
                    borderColor: ['#3F8CD9'],
                    borderWidth: 1,
                };
                datasets.push(dataset);
            }

            if (item.label == "DataUsage") {
                var dataset = {
                    label: this.DATA_USAGE,
                    data: item.data,
                    backgroundColor: ['#93bfea'],
                    borderColor: ['#93bfea'],
                    borderWidth: 1,
                };
                datasets.push(dataset);
            }

            if (item.label == "InputCount") {
                var dataset = {
                    label: this.INPUT_COUNT,
                    data: item.data,
                    backgroundColor: ['#4E4EF0'],
                    borderColor: ['#4E4EF0'],
                    borderWidth: 1,
                };
                datasets.push(dataset);
            }

        });

        return datasets;
    }

    private updateDateRange(loadData = true) {

        //we may have to change the end date logic for Months filter once we have proper data.
        const today = new Date();
        switch (this._usageDateRangeValue) {
            case '1d':
                this.startDate = startOfMonth(today);
                this.endDate = endOfDay(today);
                this._usageTimeUnit = 'day';
                break;
            case '1m':
                this.startDate = startOfMonth(sub(today, { months: 1 }));
                this.endDate = endOfMonth(sub(today, { months: 1 }));
                this._usageTimeUnit = 'day';
                break;
            case '3m':
                this.startDate = startOfMonth(sub(today, { months: 3 }));
                this.endDate = endOfDay(today); //endOfMonth(sub(today, { months: 1 }));
                this._usageTimeUnit = 'day';
                break;
            case '6m':
                this.startDate = startOfMonth(sub(today, { months: 6 }));
                this.endDate = endOfDay(today);  //endOfMonth(sub(today, { months: 1 }));
                this._usageTimeUnit = 'day';
                break;
            case '1y':
                this.startDate = startOfYear(sub(today, { years: 1 }));
                this.endDate = endOfDay(today);
                this._usageTimeUnit = 'year';
                break;
            default:
                // Default to the current date range
                this.startDate = startOfDay(today);
                this.endDate = endOfDay(today);
                break;
        }

        this._totalRecordCount = 0;
        this._data = [];
        this.loadRunUsageData();
        if (loadData) {
            this.loadData();
        }
    }

    private handleDateChange() {
        this._usageDateRangeValue = this._usageDateRange.liveValue;
        this.setCookie("usageDateRangeValue", this._usageDateRange.liveValue, 15)
        this.updateDateRange();
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder;

        this._sortColumn = sortColumn;
        this._sortOrder = sortOrder;

        this.loadData();
    }
    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;

        this.loadData();
    }

    private filterByLabel(event: CustomEvent) {
        event.stopPropagation();
    }
    private filterByAgentTemplate(event: CustomEvent) {
        event.stopPropagation();
        this.loadData();
    }

    private getDistribution(value: number): TemplateResult {
        return html`
        <span style="display: flex; justify-content: flex-start;">
            <div class="distribution">
                <span style="height: 100%; width: ${value}%"></span>
            </div>
        </span>`
    }

    /**
     * Get the columns for the data grid
     * @returns
     */
    private getColumns() {
        if (this._columns == null || this._columns.length == 0) {

            //expand decimals places if ALL of the numbers are very small
            const decimalPlaces = this.totalCost >= 0.01 ? 2 : 3;

            this._columns = [
                {
                    field: 'date',
                    title: 'Date',
                    sortable: true,
                    align: 'left',
                    template: (row, col) => html`${format(new Date(row.created), 'MMM d, yyyy, h:mm a')}`
                },
                {
                    field: 'billingType', title: 'Type', sortable: false, template: (row, col) => html`${choose(row.billingType, [
                        [undefined || null, () => html``],
                        [BillingType.RunUsage, () => html`<span>${this.RUN_USAGE}</span>`],
                        [BillingType.DataUsage, () => html`<span>${this.DATA_USAGE}</span>`],
                        [BillingType.TotalPages, () => html`<span>Pages</span>`],
                        [BillingType.DynamicPages, () => html`<span>Dynamic Pages</span>`],
                        [BillingType.TotalRequests, () => html`<span>Requests</span>`],
                        [BillingType.ExportedData, () => html`<span>Exported Rows</span>`],
                        [BillingType.InputCount, () => html`<span>${this.INPUT_COUNT}</span>`],
                    ],
                        () => html`${row.billingType}`)}`
                },
                {
                    field: 'cost',
                    title: 'Cost',
                    sortable: true,
                    align: 'right',
                    template: (row, col) => html`<span class="numbers" style="font-feature-settings: var(--font-feature-numbers);">${'$' + row.cost.toFixed(decimalPlaces)}</span>`
                },
                {
                    field: 'distribution',
                    title: 'Distribution',
                    align: 'center',
                    template: (row, col) => html`${this.getDistribution(row.distribution)}`
                },
            ]
        }

        return this._columns;
    }

    render() {

        var displayCost = this.totalCost < 0.01 && this.totalCost != 0 ? this.totalCost.toFixed(4) : this.totalCost.toFixed(2);

        return html`
            <div class="body">
                <div class="container-header gradient" >
                    <div class="left-header top-header">
                        <div class="h1">${this._pipeName} Usage</div>
                    </div>
                </div>
                <div class="container-content">
                    <div class="table-header">
                        <div class="left-header">
                            <se-select-editor class="inputEditor" id="usageDateRange" .value="${this._usageDateRangeValue}" width="100%" name="usageDateRange" label="" labelPosition="top"
                                    @valueChanged=${this.handleDateChange}
                                    .options=${[
                { id: '1d', name: "This Month" },
                { id: '1m', name: "Last Month" },
                { id: '3m', name: "3 Months" },
                { id: '6m', name: "6 Months" },
                { id: '1y', name: "1 Year" }
            ]}>
                            </se-select-editor>
                            <!-- <se-select-editor class="inputEditor" id="destinationType" .placeholder="${"All Sources"}" .value="${"-1"}" width="100%" name="destinationType" label="" labelPosition="top"></se-select-editor>
                            <se-select-editor class="inputEditor" id="destinationType" .placeholder="${"All Destinations"}" .value="${"-1"}" width="100%" name="destinationType" label="" labelPosition="top"></se-select-editor> -->
                        </div>
                        <div class="right-header">
                            Total: <span class="h3"><strong>$${displayCost} </strong></span>
                        </div>
                    </div>
                    <div class="usage-list">
                        <div style="position: relative; height:30vh; flex:1;">
                            <canvas id="chartCanvas"></canvas>
                        </div>
                        <div class="labelsFilters">
                            <!-- <div class="market-item-label marketing-label"><span>Proxy Usage</span></div>-->
                            <div class="market-item-div"><div class="usage-color" style="background-color: #4E4EF0"></div><span>Agent Inputs</span></div> 
                            <div class="market-item-div"><div class="usage-color" style="background-color: var(--color-blue-50)"></div><span>Data Delivery</span></div>
                            <div class="market-item-div"><div class="usage-color" style="background-color: var(--color-status-blue)"></div><span>Server Time</span></div> 
                        </div>
                    </div>
                </div>
                <div class="container-content">
                        <div class="table-header">
                            <div class="left-header">
                                <span class="h1">Usage by Run</span>
                           </div>
                        </div>
                        <div class="table-list">
                            <se-data-grid .selectAllCheckbox=${true} class="grid" .rows=${this._data} .recordsPerPage=${this._recordsPerPage} .pageIndex=${this._pageIndex} .columns=${this.getColumns() } selectable @selectionchanged=${this.onGridSelectionChanged} @sortdata=${this.sortDataGrid} @filterbylabel=${this.filterByLabel} @filterbyAgentTemplate=${this.filterByAgentTemplate}></se-data-grid>
                        </div>
                        <div class="table-pagination" >
                            <se-pagination .dataType=${"Usages"} .recordsPerPage=${this._recordsPerPage} .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    .body{
        background-color: white;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .container-header{
        justify-content: space-between;
    }
    .usage-item-label::before {
        content: "";
        position: absolute;
        top: -5px;
        left: -10px;
        height: 30px;
        width: calc(100% + 20px);
        border-radius: 15px;
        z-index: -1;
    }
    .transparent-bg{
        background-color: #ffffff00;
    }
    .labelsFilters{
        display: flex;
        gap: 8px;
        flex-direction: column;
        place-content: center flex-start;
        align-items: center;
        height: 100%;
    }
    .market-item-div {
        padding: 15px;
        width: 200px;
        text-align: center;
        border-radius: 9px;
        border: 1px solid var(--color-gray-2, #D0D3DC);
        display: flex;
        gap: 15px;
    }
    .usage-color{
        border-radius: 50%;
        height: 15px;
        width: 15px;
        align-self: center;
    }
    .usage-list{
        display:flex;
        gap: 20px;
    }
    
  `]
    };
}
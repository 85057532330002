import { LitElement, html, css, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { BaseEditor, ObjectEditor } from './editors/base-editor';
import { container } from 'tsyringe';
import './components/primary-button.element';
import './components/secondary-button.element';
import './editors/input-editor.element';

import logo from '../../../assets/logoDark.png'
import twitter from '../../../assets/twitter.svg'
import linkedin from '../../../assets/linkedin.svg'
import instagram from '../../../assets/instagram.svg'
import { InputEditorElement } from './editors/input-editor.element';
import { AppConfigService } from '../services/app-config.service';
import { ToasterService } from '../services/toaster.service';
import { AuthService } from '../services/auth.service';
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { User } from '../models/user';
import Styles from '../../../assets/styles';

@customElement('se-reset-password')
export class ResetPasswordElements extends LitElement {    

    private _appConfigService: AppConfigService;
    private _toasterService: ToasterService;
    private _authService: AuthService;
    private _email: string;
    private _user: User;
    private _token: string;


    //@query('#email') private _emailEditor: InputEditorElement;
    @query('#email') private _emailEditor: HTMLLabelElement;
    @query('#password') private _passwordEditor: InputEditorElement;
    @query('#confirmPassword') private _comparePasswordEditor: InputEditorElement;

    @state() private _hasChanged = false;    

    constructor() {
        super();

        this._appConfigService = container.resolve(AppConfigService);
        this._toasterService = container.resolve(ToasterService);
        this._authService = container.resolve(AuthService);
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {                
        
        const userId = (new URLSearchParams(location.search)).get('id');
        if (userId != null) {
            this._token = (new URLSearchParams(location.search)).get('token');
            this.ValidateToken(userId, this._token)
        }
        return undefined;
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev))        
    }
    disconnectedCallback() {
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev))        
        super.disconnectedCallback();
    }

    private onEditorChanged(ev) {
        this._hasChanged = true;
    }   

    updateChangedValues() {        
    }

    show() {
        this.hidden = false;
    }
    hide() {
        this.hidden = true;
    }

    firstUpdated() {
        /*
        if (this._authService != null) {
            this._emailEditor.innerText = "Email: " +this._authService.user.email;
            //this._emailEditor.value = this._authService.user.email;
            //this._emailEditor.disabled = true;
        }
        */
    }
    private fillDefaults() {
        
    }

    private reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false)
                return false;
        }
        return true;
    }

    private async ValidateToken(userId: string, token: string) {

        const res = await this._authService.ValidateToken(userId, token);
        if (res.isOk) {
            this._toasterService.showSuccess("Token Succcesfully verified.");
            this._user = res.value;
            this._emailEditor.innerText = "Email: "+this._user.email;
            //this._emailEditor.value = this._user.email;
            
        }
        else
        {
            this._toasterService.showError("Link used to reset password is expired. Please use a valid link.");
        }


    }

    private async save(ev) {
        ev.preventDefault();        
        if (this.reportValidity()) {
            this._hasChanged = false;
            if (this._token != null) {
                await this._authService.ResetPassword(this._token, this._passwordEditor.liveValue)
                    .then(() => this.success()).catch(err => this.error(err));
            }
            /*
            else {
                await this._authService.ChangePassword(this._authService.user.id, this._passwordEditor.liveValue)
                    .then(() => this.success()).catch(err => this.error(err));
            }
            */
            //await this._authService.ResetPassword(this._emailEditor.liveValue, this._token, this._passwordEditor.liveValue)
            //    .then(() => this.success()).catch(err=>this.error(err));            
        }
    }

    private success() {
        this._toasterService.showSuccess("Password successfully updated. Please use the updated password to login.");
        this._authService.logout();
        Router.go("/login");
        //Router.go("/");
    }
    private async login(ev) {
        ev.preventDefault();
        Router.go("/login");

    }

    
    private async cancel(ev) {
        ev.preventDefault();
        history.back();
    }


    private error(err: Error) {
        alert(err.message);
        this._hasChanged = true;
    }

    passwordChanged() {
        if (this._passwordEditor.liveValue !== this._comparePasswordEditor.liveValue) {
            this._comparePasswordEditor.setCustomValidity("Passwords don't match.");
        }
        else {
            this._comparePasswordEditor.setCustomValidity("");
            this._comparePasswordEditor.reportValidity();
        }
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="scroll-container">                    

                  <div class="content"> 
                    <div style="text-align: center;margin-bottom: 10px;">
                        <img src=${logo} class="header-logo" />
                    </div>
                    <se-input-editor class="inputEditor" .customStyle=${{ alignItems: 'unset' }} @editorChanged=${this.passwordChanged} id="password" name="password" type="text" label="New Password" labelPosition="top" input-type="password" required ></se-input-editor>
                    <se-input-editor class="inputEditor" .customStyle=${{ alignItems: 'unset' }} @editorChanged=${this.passwordChanged} id="confirmPassword" name="confirmPassword" type="text"  label="Confirm Password" labelPosition="top" input-type="password" required ></se-input-editor>
                    <se-primary-button class="inputEditor loginButton" @click="${this.save}" ?disabled="${!this._hasChanged}" text="Save"></se-primary-button>
                  </div>  
                </div>
            </form>
            <div class="footer"><div>© 2022 Sequentum  •  <a href="https://www.sequentum.com/privacy-policy" target="_blank">Privacy Policy</a>  •  <a href="https://www.sequentum.com/terms-of-service" target="_blank">Terms of Service</a></div>
                <div style="text-align: right; flex:1">
                    <a href="https://twitter.com/sequentuminc" target="_blank"><img src=${twitter}></a>
                    <a href="https://www.linkedin.com/company/sequentum/" target="_blank"><img src=${linkedin}></a>
                    <a href="https://www.instagram.com/sequentuminc/" target="_blank"><img src=${instagram}></a>
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    .linkLabel {
        display: flex;
        flex: 2;
        padding-top: 15px;
        flex-direction: row;
    }
    .h3 {
        background-color: var(--color-primary);
        padding: 10px 10px 10px 10px;
    }
    .inputEditor{
        width: 100%;
    }
      `]
    };
}
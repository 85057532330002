import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import "element-internals-polyfill";
import { BaseEditor } from './base-editor';
import { styleMap } from 'lit/directives/style-map.js';
import arrowDown from '../../../../assets/arrow-down.svg'

@customElement('se-select-editor')
export class SelectEditorElement extends LitElement implements BaseEditor {

    @property() value: string;

    get liveValue() { return this._inputElement?.value ? this._inputElement?.value : undefined };
    get liveName() { return this._inputElement?.selectedOptions[0].textContent ? this._inputElement?.selectedOptions[0].textContent : undefined };

    @property({ attribute: false, type: Array }) options?: { id: string | number, name: string, disabled?: boolean }[];
    @property({ attribute: false, type: Array }) textOptions?: string[];

    @property() label?: string;
    @property() labelPosition?: 'left' | 'top' | 'right' | 'bottom' = 'top';

    @property({ type: Number }) step?: number;
    @property({ type: Number }) size?: number;
    @property({ type: Number }) minlength?: number;
    @property({ type: Number }) maxlength?: number;
    @property() width?: string;
    @property() min?: string;
    @property() max?: string;
    @property() pattern?: string;
    @property() placeholder?: string;
    @property({ type: Boolean }) readonly?: boolean;
    @property({ type: Boolean }) required = false;
    @property({ type: Boolean }) disabled = false;
    @property() name: string;
    @property({ attribute: 'editor-size' }) editorSize: 'small' | 'nomal';
    @property() gap;

    @query('#input') private _inputElement: HTMLSelectElement;

    constructor() {
        super();
    }

    updateValue(event) {
        if (this._inputElement.checkValidity())
            this._inputElement.classList.remove("invalid");
        else
            this._inputElement.classList.add("invalid");
        this.dispatchEvent(new CustomEvent("editorChanged", { bubbles: true, composed: true, detail: { editor: this } }))
        this.dispatchEvent(new CustomEvent("valueChanged", { bubbles: true, composed: true, detail: { editor: this, value: this.liveValue ? this.liveValue : undefined, oldValue: this.value } }))
    }
    reportValidity(): boolean {
        if (this._inputElement.reportValidity()) {
            this._inputElement.classList.remove("invalid");
            return true;
        }
        else {
            this._inputElement.classList.add("invalid");
            return false;
        }
    }

    firstUpdated() {
    }

    cancel() {
        this._inputElement.classList.remove("invalid");
        this.requestUpdate();
    }

    render() {
        const flexDirection = (
            (this.labelPosition === 'left' && 'row-reverse') ||
            (this.labelPosition === 'top' && 'column-reverse') ||
            (this.labelPosition === 'right' && 'column') ||
            (this.labelPosition === 'bottom' && 'row')
        );
        const align = (this.labelPosition === 'left' || this.labelPosition === 'right') ? 'center' : 'start';
        const style = { position: 'relative',display: 'flex', flexDirection: flexDirection, alignItems: align, gap: this.gap !== undefined ? this.gap : ((this.labelPosition === 'left' || this.labelPosition === 'right') ? '5px' : '2px') };
        const font = { font: this.editorSize === 'small' ? 'var(--font-input-small)' : 'var(--font-input)' };
        return html`
            <div style="${styleMap({ ...style, ...font, width: this.width !== undefined ? this.width : undefined })}">
                <select style="${styleMap(font)}" id="input" name="${this.name}"
                    @change="${this.updateValue}"                
                    min="${ifDefined(this.min)}"
                    max="${ifDefined(this.max)}"
                    maxlength="${ifDefined(this.maxlength)}"
                    minlength="${ifDefined(this.minlength)}"
                    step="${ifDefined(this.step)}"
                    size="${ifDefined(this.size)}"
                    pattern="${ifDefined(this.pattern)}"                    
                    ?required="${this.required}"
                    ?disabled="${this.disabled}"
                >
                ${this.placeholder ? html`<option value="" disabled selected hidden>${this.placeholder}</option>` : ``}
                ${this.textOptions ? this.textOptions.map(option => html`
                    <option value="${option}" ?selected=${this.value === option}>${option}</option>
                `) : this.options?.map(option => html`
                    <option value="${option.id}" ?disabled=${option?.disabled || false} ?selected=${this.value === (option.id?.toString() ?? '')}>${option.name}</option>
                `)}
                </select>
                <img class="iconImage" src="${arrowDown}"/>

                ${this.label ? html`<label for="input">${this.label}</label>` : ``}
            </div>
        `;
    }

    static styles = css`
    :host {
        display: block;
        color:#1A1F4B
    }
    :host([hidden]) {
        display: none;
    }
    select {
        font: var(--font-input);
        border-radius: 4px;
        padding: 9px 13px;
        border: 1px solid #94979D;
        width: 100%;
        background: rgb(237, 241, 251);
        color:#1A1F4B;
        border: none;
    }
    select::-ms-expand {
        display: none;
    }
    .iconImage {
        position: absolute;
        transform: translate(0%, -50%);
        top: 50%;
        right: 15px;
        width: 15px;
    }
    select {
        font: var(--font-input);
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    label {
        font: var(--font-input-label);
        margin-bottom: 4px;
    }
    input:disabled+label {
        color:gray;
    }
    input.invalid {
        outline: 2px solid pink;
    }
  `;
}


import { LitElement, TemplateResult } from "lit";
import { MenuItem } from "./context-menu.element";

export interface DataGridColumn {
    name?: string;
    title?: string;
    field?: string;
    sortable?: boolean;
    breakAtPipe?: boolean;
    cellStyle?: any;
    cellClass?: any;
    headerStyle?: any;
    align?: 'left' | 'center' | 'right';
    icon?: ((row?: any, col?: DataGridColumn) => string) | string;
    iconColor?: string;
    link?: (row?: any, col?: DataGridColumn) => string;
    action?: (row?: any, col?: DataGridColumn) => void;
    menu?: (row?: any, col?: DataGridColumn) => MenuItem[];
    template?: (row?: any, col?: DataGridColumn) => TemplateResult;
    getValue?: (row?: any, col?: DataGridColumn) => any;
    setValue?: ((newValue: any, row?: any, col?: DataGridColumn) => { success: boolean, errorMessage?: string }) | ((newValue: any, row?: any, col?: DataGridColumn) => Promise<{ success: boolean, errorMessage?: string }>);
    component?: string;
    button?: (row?: any, col?: DataGridColumn) => void;
    editor?: 'none' | 'text' | 'text-area' | 'select' | 'checkbox' | 'typeahead' | 'number';        
    preformatted?: boolean;
    options?: { id: number | string; name: string }[];
    textOptions?: string[];
    isDelete?: boolean;
    deleteRow?: ((row?: any, col?: DataGridColumn) => boolean) | ((row?: any, col?: DataGridColumn) => Promise<boolean>);
    hidden?: boolean;
    description?: string;
    checkbox?: boolean;
}

export class DataGridTemplate extends LitElement {
    row: any;
    column: DataGridColumn;
}
import { css, html, LitElement } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { container } from 'tsyringe';
import Styles from '../../../../assets/styles';
import { PipeStreamViewModel } from '../../models/pipe-stream-view-model';
import { PipeService } from '../../services/pipe.service';
import { MarketplaceService } from '../../services/marketplace.service';
import { ToasterService } from '../../services/toaster.service';
import { DataGridColumn } from './data-grid-template';
import { SeDataGrid } from './data-grid.element';

@customElement('se-details-streams-tab')
export class DetailsSchemaButton extends LitElement {
    private _pipeService: PipeService;
    private _marketplaceService: MarketplaceService;
    private _pageIndex: number = 1;
    private _recordsPerPage: number = 1000;
    private _pipeId?: number;
    private _toasterService: ToasterService;
    private _columnsSchema: DataGridColumn[] = [];
    private deferredUpdates: ((pipeId: number) => Promise<any>)[] = [];


    @state() private _streams: Array<PipeStreamViewModel> = [];

    @property() pipeId: string;
    @property() sourceId: string;
    @property() isSetupNewPipe: boolean = false;
    @property() isNewSource: boolean = false;
    get getDeferredUpdates() { return this.deferredUpdates };


    @query('.schema-grid') private _schemaGrid: SeDataGrid;



    constructor() {
        super();
        this._pipeService = container.resolve(PipeService);
        this._marketplaceService = container.resolve(MarketplaceService);
        this._toasterService = container.resolve(ToasterService);

    }
    connectedCallback() {
        super.connectedCallback();
        this.loadData();
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();

        this.loadData();
    }
    private filterByLabel(event: CustomEvent) {
        event.stopPropagation();
    }
    private filterByAgentTemplate(event: CustomEvent) {
        event.stopPropagation();
        this.loadData();
    }


    //disable or enable a command
    private disableCommand(command) {
        if (command.Disabled == !true) {
            command.Disabled = !false;
            if (command._original_ref) {
                command._original_ref.Disabled = !false;
            }
        } else {
            command.Disabled = !true;
            if (command._original_ref) {
                command._original_ref.Disabled = !true;
            }
        }
    }


    /**
     * Called when the user clicks on the toggle button
     * @param row
     */
    private async updateCommand(row?: any) {
        if (row) {
            //flip the setting
            row.isDisabled = !row.isDisabled;
        }

        //update on the server

        if (this._pipeId) {
            if (this.isSetupNewPipe) {
                this.addDeferredUpdate();
            } else {
                var updateResponse = await this._pipeService.api.updatePipeStreamsAsync(this._pipeId, this._streams);
                if (!updateResponse.isOk) {
                    //an error occured
                    this._toasterService.showUnexpectedError(updateResponse.err.message);
                }
            }

        }

    }  


    private addDeferredUpdate() {
       this.deferredUpdates.push(async (pipeId: number) => {
            return await this._pipeService.api.updatePipeStreamsAsync(pipeId, this._streams);
        });
    }



    /**
     * Load the data for the page
     */
    private async loadData() {

        this._columnsSchema = [
            { title: '', template: (row, col) => html`<se-toggle .value=${!row.isDisabled} @valueChanged=${e => this.updateCommand(row)} ></se-toggle>` },
            { field: 'name', title: 'Name' }
        ]

        // get the pipe streams
        if (this.pipeId) {
            this._pipeId = Number(this.pipeId)

            var streamsResponse = null;

            if (this.isSetupNewPipe && this.isNewSource) {
                streamsResponse = await this._marketplaceService.api.getSourceStreamsAsync(this._pipeId);
            } else {
                streamsResponse = await this._pipeService.api.getPipeStreamsAsync(this._pipeId);
            }

            if (streamsResponse.isOk) {
                this._streams = streamsResponse.value
            }
            else {
                this._toasterService.showUnexpectedError(streamsResponse.err.message);
            }
        }


    }

    render() {
        return html`
                        <div class="table-list">
                            <se-data-grid class="grid schema-grid" .rows=${this._streams} .recordsPerPage=${this._recordsPerPage} .pageIndex=${this._pageIndex} .columns=${this._columnsSchema} @sortdata=${this.sortDataGrid} @filterbylabel=${this.filterByLabel} @filterbyAgentTemplate=${this.filterByAgentTemplate}></se-data-grid>
                        </div>
                        `;
    }

    firstUpdated() {

    }
    
 
    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        background:none;
        color:#1A1F4B
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .body {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
    }
    .time-section{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .action-button{
        margin-bottom: -75px;
        display: flex;
        justify-content: space-between;
    }
    .config-tab{
        max-width: 700px;
        width: 100%;
    }
    .action-button .inputEditor{
         width:100px;
    }
    .grid {
        flex:1;        
    }   
    .checkbox {
        width: 1rem;
        height: 1rem;
    }
    input[type="checkbox"]:checked {
        background-color: var(--color-secondary);
    }
    .left-header{
        flex: 1 1 0%;
        max-width: 600px;
        padding-right: 50px;
    }

      `]
    };

}


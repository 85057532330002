import { container, inject,singleton } from 'tsyringe';
import { integer } from 'vscode-languageserver-protocol';
import { User } from '../models/user';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';
import { UsersViewModel } from '../models/users-view-model';
import { UserSpaces } from '../models/user-spaces';
import { UserLabels } from '../models/user-labels';


@singleton()
export class UserApi extends BaseApi {

    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getAsync(id: number) {
        return this.getObjectAsync<User>(`/api/user/${id}`, this._authService.token);
    }
    
    //async ResetPassword(userId: integer, email: string, password: string) {
    //    return this.postObjectAsync(`/api/user/${userId}/reset-password`, { email: email, password: password }, this._authService.token);

    //}

    async getSpacesAsync(pageIndex = 1, recordsPerPage = 100, sortColumn: string = '', sortOrder: number = -1) {
        return this.getObjectAsync<UserSpaces>(`/api/user/spaces?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`, this._authService.token);
    }

    async getLabelsAsync(spaceId: number, pageIndex = 1, recordsPerPage = 100, sortColumn: string = '', sortOrder: number = -1) {
        return this.getObjectAsync<UserLabels>(`/api/user/labels/space/${spaceId}?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`, this._authService.token);
    }

    async getHomeLabelsAsync(pageIndex = 1, recordsPerPage = 100, sortColumn: string = '', sortOrder: number = -1) {
        return this.getObjectAsync<UserLabels>(`/api/user/labels/space/home?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`, this._authService.token);
    }

    async getAllUsersAsync(searchRequest: any) {
        return this.postObjectReturnObjectAsync<UsersViewModel>(`/api/user/search`, searchRequest, this._authService.token);
    }

    async createAsync(user: User) {
        return this.postObjectAsync(`/api/user`, user, this._authService.token);
    }

    async deleteAllAsync(userIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/user/all`, { ids: userIds }, this._authService.token);
    }

    async deleteAsync(id: number) {
        return this.deleteObjectAsync(`/api/user/${id}`, this._authService.token);
    }

    async restoreAccessAsync(userId: number) {
        return this.putAsync(`/api/user/${userId}/restore`, this._authService.token);
    }


    async ChangePassword(userId: integer, currentPassword: string, newPassword: string) {

        return this.postObjectAsync(`/api/user/change-password`, { userId: userId, currentPassword: currentPassword, newPassword: newPassword}, this._authService.token);

    }

    async ResetPassword(token: string, password: string) {
        return this.postObjectAsync(`/api/user/reset-password`, { token: token, password: password }, this._authService.token);
    }
    async updateAsync(userId: number, user: User) {
        return this.putObjectAsync(`/api/user/${userId}`, user, this._authService.token);
    }



}
import { LitElement, html, css } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { Route, Router } from '@vaadin/router';
import './home.element';
import './login.element';
import './forgot-password.element';
import './reset-password.element';
import './change-password.element';
import './user-registration.element';
import { RouterService } from '../services/router-service';
import { container } from 'tsyringe';
import { AppConfigService } from '../services/app-config.service';

@customElement('se-marketplace')
export class MarketplaceElement extends LitElement {    

    private _routerService: RouterService;
    private _appConfigService: AppConfigService;

    @query('#outlet') private _outlet: HTMLDivElement;

    constructor() {
        super();

        this._appConfigService = container.resolve(AppConfigService);
        this._routerService = container.resolve(RouterService);
    }

    connectedCallback() {
        super.connectedCallback();        
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    
    firstUpdated() {                
        this._routerService.initialize(this._outlet);
        if (this._appConfigService.isNew) {
            Router.go('/register');
        }
    }

    isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    render() {
        return html`
            <div id="outlet" class="outlet"></div>
            
        `;
    }

    static styles = css`
    :host {
        display: block;
        background-color: var(--color-primary);        
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .outlet {        
        height: 100%;
    }
    .mobile-message {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-family: var(--font);
        font-size: 5em;
        color: white;
    }
    .mobile-message p {
        padding: 10%;
    }
  `;
}
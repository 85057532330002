import { container, inject, singleton } from 'tsyringe';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';
import { DocumentationViewModel } from '../models/documentation-view-model';


@singleton()
export class DocumentationApi extends BaseApi {
    private _authService: AuthService;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getDocumentationAsync(url: string) {
        return this.getObjectAsync<DocumentationViewModel>(`/api/documentation?documentUrl=${url}`, this._authService.token);
    }

}
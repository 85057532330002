import { container, inject, singleton } from 'tsyringe';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';
import { MarketplaceItemsViewModel } from '../models/marketplace-items-view-model';
import { MarketplaceItemViewModel } from '../models/marketplace-item-view-model';
import { PipeStreamViewModel } from '../models/pipe-stream-view-model';


@singleton()
export class MarketplaceApi extends BaseApi {
    private _authService: AuthService;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }
    async callbackgoogle(code: string) {
        return this.postObjectAsync(`/api/marketplace/Callbackgoogle`, code, this._authService.token);
    } 
    async getAllMarketplaceAsync() {
        return this.getObjectAsync<MarketplaceItemsViewModel>(`/api/marketplace`, this._authService.token);
    }
    async getSourceAsync(sourceId: number) {
        return this.getObjectAsync<MarketplaceItemViewModel>(`/api/marketplace/source/${sourceId}`, this._authService.token);
    }
    async getSourceStreamsAsync(sourceId: number) {
        return this.getObjectAsync<Array<PipeStreamViewModel>>(`/api/marketplace/source/${sourceId}/streams`, this._authService.token);
    }

}
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { container } from 'tsyringe';
import Styles from '../../../assets/styles';
import { SourceService } from '../services/source.service';
import { ToasterService } from '../services/toaster.service';
import './change-password.element';
import './components/primary-button.element';
import './components/secondary-button.element';
import './forgot-password.element';
import './login.element';
import './reset-password.element';
import './user-registration.element';
import { AuthService } from '../services/auth.service';


@customElement('se-source-details')
export class SourceDetailsElement extends LitElement {
    private _authService: AuthService;

    private _toasterService: ToasterService;
    private _sourceService: SourceService;

    private _sourceId?: number;
    @state() private _sourceName?: string;
    @state() private _sourceIcon?: string;


    @state() private _selectedTab: string = "history";    
    @state() private _showPopup: boolean = false;

    @state() private _hasSelectedRows = false;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._sourceService = container.resolve(SourceService);
    }
   

    connectedCallback() {
        super.connectedCallback();
        this.loadData();
    }
    disconnectedCallback() {

        super.disconnectedCallback();
    }


    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isUser) {
            return commands.redirect('/login');
        }        
        if (location.params.sourceId) {
            this._sourceId = parseInt(location.params.sourceId.valueOf() as string);
        }
    }


    public onAfterEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): void {
        this._selectedTab = location.params.tab ? (location.params.tab.toString()) : "history";
        
    }


    firstUpdated() {
        history.replaceState(null, null, '/sources/' + this._sourceId + '/' + this._selectedTab);
    }






    private async loadData() {


        // get pipe info and its sources
        const currentSource = await this._sourceService.api.getSourceAsync(this._sourceId);
        if (currentSource.isOk) {
            this._sourceName = currentSource.value.name;
            this._sourceIcon = currentSource.value.icon;
        }
        else {
            this._toasterService.showUnexpectedError(currentSource.err.message);
        }

    }

    private tabHistory() {
        this._selectedTab = "history"
        history.pushState(null, null, '/sources/' + this._sourceId + '/' + this._selectedTab);
    }
    private tabAgents() {
        this._selectedTab = "agents"
        history.pushState(null, null, '/sources/' + this._sourceId + '/' + this._selectedTab);
    }

    private linkDestination() {
        Router.go(`/wizard?currentStep=2&source=${this._sourceId}`);
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">

                <div class="container-header gradient">

                    <div class="left-header top-header">
                        <div class="source-container">
                            <div class="header-image-container">
                                <img class="header-image" src="${this._sourceIcon}" />
                            </div>
                            <div class="market-item-title">
                                <div class="h2">${this._sourceName}</div>
                                <div class="market-item-label-square">
                                    <span>Source</span>
                                </div>
                                    

                            </div>
                        </div>
                    </div>
                    <div class="right-header">
                        <se-primary-button class="inputEditor" @click=${this.linkDestination} .customStyle=${{}} text="Create Agent"></se-primary-button>
                    </div>
                    
                        
                </div>
                <div class="tabs-header">
                                <div @click=${this.tabHistory} class="tabs ${classMap({ "active-tab": this._selectedTab == "history" })}">History</div>
                                <div @click=${this.tabAgents} class="tabs ${classMap({ "active-tab": this._selectedTab == "agents" })}">Agents</div>
                </div>
                <div class="container-content">

                    ${(this._selectedTab == "history") ?
                        html`<se-details-history-tab .sourceId=${String(this._sourceId)} .showPipeName=${true}></se-details-history-tab>` : ``
                    }
                    ${(this._selectedTab == "agents") ?
                        html`<se-details-pipes-tab .sourceId=${String(this._sourceId)}></se-details-pipes-tab>`: ``
                    }


                </div>

            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font); 
        background:none;
        color:#1A1F4B
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .body {
        display: flex;
        height: 100%;
        flex-direction: column;
    }
    .inputEditor{
        width: 200px;
    }
    .transparent-bg{
        background-color: #ffffff00;
    }

    .action-button{
        margin-bottom: -75px;
        display: flex;
        justify-content: space-between;
    }
    .right-action-button{
        display:flex;
        gap: 10px;
    }
    .scroll-container{
        border: none;
        overflow: auto;
    }
    .config-tab{
        margin: auto;
        max-width: 1000px;
        width: 100%;
        padding: 10px 50px;
    }

    .price-reviews{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 18px;
    }

    .reviews{
        display: flex;
        flex-direction: row-reverse;
        gap: 10px;
    }
    .stars{
        display: flex;
        gap: 5px;

    }
    .body-content{
        display: flex;
        gap: 90px
    }
    .source-text-content{
        background-color: white;
        padding: 30px 65px;
        border-radius: 6px;
    }
        .menu-item a {
        text-decoration: none;
    }
    .menu-text {
        flex:1;        
        display: block;
        padding: 5px 0px 5px 40px;
        color: #585A5F;
        border-left: 3px solid #ffffff00;
    }
    .buckets{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .labels {        
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 15px;
    }

    .left-body{
        width: 80%;
    }
    .right-body{

    }
    .setup-source {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .labels-header{
        padding-left: 43px;
    }
    @media (min-width: 992px) {
        .market-item{
            -ms-flex: 0 0 50% !important;
            flex: 0 0 50% !important;
            max-width: 50% !important;
        }
    }

    @media (min-width: 1500px) {
        .market-item{
            -ms-flex: 0 0 33% !important;
            flex: 0 0 33% !important;
            max-width: 33% !important;
        }
    }
    .market-item{
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding: 15px;
    }

     .market-item-content{
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border-radius: 16px;
        padding: 0px 35px 10px;
        background-color: white;
        border: 1px solid #D0D3DC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .market-item-header{
        display:flex;
    }
    .market-item-image{
        flex: 0 0 100px;
        max-width: 100px;
        position: relative;
        width: 100%;
        min-height: 1px;
    }
    .market-item-price{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 30px;
        gap: 10px;
    }
    .market-item-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .labelsFilters{
        display: flex;
        gap: 10px;
        flex-direction: row;
        padding: 0px 20px;
    }
    .price-reviews{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 15px;
    }
    .header {
        margin-left: 5px;
        display: flex;
        align-items: end;
        justify-content: space-between;
        overflow:hidden;
        padding-right: 5px;
        margin-right: -5px;
        padding-bottom: 5px;
        margin-bottom: -5px;
    }
    .grid {
        flex:1;        
    }   
    .checkbox {
        width: 1rem;
        height: 1rem;
    }
    input[type="checkbox"]:checked {
        background-color: var(--color-secondary);
    }
    .label {
        background-color: dimgray;
        border-radius: 3px 3px;
        font: var(--font-smaller);
    }

      `]
    };
}

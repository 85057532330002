import { container, inject, singleton } from 'tsyringe';
import { err, NetworkError, ok, Result } from '../../utils/result';
import { SeEvent } from '../../utils/se-events';
import { AppConfigApi } from './app-config.api';
import { SupportApi } from './support.api';
import { UserApi } from './user-api';

@singleton()
export class SupportService {

    private _supportApi: SupportApi;

    get api() { return this._supportApi };
    
    constructor() {        
        this._supportApi = container.resolve(SupportApi);
    }    


}
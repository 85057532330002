import { AccessLevel } from "../enums/access-level";

export class User {
        
    constructor() {
    }

    public id: string;
    public email: string;    
    public name: string;    
    public organizationName: string;
    public organizationId: string;
    public accessLevel: AccessLevel;
}

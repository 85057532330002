import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import Styles from '../../../../assets/styles';
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { container } from 'tsyringe';
import { AuthService } from '../../services/auth.service';
import { ToasterService } from '../../services/toaster.service';
import { BillingService } from '../../services/billing.service';
import { format, endOfDay, startOfMonth } from 'date-fns';
import './billing.element';

@customElement("se-billing-overview")
export class BillingOverviewElement extends LitElement {

    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _billingService: BillingService;

    @property({ type: String }) tabName = 'overview';
    @property({ type: Date }) startDate = startOfMonth(new Date());
    @property({ type: Date }) endDate = endOfDay(new Date());

    @state() private _totalCost: any;
    @state() private availableCredits: number = 0.0;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._billingService = container.resolve(BillingService);
    }

    connectedCallback() {
        super.connectedCallback();
        this.loadData();
        this.loadAvailableCredits();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isAdmin) {
            return commands.redirect('/login');
        }        
    }

    firstUpdated() {
    }

    pageChange(location: RouterLocation) {
        const message = {
            type: 'pageChange',
            payload: { location: location.route },
        };
        window.postMessage(message, '*');
    }

    private async loadData() {

        var orgId = this._authService.user.organizationId;

        const result = await this._billingService.api.getTotalUsageCostAsync(orgId, format(this.startDate, 'yyyy-MM-dd'), format(this.endDate, 'yyyy-MM-dd'));
        if (result.isOk) {
            this._totalCost = result.value;
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
        this.requestUpdate();
    }

    private async loadAvailableCredits() {

        var orgId = this._authService.user.organizationId;

        const result = await this._billingService.api.getAvailableCreditsAsync(orgId);
        if (result.isOk) {
            this.availableCredits = Math.abs(Number(result.value));
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
        this.requestUpdate();
    }

    render() {

        return html`
            <div class="body">
                <se-billing .tabName=${this.tabName}></se-billing>
                <div class="container-content">
                    <div class="form" style="margin: 10px;">
                        <span class="h2">Pay as you go</span>
                        <div><span class="h3"><b>Available Credits:</b></span>
                        <span class="h2" style="padding-left:5px">$${this.availableCredits}</span></div>
                        <div><span class="h3"><b>Pending invoice:</b></span>
                        <span class="h2" style="padding-left:5px">$${this._totalCost}</span></div>
                        You'll be billed at the end of each calendar month for usage during that month.
                    </div>
                </div>
            </div>    
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        background:none;
        color:#1A1F4B
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .body {
        display: flex;
        flex-direction: column;
        background-color: white;
    }
    .time-section{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .action-button{
        margin-bottom: -75px;
        display: flex;
        justify-content: space-between;
    }
    .config-tab{
        max-width: 700px;
        width: 100%;
    }
    .action-button .inputEditor{
         width:100px;
    }
    .grid {
        flex:1;        
    }   
    .checkbox {
        width: 1rem;
        height: 1rem;
    }
    input[type="checkbox"]:checked {
        background-color: var(--color-secondary);
    }
    .left-header{
        flex: 1 1 0%;
        max-width: 250px;
        padding-right: 50px;
    }
      `]
    };
}
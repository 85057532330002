import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import Styles from '../../../../assets/styles';
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { AuthService } from '../../services/auth.service';
import { ToasterService } from '../../services/toaster.service';
import { BillingService } from '../../services/billing.service';
import { container } from 'tsyringe';
import { PaymentCards } from '../../models/payment-cards';

@customElement("se-billing-payment")
export class BillingPaymentElement extends LitElement {

    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _billingService: BillingService;

    @state() private _showModal = false;
    @state() private _isCustomerExist = false;
    @state() private _isFirstPaymentCard = true;
    @state() private _pmId = "";
    @state() private _orgBilling: any;
    @state() private _paymentCards: PaymentCards[] = [];

    @property({ type: String }) tabName = 'payment-method';

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._billingService = container.resolve(BillingService);
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('message', this.handleMessage.bind(this));
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('message', this.handleMessage.bind(this));
    }

    firstUpdated() {
        this.getOrCreateCustomer();
        this.loadPaymentCards();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isAdmin) {
            return commands.redirect('/login');
        }        
        this.pageChange(location)
    }

    pageChange(location: RouterLocation) {
        const message = {
            type: 'pageChange',
            payload: { location: location.route },
        };
        window.postMessage(message, '*');
    }

    async getOrCreateCustomer() {

        const result = await this._billingService.api.getOrCreateCustomer();
        if (result.isOk) {
            this._orgBilling = result.value;
            this._isCustomerExist = true;
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    async loadPaymentCards() {

        var orgId = this._authService.user.organizationId;
        const result = await this._billingService.api.getPaymentCards(orgId);
        if (result.isOk) {
            this._paymentCards = result.value;

            if (this._paymentCards.length != 0)
                this._isFirstPaymentCard = false;
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    // Event listener for the postMessage event
    handleMessage(event) {
        if (event.data.type === 'closeIframe') {
            const status = event.data.status;
            if (status !== null)
                this.handleCloseIframe(status, event.data.message);

            // Optionally, close the iframe or perform other actions
            this.closePaymentModal();
        }
    }

    // Handle closing the iframe based on the status
    handleCloseIframe(status, message) {
        if (status === 'success') {
            // Handle success
            this._toasterService.showSuccess(message);
        } else if (status === 'error') {
            // Handle failure
            this._toasterService.showError(message);
        }
        else if (status === 'warning') {
            // Handle warning
            this._toasterService.showWarning(message);
        }
    }

    // Function to close the modal/iframe
    closePaymentModal() {
        const paymentIframe = this.shadowRoot.getElementById('paymentIframe');
        if (paymentIframe) {
            paymentIframe.style.display = 'none';
        }
        this._showModal = false;
        this._pmId = null;
        this.loadPaymentCards();
    }

    private handlePayment() {
        this._showModal = true;
        this.requestUpdate();
    }

    private closeModal() {
        this._showModal = false;
        this.requestUpdate();
    }

    private editPaymentCard(id: any) {
        this._showModal = true;
        this._pmId = id;
        this.requestUpdate();
    }

    private async setDefaultPaymentCard(id: any) {

        this._pmId = id;
        var orgId = this._authService.user.organizationId;

        const requestJson = {
            customerId: this._orgBilling.customerId,
            orgId,
            pmId: this._pmId
        }

        const result = await this._billingService.api.setDefaultPaymentMethod(requestJson);
        if (result.isOk)
            this._toasterService.showSuccess("Default payment method updated successfully...");
        else
            this._toasterService.showUnexpectedError(result.err.message);

        this.loadPaymentCards();
    }

    private async deletePaymentCard(id: any) {

        this._pmId = id;
        var orgId = this._authService.user.organizationId;

        const result = await this._billingService.api.deletePaymentCard(orgId, this._pmId);

        if (result.isOk)
            this._toasterService.showSuccess("Successfully Deleted Payment Card...");
        else
            this._toasterService.showUnexpectedError(result.err.message);

        this.loadPaymentCards();
    }

    render() {

        return html`
            <div class="body">
                <se-billing .tabName=${this.tabName}></se-billing>
                <div class="container-content">
                    <div class="form" style=" width: 200px; margin: 10px;">
                        ${(this._paymentCards) ? html` 
                            ${this._paymentCards.map(item => html`
                                <div class="market-item">
                                    <div class="market-item-content">
                                        <div class="market-item-header" style="cursor:default;" > <!-- @click=${() => { this.editPaymentCard(item.pmId) }} -->
                                            <div class="image-container">
                                                <img class="market-item-image" src="${item.icon}" />
                                            </div>
                                            <div class="market-item-title">
                                                <div class="h2">****${item.last4}</div>
                                                <div class="h6">Expires ${item.expMonth}/${item.expYear}</div>
                                            </div>
                                        </div>
                                        <div class="market-item-footer">
                                            ${item.isDefaultPayment == true ? html`
                                                <span style="gap: 5px;display: flex;">
                                                    <div class="h3 market-item-summary market-item-label-square"><span>Default</span></div>
                                                </span>`: html` 
                                                <div style="width: 200px;">
                                                    <button class="btn btn-color" @click="${() => { this.setDefaultPaymentCard(item.pmId) }}"><b>Set as default</b></button>
                                                </div>
                                                <div style="padding-right:20px">
                                                    <button class="btn btn-color" @click="${() => {this.deletePaymentCard(item.pmId)}}"><b>Delete</b></button>
                                                </div>`
                                            }
                                        </div>
                                    </div>
                                </div>
                            `)}`
                        : html``}
                        <div class="action-buttons"><se-primary-button @click="${this.handlePayment}" ?disabled=${!this._isCustomerExist} text="Add Payment"></se-primary-button></div>
                    </div>
                </div>
                ${this._showModal
                ? html`
                    <div id="paymentIframe" class="modal">
                        <div class="modal-content">
                            <!-- <span class="close" @click="${this.closeModal}">&times;</span>  -->
                            <iframe src="/billing-payment.html?customer_id=${this._orgBilling.customerId}&firstPaymentCard=${this._isFirstPaymentCard}&pmId=${this._pmId}" frameborder="0" style="width: 560px; height: 570px;">
                            </iframe>
                        </div>
                    </div>
                    `
                : ''}
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        background:none;
        color:#1A1F4B
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .body {
        display: flex;
        flex-direction: column;
        background-color: white;
        position: relative;
    }
    .time-section{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .action-button{
        margin-bottom: -75px;
        display: flex;
        justify-content: space-between;
    }
    .config-tab{
        max-width: 700px;
        width: 100%;
    }
    .action-button .inputEditor{
         width:100px;
    }
    .grid {
        flex:1;        
    }   
    .checkbox {
        width: 1rem;
        height: 1rem;
    }
    input[type="checkbox"]:checked {
        background-color: var(--color-secondary);
    }
    .left-header{
        flex: 1 1 0%;
        max-width: 250px;
        padding-right: 50px;
    }
    .market-item{
        max-width: 100%;
        position: relative;
        width: 100%;
        min-height: 1px;
    }
    .market-item-content{
        height: 120px;
        width: 290px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border-radius: 16px;
        padding: 0px 35px 10px;
        background-color: white;
        border: 1px solid #D0D3DC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
   .market-item-header{
        display:flex;
    }
    .market-item-title{
        position: relative;
        width: 100%;
        min-height: 1px;
        padding: 0px 0px 0px 40px;
        align-self: center;
    }
    .market-item-image{
        height: 100%;
        width: 100%;
    }
    .image-container{
        display: flex;
        justify-content: center;
        width: 80px;
        height: 40px;
        padding-top: 10px;
    }
    .market-item-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .market-item-price{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 30px;
        gap: 10px;
    }
    .market-item-label {
        position: relative;
        display: inline-block;
        z-index: 0;
        width: 120px;
        text-align: center;
    }
    .market-item-label span {
        position: relative;
        z-index: 1;
        color:#1A1F4B;
    }
    .market-item-label::before {
        content: "";
        position: absolute;
        top: -6px;
        left: 0px;
        height: 37px;
        width: calc(100%);
        border-radius: 22px;
        z-index: -1;
        border-width: 2px !important;
    }
    .market-item-label-square {
        margin: 0px 7px;
        position: relative;
        display: inline-block;
        z-index: 0;
        text-align: center;
        font: var(--font-small);
        width: fit-content;
    }
    .market-item-label-square span {
        position: relative;
        z-index: 1;
        color:#1A1F4B;
    }
    .market-item-label-square::before {
        content: "";
        position: absolute;
        top: -2px;
        left: -7px;
        height: 22px;
        width: calc(100% + 14px);
        z-index: -1;
        border-radius: 3px;
        border-width: 1px !important;
    }
    .marketing-label::before {
        background: rgba(86, 204, 242, 0.2);
        border: solid rgb(47, 128, 237);
    }
    .modal {
        display: flex; /* Hidden by default */
        position: absolute; /* Stay in place */
        z-index: 10; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100vh; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black with opacity */
    }
    .modal-content {
        display: inline-block;
        background-color: #fefefe;
        margin: auto;
        border: 1px solid #888;
        width: auto;
        height: min-content;
        border-radius: 8px;
        padding: 10px;
    }
    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
        cursor: pointer;
    }
    .close:hover,
    .close:focus {
        color: black;
        text-decoration: none;
    }
    .btn {
        border: none;
        background-color: inherit;
        padding: 5px 3px;
        font-size: 14px;
        font-style: inherit;
        cursor: pointer;
        display: inline-block;
    }
    .btn:hover {
        background: #cee1f4; 
        border-radius: 15px;
        border-width: 1px !important;
    }
    .btn-color {color: green}
    /*.btn-del-color {color: red}*/
      `]
    };
}
import { container, inject, singleton } from 'tsyringe';
import '../elements/components/toaster.element';
import { SeToasterElement } from '../elements/components/toaster.element';
import { NetworkError } from '../../utils/result';
import { ErrorSeverity } from '../enums/error-severity';
import { integer } from 'vscode-languageserver-protocol';

@singleton()
export class ToasterService {
    

    private _toaster: SeToasterElement;
    private _counter = 0;

    constructor() {
        if (document.readyState === 'complete' || document.readyState === 'interactive') {
            this.createTitleElement();
        }
        else {
            window.addEventListener('DOMContentLoaded', (event) => {
                this.createTitleElement();
            })
        }
    }

    createTitleElement() {
        if (!this._toaster) {
            this._toaster = document.createElement("se-toaster") as SeToasterElement;            
            document.body.appendChild(this._toaster);
        }        
    }

    hideToaster() {
        if (this._toaster) {
            this._toaster.style.left = '-1000px';
            this._toaster.style.top = '-1000px';        
        }        
    }

    async showNetworkError(err: NetworkError) {
        if (this._toaster)
            this._toaster.addToast({ id: this._counter++, title: err.severity === ErrorSeverity.Error ? "Error" : "Unexpected Error", description: err.message, timeout: 5000, backgroudColor: 'Firebrick', color: 'white' })
    }

    async showUnexpectedError(message: string) {
        if (this._toaster)
            this._toaster.addToast({ id: this._counter++, title: "Unexpected Error", description: message, timeout: 5000, backgroudColor: 'Firebrick', color: 'white' })
    }
    async showError(message: string) {
        if (this._toaster)
            this._toaster.addToast({ id: this._counter++, title: "Error", description: message, timeout: 5000, backgroudColor: 'Firebrick', color: 'white' })
    }
    async showSuccess(message: string, timeout: integer = 3000) {
        if (this._toaster)
            this._toaster.addToast({ id: this._counter++, title: "Success", description: message, timeout: timeout, backgroudColor: 'var(--color-secondary)', color: 'white' })
    }
    async showWarning(message: string) {
        if (this._toaster)
            this._toaster.addToast({ id: this._counter++, title: "Warning", description: message, timeout: 5000, backgroudColor: 'DarkOrange', color: 'white' })
    }

    clear() {
        if (this._toaster) {
            this._toaster.clear();
            this.hideToaster();
        }        
    }
}
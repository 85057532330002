import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import Styles from '../../../../assets/styles';
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { container } from 'tsyringe';
import { AuthService } from '../../services/auth.service';
import { ToasterService } from '../../services/toaster.service';
import { DataGridColumn } from '../components/data-grid-template';
import { format, endOfDay, startOfMonth, sub} from 'date-fns';
import { BillingService } from '../../services/billing.service';
import { InvoiceStatus } from '../../enums/invoice-status';
import { choose } from 'lit/directives/choose.js';

@customElement("se-billing-history")
export class BillingHistoryElement extends LitElement {

    @property({ type: String }) tabName = 'history';
    @property({ type: Date }) startDate = startOfMonth(sub(new Date(), { months: 12 }));
    @property({ type: Date }) endDate = endOfDay(new Date());

    @state() private _data: any[] = [];

    private _columns: DataGridColumn[] = [];
    private _pageIndex: number = 1;
    private _recordsPerPage: number = 10;
    private _totalRecordCount: number = 20;

    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _billingService: BillingService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._billingService = container.resolve(BillingService);
    }

    connectedCallback() {
        super.connectedCallback();
        this.loadData();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        this.pageChange(location)
        if (!this._authService.isAdmin) {
            return commands.redirect('/login');
        }        

       if (location.params.billingId) {
           let val = parseInt(location.params.pipeId.valueOf() as string);//location.params.billingTab as string;
        }
    }

    firstUpdated() {
    }

    pageChange(location: RouterLocation) {
        const message = {
            type: 'pageChange',
            payload: { location: location.route },
        };
        window.postMessage(message, '*');
    }

    private getStatus(color: string, text: string, labelDlass: string): TemplateResult {
        return html`
        <div class="pipe-item-status" style="background-color: ${color};">
            <span>${text}</span>
        </div>`
    }

    private getInvoiceViewLink(value: number): TemplateResult {
        return html`
        <span style="display: flex; justify-content: flex-start;">
           <a href="${value}" target="_blank">View</a>
        </span>`
    }

    private async loadData() {
        this._columns = [
            { field: 'invoiceId', title: 'Invoice', align: 'left' },
            {
                field: 'status', title: 'Status', align: 'left', template: (row, col) => html`${choose(row.status, [
                    [InvoiceStatus.Draft, () => this.getStatus('#D0D3DC', 'Draft', 'Draft')],
                    [InvoiceStatus.Open, () => this.getStatus('#3F8CD9', 'Open', 'Open')],
                    [InvoiceStatus.Paid, () => this.getStatus('#219653', 'Paid', 'Paid')],
                    [InvoiceStatus.Failed, () => this.getStatus('#EB5757', 'Failed', 'Failed')],
                    [InvoiceStatus.Void, () => this.getStatus('#EB5757', 'Deleted', 'Deleted')],
                    [InvoiceStatus.Uncollectible, () => this.getStatus('#EB5757', 'Uncollectible', 'Uncollectible')],
                ],
                    () => html`${row.status}`)}`
            },
            { field: 'amount', title: 'Amount', align: 'center', template: (row, col) => html`${'$' + row.amount}` },
            { field: 'created', title: 'Created', align: 'center', template: (row, col) => (row.created) ? html`${(new Date(row.created)).toLocaleDateString()}` : html`` },
            {
                field: 'invoiceUrl', align: 'center', template: (row, col) => html`${this.getInvoiceViewLink(row.invoiceUrl)}`
            },
        ]

        var orgId = this._authService.user.organizationId;

        const result = await this._billingService.api.getBillingHistoryAsync(orgId, format(this.startDate, 'yyyy-MM-dd'), format(this.endDate, 'yyyy-MM-dd'));
        if (result.isOk) {
            this._data = result.value;
            this._totalRecordCount = this._data.length;
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    render() {

        return html`
            <div class="body">
                <se-billing .tabName=${this.tabName}></se-billing>
                <div class="container-content">
                    <div class="form" style="margin: 10px;">
                        <span class="h4">Showing invoices within the past 12 months</span>
                        <div class="table-list">
                            <se-data-grid .selectAllCheckbox=${true} class="grid" .rows=${this._data} .recordsPerPage=${this._recordsPerPage} .pageIndex=${this._pageIndex} .columns=${this._columns}></se-data-grid>
                        </div>
                    </div>
                </div>
            </div>    
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        background:none;
        color:#1A1F4B
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .body {
        display: flex;
        flex-direction: column;
        background-color: white;
    }
    .time-section{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .action-button{
        margin-bottom: -75px;
        display: flex;
        justify-content: space-between;
    }
    .config-tab{
        max-width: 700px;
        width: 100%;
    }
    .action-button .inputEditor{
         width:100px;
    }
    .grid {
        flex:1;        
    }   
    .checkbox {
        width: 1rem;
        height: 1rem;
    }
    input[type="checkbox"]:checked {
        background-color: var(--color-secondary);
    }
    .left-header{
        flex: 1 1 0%;
        max-width: 250px;
        padding-right: 50px;
    }
  `]
  };
}
import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import Styles from '../../../../assets/styles';
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { container } from 'tsyringe';
import { AuthService } from '../../services/auth.service';

@customElement("se-billing")
export class BillingElement extends LitElement {
    private _authService: AuthService;

    @state() private _selectedTab: string = 'overview';

    @property({ type: String }) tabName = '';

    constructor() {
        super();
        this._authService = container.resolve(AuthService);

    }

    connectedCallback() {
        super.connectedCallback();
        this._selectedTab = this.tabName;
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isAdmin) {
            return commands.redirect('/login');
        }        

        if (location.params.billingTab) {
            this._selectedTab = location.params.billingTab as string;
        }
    }

    private tabOverview() {
        Router.go("/billing/overview");
    }

    private tabPaymentMethod() {
        Router.go("/billing/payment-method");
    }

    private tabHistory() {
        Router.go("/billing/history");
    }

    private tabPreferences() {
        Router.go("/billing/preferences");
    }

    render() {

        return html`
            <div class="container-header gradient" >
                <div class="left-header top-header">
                    <div class="h1">Billing</div>
                </div>
            </div>
            <div class="tabs-header">
                <div @click=${this.tabOverview} class="tabs ${classMap({ "active-tab": this._selectedTab == "overview" })}">Overview</div>
                <div @click=${this.tabPreferences} class="tabs ${classMap({ "active-tab": this._selectedTab == "preferences" })}">Preferences</div>

                <!-- The Invoice functionality has been disabled for now as we are transitioning to a Pre-Credit System for billing.
                <div @click=${this.tabPaymentMethod} class="tabs ${classMap({ "active-tab": this._selectedTab == "payment-method" })}">Payment method</div>
                <div @click=${this.tabHistory} class="tabs ${classMap({ "active-tab": this._selectedTab == "history" })}">Billing history</div> -->
                
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        background:none;
        color:#1A1F4B
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .body {
        display: flex;
        flex-direction: column;
        background-color: white;
    }
    .time-section{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .action-button{
        margin-bottom: -75px;
        display: flex;
        justify-content: space-between;
    }
    .config-tab{
        max-width: 700px;
        width: 100%;
    }
    .action-button .inputEditor{
         width:100px;
    }
    .grid {
        flex:1;        
    }   
    .checkbox {
        width: 1rem;
        height: 1rem;
    }
    input[type="checkbox"]:checked {
        background-color: var(--color-secondary);
    }
    .left-header{
        flex: 1 1 0%;
        max-width: 300px;
        padding-right: 50px;
    }

      `]
    };
}
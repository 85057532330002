import { container, inject, singleton } from 'tsyringe';
import { SourcesViewModel } from '../models/sources-view-model';
import { SourceViewModel } from '../models/source-view-model';
import { PipesViewModel } from '../models/pipes-view-model';
import { PipesConfigsViewModel } from '../models/pipes-configs-view-model';
import { PipeViewModel } from '../models/pipe-view-model';
import { AuthService } from './auth.service';
import { RunViewModel } from '../models/run-view-model';
import { BaseApi } from './base.api';


@singleton()
export class SourceApi extends BaseApi {
    private _authService: AuthService;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }
    async connectAsync(id: number) {
        return this.postObjectAsync(`/api/server/${id}/connect`, undefined, this._authService.token);
    }
    async getAllSourcesAsync(searchRequest: any) {
        return this.postObjectReturnObjectAsync<SourcesViewModel>(`/api/source/all`, searchRequest, this._authService.token);
    }
    async getSourceAsync(sourceId: number) {
        return this.getObjectAsync<SourceViewModel>(`/api/source/${sourceId}`, this._authService.token);
    }
    async getSourceRunHistory(configId: number, pageIndex = 1, recordsPerPage = 100, sortColumn: string = '', sortOrder: number = -1) {
        return this.getObjectAsync<{ configName: string, runsHistory: RunViewModel[], totalRecordCount: number }>(`/api/source/run-history/${configId}?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`, this._authService.token);
    }
    async getAllPipesBySourceIdAsync(sourceId, pageIndex = 1, recordsPerPage = 100, sortColumn: string = 'Name', pipeName: string = '', sortOrder: number = -1) {
        return this.getObjectAsync<PipesViewModel>(`/api/source/get-source-pipes/${sourceId}?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&pipeName=${pipeName}&sortOrder=${sortOrder}`, this._authService.token);
    }
    async createSource(sourceId: number, source: SourceViewModel) {
        return this.postObjectAsync(`/api/source/${sourceId}`, source, this._authService.token);
    }
    async deleteSourceAsync(sourceId: number) {
        return this.deleteObjectAsync(`/api/source/${sourceId}`, this._authService.token);
    }

}
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { css, html, LitElement } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { container } from 'tsyringe';
import search from '../../../assets/search.svg';
import Styles from '../../../assets/styles';
import { SourceService } from '../services/source.service';
import { ToasterService } from '../services/toaster.service';
import './change-password.element';
import { DataGridColumn } from './components/data-grid-template';
import './components/data-grid.element';
import { SeDataGrid } from './components/data-grid.element';
import './components/primary-button.element';
import './components/secondary-button.element';
import { InputEditorElement } from './editors/input-editor.element';
import './forgot-password.element';
//import './home.element';
import './login.element';
import './reset-password.element';
import './user-registration.element';
import { AuthService } from '../services/auth.service';
import { SePaginationElement } from './components/pagination.element';





@customElement('se-sources')
export class SourcesPageElement extends LitElement {
    private _authService: AuthService;


    private _sourceService: SourceService;
    private _toasterService: ToasterService;
    private _pageIndex: number = 1;
    private _savedPageIndex: number = 1;
    private _recordsPerPage: number = 10;
    private _totalRecordCount: number;
    private _sortOrder: number = 0;
    private _columns: DataGridColumn[] = [];
    private _sourceName: string = "";
    private _isProgrammaticallySet: boolean = true;
    private lastSearchedValue: string = "";
    private searchQueue = [];
    private processingSearch = false;
    private _sortColumn: string = 'name';

    @query('#search') private searchInput: InputEditorElement;

    @state() private _data: any[] = [];


    @query('se-data-grid') private _dataGrid: SeDataGrid;
    @query('se-pagination') private _pagination: SePaginationElement;
    private boundHandlePopState;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._sourceService = container.resolve(SourceService);
        this._toasterService = container.resolve(ToasterService);
        this.boundHandlePopState = this.handlePopState.bind(this);

    }





    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('popstate', this.boundHandlePopState);
        //this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('popstate', this.boundHandlePopState);
    }
    private handlePopState(event) {
        const customEvent = new CustomEvent('customPageChange', {
            detail: { pageIndex: (event.state?.gridPage || 1) }
        });
        this.onPageChanged(customEvent);
    }

    firstUpdated() {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);
        var pulledPage = Number(searchParams.get("gridPage") || this._pageIndex)
        const customEvent = new CustomEvent('customPageChange', {
            detail: { pageIndex: pulledPage }
        });
        this.onPageChanged(customEvent);

    }








    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isUser) {
            return commands.redirect('/login');
        }        

    }


    public onAfterEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): void {

    }





    private async loadData(nameValue: string = this.lastSearchedValue) {
        return new Promise<void>(async (resolve, reject) => {

            this._columns = [
                { field: 'name', title: 'Source', sortable: true, cellStyle: { textDecoration: 'underline' }, action: this.viewSourceDetails.bind(this) },
                { field: 'pipeCount', title: 'Agents', sortable: false, align: 'right' },
            ]

                try {
                    this.lastSearchedValue = nameValue;

                    let sourceSearchReq = {
                        pageIndex: this._pageIndex,
                        recordsPerPage: this._recordsPerPage,
                        Name: nameValue,
                        sortColumn: this._sortColumn,
                        sortOrder: this._sortOrder
                    }

                    const result = await this._sourceService.api.getAllSourcesAsync(sourceSearchReq);
                    if (result.isOk) {
                        this._data = result.value.configs
                        this._totalRecordCount = result.value.totalRecordCount;
                    }
                    else {
                        this._toasterService.showUnexpectedError(result.err.message);
                    }

                    resolve();
                } catch (error) {
                    reject(error)
                }
            //    if (result.isOk) {
            //        this._data = result.value.sources;
            //        this._totalRecordCount = result.value.totalRecordCount;
            //    }
            //    else {
            //        this._toasterService.showUnexpectedError(result.err.message);
            //    }
        })

    }



    private async filterBySourceName() {
        const newValue = this.searchInput.liveValue;
        const trimmedNewValue = newValue.trim();
        if ((this.lastSearchedValue === '' || (this.lastSearchedValue === 'null' && this._isProgrammaticallySet)) && trimmedNewValue !== '') {
            this._savedPageIndex = this._pageIndex;
        }
        this._isProgrammaticallySet = false;

        if (this._sourceName.trim() === '' && trimmedNewValue === '') {
            this.searchQueue = [];
            return;
        }
   
        this._sourceName = newValue;
        if (trimmedNewValue === '') {
            this._pageIndex = this._savedPageIndex || 1;
            this._dataGrid.pageIndex = this._savedPageIndex || 1;
            this._pagination.changePage(this._savedPageIndex || 1);
        } else {
            this._pageIndex = 1;
            this._dataGrid.pageIndex = this._pageIndex;
        }

        if (trimmedNewValue === '') {
            this._sourceName = '';
            this._isProgrammaticallySet = true;
        }
        if (this.lastSearchedValue === this._sourceName) {
            return;
        }
        if (this.processingSearch == false) {
            this.searchQueue.push(this._sourceName)
            this.processingSearch = true
            await this.loadData(this.searchQueue[0]);
            this.processingSearch = false
            this.searchQueue.shift()
        } else {
            if (this.searchQueue.length !== 1) {
                this.searchQueue.pop()
            }
            var repeating = setInterval(() => {
                if (this.processingSearch == false) {
                    clearInterval(repeating)
                    this.filterBySourceName()
                }
            }, 100)
        }

    }



    private async viewSourceDetails(row: any, col: DataGridColumn) {
        Router.go('/sources/' + row.id);
    }


    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();

    }


    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortOrder = evt.detail.sortOrder;
        const sortColumn = evt.detail.sortColumn;

        this._sortColumn = sortColumn;

        this._sortOrder = sortOrder;

        this.loadData();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadData();
    }



    private filterByLabel(event: CustomEvent) {
        event.stopPropagation();
    }
    private filterByAgentTemplate(event: CustomEvent) {
        event.stopPropagation();
        this.loadData();
    }

    
    render() {
      
        return html`
            <div class="right-body">

                <div class="container-header gradient">

                    <div class="left-header top-header">
                        <div class="h1">Sources</div>
                    </div>
                            

                </div>
                <div class="container-content">
                    <div class="table-header">
                            <se-input-editor class="inputEditor" .icon=${search} .customStyle=${{ alignItems: 'unset' }} id="search" .value=${this._sourceName === null && this._isProgrammaticallySet ? "" : this._sourceName} @editorChanged=${this.filterBySourceName} name="search" type="text" placeholder="Search" labelPosition="top" input-type="search" required ></se-input-editor>
                    </div>
                    <div class="table-list">
                        <se-data-grid .selectAllCheckbox=${true} class="grid" .rows=${this._data} .recordsPerPage=${this._recordsPerPage} .pageIndex=${this._pageIndex} .columns=${this._columns} selectable @selectionchanged=${this.onGridSelectionChanged} @sortdata=${this.sortDataGrid} @filterbylabel=${this.filterByLabel} @filterbyAgentTemplate=${this.filterByAgentTemplate}></se-data-grid>
                    </div>
                    <div class="table-pagination">
                        <se-pagination .dataType=${"Sources"} .recordsPerPage=${this._recordsPerPage} .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
                    </div>
                        
                </div>
             

                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`

            .table-header{
                flex-direction: row-reverse;
            }


      `]
    };
}

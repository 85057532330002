import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { when } from 'lit/directives/when.js';

@customElement('se-pagination')
export class SePaginationElement extends LitElement {    
        
    @property({ type: Number }) pageIndex?= 1;
    @property({ type: Number }) recordsPerPage?= 100;
    @property({ type: Number }) recordCount?= 0;
    @property({ type: String }) dataType;



    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('popstate', this.handlePopState.bind(this));
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('popstate', this.handlePopState.bind(this));
    }
    private handlePopState(event) {
        this.pageIndex = event.state?.gridPage || 1;
    }


    onPageChange(e: Event, pageIndex: number) {        
        this.pageIndex = pageIndex;
        this.dispatchEvent(new CustomEvent("pagechanged", { bubbles: true, composed: true, detail: { pageIndex: pageIndex } }));

        history.pushState({ gridPage: this.pageIndex }, null, this.getPageUrl(this.pageIndex));
    }
    changePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.recordCount = this.pageIndex * this.recordsPerPage;
        history.pushState({ gridPage: this.pageIndex }, null, this.getPageUrl(this.pageIndex));
    }

    getItemsRange() {        
        const startIndex = (this.pageIndex - 1) * this.recordsPerPage;
        const endIndex = Math.min(startIndex + this.recordsPerPage, this.recordCount);
        return {
            start: this.recordCount == 0 ? startIndex : startIndex + 1,
            end: !isNaN(endIndex) ? endIndex : 0
        };
    }

    firstUpdated() {   
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);
        var pulledPage = Number(searchParams.get("gridPage") || this.pageIndex)
        this.pageIndex = pulledPage;

    }
    private getPageUrl(pageIndex:number) {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);
        searchParams.set("gridPage", pageIndex.toString());
        url.search = searchParams.toString();
        return url.href;
    }

    render() {
        var pageCount = Math.floor(this.recordCount / this.recordsPerPage) + ((this.recordCount % this.recordsPerPage) > 0 ? 1 : 0);
        pageCount == 0 ? pageCount++ : pageCount;
        if (!this.pageIndex || this.pageIndex<1)
            this.pageIndex = 1;
        if (this.pageIndex > pageCount)
            this.pageIndex = pageCount;        
        var range = this.getItemsRange();
        

        return html`

            <div style="display: flex; justify-content: space-between;">

                <div style="display: flex; gap:5px">
                    <span>Showing ${range.start} to ${range.end} of ${this.recordCount} ${this.dataType}</span>
                </div> 
                       



                <div style="display: flex; gap:25px">

                    ${this.pageIndex > 1 ? html`<button @click="${evt => this.onPageChange(evt, this.pageIndex - 1)}">&lt;</button>` : html`<button class="disabled">&lt;</button>`}

                    <button @click=${evt => this.onPageChange(evt, 1)} class="${classMap({selected: this.pageIndex === 1})}">1</button>
                    ${when(this.pageIndex > 4, () => html`<div>...</div>`)}
            
                    ${when(this.pageIndex < 5 && pageCount > 2, () => html`<button @click="${evt => this.onPageChange(evt, 2)}" class="${classMap({selected: this.pageIndex === 2})}">2</button>`)}
            
                    ${when(this.pageIndex < 5 && pageCount > 3, () => html`<button @click=${evt => this.onPageChange(evt, 3)} class=${classMap({selected: this.pageIndex === 3})}>3</button>`)}
            
                    ${when(this.pageIndex >=3 && this.pageIndex < 5 && pageCount > 4, () => html`<button @click=${evt => this.onPageChange(evt, 4)} class=${classMap({selected: this.pageIndex === 4})}>4</button>`)}
                    ${when(this.pageIndex === 4 && pageCount > 5, () => html`<button @click=${evt => this.onPageChange(evt, 5)} class=${classMap({selected: this.pageIndex === 5})}>5</button>`)}
                    ${when(this.pageIndex >= 6 && this.pageIndex === pageCount, () => html`<button @click=${evt => this.onPageChange(evt, this.pageIndex - 2)} class=${classMap({selected: this.pageIndex === this.pageIndex - 2})}>${this.pageIndex - 2}</button>`)}
                    ${when(this.pageIndex >= 5, () => html`<button @click=${evt => this.onPageChange(evt, this.pageIndex - 1)} class=${classMap({selected: this.pageIndex === this.pageIndex - 1})}>${this.pageIndex - 1}</button>`)}
                    ${when(this.pageIndex >= 5 && this.pageIndex < pageCount, () => html`<button @click=${evt => this.onPageChange(evt, this.pageIndex)} class=${classMap({selected: this.pageIndex === this.pageIndex})}>${this.pageIndex}</button>`)}
                    ${when(this.pageIndex >= 5 && this.pageIndex < pageCount - 1, () => html`<button @click=${evt => this.onPageChange(evt, this.pageIndex + 1)} class=${classMap({selected: this.pageIndex === this.pageIndex + 1})}>${this.pageIndex + 1}</button>`)}
                    ${when(pageCount > 5 && this.pageIndex < pageCount - 2, () => html`<div>...</div>`)}
                    ${when(pageCount > 1, () => html`<button @click=${evt => this.onPageChange(evt, pageCount)} class=${classMap({selected: this.pageIndex === pageCount})}>${pageCount}</button>`)}
                    ${this.pageIndex < pageCount ? html`<button @click=${evt => this.onPageChange(evt, this.pageIndex + 1)}>&gt;</button>` : html`<button class="disabled">&gt;</button>`}

                </div> 
            </div>          
        `;
    }

    static styles = css`
    :host {      
    }
    button {
        border: none;
        background-color: var(--color-light);
        width: 33px;
        height: 30px;
        font: var(--font);
    }
    button:not(.selected):hover {
        background-color: white;
        cursor: pointer;
    }
    .selected {
        border: none;
        font-weight: bold;
        background-color: rgb(26, 31, 75);
        color: white;            
    }
    .disabled {        
        color: DarkGray; 
    }
    .disabled:hover {
        background-color: var(--color-light);  
        cursor: default;
    }
  `;
}


import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { when } from 'lit/directives/when.js';
import { Toast } from '../../models/toast';
import { repeat } from 'lit/directives/repeat.js';

@customElement('se-toaster')
export class SeToasterElement extends LitElement {    
        
    private _toasts: Toast[] = [];    
    
    firstUpdated() {        
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('resize', () => this.resize());
    }

    disconnectedCallback() {
        window.removeEventListener('resize', () => this.resize());
        super.disconnectedCallback();
    }

    private resize() {
        this.updateAsync();
    }

    removeFirstToast() {
        if (this._toasts.length > 0) {
            this._toasts.splice(0, 1);
        }        
        this.updateAsync();        
    }

    clear() {
        this._toasts = []
        this.updateAsync(); 
    }

    hideToast(toast: Toast) {
        const index = this._toasts.indexOf(toast);
        const el = this.shadowRoot.children[index] as HTMLElement;               
        el.style.opacity = '0.1';

        setTimeout(() => {
            this._toasts = this._toasts.filter(p => p !== toast);
            this.updateAsync();
        }, 1000);        
    }

    addToast(toast: Toast) {
        toast.opacity = toast.opacity ?? 0.90;
        this._toasts.push(toast);
        if (toast.timeout) {
            toast.timeoutHandle = setTimeout(() => this.hideToast(toast), toast.timeout);
        }
        this.updateAsync();
    }
    private async updateAsync() {
        this.requestUpdate();
        await this.updateComplete;
        const rect = this.getBoundingClientRect();
        if (rect.height > window.innerHeight - 10) {
            this.removeFirstToast();
        }        
        this.style.left = (window.innerWidth - 10 - rect.width) + 'px';
        this.style.top = '10px';        
    }

    render() {
        return html`
            ${ repeat(this._toasts, toast => toast.id, toast =>
                html`
                <div class="toast" @click=${() => this.hideToast(toast)} style="${styleMap({ backgroundColor: toast.backgroudColor ?? 'DimGray', color: toast.color??'white'})}">
                    <div class="header">
                        <div>${toast.title}</div>
                        <div class="icon"><fa-icon scale="1.2" fa-class="fas fa-times-circle"></fa-icon></div>
                    </div>
                    <div class=body>                
                    ${when(toast.description, () => html`<div>${toast.description}</div>`)}
                    </div>
                </div>
            `)}
        ` 
    }

    static styles = css`
    :host[hidden] {
        display: hidden;
    }
    :host {
        display: block;                        
        color: white;
        font: var(--font-small);
        position: absolute;
        z-index: 3000;        
        left: -1000px;
        top: -1000px;        
        width: 200px;                
    }
    .toast {
        box-shadow: 2px 2px 2px DimGray;
        margin-bottom: 2px;
        background-color: DimGray;
        padding: 5px 10px 5px 10px;
        border-radius: 3px 3px;
        opacity: 1;
        transition: all 1s;
        overflow: hidden;
    }
    .header {        
        padding-bottom:5px;
        margin-bottom:8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-between;
        font-weight: bold;
    }    
    .icon {        
        opacity: 0.8;
    }
    .icon:hover {
        opacity: 1;        
    }
    
  `;
}
import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import "element-internals-polyfill";
import { styleMap } from 'lit/directives/style-map.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseEditor, SimpleEditor } from '../base-editor';

@customElement('se-checkbox-inline-editor')
export class CheckboxEditorElement extends LitElement implements SimpleEditor {
    @property({ type: Boolean }) value: boolean;
    oldValue?: boolean;
    @state() _liveValue: boolean;
    get liveValue() {return this._liveValue }

    @property() tristate?: 'never' | 'always' | 'auto';
    
    @property({ type: Boolean }) readonly?: boolean;    
    @property({ type: Boolean }) disabled = false;
    @property() name: string;
    @property({ attribute: 'editor-size' }) editorSize: 'small' | 'normal' = 'normal';

    @query('#editor') private _editorElement: HTMLDivElement;    

    constructor() {
        super();
    }

    willUpdate(changedProperties) {
        if (changedProperties.has('value')) {
            this.oldValue = this.value;
            this._liveValue = this.value;
            if (this.tristate === undefined && this.value === undefined) {
                this.tristate = 'always';
            }            
        }
    };

    reportValidity(): boolean {
        return true;        
    }
    setCustomValidity(error: string) {        
    }
    
    firstUpdated() {        
    }

    cancel() {
        this._liveValue = this.value;
        this.requestUpdate();
    }

    onMouseDown() {
        if (this.disabled || this.readonly)
            return;

        switch (this._liveValue) {
            case undefined:
                if (this.tristate === 'auto')
                    this._liveValue = false;
                else
                    this._liveValue = true;
                break;
            case true:
                this._liveValue = false;
                break;
            case false:
                if(this.tristate === 'always')
                    this._liveValue = undefined;
                else
                    this._liveValue = true;
                break;
        }
        this.dispatchEvent(new CustomEvent("valueChanged", { bubbles: true, composed: true, detail: { editor: this, value: this._liveValue, oldValue: this.value } }));
        this.dispatchEvent(new CustomEvent("editorChanged", { bubbles: true, composed: true, detail: { editor: this } }));
    }

    render() {
        const iconClass = (this._liveValue === undefined && (this.tristate === 'always' || this.tristate === 'auto')) ? "fa-minus-square" : (this._liveValue === true ? "fa-check-square" : "fa-square");
        const iconStyle = (this._liveValue || (this.tristate === 'always' && this._liveValue === undefined)) ? { color: "black" } : { color: "gray" };
        const style = { opacity: this.disabled ? '0.7' : '1' };
        const font = { font: this.editorSize === 'small' ? 'var(--font-input)' : 'var(--font-input-large)' };
        return html`
            <div id="editor" style="${styleMap({ ...style, ...font })}" @mousedown=${this.onMouseDown}>
                <fa-icon style=${styleMap(iconStyle)} class="icon" fa-class="far ${iconClass}"></fa-icon>                
            </div>
        `;
    }

    static styles = css`
    :host {
        display: block;
        font: var(--font);
        line-height: 1.5em;
    }
    :host([hidden]) {
        display: none;
    }
    .icon {
        color: gray;
    }
    .icon:hover {
        color: black;
    }        
  `;
}


import { PreventAndRedirectCommands, PreventResult, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
//import './home.element';
import './login.element';
import './forgot-password.element';
import './reset-password.element';
import './change-password.element';
import './user-registration.element';
import { RouterService } from '../services/router-service';
import { container } from 'tsyringe';
import Styles from '../../../assets/styles';
import { AuthService } from '../services/auth.service';
import './components/primary-button.element';
import './components/secondary-button.element';
import { ToasterService } from '../services/toaster.service';
import { MenuService } from '../services/menu.service';
import { PipeService } from '../services/pipe.service';
import { when } from 'lit/directives/when.js';
import { SeDataGrid } from './components/data-grid.element';
import './components/data-grid.element';

import { DataGridColumn } from './components/data-grid-template';
import { MenuItem } from './components/context-menu.element';
import { htmlTitle } from '../directives/html-title.directive';
import search from '../../../assets/search.svg'
import { choose } from 'lit/directives/choose.js';
import { integer } from 'vscode-languageserver-protocol';
import { RunStatus } from '../enums/run-status';
import { InputEditorElement } from './editors/input-editor.element';
import { SePaginationElement } from './components/pagination.element';
import './components/loading.element';




@customElement('se-pipes')
export class PipesPageElement extends LitElement {
    private _pipeService: PipeService;
    private _menuService: MenuService;
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _pageIndex: number = 1;
    private _savedPageIndex: number = 1;
    private _recordsPerPage: number = 10;
    private _totalRecordCount: number;
    private _sortColumn: string = 'name';
    private _sortOrder: number = 0;
    private _columns: DataGridColumn[] = [];
    private _pipeName: string = "null";
    private _isProgrammaticallySet: boolean = true;
    private lastSearchedValue: string = "null";
    private searchQueue = [];
    private processingSearch = false;
    private loading = true;
    private welcome = false;

    @query('#search') private searchInput: InputEditorElement;

    @state() private _data: any[] = [];
    @state() private _hasSelectedRows = false;
    @state() private _hasSelectedAgentsWithUnknownValidation = false;

    @query('se-data-grid') private _dataGrid: SeDataGrid;
    @query('se-pagination') private _pagination: SePaginationElement;
    private boundHandlePopState;
    private _runRefresh: boolean = true;
    private _loadTimeout;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._pipeService = container.resolve(PipeService);
        this._toasterService = container.resolve(ToasterService);
        this._menuService = container.resolve(MenuService);
        this.loadData();
        this.boundHandlePopState = this.handlePopState.bind(this);
        this.loading = true;

    }


    private configMenu(row: any, col: DataGridColumn): MenuItem[] {
        return [
            {
                text: 'Open', menu: [
                    { text: 'Open In Editor' },
                    { text: 'Open As Text' }]
            },
        ];
    }
    
    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('popstate', this.boundHandlePopState);
        this._runRefresh = true;
        this.updateDataAsync();
        
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('popstate', this.boundHandlePopState);
        if (this._loadTimeout) {
            clearTimeout(this._loadTimeout);
        }
    }
    private handlePopState(event) {
        const customEvent = new CustomEvent('customPageChange', {
            detail: { pageIndex: (event.state?.gridPage || 1) }
        });
        this.onPageChanged(customEvent);
    }
    firstUpdated() {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);
        var pulledPage = Number(searchParams.get("gridPage") || this._pageIndex)
        const customEvent = new CustomEvent('customPageChange', {
            detail: { pageIndex: pulledPage }
        });
        this.onPageChanged(customEvent);
        //this.loading = false;

    }
    private async updateDataAsync() {
        if (!this._runRefresh) {
            return;
        }
        this._loadTimeout = setTimeout(() => { this.updateDataAsync(); this.loadData(); }, 5000);
    }

    private openVersionHistory(row: any, col: DataGridColumn) {
        Router.go(`/config-versions/${row.id}`);
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isUser) {
            return commands.redirect('/login');
        }        
    }

    public onAfterEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): void {
    }
    
    private loadData(nameValue: string = this.lastSearchedValue) {
        return new Promise<void>(async (resolve, reject) => {

            this._columns = [
                { field: 'name', title: 'Agent Name', cellStyle: { textDecoration: 'underline' }, sortable: true, action: this.viewPipeDetails.bind(this) },
                { field: 'source', title: 'Source', sortable: true },
                { field: 'destination', title: 'Destination', sortable: true },
                { field: 'nextRunTime', title: 'Schedule', sortable: true, align: 'center', template: (row, col) => (row.nextRunTime) ? html`${(new Date(row.nextRunTime)).toLocaleString()}` : html`` },
                { field: 'lastActivity', title: 'Last Run',  sortable: true, template: (row, col) => (row.lastActivity) ? html`${(new Date(row.lastActivity)).toLocaleDateString()}` : html`` },
                {
                    field: 'status', title: 'Status', sortable: true, template: (row, col) => html`${choose(row.status, [
                        [undefined || null, () => this.getStatus('#D0D3DC', 'Not Run', 'No Activity')],
                        [RunStatus.success, () => this.getStatus('#219653', 'Completed', 'Success')],
                        [RunStatus.completed, () => this.getStatus('#219653', 'Completed', 'Completed')],
                        [RunStatus.running, () => this.getStatus('#3F8CD9', 'Running', 'Running')],
                        [RunStatus.exporting, () => this.getStatus('#3F8CD9', 'Running', 'Exporting')],
                        [RunStatus.starting, () => this.getStatus('#3F8CD9', 'Running', 'Starting')],
                        [RunStatus.stopped, () => this.getStatus('#EB5757', 'Failed', 'Stopped')],
                        [RunStatus.failed, () => this.getStatus('#EB5757', 'Failed', 'Failed')],
                        [RunStatus.stopping, () => this.getStatus('#EB5757', 'Failed', 'Stopping')],
                        [RunStatus.failure, () => this.getStatus('#EB5757', 'Failed', 'Failed')],
                        [RunStatus.queuing, () => this.getStatus('#D0D3DC', 'Queued', 'Queuing')]
                    ],
                        () => html`${row.status}`)}`
                },
            ]
                try {
                    this.lastSearchedValue = nameValue;
                    const result = await this._pipeService.api.getAllPipesAsync(this._pageIndex, this._recordsPerPage, nameValue, this._sortColumn, this._sortOrder);
                    if (result.isOk) {
                        this._data = result.value.configs;
                        this._totalRecordCount = result.value.totalRecordCount;
                        if (((this._isProgrammaticallySet && this.lastSearchedValue === 'null') || (!this._isProgrammaticallySet && this.lastSearchedValue === '')) && this._totalRecordCount == 0) {
                            this.welcome = true;
                        }
                    }
                    else {
                        this._toasterService.showUnexpectedError(result.err.message);
                    }

                    resolve();
                } catch (error) {
                    reject(error)
            }
            this.loading = false;

            //    if (result.isOk) {
            //        this._data = result.value.pipes;
            //        this._totalRecordCount = result.value.totalRecordCount;
            //    }
            //    else {
            //        this._toasterService.showUnexpectedError(result.err.message);
            //    }
        })
    }

    private async filterByPipeName() {
        const newValue = this.searchInput.liveValue;
        const trimmedNewValue = newValue.trim();

        if ((this.lastSearchedValue === '' || (this.lastSearchedValue === 'null' && this._isProgrammaticallySet)) && trimmedNewValue !== '') {
            this._savedPageIndex = this._pageIndex;
        }

        this._isProgrammaticallySet = false;

        if (this._pipeName.trim() === '' && trimmedNewValue === '') {
            this.searchQueue = [];
            return;
        }
 
        this._pipeName = newValue;

        if (trimmedNewValue === '') {
            this._pageIndex = this._savedPageIndex || 1;
            this._dataGrid.pageIndex = this._savedPageIndex || 1;

            this._pagination.changePage(this._savedPageIndex || 1);
        } else {
            this._pageIndex = 1;
            this._dataGrid.pageIndex = this._pageIndex;
        }


        if (trimmedNewValue === '') {
            this._pipeName = 'null';
            this._isProgrammaticallySet = true;
        }
        if (this.lastSearchedValue === this._pipeName) {
            return;
        }
        if (this.processingSearch == false) {
            this.searchQueue.push(this._pipeName)
            this.processingSearch = true
            await this.loadData(this.searchQueue[0]);
            this.processingSearch = false
            this.searchQueue.shift()
        } else {
            if (this.searchQueue.length !== 1) {
                this.searchQueue.pop()
            }
            var repeating = setInterval(() => {
                if (this.processingSearch == false) {
                    clearInterval(repeating)
                    this.filterByPipeName()
                }
            }, 100)
        }

    }





    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();

    }

    private async viewPipeDetails(row: any, col?: DataGridColumn) {
        Router.go('/agents/' + row.id);
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder;

        this._sortColumn = sortColumn;
        this._sortOrder = sortOrder;

        this.loadData();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;

        if (this._dataGrid) { 
            this._dataGrid.pageIndex = this._pageIndex;
        }
        

        this.loadData();
    }

    private resetPagination() {
        //this._pageIndex = 1;
        //this._dataGrid.pageIndex = 1;
        //this._dataPagination.pageIndex = 1;
    }

    private refresh() {
        this.loadData();
    }

    private openLabelsMenu(event: MouseEvent) {
        event.stopPropagation();


    }

    private openAgentRulesMenu(event: MouseEvent) {
        event.stopPropagation();


    }

    private filterByLabel(event: CustomEvent) {
        event.stopPropagation();
    }
    private filterByAgentTemplate(event: CustomEvent) {
        event.stopPropagation();
        this.loadData();
    }

    private openMoveMenu(event: MouseEvent) {
        event.stopPropagation();


    }

    private openCopyMenu(event: MouseEvent) {
        event.stopPropagation();


    }

    private getStatus(color: string, text: string, labelDlass: string): TemplateResult {
        return html`
        <div class="pipe-item-status" style="background-color: ${color};">

                <span>${text}</span>
        </div>`
    }
    private shadeColor(color: string, percent: number) {

        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = R * (100 + percent) / 100;
        G = G * (100 + percent) / 100;
        B = B * (100 + percent) / 100;

        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;

        R = Math.round(R)
        G = Math.round(G)
        B = Math.round(B)

        var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
        var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
        var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

        return "#" + RR + GG + BB;
    }

    private async validateAgents(event: MouseEvent) {
        event.stopPropagation();

    }

    private async onDeleteMany(event: MouseEvent) {
        event.stopPropagation();
    }
    private async filterList() {

    }
    private createPipe(row: any, col: DataGridColumn) {
        Router.go(`/wizard`);
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="right-body">
                ${this.loading ? html`
                <div class="container-header gradient" style="height: 150px;"></div>
                <se-loading></se-loading>
                ` : html`

                ${this.welcome ? html`
                <se-welcome></se-welcome>
                `: html`

                <div class="container-header gradient">

                    <div class="left-header top-header">
                        <div class="h1">Agents</div>
                    </div>

                    <div class="right-header">
                        <se-primary-button @click=${this.createPipe} class="inputEditor" text="Create Agent"></se-primary-button>
                    </div>
                        
                </div>
                    
                <div class="container-content">
                    <div class="table-header">
                        <se-input-editor class="inputEditor" .icon=${search} .value=${this._pipeName === "null" && this._isProgrammaticallySet ? "" : this._pipeName} @editorChanged=${this.filterByPipeName} .customStyle=${{ alignItems: 'unset' }} id="search" name="search" type="text" placeholder="Search" labelPosition="top" input-type="search" required ></se-input-editor>
                    </div>
                    <div class="table-list">
                        <se-data-grid .selectAllCheckbox=${true} class="grid" .rows=${this._data} .recordsPerPage=${this._recordsPerPage} .pageIndex=${this._pageIndex} .columns=${this._columns} selectable @selectionchanged=${this.onGridSelectionChanged} @sortdata=${this.sortDataGrid} @filterbylabel=${this.filterByLabel} @filterbyAgentTemplate=${this.filterByAgentTemplate}></se-data-grid>
                    </div>
                    <div class="table-pagination" >
                            <se-pagination .dataType=${"Agents"} .recordsPerPage=${this._recordsPerPage} .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
                    </div>
 
                </div>

                `}
                `}
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
        .table-header{
            flex-direction: row-reverse;
        }
    
      `]
    };
}

import { css, html, LitElement } from 'lit';
import { customElement, property, query, queryAll, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { container } from 'tsyringe';
import Styles from '../../../../assets/styles';
import { PipeViewModel } from '../../models/pipe-view-model';
import { ScheduleRunModel } from '../../models/schedule-run-model';
import { PipeService } from '../../services/pipe.service';
import { ToasterService } from '../../services/toaster.service';
import { SePrimaryButton } from '../components/primary-button.element';
import { DateEditorElement } from '../editors/date-editor.element';
import { InputEditorElement } from '../editors/input-editor.element';
import { NumberEditorElement } from '../editors/number-editor.element';
import { SelectEditorElement } from '../editors/select-editor.element';

@customElement('se-details-pipe-schedule-tab')
export class DetailsPipeConfigButton extends LitElement {
    private _pipeService: PipeService;
    private _pageIndex: number = 1;
    private _recordsPerPage: number = 10;
    private _sortOrder: number = -1;
    private _pipeId?: number;
    private _toasterService: ToasterService;
    private _currentPipe: PipeViewModel;

    @property() pipeId: string;
    @state() private _pipeSchedule: ScheduleRunModel;

    
    @state() private _runOnceOption = false;
    @state() private _runEveryOption = false;
    private __today?= new Date();
    private __scheduleType?
    private __runEveryCount?
    private __runEveryPeriod?
    private __startTime?
    private __timePMAM?

    @query('#scheduleType') private _scheduleType: SelectEditorElement;

    @query('#StartDate') private _StartDate: DateEditorElement;
    @query('#StartHour') private _StartHour: NumberEditorElement;
    @query('#StartMinutes') private _StartMinutes: NumberEditorElement;
    @query('#StartPeriod') private _StartPeriod: SelectEditorElement;

    @query('#runEveryDate') private _runEveryDate: DateEditorElement;
    @query('#runEveryCount') private _runEveryCount: NumberEditorElement;
    @query('#runEveryPeriod') private _runEveryPeriod: SelectEditorElement;


    @queryAll('se-number-editor') private numberEditors: NumberEditorElement[];
    @queryAll('se-input-editor:not(.cronInput)') private inputEditors: InputEditorElement[];

    @query('#saveButton') private _saveButton: SePrimaryButton;

    constructor() {
        super();
        this._pipeService = container.resolve(PipeService);
        this._toasterService = container.resolve(ToasterService);

    }
    connectedCallback() {
        super.connectedCallback();
        this.loadData();
    }

    private scheduleChangeEdit(scheduleType) {
        if (scheduleType == 0) {
            this._runOnceOption = false;
            this._runEveryOption = false;
        } else if (scheduleType == 1) {
            this._runOnceOption = true;
            this._runEveryOption = false;
        }
        else if (scheduleType == 2) {
            this._runOnceOption = false;
            this._runEveryOption = true;
        }
        else if (scheduleType == 3) {
            this._runOnceOption = false;
            this._runEveryOption = false;
        }
    }



    private async loadData() {
        this._pipeId = Number(this.pipeId)

        // get pipe info and its sources
        var currentPipe = await this._pipeService.api.getPipeAsync(this._pipeId);

        if (currentPipe.isOk) {
            this._currentPipe = currentPipe.value

            var pipeSchedule = await this._pipeService.api.getPipeScheduleAsync(this._pipeId);
            if (pipeSchedule.isOk) {
                this._pipeSchedule = pipeSchedule.value;
                //set start date to today
                var rightNow = new Date();

                if (this._pipeSchedule != null && this._pipeSchedule.id != null) {
                    this.__scheduleType = this._pipeSchedule.scheduleType;
                    this.__runEveryCount = this._pipeSchedule.runEveryCount;
                    this.__runEveryPeriod = this._pipeSchedule.runEveryPeriod;
                    setTimeout(() => {
                        this.scheduleChangeEdit(this.__scheduleType)
                    }, 0);

                    const startTime = new Date(this._pipeSchedule.startTime);
                    //keep startTime if it's in the future
                    if (startTime > new Date()) {
                        this.__startTime = this._pipeSchedule.scheduleType == 0 ? new Date() : startTime;
                        rightNow = this.__startTime;
                    }

                } else {
                    //round right now to the nearest one hour
                    rightNow.setHours(rightNow.getHours() + 1);
                    rightNow.setMinutes(0);
                    //default to run every day
                    this.__runEveryPeriod = 3;
                }


                this.__today = rightNow;
                //set am and pm
                this.__timePMAM = (String((this.__today).getHours() >= 12 ? this.__timePMAM = "12" : this.__timePMAM = "0"))

            }

        }
        else {
            this._toasterService.showUnexpectedError(currentPipe.err.message);
        }


    }

    private adjustDayOfWeek(originalDate, adjustedDate, dayOfWeek) {
        if (isNaN(dayOfWeek)) return dayOfWeek; // when dayOfWeek is *

        //when converting goes to next day or prev day
        const diff = (adjustedDate.getDate() - originalDate.getDate() + 7) % 7;
        return (parseInt(dayOfWeek) + diff) % 7;
    }


    /**
     * Called when the schedule drop down changes
     */
    private scheduleChange() {

        if (this._scheduleType.liveValue == "0") {
            this._runOnceOption = false;
            this._runEveryOption = false;
        } else if (this._scheduleType.liveValue == "1") {
            this._runOnceOption = true;
            this._runEveryOption = false;
        }
        else if (this._scheduleType.liveValue == "2") {
            this._runOnceOption = false;
            this._runEveryOption = true;
        }
        else if (this._scheduleType.liveValue == "3") {
            this._runOnceOption = false;
            this._runEveryOption = false;
        }

        //force the UI to update
        this.requestUpdate();
    }

    private get12HoursFormat(today) {
        return (String((today).getHours() > 12 ? ((today).getHours() - 12) : (today).getHours())).padStart(2, "0")
    }
    private getMinsFormat(today) {
        return (String((today).getMinutes())).padStart(2, "0")
    }

    private toCron(mins, hrs, dayOfMonth, month, dayOfWeek) {
        return `${mins} ${hrs} ${dayOfMonth} ${month} ${dayOfWeek}`
    }

    /**
     * Check if the schedule is new or existing
     */
    private isNewSchedule(): boolean {
        return this._pipeSchedule == null || this._pipeSchedule.id == null;
    }

    private getScheduleDate(): any {
        var result
        var localschedule
        var runEveryData


        var dateTime = this._StartDate.liveValue

        for (let editor of this.inputEditors) {
            let value = editor.liveValue;
            if (!isNaN(Number(value))) {
                if (Number(value) !== Math.floor(Number(value))) {
                    //is decimal
                    return this._toasterService.showUnexpectedError(`Decimals are not allowed "${value}".`);;
                } else {
                    //is int
                    continue;
                }
            }
            //is string
            return this._toasterService.showUnexpectedError(`Invalid value detected "${value}".`);
        }

        for (let editor of this.numberEditors) {
            let value = String(editor.liveValue);
            if (!isNaN(Number(value))) {
                if (Number(value) !== Math.floor(Number(value))) {
                    //is decimal
                    return this._toasterService.showUnexpectedError(`Decimals are not allowed "${value}".`);;
                } else {
                    //is int
                    continue;
                }
            }
            //is string
            return this._toasterService.showUnexpectedError(`Invalid value detected "${value}".`);
        }

        if (dateTime == null) return this._toasterService.showUnexpectedError("Please pick a date and fill the necessary information.");
        if (Number(this._StartMinutes.liveValue) > 60) return this._toasterService.showUnexpectedError("Minutes cannot go above 60.");
        if (Number(this._StartHour.liveValue) > 12) return this._toasterService.showUnexpectedError("Hours cannot go above 12.");
        var hours = Number(this._StartHour.liveValue) == 12 ? Number(Number(this._StartHour.liveValue) + Number(this._StartPeriod.liveValue)) - 12 : Number(Number(this._StartHour.liveValue) + Number(this._StartPeriod.liveValue));
        hours = hours == 24 ? 0 : hours;
        dateTime.setHours(hours, this._StartMinutes.liveValue)
        let currentDate = new Date();
        if (currentDate.getTime() > dateTime.getTime()) {
            return this._toasterService.showUnexpectedError("Please pick a date in the future.");
        }

        var UTCDate = new Date(dateTime.getTime() + dateTime.getTimezoneOffset() * 60 * 1000)
        if (this._scheduleType.liveValue == "1") {
            result = this.toCron(
                (UTCDate.getMinutes()),
                (UTCDate.getHours()),
                (UTCDate.getDate()),
                (UTCDate.getMonth() + 1),
                (UTCDate.getDay()))
            localschedule = this.toCron(
                (dateTime.getMinutes()),
                (dateTime.getHours()),
                (dateTime.getDate()),
                (dateTime.getMonth() + 1),
                (dateTime.getDay()))
        }
        else if (this._scheduleType.liveValue == "2") {
            runEveryData = { runEveryCount: this._runEveryCount.liveValue, runEveryPeriod: this._runEveryPeriod.liveValue }

            var obj = {
                "1": [`*/${this._runEveryCount.liveValue}`, "*", "*", "*", "*"],
                "2": [`${UTCDate.getMinutes()}`, `*/${this._runEveryCount.liveValue}`, "*", "*", "*"],
                "3": [`${UTCDate.getMinutes()}`, `${UTCDate.getHours()}`, `*/${this._runEveryCount.liveValue}`, "*", "*"],
                "4": [`${UTCDate.getMinutes()}`, `${UTCDate.getHours()}`, `*/${7 * this._runEveryCount.liveValue}`, "*", "*"],
                "5": [`${UTCDate.getMinutes()}`, `${UTCDate.getHours()}`, "*", `*/${this._runEveryCount.liveValue}`, "*"],
            }
            const localObj = {
                "1": [`*/${this._runEveryCount.liveValue}`, "*", "*", "*", "*"],
                "2": [`${dateTime.getMinutes()}`, `*/${this._runEveryCount.liveValue}`, "*", "*", "*"],
                "3": [`${dateTime.getMinutes()}`, `${dateTime.getHours()}`, `*/${this._runEveryCount.liveValue}`, "*", "*"],
                "4": [`${dateTime.getMinutes()}`, `${dateTime.getHours()}`, `*/${7 * this._runEveryCount.liveValue}`, "*", "*"],
                "5": [`${dateTime.getMinutes()}`, `${dateTime.getHours()}`, "*", `*/${this._runEveryCount.liveValue}`, "*"],
            };
            result = this.toCron(
                (obj[this._runEveryPeriod.liveValue][0]),
                (obj[this._runEveryPeriod.liveValue][1]),
                (obj[this._runEveryPeriod.liveValue][2]),
                (obj[this._runEveryPeriod.liveValue][3]),
                (obj[this._runEveryPeriod.liveValue][4]))
            localschedule = this.toCron(
                localObj[this._runEveryPeriod.liveValue][0],
                localObj[this._runEveryPeriod.liveValue][1],
                localObj[this._runEveryPeriod.liveValue][2],
                localObj[this._runEveryPeriod.liveValue][3],
                localObj[this._runEveryPeriod.liveValue][4]
            );


        }

        return { "cron": result, "startDateTime": dateTime, "localSchedule": localschedule, runEveryData };
    }

    /**
     * Save the updated schedule to the server or delete it
     * 
     */
    async saveChangesAsync() {


        if (this._scheduleType.liveValue == "0") {
            //delete the existing schedule
            await this.deleteTaskAsync();
        } else {
            await this.updateTaskAsync();
        }

    }

    /**
     * Save to the server
     * @returns
     */
    async updateTaskAsync() {
        this._saveButton.loading = true;
        var dateRes = this.getScheduleDate();
        if (dateRes.cron == undefined) {
            this._saveButton.loading = false;
            return;
        }

        const runEveryPeriod = dateRes.runEveryData != null ? dateRes.runEveryData.runEveryPeriod : null;
        const runEveryCount = dateRes.runEveryData != null ? dateRes.runEveryData.runEveryCount : null;

        var runName = this._currentPipe.name + " - " + new Date().toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric' }).replace(',', '')
        const pipeScheduleId = this._pipeSchedule != null && this._pipeSchedule.id !=  null ? this._pipeSchedule.id : 0;


        const task = {
            id: pipeScheduleId,
            runEveryPeriod: runEveryPeriod,
            runEveryCount: runEveryCount,
            schedule: dateRes.cron,
            scheduleType: Number(this._scheduleType.liveValue),
            localSchedule: dateRes.localSchedule,
            startTime: dateRes.startDateTime,
            configId: this._pipeId,
            proxyPoolId: null,
            parallelism: 1,
            serverGroupId: this._pipeSchedule != null ? this._pipeSchedule.serverGroupId : null,
            serverOrganizationId: this._pipeSchedule != null ? this._pipeSchedule.serverOrganizationId : null,
            parameters: "{}",
            isSaveAsTask: true,
            taskName: runName,
            isExclusive: false, isWaitOnFailure: false
        }

        var result = await this._pipeService.api.updateTaskAsync(pipeScheduleId, task);
        if (!result.isOk) {
            this._toasterService.showNetworkError(result.err);
        }
        else {
            this.loadData();
            this._toasterService.showSuccess("The schedule has been updated.");
            
        }

        this._saveButton.loading = false;



    }

    /**
     * Delete the existing task schedule
     */
    private async deleteTaskAsync() {

        this._saveButton.loading = true;
        if (this._pipeSchedule != null && this._pipeSchedule.id != null) {

            var result = await this._pipeService.api.deleteTaskAsync(this._pipeId);
            if (!result.isOk) {
                this._toasterService.showNetworkError(result.err);
            }
            else {
                this._toasterService.showSuccess("The schedule has been deleted.");
                this._pipeSchedule = null;
            }
        }

        this._saveButton.loading = false;
    }

    private updateRunEveryPeriod() {
        
    }



    render() {
        const runOnceOptionsStyle = { display: this._runOnceOption ? 'flex' : 'none', gap: '10px', 'flex-direction': 'column' };
        const runEveryOptionsStyle = { display: this._runEveryOption ? 'flex' : 'none', gap: '10px', 'flex-direction': 'column' };

        const scheduleOptionsStyle = { display: 'flex' };

        const noSchedule = this._scheduleType == null || this._scheduleType.liveValue == "0";
        const dateTimeStyle = { display: noSchedule ? 'none' : 'flex', gap: '10px'};

        return html`

                    <div class="form">
                        <h2>Schedule</h2>
                            <div style=${styleMap(scheduleOptionsStyle)} class="type" >
                                <div class="labeled-input">
                                    <se-select-editor class="inputEditor" .value="${String(this.__scheduleType)}" id="scheduleType" width="100%" name="Schedule" label="" labelPosition="top" @valueChanged=${this.scheduleChange}
                                        .options=${[
                                                    { id: 0, name: "No Schedule" },
                                                    { id: 1, name: "Run Once" },
                                                    { id: 2, name: "Repeat" },
                                                ]}>
                                    </se-select-editor>
                                </div>
                            </div>
                            <div style=${styleMap(scheduleOptionsStyle)}>
                                <div id="taskOptions" style=${styleMap(runOnceOptionsStyle)}>
                                        
                                </div>
                                <div id="taskOptions" style=${styleMap(runEveryOptionsStyle)}>
                                    <div style="display: flex; gap: 10px;flex-direction: column;">
                                        <span style="display: flex; gap: 10px;">
                                            <span style="align-self: center;">Every </span>
                                            <se-number-editor id="runEveryCount" .value="${this.__runEveryCount || 1}" min="1" label="" labelPosition="top" input-type="number" size="2" width="60px"></se-number-editor>
                                            <se-select-editor id="runEveryPeriod" .value="${String(this.__runEveryPeriod)}" width="120px" name="Proxy Pools" label="" labelPosition="top" @valueChanged=${this.updateRunEveryPeriod}
                                                .options=${[
                                                            { id: 1, name: "minutes" },
                                                            { id: 2, name: "hours" },
                                                            { id: 3, name: "days" },
                                                            { id: 4, name: "weeks" },
                                                            { id: 5, name: "months" }
                                                        ]}>
                                            </se-select-editor>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div style=${styleMap(dateTimeStyle)} class="date-time">
                                <div class="labeled-input">
                                    <label >Start Date</label>
                                    <se-date-editor id="StartDate" style="flex: 1; display: flex;" .value=${this.__today} .min=${(new Date()).toLocaleString("en-CA").split(",")[0]} label="" labelPosition="top"></se-date-editor>
                                </div>
                                <div class="labeled-input">
                                    <label >Start Time</label>
                                    <div class="time-editor">
                                        <se-input-editor class="time-input" style="width: 33%;" .customEditor=${{ textAlign: 'center', flex: '1', padding: '0px' }} .value = ${this.get12HoursFormat(this.__today)} id = "StartHour" value = "01" min = "0" max = "12" label = "" labelPosition = "top" size = "2" width = "100%" > </se-input-editor>
                                        &nbsp;:&nbsp;
                                        <se-input-editor class="time-input" style="width: 33%;" .customEditor=${{ textAlign: 'center', flex: '1', padding: '0px' }} .value=${this.getMinsFormat(this.__today)} id="StartMinutes" value="00" min="0" max="60" label="" labelPosition="top"  size="2" width="100%"></se-input-editor>
                                        <se-select-editor style="width: 33%;min-width: 70px; margin-left:5px;" .value=${this.__timePMAM} id="StartPeriod" width="100%" name="Proxy Pools" label="" labelPosition="top"
                                            .options=${[
                                                        { id: 12, name: "PM" },
                                                        { id: 0, name: "AM" }
                                                    ]}>
                                        </se-select-editor>
                                    </div>
                                </div>                         
                            </div>

                                
                            <div class="action-buttons" style="width: 200px;">
                                <div style=""><se-primary-button id="saveButton" .customStyle=${{ 'padding': '10px 40px' }} @click="${() => { this.saveChangesAsync(); }}"  text="Save"></se-primary-button></div>
                            </div>

                            
                        </div>



                        
                        `;
    }

    firstUpdated() {

    }


    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        background:none;
        color:#1A1F4B
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .body {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
    }
    .time-section{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .date-time {
        dsiplay: flex;
    }

    .action-button{
        margin-bottom: -75px;
        display: flex;
        justify-content: space-between;
    }
    .config-tab{
        max-width: 700px;
        width: 100%;
    }
    .action-button .inputEditor{
         width:100px;
    }
    .grid {
        flex:1;        
    }   
    .checkbox {
        width: 1rem;
        height: 1rem;
    }
    input[type="checkbox"]:checked {
        background-color: var(--color-secondary);
    }
    .left-header{
        flex: 1 1 0%;
        max-width: 600px;
        padding-right: 50px;
    }

      `]
    };

}


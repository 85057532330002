import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';


@customElement('se-primary-button')
export class SePrimaryButton extends LitElement {

    @property() customStyle: any = {};
    @property() icon: string;
    @property() text: string;
    @property() title: string;
    @property({ type: Boolean }) disabled = false;
    @property() size: 'normal' | 'small';
    @property() loading = false;
    @property() red = false;

    firstUpdated() {
    }

    render() {
        const style = this.size === 'small' ? { ...this.customStyle } : this.customStyle;
        const buttonInnerHtml = this.loading
            ? html`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="loading-icon"><path d="M21 12a9 9 0 1 1-6.219-8.56"/></svg>
             Please wait...`
            : html`${this.icon ? html`<fa-icon style="font-size:0.9em" fa-class="${this.icon}"></fa-icon>&nbsp;&nbsp;` : ''}${this.text}`;

        return html`
        <button ?disabled=${this.disabled || this.loading} ?red="${this.red}" title="${this.title}" class="button" style=${styleMap(style)}>
            ${buttonInnerHtml}
        </button>
    `;
    }
       

    static styles = css`
    :host([hidden]) {
        display: none;
    }
    :host {
        display: block;
        width: 100%;
    }
    .button {
        padding: 10px 40px;
        border-radius: 4px;
        justify-content: center;
        width:100%;
        transition: all 0.25s;
        font: var(--font-button);
        background-color: var(--color-purple);
        color: white;
        display: inline-flex;
        align-items: center;     
        cursor: pointer;
        border: none;
        border: 1px solid var(--color-purple);
        transition: 0.3s;
        text-wrap: nowrap;
    }
    .button[red] {
        background-color: var(--color-status-red);
        border:  1px solid var(--color-status-red);
    }
    .button[red]:hover {
        background-color: #EF6F6F;
        border: 1px solid #EF6F6F;
    }
    .button[red]:active {
        background-color: #CC4D4D;
        border: 1px solid #CC4D4D;
    }
    .button:disabled {
        opacity: 0.7;
        background-color: var(--color-secondary);
        border: 1px solid gray;
        color: lightgray;
        transition: none;
        cursor: not-allowed;
        pointer-events: none;
    }
    .button:disabled[red] {
        background-color: #EF6F6F;
        border: 1px solid #EF6F6F;
        color: lightgray;
    }
    .button:hover {
        background-color: var(--color-purple-75);
        border:  1px solid var(--color-purple-75);
    }
    .button:hover:disabled {
        background-color: var(--color-secondary);
        border: 1px solid gray;
        box-shadow: none;
    }
    .button:active {
        box-shadow: none;
        background-color: var(--color-purple-dark);
        border: 1px solid var(--color-purple-dark);
    }
    .loading-icon {
        margin-right: 8px;
        height: 16px;
        width: 16px;
        animation: spin 1s linear infinite;
    }
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
  `;
}
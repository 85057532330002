import { inject,singleton } from 'tsyringe';
import { AppAuthDetails } from '../models/app-auth-details';
import { LoginToken } from '../models/login';
import { BaseApi } from './base.api';

@singleton()
export class AppConfigApi extends BaseApi {

    constructor() {
        super();
    }
    
    async getAuthDetailsAsync() {
        return this.getObjectAsync<AppAuthDetails>(`/api/app-config/auth-details`);
    }    

    async createFirstUserAsync(name: string, organizationName: string, email: string, password: string) {
        return this.postObjectReturnObjectAsync<LoginToken>(`/api/app-config/create-first-user`, { name: name, organizationName: organizationName, email: email, password: password });
    }

    async registerUserAsync(name: string, organizationName: string, email: string, password: string) {
        return this.postObjectReturnObjectAsync<LoginToken>(`/api/app-config/register-user`, { name: name, organizationName: organizationName, email: email, password: password });
    }
}
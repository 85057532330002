import { inject,singleton } from 'tsyringe';
import { ModalEditorResult} from '../../utils/result';
import '../elements/model-editors/confirm-dialog.element';
import '../elements/model-editors/info-dialog.element';
import { LitElement } from 'lit';

export interface ModelDialog {
    showDialog(options: any);
}

@singleton()
export class ModalDialogService {        

    private _closeResolve: (value?: ModalEditorResult<any> | PromiseLike<ModalEditorResult<any>>) => void;
    private _closePromise: Promise<ModalEditorResult<any>>;

    //private _modalPanel: ModalEditorPanelElement;
    private _modalDialog: LitElement & ModelDialog;
       
    private _isOpen = false;

    get isOpen() { return this._isOpen };    

    private createElement(component: string) {
        if (!this._modalDialog) {
            this._modalDialog = document.createElement(component) as LitElement & ModelDialog;
            document.body.appendChild(this._modalDialog);
            this._modalDialog.style.position = "fixed";
            this._modalDialog.style.zIndex = "2000";
            this._modalDialog.style.left = "0";
            this._modalDialog.style.top = "0";
            this._modalDialog.style.width = "100%";
            this._modalDialog.style.height = "100%";
            this._modalDialog.style.overflow = "auto";
            this._modalDialog.style.overflow = "auto";
            this._modalDialog.style.overflow = "auto";
            this._modalDialog.style.overflow = "auto";
            this._modalDialog.style.backgroundColor = "rgba(0,0,0,0.4)";
            (this._modalDialog.style as any).backdropFilter = "blur(1px)";
            this._modalDialog.style.font = "var(----font-ui)";
        }
    }

    close(isSave: boolean, value?: any) {        
        if (this._modalDialog) {
            document.body.removeChild(this._modalDialog);
            this._modalDialog = undefined;
        }
        this._isOpen = false;
        const result = new ModalEditorResult(isSave, value);
        this._closeResolve(result);        
    }

    private async openModelDialogAsync(component: string, options?: any) {        
        this.createElement(component);
        await this._modalDialog.showDialog(options);
        this._isOpen = true;
        this._closePromise = new Promise(r => { this._closeResolve = r });
        return this._closePromise;
    }

    async openConfirmDialogAsync(options: { title: string, body: string, saveCaption?: string, cancelCaption?: string }) {
        return this.openModelDialogAsync("se-confirm-dialog", options);
    }
    async openInfoDialogAsync(options: { title: string, body: string}) {
        return this.openModelDialogAsync("se-info-dialog", options);
    }
    async openPublishToSpaceDialogAsync(options: { data: any[], draftId: number, defaultName: string, defaultSpace: string }) {
        return this.openModelDialogAsync("se-publish-to-space-dialog", options);
    }
    async openPublishDialogAsync(options: { data: any[], row: any}) {
        return this.openModelDialogAsync("se-publish-dialog", options);
    }
    async openCopyDialogAsync(options: { configId: number, defaultName: string }) {
        return this.openModelDialogAsync("se-copy-dialog", options);
    }
    async openRenameDialogAsync(options: { configId: number, defaultName: string }) {
        return this.openModelDialogAsync("se-rename-dialog", options);
    }

    /*async openSaveNewDraftAsync(defaultName: string, func: (name: string) => Promise<VoidNetworkResult>): Promise<ModalEditorResult<string>> {
        return this._modalPanel.openEditorAsync<string>({ title: "Save Draft", element: "se-name-modal", saveCaption: "Save", input: defaultName});
    }
    async openPublishAsync(defaultName: string, defaultSpaceId: string, func: (val: { name: string, spaceId: string, allowOverwrite: boolean }) => Promise<VoidNetworkResult>): Promise<ModalEditorResult<{ name: string, spaceId: string, allowOverwrite: boolean }>> {
        return this._modalPanel.openEditorAsync({ title: "Publish Draft", element: "se-name-space-modal", saveCaption: "Publish", input: { defaultName, defaultSpaceId} });
    }   
    async openMoveToSpaceAsync(excludeSpaceId: number, func: (name: string) => Promise<VoidNetworkResult>): Promise<ModalEditorResult<string>> {
        return this._modalPanel.openEditorAsync({ title: "Move Config", element: "se-space-modal", saveCaption: "Move", input: excludeSpaceId });
    }
    async openCopyToSpaceAsync(excludeSpaceId: number, func: (name: string) => Promise<VoidNetworkResult>): Promise<ModalEditorResult<string>> {
        return this._modalPanel.openEditorAsync({ title: "Copy Config", element: "se-space-modal", saveCaption: "Copy", input: excludeSpaceId });
    }
    async openCopyAsync(excludeSpaceId: number, func: (name: string)=>Promise<VoidNetworkResult>): Promise<ModalEditorResult<string>> {
        return this._modalPanel.openEditorAsync({ title: "Copy Config", element: "se-name-modal", saveCaption: "Copy", input: excludeSpaceId, func: func });
    }
    async openRenameAsync(defaultName: string, func: (name: string) => Promise<VoidNetworkResult>): Promise<ModalEditorResult<string>> {
        return this._modalPanel.openEditorAsync({ title: "Rename Config", element: "se-name-modal", saveCaption: "Rename", input: defaultName });
    }

    async openConfirmAsync(title: string, text: string, saveCaption: string): Promise<ModalEditorResult<void>> {
        return this._modalPanel.openTextAsync(title, text, saveCaption);
    }*/
}
import { container, inject, singleton } from 'tsyringe';
import { err, NetworkError, ok, Result } from '../../utils/result';
import { SeEvent } from '../../utils/se-events';
import { AppConfigApi } from './app-config.api';
import { MarketplaceApi } from './marketplace.api';
import { UserApi } from './user-api';

@singleton()
export class MarketplaceService {

    private _marketplaceApi: MarketplaceApi;

    get api() { return this._marketplaceApi };
    
    constructor() {        
        this._marketplaceApi = container.resolve(MarketplaceApi);
    }    


}
import { LitElement, html, css, } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { ModalDialogService, ModelDialog } from '../../services/modal-editor.service';
import { container } from 'tsyringe';

@customElement('se-info-dialog')
export class InfoDialogElement extends LitElement implements ModelDialog {

    private _modalService: ModalDialogService;
    @state() private _title: string;
    @state() private _body: string;
    @state() private _buttonCaption: string;

    @query('#editorForm') private _editorForm: HTMLFormElement;
    @query('#editorContainer') private _editorContainer: HTMLDivElement;

    @property() name: string;

    constructor() {
        super();

        this._modalService = container.resolve(ModalDialogService);
    }

    connectedCallback() {
        super.connectedCallback();

    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    showDialog(options: { title: string, body: string, _buttonCaption?: string}) {
        this._title = options.title;
        this._body = options.body;
        this._buttonCaption = options._buttonCaption;
    }

    firstUpdated() {        
    }


    private async close(ev) {
        ev.preventDefault();
        this._modalService.close(true);        
    }

    render() {
        return html`
              <form id="editorForm" class="editor">
                <div class="header">
                    <div style="font-weight: bold">${this._title}</div>
                    <div class="icon" @click=${this.close}><fa-icon scale="1.2" fa-class="fas fa-times-circle"></fa-icon></div>
                </div>
                <div class="body">
                    <div class="text-container" id="editorContainer">${unsafeHTML(this._body)}</div>
                    <div class="savePanel">
                        <se-primary-button @click="${this.close}" text=${this._buttonCaption??"Ok"}></se-primary-button>
                    </div>
                </div>
            </form>
            `;
    }

    static styles = css`
    :host([hidden]) {
        display: none;        
    }
    :host {        
    }
    .editor {
        display: flex;
        flex-direction: column;
        width: fit-content;
        min-height: 0;
        margin: auto;
        margin-top: 20vh;
        box-shadow: 4px 4px 4px rgb(80,80,80);
        font: var(--font);        
        width: 350px;
    }
    .body {
        background-color: var(--color-light);
        display: flex;
        flex-direction: column;
        width: fit-content;
        min-height: 0;
        padding: 0px;        
        border-radius: 0px 0px 5px 5px;
        width: 100%;
    }
    .header {
        background-color: var(--color-light);
        padding:5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        color:black;
        border-radius: 5px 5px 0px 0px;
        border-bottom: solid 1px whitesmoke;        
    }
    .icon {
        color: gray;
    }
    .icon:hover {
        color: darkred;
    }    
    .text-container {
        height:100%;
        min-height: 60px;
        overflow: auto;
        padding: 15px;        
        background-color: white;
        box-sizing: border-box;        
        border-top: 1px solid gray;
        border-bottom: 1px solid gray;
        border-radius: 3px 3px;
    }
    .savePanel {
        display: flex;
        flex-direction: rows;
        justify-content: right;        
        padding: 8px 5px 10px 50px;
    }
  `;
}
import { RunStatus } from "../enums/run-status";

export class RunViewModel {
        
    constructor() {        
    }
    
    id: number;
    hasFiles?: boolean;
    parallelSet?: number;f
    parallelism?: number;
    sequence?: number;
    serverName?: string;
    dataCount?: number;
    errorCount?: number;
    status?: RunStatus;
    startTime: Date;
    endTime?: Date;

    static canDelete(status: RunStatus) {
        return status !== RunStatus.running && status !== RunStatus.starting && status !== RunStatus.exporting && status !== RunStatus.stopping;
    }
    static canStop(status: RunStatus) {
        return status === RunStatus.running || status === RunStatus.exporting;
    }
}

export class RunDetailsViewModel extends RunViewModel  {

    
    pageCount?: number;
    requetCount?: number;
    requetAction?: number;
}
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';

@customElement('se-secondary-button')
export class SeSecondaryButton extends LitElement {    

    @property() customStyle: any = {};
    @property() icon: string;
    @property() iconColor: string;    
    @property() text: string;
    @property() title: string;    
    @property({ type: Boolean }) redHover = false;
    @property({ type: Boolean }) disabled = false;
    @property({ type: Boolean }) active = false;

    firstUpdated() {        
    }
    
    render() {
        const style = this.active ? (this.redHover ? {
            ...this.customStyle, ...{
                backgroundColor: 'crimson',
                color: 'white',
                border: '1px solid crimson'
            }
        } : {
            ...this.customStyle, ...{backgroundColor: 'white',
                boxShadow: 'none',
            }
        }) : this.customStyle;

        if (this.icon && this.text)
            return html`    
                <button ?disabled=${this.disabled} title="${this.title}" class="button ${classMap({ red: this.redHover })}" type="button" style="display: flex; align-items: center;${styleMap(style)}"><fa-icon style="font-size:0.9em" fa-class="${this.icon}" single-color="${this.iconColor}"></fa-icon>${this.text ? '&nbsp;' + this.text : ''}</button>
            `;        
        else if (this.icon)
            return html`    
                <button ?disabled=${this.disabled} title="${this.title}" class="button ${classMap({ red: this.redHover })}" type="button" style="${styleMap(style)}"><fa-icon style="font-size:0.9em" fa-class="${this.icon}" single-color="${this.iconColor}"></fa-icon></button>
            `;        
        else
            return html`    
                <button ?disabled=${this.disabled} title="${this.title}" class="button ${classMap({ red: this.redHover })}" type="button" style="display:block; ${styleMap(style)}">${this.text}</button>
            `;
    }

    static styles = css`
    :host([hidden]) {
        display: none;
    }
    :host {
        display: block;
        width: 100%;
    }
    .button {       
        padding: 10px 24px;
        border-radius: 6px;
        justify-content: center;
        width:100%;
        transition: all 0.25s;
        color: var(--color-navy-1);
        font: var(--font-button);
        background-color: white;
        border: 1px solid var(--color-gray-4, #94979D);
        color: black;
        cursor: pointer;
    }
    .button:disabled {
        opacity: 1;
        background-color: var(--primary-color);
        border: none;
        color: gray;
        transition: none;
    }
    .button:hover {
        border: 1px solid black;
        background-color: white;
        box-shadow: 2px 2px 2px lightgray;
    }
    .button.red:hover {
        background-color: crimson;
        color: white;
        border: 1px solid crimson;
    }
    .button:hover:disabled {
        background-color: var(--primary-color);
        border: 1px solid lightgray;
        box-shadow: none;
    }
    .button:active {
        background-color: white;
        box-shadow: none;
    }
    .button.red:active {
        background-color: crimson;
        color: white;
        border: 1px solid crimson;
    }
  `;
}
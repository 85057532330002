import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { AuthService } from '../services/auth.service';
import { container } from 'tsyringe';

@customElement('se-oauth-response')
export class SeOAuthResponseElement extends LitElement {


    @state() private _hasSelectedRows = false;

    private tempId: any;
    private token: any;
    private type: any;

    constructor() {
        super();
    }



    connectedCallback() {
        super.connectedCallback();

    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        var url = new URL(window.location.href)
        url.searchParams.get("tempId")
        if (url.searchParams.get("tempId")) {
            this.tempId = decodeURIComponent(url.searchParams.get("tempId"));
            this.token = decodeURIComponent(url.searchParams.get("token"));
            this.type = decodeURIComponent(url.searchParams.get("type"));
        }
        else {
            return commands.prevent();
        }

    }




    firstUpdated() {
        window.opener.postMessage(
            {
                message:"oauthResponse",
                tempId: this.tempId,
                token: this.token,
                type: this.type,
            }, '*');
    }



    render() {
        return html`            
            <div class="body">

            </div>
        `;
    }

    static styles = css``;


}
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { css, html, LitElement } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { container } from 'tsyringe';
import Styles from '../../../assets/styles';
import { PipeViewModel } from '../models/pipe-view-model';
import { PipeService } from '../services/pipe.service';
import { ToasterService } from '../services/toaster.service';
import './change-password.element';
import './components/primary-button.element';
import './components/secondary-button.element';
import './forgot-password.element';
import './login.element';
import './reset-password.element';
import './user-registration.element';
import pencil from '../../../assets/pencil.svg';
import checkmark from '../../../assets/modified_checkmark.svg';
import cancel from '../../../assets/cancel.svg';
import { InputEditorElement } from './editors/input-editor.element';
import { AuthService } from '../services/auth.service';
import { SePrimaryButton } from './components/primary-button.element';




@customElement('se-pipe-details')
export class PipeDetailsElement extends LitElement {
    private _authService: AuthService;

    private _pipeService: PipeService;
    private _toasterService: ToasterService;
    private _pipeId?: number;
    private _selectedDestination?: string;
    @state() private _currentPipe: PipeViewModel;
    @state() private _showPopup: boolean = false;
    @state() private _selectedTab: string = "history";
    @state() private _isEditingName: boolean = false;
    @state() private _newPipeName: string = '';
   
    @query('#nameEditor') private input: InputEditorElement;
    @query('#startRunBtn') private startRunBtn: SePrimaryButton;
    @query('#deleteButton') private deleteButton: SePrimaryButton;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._pipeService = container.resolve(PipeService);
        
    }


    connectedCallback() {
        super.connectedCallback();
        
        this.loadData();
    }
    disconnectedCallback() {
       
        super.disconnectedCallback();
    }


    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isLoggedIn) {
            return commands.redirect('/login');
        }        
        if (location.params.pipeId) {
            this._pipeId = parseInt(location.params.pipeId.valueOf() as string);
        }

    }


    public onAfterEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): void {
        this._selectedTab = location.params.tab ? (location.params.tab.toString()) : "history";
        
    }

    private async loadData() {
        
        // get pipe info and its sources
        var currentPipe = await this._pipeService.api.getPipeAsync(this._pipeId);
       
        if (currentPipe.isOk) {
            this._currentPipe = currentPipe.value
        }
        else {
            this._toasterService.showUnexpectedError(currentPipe.err.message);
        }
    }



    //navigate safely in a json 
    private safeAccess(obj, path) {
        return path.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, obj)
    }


    //delete pipe
    private async deletePipe() {
        this.deleteButton.loading = true;
        //delete
        var result = await this._pipeService.api.deletePipeAsync(this._pipeId);
        if (result.isOk) {
            Router.go('/agents');
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
        this.deleteButton.loading = false;
    }


    private async startRun() {
        this.startRunBtn.disabled = true
        var runName = this._currentPipe.name + " - " + new Date().toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric' }).replace(',', '')

        var run = {
            configId: this._pipeId,
            parallelism: 1,
            serverGroupId: undefined,
            serverOrganizationId: 0,
            parameters: "{}",
            isSaveAsTask: false,
            taskName: runName,
            isExclusive: false,
            isWaitOnFailure: false,
        };
        var result = await this._pipeService.api.createPipeRun(run);
        if (!result.isOk) {
            this._toasterService.showNetworkError(result.err);
        }
        else {
            this._toasterService.showSuccess("Starting agent");
            this.loadData();
        }
        this.startRunBtn.disabled = false

    }

    private editName() {
        // Set the new pipe name to the current pipe name
        this._newPipeName = this._currentPipe.name;
        // Set isEditingName to true to show the input field
        this._isEditingName = true;
    }

    private async updateChangedName() {
        // Update the pipe name in the database
        const newValue = this.input.liveValue;
        this._newPipeName = newValue.trim();
        var result = await this._pipeService.api.updatePipeNameAsync(this._pipeId, this._newPipeName);
        if (result.isOk) {
            // If the update was successful, update the current pipe name and set isEditingName to false
            this._currentPipe.name = this._newPipeName;
            this._isEditingName = false;
        }
        else {
            // If the update failed, show an error message
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }


    private tabConfig() {
        this._selectedTab = "config"
        history.pushState(null, null, '/agents/' + this._pipeId + '/' + this._selectedTab);
    }
    private tabStreams() {
        this._selectedTab = "streams"
        history.pushState(null, null, '/agents/' + this._pipeId + '/' + this._selectedTab);
    }
    private tabHistory() {
        this._selectedTab = "history"
        history.pushState(null, null, '/agents/' + this._pipeId + '/' + this._selectedTab);
    }
    private tabSchedule() {
        this._selectedTab = "schedule"
        history.pushState(null, null, '/agents/' + this._pipeId + '/' + this._selectedTab);
    }
    private tabSettings() {
        this._selectedTab = "settings"
        history.pushState(null, null, '/agents/' + this._pipeId + '/' + this._selectedTab);
    }

    render() {

        if (this._currentPipe == null) {
            return html `<div class="body"></div>`
        }
       
        return html`
            <div class="body">

                <div class="container-header gradient">

                    <div class="left-header top-header">
                        <div class="pipe-name-header">
                            ${this._isEditingName ?
                                html`
                                <se-input-editor style="min-width: max-content;" id="nameEditor" type="text" .value="${this._newPipeName}"></se-input-editor>
                                <img @click="${this.updateChangedName}" class="icon-button" src=${checkmark}>
                                <img @click="${() => this._isEditingName = false}" class="icon-button" src=${cancel}>
                                ` : html`
                                <div class="h1" style="${(this._currentPipe.name?.length > 50) ? "text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 20vw;display: inline-block;" : ""}" title="${this._currentPipe.name}">${this._currentPipe.name}</div>
                                <img @click="${this.editName}" class="icon-button" src=${pencil}>`
                            }
                        </div>
                        <div class="pipe-details-connections">
                            <div class="source-container">
                                <div class="header-image-container">
                                    <img class="header-image" src="${this._currentPipe.srcIcon}" />
                                </div>
                                <div class="market-item-title">
                                    <div class="h3">${this._currentPipe.source}</div>
                                </div>
                            </div>
                            ${false ? html`
                            <div class="pipe-arrow"></div>
                            <div class="source-container">
                                <div class="header-image-container">
                                    <img class="header-image" src="${this._currentPipe.destIcon}" />
                                </div>
                                <div class="market-item-title">
                                    <div class="h3">${this._currentPipe.destination}</div>
                                </div>
                            </div>

                            ` : html``}
                        </div>
                    </div>
                    <div class="right-header">
                        <se-primary-button id="startRunBtn" class="inputEditor" .customStyle=${{}} @click="${this.startRun}" text="Run Now"></se-primary-button>

                    </div>
                        
                </div>
                <div class="tabs-header">
                    <div @click=${this.tabHistory} class="tabs ${classMap({ "active-tab": this._selectedTab == "history" })}">History</div>
                    <div @click=${this.tabSchedule} class="tabs ${classMap({ "active-tab": this._selectedTab == "schedule" })}">Schedule</div>
                    <div @click=${this.tabConfig} class="tabs ${classMap({ "active-tab": this._selectedTab == "config" })}">Configuration</div>
                    <div @click=${this.tabSettings} class="tabs ${classMap({ "active-tab": this._selectedTab == "settings" })}">Settings</div>
                </div>

                <div class="container-content ${classMap({ "config-tab": this._selectedTab != "history" })}">

                    ${(this._selectedTab == "history") ?
                        html`<se-details-history-tab .pipeId=${String(this._pipeId)}></se-details-history-tab>`: ``
                    }
                    ${(this._selectedTab == "streams") ?
                        html`<se-details-streams-tab .pipeId=${String(this._pipeId)}></se-details-streams-tab>`: ``
                    }
                    ${(this._selectedTab == "schedule") ?
                        html`<se-details-pipe-schedule-tab .pipeId=${String(this._pipeId)}></se-details-pipe-schedule-tab>`: ``
                    }
                    ${(this._selectedTab == "config") ?
                        html`<se-details-pipe-params-tab .pipeId=${String(this._pipeId)}></se-details-pipe-params-tab>` : ``
                    }
                    ${(this._selectedTab == "settings") ?
                    html`
                        <div class="form" style="margin: 10px;">
                            <div class="action-buttons" style="width: 200px;">
                                <se-primary-button .red="${true}" @click="${() => this._showPopup = true}" text="Delete Agent"></se-primary-button>
                                ${this._showPopup ? html`
                                    <div id="deleteConfirmPopup">
                                      <p>Are you sure you want to delete?</p>
                                      <div class="button-container">
                                          <se-primary-button id="deleteButton" size="small" .red="${true}" @click="${this.deletePipe}" text="Delete"></se-primary-button>
                                          <se-primary-button size="small" @click="${() => this._showPopup = false}" text = "Cancel"></se-primary-button>
                                      </div>
                                    </div>
                                  ` : ''}
                            </div>
                        </div>
                    `: ``
                    }



 
                </div>


            </div>
        `;
    }
    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        background:none;
        color:#1A1F4B
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .body {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
    }
    .time-section{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .action-button{
        margin-bottom: -75px;
        display: flex;
        justify-content: space-between;
    }
    .config-tab{
        width: 100%;
    }
    .action-button .inputEditor{
         width:100px;
    }
    .grid {
        flex:1;        
    }   
    .checkbox {
        width: 1rem;
        height: 1rem;
    }
    input[type="checkbox"]:checked {
        background-color: var(--color-secondary);
    }
    .top-header {
        flex-direction: column;
        align-items: flex-start;
    }
    .pipe-details-connections {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
    }
    .pipe-name-header {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
    }
    .icon-button {
        width: 20px;
        height: 20px;
        cursor: pointer;
        fill: var(--color-primary);
    }
      `]
    };
}

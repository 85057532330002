import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { LitElement, html, css, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import './components/primary-button.element';
import { BaseEditor } from './editors/base-editor';
import { AppConfigApi } from '../services/app-config.api';
import { container } from 'tsyringe';
import { AppConfigService } from '../services/app-config.service';
import { InputEditorElement } from './editors/input-editor.element';
import  { TextAreaEditorElement } from'./editors/textarea-editor.element';
import { CheckboxEditorElement } from './editors/checkbox-editor.element';
import { SupportService } from '../services/support.service';
import { ToasterService } from '../services/toaster.service';
import Styles from '../../../assets/styles';
import { SePrimaryButton } from './components/primary-button.element';
import { SelectEditorElement } from './editors/select-editor.element';

@customElement('se-contact-us')
export class ContactUsElement extends LitElement {

    private _appConfigService: AppConfigService;
    private _supportService: SupportService;
    private _toasterService: ToasterService;
    @query('#sendButton') private _sendButton: SePrimaryButton;
    @query('#email') private _email: InputEditorElement;
    @query('#subject') private _subject: InputEditorElement;
    @query('#category') private _category: SelectEditorElement;
    @query('#content') private _content: TextAreaEditorElement;

    constructor() {
        super();

        this._appConfigService = container.resolve(AppConfigService);
        this._supportService = container.resolve(SupportService);
        this._toasterService = container.resolve(ToasterService);
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }



    async sendAsync(ev) {
        this._sendButton.loading = true;
        ev.preventDefault();

        let contactUsForm = {
            email: this._email.liveValue,
            subject: this._subject.liveValue,
            category: this._category.liveName,
            content: this._content.liveValue,
        }
        const res = await this._supportService.api.sendContactUs(contactUsForm);

        if (res.isOk) {
            this._toasterService.showSuccess("Your inquiry has been sent.");
        }
        else {
            this._toasterService.showNetworkError(res.err);
        }


        this._sendButton.loading = false;
    }

    private cancel() {
        history.back();
    };
    render() {
        return html`
            <div class="body">
                <div class="container-header gradient">
                    <div class="left-header top-header">
                        <div class="h1">Contact Us</div>
                    </div>
                </div>
                <div class="container-content">
                    <div class="wizard">
                        <div class="form">

                            <div>
                                <label class="h3">Email</label>
                                <se-input-editor class="inputEditor" .width=${'100'} .customStyle=${{ alignItems: 'unset' }} id="email" name="email" type="text" input-type="text" required .value=""></se-input-editor>
                            </div>
                            <div>
                                <label class="h3">Subject</label>
                                <se-input-editor class="inputEditor" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} id="subject" name="subject" type="subject" input-type="text" required .value=""></se-input-editor>
                            </div>
                            <div>
                                <label class="h3">Category</label>
                                <se-select-editor class="inputEditor" label=" " required id="category" name="category" type="text" .value="" .options=${[{ "id": 1, name: "Billing" }, { "id": 2, name: "Account" }, { "id": 3, name: "Report a Bug" }]} width="100%"></se-select-editor>
                            </div>
                            <div>
                                <se-textarea-editor class="inputEditor" .width=${'100%'} .customStyle=${{ alignItems: 'unset' }} id="content" name="content" rows="8" type="content" input-type="text" required value=""></se-input-editor>
                            </div>
                        </div>
                        <div class="action-buttons">
                            <div style=""><se-primary-button id="sendButton" .customStyle=${{ 'padding': '10px 40px' }} @click="${this.sendAsync}"  text="Send"></se-primary-button></div>
                        </div>

                    </div>
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
                :host {
                    display: block;
                    box-sizing: border-box;        
                    font: var(--font);
                    height: unset;    
                    background:none;
                }
                :host *, :host *:before, :host *:after {
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                }
                :host *, :host *:before, :host *:after {
                    outline:0px dashed red
                }
    
                .action-buttons{
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    justify-content: flex-end;
                }
                .inputEditor{
                    width: 400px;
                }
                .wizard{
                    align-self: center;
                    gap: 15px;
                }
                .container-content{
                    padding: 40px 55px;
                }

          `]
    };


}

import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { container } from 'tsyringe';
import sheet from '../../../assets/google-sheets.svg';
import Styles from '../../../assets/styles';
import { DestinationService } from '../services/destination.service';
import { ToasterService } from '../services/toaster.service';
import './change-password.element';
import './components/primary-button.element';
import './components/secondary-button.element';
import './forgot-password.element';
//import './home.element';
import './login.element';
import './reset-password.element';
import './user-registration.element';
import { AuthService } from '../services/auth.service';


@customElement('se-destination-details')
export class DestinationDetailsElement extends LitElement {
    private _authService: AuthService;

    private _destinationService: DestinationService;
    private _toasterService: ToasterService; 
    private _destinationId?: number;
    @state() private _destinationName?: string;
    @state() private _destinationIcon?: string;

    @state() private _selectedTab: string = "history";    
    @state() private _showPopup: boolean = false;

   
    constructor() {
        super();
        this._toasterService = container.resolve(ToasterService);
        this._destinationService = container.resolve(DestinationService);
        this._authService = container.resolve(AuthService);
    }

    connectedCallback() {
        super.connectedCallback();
        this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isLoggedIn) {
            return commands.redirect('/login');
        }        
        this.pageChange(location)
        if (location.params.destinationId) {
            this._destinationId = parseInt(location.params.destinationId.valueOf() as string);
        }
       
    }

    pageChange(location: RouterLocation) {

        const message = {
            type: 'pageChange',
            payload: { location: location.route },
        };
        window.postMessage(message, '*');
    }


    public onAfterEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): void {
        this._selectedTab = location.params.tab ? (location.params.tab.toString()) : "history";
      
    }

    private async loadData() {


        // get destinations info
        const currentDestination = await this._destinationService.api.getDestinationAsync(this._destinationId);
        if (currentDestination.isOk) {
            this.loadDestinationsConfigs(currentDestination.value.destinationType)

        }
        else {
            this._toasterService.showUnexpectedError(currentDestination.err.message);
        }

    }

    //get destinations config json file
    private async loadDestinationsConfigs(destinationType) {

        const result = await this._destinationService.api.getDestinationsConfigsAsync();
        if (result.isOk) {
            var destinationsConfigs = JSON.parse(result?.value || "[]");

            var destinationsConfig = destinationsConfigs.filter(config => config.name == destinationType)
            this._destinationName = destinationsConfig?.[0].name || "";
            this._destinationIcon = destinationsConfig?.[0].icon || "";

        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }


    }

    //delete function
    private async deleteDestination() {
        var result = await this._destinationService.api.deleteDestinationAsync(this._destinationId);
        if (result.isOk) {
            Router.go('/destinations');
        }
        else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

 
    private tabAgents() {
        this._selectedTab = "agents" 
        history.pushState(null, null, '/destinations/' + this._destinationId + '/' + this._selectedTab);
    }
    private tabConfig() {
        this._selectedTab = "config"
        history.pushState(null, null, '/destinations/' + this._destinationId + '/' + this._selectedTab);
    }
    private tabHistory() {
        this._selectedTab = "history"
        history.pushState(null, null, '/destinations/' + this._destinationId + '/' + this._selectedTab);
    }
    private tabSettings() {
        this._selectedTab = "settings"
        history.pushState(null, null, '/destinations/' + this._destinationId + '/' + this._selectedTab);
    }
    render() {
        return html`
            <div class="body">

                <div class="container-header gradient">

                    <div class="left-header top-header">
                        <div class="source-container">
                            <div class="header-image-container">
                                <img class="header-image" src="${this._destinationIcon}" />
                            </div>
                            <div class="market-item-title">
                                <div class="h2">${this._destinationName}</div>
                                <div class="market-item-label-square">
                                    <span>Destination</span>
                                </div>
                                    

                            </div>
                        </div>
                    </div>
                    <div class="right-header">
                        <se-primary-button class="inputEditor" .customStyle=${{}} text="Create Agent"></se-primary-button>
                    </div>
                    
                        
                </div>
                <div class="tabs-header">
                    <div @click=${this.tabHistory} class="tabs ${classMap({ "active-tab": this._selectedTab == "history" })}">History</div>
                    <div @click=${this.tabAgents} class="tabs ${classMap({ "active-tab": this._selectedTab == "agents" })}">Agents</div>
                    <div @click=${this.tabConfig} class="tabs ${classMap({ "active-tab": this._selectedTab == "config" })}">Configuration</div>
                    <div @click=${this.tabSettings} class="tabs ${classMap({ "active-tab": this._selectedTab == "settings" })}">Settings</div>
                </div>
                <div class="container-content">


                    ${(this._selectedTab == "agents") ?
                        html`
                           <se-details-pipes-tab .destinationId=${String(this._destinationId)}></se-details-pipes-tab>
                        `: ``
                    }
                    ${(this._selectedTab == "history") ?
                        html`<se-details-history-tab .destinationId=${String(this._destinationId)} .showPipeName=${true}></se-details-history-tab>` : ``
                    }
                    ${(this._selectedTab == "config") ?
                        html`<se-details-destination-config-tab .destinationId=${String(this._destinationId)}></se-details-destination-config-tab>` : ``
                    }
                    ${(this._selectedTab == "settings") ?
                    html`
                        <div class="form" style="margin: 10px;">
                            <div class="action-buttons" style="width: 230px;">
                                <se-primary-button .customStyle=${{ backgroundColor: '#EB5757', borderColor: '#EB5757' }} @click="${() => this._showPopup = true}"  text="Delete Destination"></se-primary-button>
                                ${this._showPopup ? html`
                                    <div id="deleteConfirmPopup">
                                      <p>Are you sure you want to delete?</p>
                                      <div class="button-container">
                                          <se-primary-button size="small" .customStyle=${{ backgroundColor: '#EB5757', borderColor: '#EB5757' }} @click="${this.deleteDestination}" text="Delete"></se-primary-button>
                                          <se-primary-button size="small" @click="${() => this._showPopup = false}" text = "Cancel"></se-primary-button>
                                      </div>
                                    </div>
                                  ` : ''}
                            </div>
                        </div>
                    `: ``
                    }



 
                    

                </div>

            </div>
        `;
    }

    static get styles() {
        return [
            Styles,
            css`
    :host {
        display: block;
        box-sizing: border-box;        
        font: var(--font);
        height: unset;    
        background:none;
        color:#1A1F4B
    }
    :host *, :host *:before, :host *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .body {
        height: 100%;
    }
    

      `]
    };
}

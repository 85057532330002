export enum RunStatus {    
    running = 1,
    exporting = 2,
    starting = 3,
    queuing = 4,
    stopping = 5,
    failure = 6,
    failed = 7,
    stopped = 8,
    completed = 9,
    success = 10,
    scheduled = 11
}

export function isRunningOnServer(status: RunStatus) {
    return status === RunStatus.running || status === RunStatus.exporting || status === RunStatus.stopping;
}

export function isRunningOnServerOrStarting(status: RunStatus) {
    return status === RunStatus.running || status === RunStatus.exporting || status === RunStatus.stopping;
}

export function isQueuingOrStarting(status: RunStatus) {
    return status === RunStatus.queuing || status === RunStatus.starting;
}
import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { ModalDialogService, ModelDialog } from "../../services/modal-editor.service";
import { container } from "tsyringe";

@customElement("se-confirm-dialog")
export class ConfirmDialogElement extends LitElement implements ModelDialog {
    private _modalService: ModalDialogService;
    @state() private _title: string;
    @state() private _body: string;
    @state() private _saveCaption: string;
    @state() private _cancelCaption: string;

    @query("#editorForm") private _editorForm: HTMLFormElement;
    @query("#editorContainer") private _editorContainer: HTMLDivElement;

    @property() name: string;

    constructor() {
        super();

        this._modalService = container.resolve(ModalDialogService);
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    showDialog(options: { title: string; body: string; saveCaption?: string; cancelCaption?: string }) {
        this._title = options.title;
        this._body = options.body;
        this._saveCaption = options.saveCaption;
        this._cancelCaption = options.cancelCaption;
    }

    private cancel(ev) {
        ev.preventDefault();
        this._modalService.close(false);
    }

    private async saveAsync(ev) {
        ev.preventDefault();
        this._modalService.close(true);
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="header">
                    <div style="font-weight: bold">${this._title}</div>
                    <div class="icon" @click=${this.cancel}><fa-icon scale="1.2" fa-class="fas fa-times-circle"></fa-icon></div>
                </div>
                <div class="body">
                    <div class="text-container" id="editorContainer">${unsafeHTML(this._body)}</div>
                    <div class="savePanel">
                        <se-primary-button @click="${this.saveAsync}" text=${this._saveCaption ?? "Save"}></se-primary-button>
                        <se-secondary-button @click="${this.cancel}" text="${this._cancelCaption ?? "Cancel"}"></se-secondary-button>
                    </div>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
        }
        .editor {
            display: flex;
            flex-direction: column;
            width: fit-content;
            min-height: 0;
            margin: auto;
            margin-top: 20vh;
            box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
            font: var(--font) var(--color-primary);
            width: 400px;
            border-radius: 8px;
            padding: 20px;
            background-color: var(--color-light);
            gap: 10px;
        }
        .body {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;            
            min-height: 0;
            padding: 0px;
            border-radius: 0px 0px 5px 5px;
            width: 100%;
        }
        .header {
            background-color: var(--color-light);
            padding: 0px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: space-between;
            align-items: center;
            color: black;
            border-radius: 5px 5px 0px 0px;
        }
        .icon {
            color: gray;
        }
        .icon:hover {
            color: darkred;
        }
        .text-container {
            height: 100%;
            min-height: 60px;
            overflow: auto;
            display: flex;
            align-items: center;
        }
        .savePanel {
            display: flex;
            gap: 10px;
            justify-content: center;
            margin-top: 10px;
        }
    `;
}
